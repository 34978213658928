import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import {
    get_carga_crep,
    update_carga_crep,
    save_carga_crep,
    anular_carga_crep,
    carga_crep_masivo,
} from './carga_crepThunk';
import {
    get_movimiento_crep,
    update_movimiento_crep,
    save_movimiento_crep,
    anular_movimiento_crep,
} from './movimiento_crepThunk';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import { CARGA_REGISTRO_EXITO, CARGA_REGISTRO_ERROR } from '../../constants/constants';


const CargaCREPsPage = () => {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [loading, setLoading] = useState(false);
    const [header, setHeader] = useState(null);
    const [movimientos, setMovimientos] = useState([]);
    const [isSaved, setIsSaved] = useState(false); // Estado para desactivar el botón

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setFileName(selectedFile ? selectedFile.name : "");
    };

    const handleReset = () => {
        setFile(null);
        setFileName("");
        setHeader(null);
        setMovimientos([]);
        setIsSaved(false);
        M.toast({ html: 'Formulario reseteado.', classes: 'blue darken-1' });
    };
    
    const parseCREPFile = async (fileContent, cargaCREPId) => {
        const lines = fileContent.split('\n');
        let newMovimientos = [];
        let newHeader = null;

        lines.forEach((line) => {
            if (line.startsWith('CC')) {
                newHeader = {
                    numero_cuenta: line.substring(2, 13).trim(),
                    tipo_cuenta: line.substring(13, 14).trim(),
                    fecha_proceso: line.substring(14, 22).trim(),
                    cantidad_registros: parseInt(line.substring(22, 31).trim(), 10),
                    importe_total: parseFloat(line.substring(31, 46).trim()) / 100,
                    nombre_archivo: fileName, // Guardar nombre del archivo
                };
            } else if (line.startsWith('DD')) {
                const movimiento = {
                    cargaCREPId,
                    numero_cuenta: line.substring(2, 13).trim(),
                    codigo_depositante: line.substring(13, 27).trim(),
                    dato_adicional_depositante: line.substring(27, 57).trim(),
                    //fecha_pago: line.substring(57, 65).trim(),
                    fecha_pago: `${line.slice(57, 61)}-${line.slice(61, 63)}-${line.slice(63, 65)}`,
                    //fecha_vencimiento: line.substring(65, 73).trim(),
                    fecha_vencimiento: `${line.slice(65, 69)}-${line.slice(69, 71)}-${line.slice(71, 73)}`,
                    importe_cobranza: parseFloat(line.substring(73, 88).trim()) / 100,
                    importe_mora: parseFloat(line.substring(88, 103).trim()) / 100,
                    importe_pago_total: parseFloat(line.substring(103, 118).trim()) / 100,
                    sucursal: line.substring(118, 121).trim(),
                    agencia: line.substring(121, 124).trim(),
                    numero_operacion: line.substring(124, 130).trim(),
                    documento_pago: line.substring(205, 217).trim(),
                    //terminal: line.substring(150, 154).trim(),
                    canal: line.substring(156, 159).trim(),
                    numero_cheque: line.substring(156, 166).trim(),
                    numero_operacion_bd: line.substring(217, 225).trim(),
                    numero_operacion_canal: line.substring(226, 234).trim(),
                    referencia: line.substring(130, 153).trim(),
                    //hora_operacion: line.substring(168, 170).trim()+':'+line.substring(170, 172).trim()+':'+line.substring(172, 174).trim(),
                    hora_operacion: `${line.slice(168, 170)}:${line.slice(170, 172)}:${line.slice(172, 174)}`,

                };
                newMovimientos.push(movimiento);
            }
        });
        setHeader(newHeader);
        setMovimientos(newMovimientos);
    };
    const handleRetornar = () => {
        navigate(-1);
    };
    const handleUpload = async () => {
        if (!file) {
            M.toast({ html: 'Por favor, selecciona un archivo CREP.', classes: 'red darken-1' });
            return;
        }

        setLoading(true);
        try {
            const fileContent = await file.text();
            await parseCREPFile(fileContent);
        } catch (error) {
            console.error('Error al procesar el archivo CREP:', error);
            M.toast({ html: 'Hubo un error al procesar el archivo.', classes: 'red darken-1' });
        } finally {
            setLoading(false);
        }
    };

    const handleSaveOLD = async () => {
        if (!header || movimientos.length === 0) {
            M.toast({ html: 'No hay datos procesados para guardar.', classes: 'orange darken-1' });
            return;
        }

        setLoading(true);
        try {
            const resultAction = await dispatch(save_carga_crep(header));
            if (save_carga_crep.fulfilled.match(resultAction)) {
                const cargaCREPId = resultAction.payload.id;
                await Promise.all(movimientos.map(mov => dispatch(save_movimiento_crep({ ...mov, cargaCREPId }))));
                M.toast({ html: 'Archivo CREP guardado correctamente.', classes: 'green darken-1' });
            } else {
                throw new Error('Error al guardar carga CREP');
            }

        } catch (error) {
            console.error('Error al guardar el archivo CREP:', error);
            M.toast({ html: 'Hubo un error al guardar los datos.', classes: 'red darken-1' });
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        if (!header || movimientos.length === 0) {
            M.toast({ html: 'No hay datos procesados para guardar.', classes: 'orange darken-1' });
            return;
        }

        setLoading(true);
        try {
            // Estructura de carga masiva
            const cargaData = {
                carga_crep: header,
                movimientos_crep: movimientos,
            };

            // Llamada a la API con la carga masiva
            const resultAction = await dispatch(carga_crep_masivo(cargaData));

            if (carga_crep_masivo.fulfilled.match(resultAction)) {
                const responseData = resultAction.payload;

                if (responseData.cargaCREPId) {
                    const cargaInfo = responseData.cargaCREPId.carga_crep;
                    const movimientosInfo = responseData.cargaCREPId.carga_movimiento_crep;

                    // Actualizar estado del header con los nuevos valores
                    setHeader({
                        numero_cuenta: cargaInfo.numero_cuenta,
                        tipo_cuenta: cargaInfo.tipo_cuenta,
                        fecha_proceso: cargaInfo.fecha_proceso,
                        cantidad_registros: cargaInfo.cantidad_registros,
                        importe_total: cargaInfo.importe_total,
                        estado_carga: cargaInfo.estado_carga,
                        mensaje_carga: cargaInfo.mensaje_carga,
                        fecha_carga: cargaInfo.fecha_carga,
                        fecha_hora_carga: cargaInfo.fecha_hora_carga,
                        registros_exitosos: cargaInfo.registros_exitosos,
                        registros_errados: cargaInfo.registros_errados
                    });

                    // Actualizar estado de movimientos con el estado de carga y mensaje
                    setMovimientos(movimientosInfo || []);

                    // **Desactivar botón después de la carga exitosa**
                    setIsSaved(true);

                    // Mostrar mensaje en pantalla
                    M.toast({ html: 'Archivo CREP procesado correctamente.', classes: 'green darken-1' });
                } else {
                    M.toast({ html: 'Error en la estructura de respuesta del backend.', classes: 'red darken-1' });
                }
            } else {
                throw new Error('Error al guardar carga CREP masiva');
            }
        } catch (error) {
            console.error('Error al guardar el archivo CREP:', error);
            M.toast({ html: 'Hubo un error al guardar los datos.', classes: 'red darken-1' });
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="carga-creps-page container">
            <div className="card">
                <div className="card-content">
                    <span className="card-title">Carga de Archivos CREP</span>

                    <div className="file-field input-field ">
                        <div className="btn blue">
                            <span>Seleccionar Archivo</span>
                            <input type="file" accept=".txt" onChange={handleFileChange} />
                        </div>
                        <div className="file-path-wrapper">
                            <input className="file-path validate" type="text" placeholder="Sube un archivo CREP" />
                        </div>
                    </div>
                    <button className="btn waves-effect waves-light blue" onClick={handleUpload} disabled={loading}>
                        {loading ? 'Procesando...' : 'Procesar Archivo'}
                    </button>

                    {header && (
                        <div className="header-container">
                            <h5>Datos de Cabecera</h5>
                            <p><strong>Número de Cuenta:</strong> {header.numero_cuenta}</p>
                            <p><strong>Tipo de Cuenta:</strong> {header.tipo_cuenta}</p>
                            <p><strong>Fecha de Proceso:</strong> {header.fecha_proceso}</p>
                            <p><strong>Total de Registros:</strong> {header.cantidad_registros}</p>
                            <p><strong>Importe Total:</strong> {header.importe_total}</p>
                            <p><strong>Estado de Carga:</strong> {header.estado_carga}</p>
                            <p><strong>Mensaje de Carga:</strong> {header.mensaje_carga}</p>
                            <p><strong>Registros Exitosos:</strong> {header.registros_exitosos}</p>
                            <p><strong>Registros Errados:</strong> {header.registros_errados}</p>
                        </div>
                    )}
                    {Array.isArray(movimientos) && movimientos.length > 0 && (
                        <div className="table-container">
                            <h5>Movimientos</h5>
                            <table className="striped responsive-table">
                                <thead>
                                    <tr>
                                        <th>Cuenta</th>
                                        <th>Depositante</th>
                                        <th>Importe Cobranza</th>
                                        <th>Importe Mora</th>
                                        <th>Importe Pago Total</th>
                                        <th>Fecha Pago</th>
                                        <th>Hora</th>
                                        <th>Vencimiento</th>
                                        <th>Referencia</th>
                                        <th>Suc.</th>
                                        <th>Age.</th>
                                        <th>Oper.</th>
                                        <th>Canal</th>
                                        <th>Oper.BD</th>
                                        <th>Oper.Canal</th>
                                        <th>Doc.Pago</th>
                                        <th>Estado Carga</th>
                                        <th>Mensaje Carga</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {movimientos.map((mov, index) => (
                                        <tr key={index}>
                                            <td>{mov.numero_cuenta}</td>
                                            <td>{mov.codigo_depositante}</td>
                                            <td>{mov.importe_cobranza}</td>
                                            <td>{mov.importe_mora}</td>
                                            <td>{mov.importe_pago_total}</td>
                                            <td>{mov.fecha_pago}</td>
                                            <td>{mov.hora_operacion}</td>
                                            <td>{mov.fecha_vencimiento}</td>
                                            <td>{mov.referencia}</td>
                                            <td>{mov.sucursal}</td>
                                            <td>{mov.agencia}</td>
                                            <td>{mov.numero_operacion}</td>
                                            <td>{mov.canal}</td>
                                            <td>{mov.numero_operacion_bd}</td>
                                            <td>{mov.numero_operacion_canal}</td>
                                            <td>{mov.documento_pago}</td>
                                            <td>{mov.estado_carga}</td>
                                            <td>{mov.mensaje_carga}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    
                    <button
                        className="btn waves-effect waves-light green"
                        onClick={handleSave}
                        disabled={loading || !header || movimientos.length === 0 || isSaved}
                    >
                        {loading ? 'Guardando...' : 'Guardar Datos'}
                    </button>
                    <button className="btn waves-effect waves-light red" onClick={handleReset}>Nueva Carga</button>
                </div>
            </div>
        </div>
    );
};

export default CargaCREPsPage;
