import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './MovimientosCREPPage.css';
import { get_movimientos_crep } from './movimiento_crepThunk';
import { conciliar_crep, conciliar_crep_recibos } from './proceso_conciliacionThunk';
import { get_cuentas_banco } from './cuenta_bancoThunk';
import { format } from 'date-fns';
import { formatDate, formatCurrency, formatPeriod, formatDate02, formatearPeriodo_MesAnio01, formatearImporte01 } from '../../utils/formateo';
import ExcelJS from 'exceljs';

const MovimientosCREP = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const usuario = useSelector((state) => state.auth.usuario);
    const cuentas = useSelector((state) => state.cuenta_banco.cuentas_banco);
    const movimientos = useSelector((state) => state.movimiento_crep.movimientos_crep);
    const status = useSelector((state) => state.movimiento_crep.status);
    const edificioSeleccionado = useSelector((state) => state.edificio.edificio);
    const buildingId = edificioSeleccionado.id; // ID del edificio desde la URL
    const [filtro, setFiltro] = useState('');

    const [cuentaSeleccionada, setCuentaSeleccionada] = useState('');
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);
    const [descripcion, setDescripcion] = useState('');
    const [tipoMovimiento, setTipoMovimiento] = useState('TODOS');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(get_cuentas_banco(buildingId));
    }, [dispatch, buildingId]);

    useEffect(() => {
        if (cuentas.length > 0) {
            setCuentaSeleccionada(cuentas[0].id);
        }
    }, [cuentas]);

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [cuentaSeleccionada, tipoMovimiento]);

    const handleExportExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Movimientos CREP');

        worksheet.columns = [
            { header: 'Cuenta', key: 'numero_cuenta', width: 20 },
            { header: 'Depositante', key: 'codigo_depositante', width: 20 },
            { header: 'Importe Cobranza', key: 'importe_cobranza', width: 20 },
            { header: 'Importe Mora', key: 'importe_mora', width: 20 },
            { header: 'Importe Pago Total', key: 'importe_pago_total', width: 20 },
            { header: 'Fecha Pago', key: 'fecha_pago', width: 15 },
            { header: 'Hora', key: 'hora_operacion', width: 10 },
            { header: 'Vencimiento', key: 'fecha_vencimiento', width: 15 },
            { header: 'Referencia', key: 'referencia', width: 20 },
            { header: 'Sucursal', key: 'sucursal', width: 10 },
            { header: 'Agencia', key: 'agencia', width: 10 },
            { header: 'Operación', key: 'numero_operacion', width: 20 },
            { header: 'Canal', key: 'canal', width: 15 },
            { header: 'Oper.BD', key: 'numero_operacion_bd', width: 20 },
            { header: 'Oper.Canal', key: 'numero_operacion_canal', width: 20 },
            { header: 'Doc.Pago', key: 'documento_pago', width: 20 },
            { header: 'Conc.Estado', key: 'conciliacion_estado', width: 20 },
            { header: 'Conc.Concepto', key: 'conciliacion_concepto', width: 20 },
            { header: 'Conc.TipoDoc', key: 'conciliacion_tipo_documento', width: 20 },
            { header: 'Conc.Titular', key: 'conciliacion_titular', width: 20 },
            { header: 'Conc.IdDoc', key: 'conciliacion_id_documento', width: 20 },
            { header: 'Carga', key: 'fecha_hora_carga', width: 20 },
            { header: 'Id Carga', key: 'cargaCREPId', width: 20 },
        ];

        movimientosFiltrados.forEach((mov) => {
            worksheet.addRow({
                ...mov,
                conciliacion_concepto: mov.conciliacion_concepto || '-',
                conciliacion_estado: mov.conciliacion_estado || '-',
                conciliacion_tipo_documento: mov.conciliacion_tipo_documento || '-',
                conciliacion_titular: mov.conciliacion_titular || '-',
                conciliacion_id_documento: mov.conciliacion_id_documento || '-',
                importe_cobranza: mov.importe_cobranza || 0,
                importe_mora: mov.importe_mora || 0,
                importe_pago_total: mov.importe_pago_total || 0,
            });
        });

        worksheet.getRow(1).font = { bold: true, color: { argb: 'FFFFFF' } };
        worksheet.getRow(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '007bff' },
        };

        worksheet.eachRow((row) => {
            row.eachCell((cell) => {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `MovimientosCREP_${format(new Date(), 'yyyyMMdd_HHmmss')}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handleConciliarCREP = async () => {
        if (!usuario) {
            M.toast({ html: 'Usuario no identificado.', classes: 'red darken-1' });
            return;
        }

        setLoading(true);
        try {
            const resultAction = await dispatch(conciliar_crep({ usuario: usuario.codigo_usuario, tipo_conciliacion: "COBRANZAS_CREP" }));
            if (conciliar_crep.fulfilled.match(resultAction)) {
                const responseData = resultAction.payload;
                M.toast({ html: `Conciliación completada: ${responseData.registros_conciliados} conciliados, ${responseData.registros_no_conciliados} no conciliados.`, classes: 'green darken-1' });
            } else {
                throw new Error('Error al conciliar CREP');
            }
        } catch (error) {
            console.error('Error en la conciliación:', error);
            M.toast({ html: 'Hubo un error al realizar la conciliación.', classes: 'red darken-1' });
        } finally {
            setLoading(false);
        }
    };
    const handleConciliarCREP_recibos = async () => {
        if (!usuario) {
            M.toast({ html: 'Usuario no identificado.', classes: 'red darken-1' });
            return;
        }

        setLoading(true);
        try {
            const resultAction = await dispatch(conciliar_crep_recibos({ usuario: usuario.codigo_usuario, tipo_conciliacion: "COBRANZAS_CREP" }));
            if (conciliar_crep_recibos.fulfilled.match(resultAction)) {
                const responseData = resultAction.payload;
                M.toast({ html: `Conciliación completada: ${responseData.registros_conciliados} conciliados, ${responseData.registros_no_conciliados} no conciliados.`, classes: 'green darken-1' });
            } else {
                throw new Error('Error al conciliar CREP');
            }
        } catch (error) {
            console.error('Error en la conciliación:', error);
            M.toast({ html: 'Hubo un error al realizar la conciliación.', classes: 'red darken-1' });
        } finally {
            setLoading(false);
        }
    };
    const handleBuscar = () => {

        const formattedFechaInicio = fechaInicio ? format(fechaInicio, 'dd-MM-yyyy') : null;
        const formattedFechaFin = fechaFin ? format(fechaFin, 'dd-MM-yyyy') : null;
        console.log(" cuentaSeleccionada " + JSON.stringify(cuentaSeleccionada))

        dispatch(get_movimientos_crep({
            buildingId: buildingId,
            cuenta_bancoId: cuentaSeleccionada,
            fecha_inicio: formattedFechaInicio,
            fecha_fin: formattedFechaFin,
            descripcion,
            tipo_movimiento: tipoMovimiento,
        })
        );
    };
    const handleFilterChange = (e) => {
        setFiltro(e.target.value);
    };
    const movimientosFiltrados = Array.isArray(movimientos) ? movimientos.filter((mov) => {
        const importe_cobranzaFormateado = formatearImporte01(mov.importe_cobranza) || '';
        const importe_moraFormateado = formatearImporte01(mov.importe_mora) || '';
        const importe_pago_totalFormateado = formatearImporte01(mov.importe_pago_total) || '';

        const filterLowerCase = filtro.toLowerCase();

        return (
            (mov.fecha_pago || '').toLowerCase().includes(filterLowerCase) ||
            (mov.hora_operacion || '').toLowerCase().includes(filterLowerCase) ||
            (mov.descripcion || '').toLowerCase().includes(filterLowerCase) ||
            (mov.fecha_vencimiento || '').toLowerCase().includes(filterLowerCase) ||
            (mov.referencia || '').toLowerCase().includes(filterLowerCase) ||
            (mov.sucursal || '').toLowerCase().includes(filterLowerCase) ||
            (mov.agencia || '').toLowerCase().includes(filterLowerCase) ||
            (mov.numero_operacion || '').toLowerCase().includes(filterLowerCase) ||
            (mov.canal || '').toLowerCase().includes(filterLowerCase) ||
            (mov.numero_operacion_bd || '').toLowerCase().includes(filterLowerCase) ||
            (mov.numero_operacion_canal || '').toLowerCase().includes(filterLowerCase) ||
            (mov.documento_pago || '').toLowerCase().includes(filterLowerCase) ||
            (mov.conciliacion_estado || '').toLowerCase().includes(filterLowerCase) ||
            (mov.conciliacion_concepto || '').toLowerCase().includes(filterLowerCase) ||
            (mov.conciliacion_tipo_documento || '').toLowerCase().includes(filterLowerCase) ||
            (mov.fecha_hora_carga || '').toLowerCase().includes(filterLowerCase) ||
            (importe_cobranzaFormateado.toLowerCase().includes(filterLowerCase)) ||
            (importe_moraFormateado.toLowerCase().includes(filterLowerCase)) ||
            (importe_pago_totalFormateado.toLowerCase().includes(filterLowerCase))

        );
    }) : [];

    console.log("Movimientos obtenidos:", movimientos);
    return (
        <div className="container">
            <div className="card">
                <div className="card-action">
                    <button className="btn orange" onClick={() => navigate('/carga-creps')}>Carga CREP</button>
                    <button className="btn green" onClick={handleExportExcel} style={{ marginLeft: "10px" }}>
                        <i className="material-icons left">file_download</i>Exportar Excel
                    </button>
                </div>

                <div className="card-content">
                    <span className="card-title">Movimientos CREP</span>
                    <div className="row">
                        <div className="input-field col s12 m4">
                            <select value={cuentaSeleccionada} onChange={(e) => setCuentaSeleccionada(e.target.value)}>
                                {cuentas.map((cuenta) => (
                                    <option key={cuenta.id} value={cuenta.id}>
                                        {cuenta.banco} - {cuenta.numero_cuenta}
                                    </option>
                                ))}
                            </select>
                            <label>Cuentas Bancarias</label>
                        </div>
                        <div className="input-field col s6 m2">
                            <DatePicker selected={fechaInicio} onChange={setFechaInicio} dateFormat="dd-MM-yyyy" placeholderText="Fecha Inicio" />
                        </div>
                        <div className="input-field col s6 m2">
                            <DatePicker selected={fechaFin} onChange={setFechaFin} dateFormat="dd-MM-yyyy" placeholderText="Fecha Fin" />
                        </div>
                        <div className="input-field col s6 m2">
                            <select value={tipoMovimiento} onChange={(e) => setTipoMovimiento(e.target.value)}>
                                <option value="TODOS">TODOS</option>
                                <option value="INGRESO">INGRESO</option>
                                <option value="EGRESO">EGRESO</option>
                            </select>
                            <label>Tipo de Movimiento</label>
                        </div>
                        <div className="input-field col s6 m2">
                            <input type="text" value={descripcion} onChange={(e) => setDescripcion(e.target.value)} placeholder="Descripción" />
                        </div>
                        <div className="col s12 m4">
                            <button className="btn blue" onClick={handleBuscar}>Buscar</button>
                        </div>
                        <div className="col s12 m4">
                            <button
                                className="btn waves-effect waves-light blue"
                                onClick={handleConciliarCREP}
                                disabled={loading}
                            >
                                {loading ? 'Conciliando...' : 'Conciliar Reservas CREP'}
                            </button>
                        </div>
                        <div className="col s12 m4">
                            <button
                                className="btn waves-effect waves-light green"
                                onClick={handleConciliarCREP_recibos}
                                disabled={loading}
                            >
                                {loading ? 'Conciliando...' : 'Conciliar Recibos CREP'}
                            </button>
                        </div>
                    </div>
                    <div className="col s12 m12 input-field">
                        <label htmlFor="filtro_tabla_movimientos_crep"><i className="material-icons">search</i> Filtrar</label>
                        <input
                            type="text"
                            id="filtro_tabla_movimientos_crep"
                            value={filtro}
                            onChange={handleFilterChange}
                        />
                    </div>
                    <div className="table-container">
                        {status === 'loading' ? <div>Cargando...</div> : (
                            <table className="striped responsive-table tabla_movimientos_crep">
                                <thead>
                                    <tr>
                                        <th>Cuenta</th>
                                        <th>Depositante</th>
                                        <th>Importe Cobranza</th>
                                        <th>Importe Mora</th>
                                        <th>Importe Pago Total</th>
                                        <th>Fecha Pago</th>
                                        <th>Hora</th>
                                        <th>Vencimiento</th>
                                        <th>Referencia</th>
                                        <th>Suc.</th>
                                        <th>Age.</th>
                                        <th>Oper.</th>
                                        <th>Canal</th>
                                        <th>Oper.BD</th>
                                        <th>Oper.Canal</th>
                                        <th>Doc.Pago</th>
                                        <th>Conc.Estado</th>
                                        <th>Conc.Concepto</th>
                                        <th>Conc.TipoDoc</th>
                                        <th>Conc.IdDoc</th>
                                        <th>Conc.Titular</th>
                                        <th>Carga</th>
                                        <th>Id Carga</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {movimientosFiltrados.length > 0 ? (
                                        movimientosFiltrados.map((mov) => (
                                            <tr key={mov.id}>
                                                <td>{mov.numero_cuenta}</td>
                                                <td>{mov.codigo_depositante}</td>
                                                <td>{mov.importe_cobranza}</td>
                                                <td>{mov.importe_mora}</td>
                                                <td>{mov.importe_pago_total}</td>
                                                <td>{mov.fecha_pago}</td>
                                                <td>{mov.hora_operacion}</td>
                                                <td>{mov.fecha_vencimiento}</td>
                                                <td>{mov.referencia}</td>
                                                <td>{mov.sucursal}</td>
                                                <td>{mov.agencia}</td>
                                                <td>{mov.numero_operacion}</td>
                                                <td>{mov.canal}</td>
                                                <td>{mov.numero_operacion_bd}</td>
                                                <td>{mov.numero_operacion_canal}</td>
                                                <td>{mov.documento_pago}</td>

                                                <td>
                                                    {mov.conciliacion_estado ? (
                                                        <span>{mov.conciliacion_estado}</span>
                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </td>
                                                <td>
                                                    {mov.conciliacion_concepto ? (
                                                        <span>{mov.conciliacion_concepto}</span>
                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </td>
                                                <td>
                                                    {mov.conciliacion_tipo_documento ? (
                                                        <span>{mov.conciliacion_tipo_documento}</span>
                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </td>
                                                <td>
                                                    {mov.conciliacion_id_documento ? (
                                                        <span>{mov.conciliacion_id_documento}</span>
                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </td>
                                                <td>
                                                    {mov.conciliacion_titular ? (
                                                        <span>{mov.conciliacion_titular}</span>
                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </td>
                                                <td>{mov.fecha_hora_carga}</td>
                                                <td>{mov.cargaCREPId}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="10" style={{ textAlign: 'center' }}>No se encontraron movimientos.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default MovimientosCREP;
