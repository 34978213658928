// frontend/src/pages/facturacion_individualFormPage.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    get_facturacion_individual,
    update_facturacion_individual,
    save_facturacion_individual,
    anular_facturacion_individual
} from './facturacion_individualThunk';

import { get_unidades_facturacion_x_edificio } from '../edificio/unidad_facturacionThunk';
import { get_conceptos_facturacion } from '../conceptos-facturacion/conceptos_facturacionThunk';

import { useNavigate, useParams } from 'react-router-dom';
import { TIPOS_CONCEPTO, TIPOS_FACTURACION, TIPOS_CUOTA, TIPOS_SUMINISTRO, CRITERIOS_ASIGNACION_CUOTA, FUENTES_DATOS, MONEDAS } from '../../constants/constants';
import Cleave from 'cleave.js/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseFromYYYYMM, formatPeriodo } from '../../utils/util';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './facturacion_individualFormPage.css';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const Facturacion_individualForm = ({ mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: idFacturacion_individual } = useParams();
    useMaterializeTooltips();

    registerLocale('es', es);

    const [isEditing, setIsEditing] = useState(mode !== 'view');
    const edificioSeleccionado = useSelector((state) => state.edificio.edificio);
    const buildingId = edificioSeleccionado.id;
    const codigo_edificio = edificioSeleccionado.codigo;
    const facturacion_individual = useSelector(state => state.facturacion_individual.facturacion_individual);
    const codigo_usuario = useSelector((state) => state.auth.usuario.id);

    // Obtener el periodo actual al iniciar el componente
    const periodoActual = formatPeriodo(new Date());

    const defaultValues = {
        moneda: { codigo: 'PEN', simbolo: 'S/' },
        importe: 0,
    };

    const [formData, setFormData] = useState({
        codigo_unidad_facturacion: '',
        periodo: periodoActual,
        idconcepto_facturacion: '',
        concepto: '',
        tipo_concepto: '',
        tipo_facturacion: '',
        titulo_concepto: '',
        ...defaultValues
    });

    useEffect(() => {
        if (facturacion_individual && (mode === 'view' || mode === 'edit')) {
            setFormData({
                codigo_unidad_facturacion: facturacion_individual.codigo_unidad_facturacion,
                periodo: facturacion_individual.periodo,
                idconcepto_facturacion: facturacion_individual.idconcepto_facturacion,
                concepto: facturacion_individual.concepto,
                tipo_concepto: facturacion_individual.tipo_concepto,
                tipo_facturacion: facturacion_individual.tipo_facturacion,
                titulo_concepto: facturacion_individual.titulo_concepto,
                importe: facturacion_individual.importe,
                fuente_datos: facturacion_individual.fuente_datos, // Corregido
                moneda: facturacion_individual.moneda,
            });
            setIsEditing(false);
        }
    }, [facturacion_individual, mode]);

    const conceptos_facturacion = useSelector((state) => state.conceptos_facturacion.conceptos_facturacion) || [];
    const unidades_facturacion = useSelector((state) => state.unidad_facturacion.unidades_facturacion) || [];

    useEffect(() => {
        dispatch(get_unidades_facturacion_x_edificio(buildingId));
        dispatch(get_conceptos_facturacion(buildingId));
    }, [dispatch, mode, buildingId]);

    useEffect(() => {
        if (mode !== 'create') {
            dispatch(get_facturacion_individual(idFacturacion_individual));
        }
    }, [dispatch, idFacturacion_individual, mode]);

    useEffect(() => {
        console.log('Unidades de Facturación:', unidades_facturacion);
        console.log('Conceptos de Facturación:', conceptos_facturacion);

        //if (conceptos_facturacion.length > 0 && !formData.concepto) {
        if (
                mode === 'create' &&
                conceptos_facturacion.length > 0 &&
                (!formData.idconcepto_facturacion || formData.idconcepto_facturacion === '')
            ) {
            const conceptoInicial = conceptos_facturacion[0];
            setFormData(prev => ({
                ...prev,
                idconcepto_facturacion: conceptoInicial.id,
                concepto: conceptoInicial.nombre, // Inicializa concepto
                tipo_concepto: conceptoInicial.tipo_concepto,
                tipo_facturacion: conceptoInicial.tipo_facturacion,
                titulo_concepto: conceptoInicial.titulo,
                importe: conceptoInicial.importe_defecto !== undefined ? conceptoInicial.importe_defecto.toFixed(2) : '0.00',
                moneda: conceptoInicial.moneda,
            }));
        }

        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields();
    }, [conceptos_facturacion]);

    useEffect(() => {
        if (unidades_facturacion.length > 0 && !formData.codigo_unidad_facturacion) {
            setFormData(prev => ({
                ...prev,
                codigo_unidad_facturacion: unidades_facturacion[0].codigo_unidad_facturacion, // Inicializa codigo unidad de facturacion 
            }));
        }

        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields();
    }, [unidades_facturacion]);

    useEffect(() => {
        // Actualiza los campos de texto de Materialize cuando el componente se monta
        M.updateTextFields();
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [isEditing]);

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [TIPOS_CONCEPTO]);

    useEffect(() => {
        if (!isEditing) {
            setFormData(prevState => ({
                ...prevState,
                ...defaultValues
            }));
        }
    }, [isEditing]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleCleaveChange = (value) => {
        // Remove the currency symbol and any non-numeric characters (like commas) from the value
        const numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));
        setFormData(prev => ({
            ...prev,
            importe: isNaN(numericValue) ? 0 : numericValue
        }));
    };

    const handleSave = async () => {
        if (isEditing) {
            const dataPrevia = {
                ...formData,
                importe: parseFloat(formData.importe)
            };

            if (mode === 'create') {
                const data = {
                    ...dataPrevia,
                    buildingId,
                    codigo_edificio,
                    usuarioRegistrador: codigo_usuario
                };
                dispatch(save_facturacion_individual(data));
            } else {
                const data = { ...dataPrevia, usuarioActualizacion: codigo_usuario };
                dispatch(update_facturacion_individual({ idFacturacion_individual, facturacion_individualData: dataPrevia }));
            }
        }

        navigate('/facturaciones-individuales');
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [formData.tipo_facturacion_individual]);

    const handleAnular = () => {
        if (window.confirm("¿Estás seguro de que quieres anular esta factura? Esta acción no se puede deshacer.")) {
            dispatch(anular_facturacion_individual({ idFacturacion_individual, usuarioAnulacion: codigo_usuario }));
            M.toast({ html: 'Factura anulada correctamente', classes: 'rounded' });
            navigate('/facturaciones-individuales');
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        if (mode === 'create') {
            navigate('/facturaciones-individuales');
        }
    };

    const handleIdconceptoChange = (e) => {
        const selectedIdconcepto = e.target.value;
        setFormData(prev => ({ ...prev, idconcepto_facturacion: selectedIdconcepto }));

        console.log("selectedIdconcepto: " + selectedIdconcepto);

        // Encontrar el turno seleccionado para obtener sus atributos
        const selectedConcepto = conceptos_facturacion.find(concepto_facturacion => concepto_facturacion.id === selectedIdconcepto);

        if (selectedConcepto) {
            setFormData(prev => ({
                ...prev,
                idconcepto_facturacion: selectedConcepto.id,
                concepto: selectedConcepto.nombre,
                tipo_concepto: selectedConcepto.tipo_concepto,
                tipo_facturacion: selectedConcepto.tipo_facturacion,
                titulo_concepto: selectedConcepto.titulo,
                importe: selectedConcepto.importe_defecto !== undefined ? selectedConcepto.importe_defecto.toFixed(2) : '0.00',
                moneda: selectedConcepto.moneda,
            }));

        } else {
            console.log("Concepto no encontrado o no existe");
            setFormData(prev => ({
                ...prev,
                concepto: "",
                tipo_concepto: "",
                tipo_facturacion: "",
                titulo_concepto: "",
                importe: "0.00",
                moneda: "",
            }));
        }
    };


    const handlePeriodoChange = (date) => {
        const periodoFormateado = formatPeriodo(date); // Convierte la fecha seleccionada a "MM-YYYY"
        console.log("periodoFormateado " + periodoFormateado);
        setFormData((prev) => ({ ...prev, periodo: periodoFormateado }));
    };

    return (
        <div className="container">
            <div>
                <div className="card">
                    <div className="card-content">
                        <div className="card-action">
                            {isEditing ? (
                                <>
                                    <button className="btn green tooltipped" data-position="top" data-tooltip="Guardar cambios" onClick={handleSave}>
                                        <i className="material-icons left">save</i>Guardar
                                    </button>
                                    <button className="btn red tooltipped" data-position="top" data-tooltip="Cancelar edición" onClick={handleCancel}>
                                        <i className="material-icons left">cancel</i>Cancelar
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button className="btn green tooltipped" data-position="top" data-tooltip="Editar detalles" onClick={handleEdit} >
                                        <i className="material-icons left">edit</i>Editar
                                    </button>
                                    <button className="btn orange tooltipped" data-tooltip="Anular Facturación Individual" onClick={handleAnular}>
                                        <i className="material-icons left">delete</i>Anular
                                    </button>
                                </>
                            )}
                            <button className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/facturaciones-individuales')}>
                                <i className="material-icons left">arrow_back</i>Volver
                            </button>
                        </div>
                        <span className="card-title">
                            {mode === 'create' ? 'Crear Facturación Individual' : mode === 'edit' ? 'Editar Facturación Individual' : 'Detalles de la Facturación Individual'}
                        </span>
                        <form onSubmit={e => e.preventDefault()}>
                            <div className="row">
                                <div className="input-field col s6 m2">
                                    <DatePicker
                                        selected={parseFromYYYYMM(formData.periodo)}
                                        onChange={handlePeriodoChange}
                                        showMonthYearPicker
                                        dateFormat="MM/yyyy"
                                        name="periodo"
                                        disabled={!isEditing}
                                        locale="es" // Asegúrate de importar el locale si usas uno
                                    />
                                    <label className="active" htmlFor="periodo">Período:</label>
                                </div>

                                <div className="input-field col s6 m2">
                                    <select
                                        name="codigo_unidad_facturacion"
                                        value={formData.codigo_unidad_facturacion || ''}
                                        onChange={handleInputChange}
                                        disabled={!isEditing}
                                    >
                                        <option value="" disabled>Selecciona una unidad</option>
                                        {Array.isArray(unidades_facturacion) && unidades_facturacion.map(s => (
                                            <option key={s.codigo_unidad_facturacion} value={s.codigo_unidad_facturacion}>
                                                {s.codigo_unidad_facturacion}
                                            </option>
                                        ))}
                                    </select>
                                    <label>Unid. Facturac.</label>
                                </div>

                                <div className="input-field col s12 m4">
                                    <select
                                        name="idconcepto_facturacion"
                                        value={formData.idconcepto_facturacion || ''}
                                        onChange={handleIdconceptoChange}
                                        disabled={!isEditing}
                                    >
                                        <option value="" disabled>Selecciona un concepto</option>
                                        {Array.isArray(conceptos_facturacion) && conceptos_facturacion.map(s => (
                                            <option key={s.id} value={s.id}>
                                                {s.nombre}
                                            </option>
                                        ))}
                                    </select>
                                    <label>Concepto</label>
                                </div>

                                <div className="input-field col s6 m3">
                                    <input
                                        type="text"
                                        name="tipo_concepto"
                                        value={formData.tipo_concepto || ''}
                                        onChange={handleInputChange}
                                        disabled={true}
                                    />
                                    <label htmlFor="tipo_concepto" className="active">Tipo</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12 m3">
                                    <input
                                        type="text"
                                        name="titulo_concepto"
                                        value={formData.titulo_concepto || ''}
                                        onChange={handleInputChange}
                                        disabled={!isEditing}
                                    />
                                    <label className="active" htmlFor="titulo_concepto">Título concepto</label>
                                </div>


                                <div className="input-field col s6 m2">
                                    <Cleave
                                        name="importe"
                                        value={formData.importe}
                                        onChange={(e) => handleCleaveChange(e.target.value)}
                                        options={{
                                            numeral: true,
                                            numeralThousandsGroupStyle: 'thousand',
                                            numeralDecimalMark: '.',
                                            numeralDecimalScale: 2,
                                            prefix: formData.moneda.simbolo + ' '
                                        }}
                                        disabled={!isEditing}
                                    />
                                    <label>Importe</label>
                                </div>
                                <div className="input-field col s6 m2">
                                    <input
                                        type="text"
                                        name="tipo_facturacion"
                                        value={formData.tipo_facturacion || ''}
                                        onChange={handleInputChange}
                                        disabled={true}
                                    />
                                    <label className="active" htmlFor="tipo_facturacion">Facturación</label>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Facturacion_individualForm;
