import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {signOutUsuario} from '../auth/authSlice';
import { resetState as resetEdificio } from '../edificio/edificioSlice';
import { resetState as resetPersona } from '../edificio/personaSlice';
import { resetState as resetUnidadFacturacion } from '../edificio/unidad_facturacionSlice';
import { resetState as resetReservas } from '../mis-areas-comunes/reservaSlice';
import { resetState as resetAreasComunes } from '../mis-areas-comunes/area_comunSlice';
import { resetState as resetFacturaciones_individuales } from '../facturacion_concepto/facturacion_individualSlice';
import { resetState as resetRecibos } from '../mis_recibos/recibosSlice';
import { resetState as resetConceptos_facturacion } from '../conceptos-facturacion/conceptos_facturacionSlice';
import { setLogoutVoluntario } from "../../utils/authSession";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  console.log("INICIANDO LOGOUT");

  const handleLogout = async () => {
    try{
        console.log("Logout firebase");
        setLogoutVoluntario(true);
        await dispatch(signOutUsuario());
        dispatch(resetEdificio()); // 🔥 Resetea `edificioSlice`
        dispatch(resetPersona()); // 🔥 Resetea `personaSlice`        
        dispatch(resetUnidadFacturacion()); // 🔥 Resetea `unidad_facturacionSlice`
        dispatch(resetAreasComunes()); // 🔥 Resetea `area_counSlice`
        dispatch(resetReservas()); // 🔥 Resetea `reservaSlice`
        dispatch(resetFacturaciones_individuales()); // 🔥 Resetea `reservaSlice`
        dispatch(resetRecibos()); // 🔥 Resetea 
        dispatch(resetConceptos_facturacion()); // 🔥 Resetea 
        
        console.log("Logout redireccion");
        navigate('/'); // Redirigir a la página de login después de cerrar sesión    
    }catch(error){
        console.error("Error signout: ", error);
    }
  };
  
  useEffect(() => {
    handleLogout();
  }, [handleLogout]);


  return (
    <div>
      Logging out...
    </div>
  );
};

export default Logout;