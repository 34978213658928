import { useEffect } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';

const useMaterializeTooltips = () => {
    useEffect(() => {
        // 1️⃣ Seleccionar todos los elementos con tooltip
        const elems = document.querySelectorAll('.tooltipped');
        
        // 2️⃣ Inicializar los tooltips correctamente con configuración óptima
        const instances = M.Tooltip.init(elems, {
            exitDelay: 0,  // El tooltip desaparece inmediatamente al salir del cursor
            enterDelay: 100, // Retardo antes de que aparezca el tooltip
            margin: 5,  // Separación entre el tooltip y el botón
            inDuration: 200, // Duración de la animación de entrada
            outDuration: 200, // Duración de la animación de salida
            position: 'bottom' // Ubicación del tooltip
        });

        return () => {
            // 3️⃣ Destruir los tooltips al desmontar el componente
            instances.forEach(instance => instance.destroy());
        };
    }, []);
};

export default useMaterializeTooltips;
