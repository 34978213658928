import { createSlice } from '@reduxjs/toolkit';
import { get_lectura_agua, update_lectura_agua, get_lecturas_agua, save_lectura_agua, anular_lectura_agua, guardar_lectura_depa } from './lectura_aguaThunk';

const initialState = {
  lectura_agua: null,
  lecturas_agua: [],
  lectura_agua_x_periodo: null,
  lectura_aguaSeleccionado: null,
  status: 'idle',
  error: null,
  status_get_lecturas_agua: 'idle',
  error_get_lecturas_agua: null,
  status_update_lectura_agua: 'idle',
  error_update_lectura_agua: null,
  status_save_lectura_agua: 'idle',
  error_save_lectura_agua: null,
  status_guardar_lectura_depa: 'idle',
  error_guardar_lectura_depa: null,
  status_anular_lectura_agua: 'idle',
  error_anular_lectura_agua: null,
  status_get_lectura_agua_x_periodo: 'idle',
  error_get_lectura_agua_x_periodo: null,

}

const lectura_aguaSlice = createSlice({

  name: 'lectura_agua',
  initialState,
  reducers: {
    setLectura_aguaSeleccionado: (state, action) => {
      state.lectura_aguaSeleccionado = action.payload;
    },
    clearLectura_aguaSeleccionado(state) {
      state.lectura_aguaSeleccionado = null;
      state.lectura_agua = null;
    },
    resetState: () => initialState, // 🔥 Reiniciar todo el estado al estado inicial
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_lectura_agua.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_lectura_agua.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.lectura_agua = action.payload;

      })
      .addCase(get_lectura_agua.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })

      .addCase(update_lectura_agua.pending, (state) => {
        state.status_update_lectura_agua = 'loading';
      })
      .addCase(update_lectura_agua.fulfilled, (state, action) => {
        state.status_update_lectura_agua = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.lectura_agua = action.payload.lectura_agua;

      })
      .addCase(update_lectura_agua.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_lectura_agua = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_lectura_agua = action.payload || 'Failed to fetch building';
      })
      .addCase(save_lectura_agua.pending, (state) => {
        state.status_save_lectura_agua = 'loading';
      })
      .addCase(save_lectura_agua.fulfilled, (state, action) => {
        state.status_save_lectura_agua = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.lectura_agua = action.payload.lectura_agua;

      })
      .addCase(save_lectura_agua.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_lectura_agua = 'failed';
        //console.log("error JSON.stringify(action.payload) " + JSON.stringify(action.payload));
        //state.error_save_lectura_agua = action.payload.error || '-Falló al guardar lectura_agua';
        state.error_save_lectura_agua = action.payload.error;
      })

      .addCase(guardar_lectura_depa.pending, (state) => {
        state.status_guardar_lectura_depa = 'loading';
      })
      .addCase(guardar_lectura_depa.fulfilled, (state, action) => {
        state.status_guardar_lectura_depa = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.lectura_agua = action.payload.lectura_agua;

      })
      .addCase(guardar_lectura_depa.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_guardar_lectura_depa = 'failed';
        //console.log("error JSON.stringify(action.payload) " + JSON.stringify(action.payload));
        //state.error_save_lectura_agua = action.payload.error || '-Falló al guardar lectura_agua';
        state.error_guardar_lectura_depa = action.payload.error;
      })
      .addCase(anular_lectura_agua.pending, (state) => {
        state.status_save_lectura_agua = 'loading';
      })
      .addCase(anular_lectura_agua.fulfilled, (state, action) => {
        state.status_anular_lectura_agua = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.lectura_agua = action.payload.lectura_agua;

      })
      .addCase(anular_lectura_agua.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_lectura_agua = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_lectura_agua = action.payload || 'Failed to fetch building';
      })

      .addCase(get_lecturas_agua.pending, (state) => {
        state.status_get_lecturas_agua = 'loading';

      })
      .addCase(get_lecturas_agua.fulfilled, (state, action) => {
        state.status_get_lecturas_agua = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.lecturas_agua = action.payload;

      })
      .addCase(get_lecturas_agua.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_lecturas_agua = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_lecturas_agua = action.payload || 'Failed to fetch building';
      })

  },
});

export const { setLectura_aguaSeleccionado, clearLectura_aguaSeleccionado, resetState } = lectura_aguaSlice.actions;
export default lectura_aguaSlice.reducer;
