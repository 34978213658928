// frontend/src/pages/requerimientoFormPage.js 
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    get_requerimiento,
    update_requerimiento,
    save_requerimiento,
    anular_requerimiento
} from './requerimientoThunk';
import { get_categorias_requerimientos } from '../requerimientos/categoria_requerimientoThunk';
import { useNavigate, useParams } from 'react-router-dom';
import { TIPOS_REQUERIMIENTOS } from '../../constants/constants';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './requerimientoFormPage.css';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';

// Importar Firebase Storage
import { storage } from '../../firebase'; // Ajusta la ruta según la ubicación de tu archivo firebase.js
import { ref, uploadBytes, getDownloadURL, connectStorageEmulator } from 'firebase/storage';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

// Función para formatear fechas a "dd-mm-YYYY"
const formatDate = (date) => {
    const d = new Date(date);
    const day = (`0${d.getDate()}`).slice(-2);
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
};

const RequerimientoForm = ({ mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: idRequerimiento } = useParams();
    useMaterializeTooltips();

    registerLocale('es', es);

    const [isEditing, setIsEditing] = useState(mode !== 'view');
    const requerimiento = useSelector(state => state.requerimiento.requerimiento);
    const codigo_usuario = useSelector((state) => state.auth.usuario.id);
    const titular = useSelector((state) => state.auth.usuario.titular);
    const unidad_facturacionSeleccionado = useSelector((state) => state.unidad_facturacion.unidad_facturacionSeleccionado);
    const codigo_unidad_facturacion = unidad_facturacionSeleccionado.codigo_unidad_facturacion;
    const edificioSeleccionado = useSelector((state) => state.edificio.edificio);
    const buildingId = edificioSeleccionado.id;
    const codigo_edificio = edificioSeleccionado.codigo;
    const [newMessage, setNewMessage] = useState(''); // Para el texto del mensaje adicional
    const [messageFile, setMessageFile] = useState(null); // Para el archivo adjunto del mensaje


    const defaultValues = {
        tipo_atencion: TIPOS_REQUERIMIENTOS[0].value
    };

    const [formData, setFormData] = useState({
        numero_requerimiento: '',
        codigo_unidad_facturacion: '',
        categoria: '',
        idcategoria_requerimiento: '',
        idsubcategoria: '',
        subcategoria: '',
        fecha_solicitud: '', // Ahora será un string
        fecha_cierre: '',
        estado: '',
        detalle: '', // Nuevo campo para detalles
        archivos: [], // Nuevo campo para archivos adjuntos
        archivos_url: [],
        ...defaultValues
    });

    // Estados para manejar la subida de archivos
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState('');

    const [currentSubcategorias, setCurrentSubcategorias] = useState([]);

    useEffect(() => {
        dispatch(get_categorias_requerimientos({ buildingId, estado: 'ACTIVO' }));
    }, [dispatch, buildingId]);

    useEffect(() => {
        if (mode !== 'create') {
            dispatch(get_requerimiento(idRequerimiento));
        } else {
            // Inicializar fecha_solicitud con la fecha actual en formato "dd-mm-YYYY" en modo create
            const today = formatDate(new Date());
            setFormData(prev => ({
                ...prev,
                fecha_solicitud: today
            }));
        }
    }, [dispatch, idRequerimiento, mode]);

    useEffect(() => {
        if (requerimiento && (mode === 'view' || mode === 'edit')) {
            setFormData({
                numero_requerimiento: requerimiento.numero_requerimiento,
                codigo_unidad_facturacion: requerimiento.codigo_unidad_facturacion,
                tipo_atencion: requerimiento.tipo_atencion,
                categoria: requerimiento.categoria.nombre, // Asumiendo que 'categoria' tiene 'nombre'
                idcategoria_requerimiento: requerimiento.categoria.id, // Asegúrate que 'categoria' tiene 'id'
                idsubcategoria: requerimiento.subcategoria.idsubcategoria,
                subcategoria: requerimiento.subcategoria.subcategoria,
                fecha_solicitud: requerimiento.fecha_solicitud,
                fecha_cierre: requerimiento.fecha_cierre ? formatDate(requerimiento.fecha_cierre) : '',
                estado: requerimiento.estado,
                detalle: requerimiento.detalle || '', // Asegúrate de que 'detalle' existe
                archivos: [], // Inicialmente vacío, puedes cargar archivos existentes si es necesario
                archivos_url: requerimiento.archivos_url || [],
            });
            setCurrentSubcategorias(requerimiento.categoria.subcategorias); // Si 'categoria' tiene 'subcategorias'
            setIsEditing(false);
        }
    }, [requerimiento, mode]);

    const categorias_requerimientos = useSelector((state) => state.categoria_requerimiento.categorias_requerimientos) || [];

    useEffect(() => {
        if (categorias_requerimientos.length > 0 && !formData.idcategoria_requerimiento) {
            const categoria_requerimientoInicial = categorias_requerimientos[0];
            setFormData(prev => ({
                ...prev,
                idcategoria_requerimiento: categoria_requerimientoInicial.id,
                categoria: categoria_requerimientoInicial.nombre,
                idsubcategoria: categoria_requerimientoInicial.subcategorias[0].idsubcategoria,
                subcategoria: categoria_requerimientoInicial.subcategorias[0].subcategoria,
                fecha_solicitud: mode === 'create' ? formatDate(new Date()) : prev.fecha_solicitud
            }));
            setCurrentSubcategorias(categoria_requerimientoInicial.subcategorias);
        }

        // Inicializar Materialize CSS select
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields();
    }, [categorias_requerimientos, mode]);

    useEffect(() => {
        if (Array.isArray(currentSubcategorias) && currentSubcategorias.length > 0) {
            var elems = document.querySelectorAll('select');
            M.FormSelect.init(elems);
            M.updateTextFields();
        }
    }, [currentSubcategorias]);

    useEffect(() => {
        // Inicializar Materialize CSS select cuando isEditing cambia
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields();
    }, [isEditing]);

    useEffect(() => {
        // Inicializar Materialize CSS select cuando TIPOS_REQUERIMIENTOS cambia
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields();
    }, [TIPOS_REQUERIMIENTOS]);

    useEffect(() => {
        if (!isEditing && mode === 'create') {
            const today = formatDate(new Date());
            setFormData(prevState => ({
                ...prevState,
                fecha_solicitud: today
            }));
        }
    }, [isEditing, mode]);

    // Manejador para cambios en campos de texto y textarea
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Manejador para cambios en el campo de archivos
    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setFormData(prev => ({
            ...prev,
            archivos: files
        }));
    };

    const handleSave = async () => {
        if (isEditing) {
            setUploading(true);
            setUploadError('');

            try {
                // Crear una instancia de FormData para enviar datos sin archivos al backend
                const dataToSave = {
                    numero_requerimiento: formData.numero_requerimiento,
                    codigo_unidad_facturacion: formData.codigo_unidad_facturacion,
                    tipo_atencion: formData.tipo_atencion,
                    idcategoria_requerimiento: formData.idcategoria_requerimiento,
                    categoria: formData.categoria,
                    idsubcategoria: formData.idsubcategoria,
                    subcategoria: formData.subcategoria,
                    detalle: formData.detalle,
                    fecha_solicitud: formData.fecha_solicitud,
                    fecha_cierre: formData.fecha_cierre,
                    archivos_url: formData.archivos_url,
                    estado: formData.estado,
                };

                if (mode === 'create') {
                    dataToSave.codigo_unidad_facturacion = codigo_unidad_facturacion;
                    dataToSave.buildingId = buildingId;
                    dataToSave.codigo_edificio = codigo_edificio;
                    dataToSave.usuarioRegistrador = codigo_usuario;
                } else {
                    dataToSave.usuarioActualizacion = codigo_usuario;
                }

                // Subir los archivos a Firebase Storage y obtener las URLs
                const uploadPromises = formData.archivos.map(async (archivo, index) => {
                    // Conectar al emulador de Storage si se está ejecutando en local
                    if (window.location.hostname === 'localhost') {
                        connectStorageEmulator(storage, 'localhost', 9199);
                    }

                    // Crear una referencia al archivo en Firebase Storage
                    const storageRefPath = `cargaRequerimientos/${idRequerimiento || 'create'}/${archivo.name}_${Date.now()}`;
                    const archivoRef = ref(storage, storageRefPath);

                    // Subir el archivo
                    const snapshot = await uploadBytes(archivoRef, archivo);

                    // Obtener la URL de descarga
                    const downloadURL = await getDownloadURL(archivoRef);

                    return downloadURL;
                });

                // Esperar a que todos los archivos se suban
                const downloadURLs = await Promise.all(uploadPromises);

                console.log("Archivos subidos. URLs:", downloadURLs);

                // Añadir las URLs de los archivos al objeto de datos
                dataToSave.archivos_url = downloadURLs; // Asegúrate de que el backend espera este campo

                if (mode === 'create') {
                    dispatch(save_requerimiento(dataToSave));
                } else {
                    dispatch(update_requerimiento({ idRequerimiento, requerimientoData: dataToSave }));
                }

                M.toast({ html: 'Requerimiento guardado exitosamente.', classes: 'green' });
                navigate('/requerimientos');
            } catch (error) {
                console.error("Error al subir los archivos:", error);
                setUploadError(error.message || 'Ocurrió un error al subir los archivos.');
                M.toast({ html: uploadError || 'Error al subir los archivos.', classes: 'red' });
            } finally {
                setUploading(false);
            }
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleAnular = () => {
        if (window.confirm("¿Estás seguro de que quieres anular este requerimiento? Esta acción no se puede deshacer.")) {
            dispatch(anular_requerimiento({ idRequerimiento, usuarioAnulacion: codigo_usuario }));
            M.toast({ html: 'Requerimiento anulado correctamente', classes: 'rounded' });
            navigate('/requerimientos');
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        if (mode === 'create') {
            navigate('/requerimientos');
        }
    };

    const handleAddMessage = async () => {
        if (!newMessage && !messageFile) {
            M.toast({ html: 'Agrega un mensaje o archivo.', classes: 'red' });
            return;
        }

        setUploading(true);
        try {
            let fileUrl = null;

            if (messageFile) {
                // Subir archivo a Firebase Storage
                const storageRefPath = `mensajesRequerimientos/${idRequerimiento}/${messageFile.name}_${Date.now()}`;
                const archivoRef = ref(storage, storageRefPath);
                await uploadBytes(archivoRef, messageFile);
                fileUrl = await getDownloadURL(archivoRef);
            }

            const newMessageData = {
                texto: newMessage,
                archivo: fileUrl,
                fecha: new Date().toISOString(),
                usuario: codigo_usuario,
                titular: titular, // Asegúrate de tener este dato
            };

            // Actualizar la colección requerimiento con el nuevo mensaje
            const updatedMessages = [...(requerimiento.mensajes || []), newMessageData];
            await dispatch(update_requerimiento({ idRequerimiento, requerimientoData: { mensajes: updatedMessages } }));

            M.toast({ html: 'Mensaje agregado.', classes: 'green' });

            // Resetear campos
            setNewMessage('');
            setMessageFile(null);
        } catch (error) {
            console.error("Error al agregar mensaje:", error);
            M.toast({ html: 'Error al agregar mensaje.', classes: 'red' });
        } finally {
            setUploading(false);
        }
    };

    // Manejo de cambio de categoría
    const handleIdcategoriaChange = (e) => {
        const selectedIdcategoria = e.target.value;
        setFormData(prev => ({
            ...prev,
            idcategoria_requerimiento: selectedIdcategoria
        }));

        //console.log("selectedIdcategoria: " + selectedIdcategoria);
        //console.log("categorias_requerimientos: " + JSON.stringify(categorias_requerimientos));

        // Encontrar la categoría seleccionada
        const selectedCategoria = categorias_requerimientos.find(categoria => categoria.id === selectedIdcategoria);

        //console.log("selectedCategoria: " + JSON.stringify(selectedCategoria));

        if (selectedCategoria) {
            console.log("entro a poblar FormData: ");

            setFormData(prev => ({
                ...prev,
                categoria: selectedCategoria.nombre,
                idsubcategoria: selectedCategoria.subcategorias[0].idsubcategoria,
                subcategoria: selectedCategoria.subcategorias[0].subcategoria
            }));
            setCurrentSubcategorias(selectedCategoria.subcategorias);
        } else {
            console.log("Categoría no encontrada o no existe");
            setFormData(prev => ({
                ...prev,
                idcategoria_requerimiento: "",
                categoria: "",
                idsubcategoria: "",
                subcategoria: "",
                detalle: '', // Opcional: resetear detalle
                archivos: [] // Opcional: resetear archivos
            }));
            setCurrentSubcategorias([]);
        }
    };

    // Manejo de cambio de subcategoría
    const handleIdsubcategoriaChange = (e) => {
        const selectedIdsubcategoria = e.target.value;
        setFormData(prev => ({
            ...prev,
            idsubcategoria: selectedIdsubcategoria
        }));

        console.log("selectedIdsubcategoria: " + selectedIdsubcategoria);

        // Encontrar la subcategoría seleccionada dentro de currentSubcategorias
        const selectedSubcategoria = currentSubcategorias.find(subcategoria => subcategoria.idsubcategoria === selectedIdsubcategoria);

        if (selectedSubcategoria) {
            setFormData(prev => ({
                ...prev,
                subcategoria: selectedSubcategoria.subcategoria,
            }));
        } else {
            console.log("Subcategoría no encontrada o no existe");
            setFormData(prev => ({
                ...prev,
                idsubcategoria: "",
                subcategoria: "",
            }));
        }
    };

    return (
        <div className="container requerimiento-form">
            <div>
                <div className="card">
                    <div className="card-content">
                        <div className="card-action">
                            {isEditing ? (
                                <>
                                    <button
                                        className={`btn green tooltipped ${uploading ? 'disabled' : ''}`}
                                        data-position="top"
                                        data-tooltip="Guardar cambios"
                                        onClick={handleSave}
                                        disabled={uploading}
                                    >
                                        <i className="material-icons left">save</i>
                                        {uploading ? 'Guardando...' : 'Guardar'}
                                    </button>
                                    <button className="btn red tooltipped" data-position="top" data-tooltip="Cancelar edición" onClick={handleCancel}>
                                        <i className="material-icons left">cancel</i>Cancelar
                                    </button>
                                </>
                            ) : (
                                <>
                                {/* 
                                    <button className="btn green tooltipped" data-position="top" data-tooltip="Editar detalles" onClick={handleEdit} >
                                        <i className="material-icons left">edit</i>Editar
                                    </button>
                                 */}
                                    <button className="btn orange tooltipped" data-tooltip="Anular Requerimiento" onClick={handleAnular}>
                                        <i className="material-icons left">delete</i>Anular
                                    </button>
                                </>
                            )}
                            <button className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/requerimientos')}>
                                <i className="material-icons left">arrow_back</i>Volver
                            </button>
                        </div>
                        <span className="card-title">
                            {mode === 'create' ? 'Crear Requerimiento' : mode === 'edit' ? 'Editar Requerimiento' : 'Detalles del Requerimiento'}
                        </span>
                        <form onSubmit={e => e.preventDefault()}>
                            <div className="row">
                                {mode !== 'create' && (
                                    <div className="input-field col s6 m2">
                                        <input
                                            type="text"
                                            name="numero_requerimiento"
                                            value={formData.numero_requerimiento || ''}
                                            onChange={handleInputChange}
                                            disabled={true}
                                        />
                                        <label htmlFor="numero_requerimiento" className="active">Num. Req.</label>
                                    </div>
                                )}

                                <div className="input-field col s6 m3">
                                    <select
                                        name="tipo_atencion"
                                        value={formData.tipo_atencion}
                                        onChange={handleInputChange}
                                        disabled={!isEditing}
                                    >
                                        {TIPOS_REQUERIMIENTOS.map(tipo => (
                                            <option key={tipo.value} value={tipo.value}>{tipo.label}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="tipo_atencion">Tipo de Atención</label>
                                </div>

                                <div className="input-field col s12 m4">
                                    <select
                                        name="idcategoria_requerimiento"
                                        value={formData.idcategoria_requerimiento || ''}
                                        onChange={handleIdcategoriaChange}
                                        disabled={!isEditing}
                                    >
                                        <option value="" disabled>Selecciona una categoría</option>
                                        {Array.isArray(categorias_requerimientos) && categorias_requerimientos.map(s => (
                                            <option key={s.id} value={s.id}>
                                                {s.nombre}
                                            </option>
                                        ))}
                                    </select>
                                    <label>Categoría</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12 m4">
                                    <select
                                        name="idsubcategoria"
                                        value={formData.idsubcategoria || ''}
                                        onChange={handleIdsubcategoriaChange}
                                        disabled={!isEditing || currentSubcategorias.length === 0}
                                    >
                                        <option value="" disabled>Selecciona una Subcategoría</option>
                                        {Array.isArray(currentSubcategorias) && currentSubcategorias.map(s => (
                                            <option key={s.idsubcategoria} value={s.idsubcategoria}>
                                                {s.subcategoria}
                                            </option>
                                        ))}
                                    </select>
                                    <label>Subcategoría</label>
                                </div>

                                <div className="input-field col s6 m2">
                                    <input
                                        type="text"
                                        name="fecha_solicitud"
                                        value={formData.fecha_solicitud || ''}
                                        onChange={handleInputChange}
                                        disabled={true}
                                    />
                                    <label className="active" htmlFor="fecha_solicitud">Fecha solicitud</label>
                                </div>

                                {mode !== 'create' && (
                                    <div className="row">
                                        <div className="input-field col s6 m2">
                                            <input
                                                type="text"
                                                name="fecha_cierre"
                                                value={formData.fecha_cierre || ''}
                                                onChange={handleInputChange}
                                                disabled={true}
                                            />
                                            <label className="active" htmlFor="fecha_cierre">Fecha cierre</label>
                                        </div>

                                        <div className="input-field col s6 m2">
                                            <input
                                                type="text"
                                                name="estado"
                                                value={formData.estado || ''}
                                                onChange={handleInputChange}
                                                disabled={true}
                                            />
                                            <label className="active" htmlFor="estado">Estado</label>
                                        </div>
                                    </div>
                                )}

                            </div>
                            <div className="row">
                                {/* Campo "Detalle" */}
                                <div className="input-field col s12">
                                    <textarea
                                        id="detalle"
                                        name="detalle"
                                        className="materialize-textarea"
                                        value={formData.detalle}
                                        onChange={handleInputChange}
                                        disabled={!isEditing}
                                    ></textarea>
                                    <label htmlFor="detalle" className={formData.detalle ? 'active' : ''}>Detalle</label>
                                </div>
                            </div>
                            <div className="row">
                                {mode === 'create' && (
                                    <div className="file-field input-field col s12">
                                        <div className="btn">
                                            <span>Subir Archivos</span>
                                            <input
                                                type="file"
                                                multiple
                                                onChange={handleFileChange}
                                                disabled={!isEditing}
                                            />
                                        </div>
                                        <div className="file-path-wrapper">
                                            <input className="file-path validate" type="text" placeholder="Selecciona uno o más archivos" />
                                        </div>
                                    </div>
                                )}
                            </div>

                            {(mode === 'view' || mode === 'edit') && formData.archivos_url && formData.archivos_url.length > 0 && (
                                <div className="row">
                                    <h5>Archivos Adjuntos</h5>
                                    <ul className="collection">
                                        {formData.archivos_url.map((fileUrl, index) => (
                                            <li key={index} className="collection-item">
                                                <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                                                    📄 Archivo {index + 1}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                          
                            {formData.archivos.length > 0 && (
                                <div className="row">
                                    <div className="col s12">
                                        <ul className="collection">
                                            {formData.archivos.map((file, index) => (
                                                <li key={index} className="collection-item">
                                                    {file.name} ({(file.size / 1024).toFixed(2)} KB)
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            {(mode === 'view' || mode === 'edit') && (
                                <>
                                    <h5 className="message-header">Historial de Mensajes</h5>
                                    <div className="message-history">
                                        {requerimiento.mensajes && requerimiento.mensajes.length > 0 ? (
                                            requerimiento.mensajes.map((mensaje, index) => (
                                                <div
                                                    key={index}
                                                    className={`message-item ${mensaje.usuario === codigo_usuario ? 'sent' : 'received'
                                                        }`}
                                                >
                                                    <div className="message-bubble">
                                                        <strong>{mensaje.titular || 'Usuario Desconocido'}</strong>
                                                        <p>{mensaje.texto}</p>
                                                        {mensaje.archivo && (
                                                            <a
                                                                href={mensaje.archivo}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="file-link"
                                                            >
                                                                📎 Ver Archivo
                                                            </a>
                                                        )}
                                                        <small className="message-date">
                                                            {new Date(mensaje.fecha).toLocaleString()}
                                                        </small>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="no-messages">No hay mensajes aún.</p>
                                        )}
                                    </div>

                                    <h5>Agregar Nuevo Mensaje</h5>
                                    <div className="new-message-section">
                                        <div className="row">
                                            <div className="input-field col s12">
                                                <textarea
                                                    id="newMessage"
                                                    name="newMessage"
                                                    className="materialize-textarea"
                                                    value={newMessage}
                                                    onChange={(e) => setNewMessage(e.target.value)}
                                                ></textarea>
                                                <label htmlFor="newMessage" className={newMessage ? 'active' : ''}>
                                                    Escribe un mensaje...
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="file-field input-field col s12">
                                                <div className="btn">
                                                    <span>Subir Archivo</span>
                                                    <input
                                                        type="file"
                                                        onChange={(e) => setMessageFile(e.target.files[0])}
                                                    />
                                                </div>
                                                <div className="file-path-wrapper">
                                                    <input
                                                        className="file-path validate"
                                                        type="text"
                                                        placeholder="Selecciona un archivo"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <button
                                                className={`btn green ${uploading ? 'disabled' : ''}`}
                                                onClick={handleAddMessage}
                                                disabled={uploading}
                                            >
                                                {uploading ? 'Guardando...' : 'Agregar Mensaje'}
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}



                        </form>
                        {/* Opcional: Mostrar estado de subida */}
                        {uploading && (
                            <div className="progress">
                                <div className="indeterminate"></div>
                            </div>
                        )}
                        {uploadError && (
                            <div className="red-text text-darken-2">
                                {uploadError}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequerimientoForm;