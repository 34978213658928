import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetMovimientos_crep } from './movimiento_crepSlice';



export const update_movimiento_crep = createAsyncThunk('movimiento_crep/update_movimiento_crep', async ({ idMovimiento_crep, movimiento_crepData }, thunkAPI) => {
  //console.log("ejecutando thunk update_movimiento_crep con idMovimiento_crep: " + idMovimiento_crep);

  try {

    const response = await apiClient.put(`/movimientos_crep/${idMovimiento_crep}`, movimiento_crepData);

    const movimiento_crep = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetMovimientos_crep(movimiento_crep.buildingId));// Resetear el estado para refetch de movimientos_crep

    return movimiento_crep;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_movimiento_crep = createAsyncThunk('movimiento_crep/save_movimiento_crep', async (movimiento_crepData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/movimientos_crep`, movimiento_crepData);

    const movimiento_crep = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetMovimientos_crep(movimiento_crep.buildingId));// Resetear el estado para refetch de movimientos_crep

    return movimiento_crep;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_movimiento_crep = createAsyncThunk('movimiento_crep/anular_movimiento_crep', async ({ idMovimiento_crep, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_movimiento_crep con idMovimiento_crep: " + idMovimiento_crep);

  try {

    const response = await apiClient.patch(`/movimientos_crep/${idMovimiento_crep}/anular`, { usuarioAnulacion });

    const movimiento_crep = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetMovimientos_crep(movimiento_crep.buildingId));// Resetear el estado para refetch de movimientos_crep

    return movimiento_crep;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_movimiento_crep = createAsyncThunk('movimiento_crep/get_movimiento_crep', async (id, thunkAPI) => {
  console.log("ejecutando thunk get_movimiento_crep con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/movimientos_crep/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const movimiento_crep = response.data;

    return movimiento_crep;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const conciliar_crep = createAsyncThunk(
    'movimiento_crep/conciliar_crep',
    async ({ usuario }, thunkAPI) => {
        try {
            const response = await apiClient.post('/movimientos_crep/conciliar', { usuario });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const get_movimientos_crep = createAsyncThunk('movimiento_crep/get_movimientos_crep', async (codigo_unidad_facturacion, thunkAPI) => {
  console.log("ejecutando thunk get_movimientos_crep con codigo_unidad_facturacion: "+codigo_unidad_facturacion);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/movimientos_crep',{
        params: {codigo_unidad_facturacion}
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const movimientos_crep = response.data;
          
      return movimientos_crep ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const get_movimientos_crep_admin = createAsyncThunk('movimiento_crep/get_movimientos_crep_admin', async ({ tipo_area_comun, fecha_inicio, fecha_fin, estado_pago, estado, codigo_unidad_facturacion }, thunkAPI) => {
  console.log("ejecutando thunk get_movimientos_crep_admin con codigo_unidad_facturacion: "+codigo_unidad_facturacion);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/movimientos_crep/admin',{
        params: {
          tipo_area_comun,
          fecha_inicio,
          fecha_fin,
          estado_pago,
          estado,
          codigo_unidad_facturacion,
        },
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const movimientos_crep = response.data;
          
      return movimientos_crep ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
