// src/components/ProtectedRoute.jsx
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import M from "materialize-css";
import { getLogoutVoluntario, clearLogoutVoluntario } from "../utils/authSession";

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const status = useSelector((state) => state.auth.status);
  const location = useLocation();
  const toastShown = useRef(false);

  useEffect(() => {
    const logoutVoluntario = getLogoutVoluntario();
    
    if (!isAuthenticated && status === "idle" && !toastShown.current && !logoutVoluntario) {
      toastShown.current = true;
      M.toast({
        html: `<i class="material-icons left">lock_outline</i>Tu sesión ha expirado. Inicia sesión nuevamente.`,
        classes: "red darken-2 white-text rounded",
        displayLength: 5000,
      });
    }

        // Limpiar la bandera al salir
        if (logoutVoluntario) clearLogoutVoluntario();

  }, [isAuthenticated, status]);

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
