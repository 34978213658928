import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js';

// Thunks hipotéticos (ajusta según tu proyecto)
import {
    get_unidad_inmobiliaria,
    update_unidad_inmobiliaria,
    save_unidad_inmobiliaria,
} from './unidad_inmobiliariaThunk';

const Unidad_inmobiliariaForm = ({ mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: unidadInmobiliariaId } = useParams();

    // Determinamos si está en modo edición
    // Si el mode es 'view', está en solo lectura
    // Si es 'edit' o 'create', estará en edición.
    const [isEditing, setIsEditing] = useState(mode !== 'view');

    // Datos que obtenemos del store (ajusta con tu reducer real)
    const unidadInmobiliariaRedux = useSelector(
        (state) => state.unidad_inmobiliaria.unidad_inmobiliaria
    );
    const fetchStatus = useSelector((state) => state.unidad_inmobiliaria.fetchStatus);

    // Estado local para el formulario
    const [formData, setFormData] = useState({
        area_ocupada: '',
        area_techada: '',
        nivel: '',
        numero_unidad_inmobiliaria: '',
        orden: '',
        tipo_nivel: '',
        tipo_unidad_inmobiliaria: '',
        estado: '',
        fecha_registro_formateada: '',
    });

    // Inicialización de Materialize
    useEffect(() => {
        M.AutoInit();
    }, []);

    // Si no estamos creando, obtenemos la unidad inmobiliaria actual
    useEffect(() => {
        if (mode !== 'create' && unidadInmobiliariaId) {
            dispatch(get_unidad_inmobiliaria(unidadInmobiliariaId));
        }
    }, [dispatch, unidadInmobiliariaId, mode]);

    // Cuando llegan los datos desde Redux, los volcamos al form
    useEffect(() => {
        if (unidadInmobiliariaRedux && (mode === 'view' || mode === 'edit')) {
            setFormData({
                area_ocupada: unidadInmobiliariaRedux.area_ocupada || '',
                area_techada: unidadInmobiliariaRedux.area_techada || '',
                nivel: unidadInmobiliariaRedux.nivel || '',
                numero_unidad_inmobiliaria:
                    unidadInmobiliariaRedux.numero_unidad_inmobiliaria || '',
                orden: unidadInmobiliariaRedux.orden || '',
                tipo_nivel: unidadInmobiliariaRedux.tipo_nivel || '',
                tipo_unidad_inmobiliaria:
                    unidadInmobiliariaRedux.tipo_unidad_inmobiliaria || '',
                estado: unidadInmobiliariaRedux.estado || '',
                fecha_registro_formateada:
                    unidadInmobiliariaRedux.fecha_registro_formateada || '',
            });
            // Si es 'edit', permanece en edición; si es 'view', se bloquea edición
            setIsEditing(mode === 'edit');
        }
    }, [unidadInmobiliariaRedux, mode]);

    // Actualizar estilos de Materialize al cambiar formData o isEditing
    useEffect(() => {
        M.updateTextFields();
        M.FormSelect.init(document.querySelectorAll('select'));
    }, [formData, isEditing]);

    // Manejador de cambios en los inputs/select
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Guardar (crear o actualizar)
    const handleSave = async () => {
        if (mode === 'create') {
            // Crear nueva
            await dispatch(save_unidad_inmobiliaria(formData));
            // Si quieres quedarte en modo view de la nueva unidad:
            //   1. Podrías obtener el ID resultante de la creación y navegar a esa URL, 
            //   2. O simplemente volver a la lista.
            // Por ahora, quedémonos aquí y pasamos a modo view:
            setIsEditing(false);
        } else {
            // Actualizar
            await dispatch(
                update_unidad_inmobiliaria({
                    idUnidad_inmobiliaria: unidadInmobiliariaId,
                    unidad_inmobiliariaData: formData,
                })
            );
            // Volvemos a leer la data para ver la actualización reflejada
            dispatch(get_unidad_inmobiliaria(unidadInmobiliariaId));
            // Pasamos a modo view sin navegar
            setIsEditing(false);
        }
    };

    // Botón para pasar a modo edición
    const handleEdit = () => {
        setIsEditing(true);
    };

    // Cancelar o volver
    const handleCancel = () => {
        navigate(-1);
    };

    if (fetchStatus === 'loading') {
        return <div>Cargando datos...</div>;
    }

    return (
        <div className="container">
            {/* Acciones */}
            <div className="card-action" style={{ marginBottom: '1rem' }}>
                {isEditing ? (
                    <>
                        <button
                            className="btn green"
                            onClick={handleSave}
                            style={{ marginRight: '1rem' }}
                        >
                            <i className="material-icons left">save</i>Guardar
                        </button>
                        <button
                            className="btn red"
                            onClick={handleCancel}
                            style={{ marginRight: '1rem' }}
                        >
                            <i className="material-icons left">cancel</i>Cancelar
                        </button>
                    </>
                ) : (
                    // Si está en modo vista y no es creación, mostramos botón Editar
                    mode !== 'create' && (
                        <button
                            className="btn green"
                            onClick={handleEdit}
                            style={{ marginRight: '1rem' }}
                        >
                            <i className="material-icons left">edit</i>Editar
                        </button>
                    )
                )}
                <button className="btn blue" onClick={handleCancel}>
                    <i className="material-icons left">arrow_back</i>Volver
                </button>
            </div>

            <h4>
                {mode === 'create'
                    ? 'Registrar Unidad Inmobiliaria'
                    : isEditing
                        ? 'Editar Unidad Inmobiliaria'
                        : 'Detalle de Unidad Inmobiliaria'}
            </h4>

            {/* Formulario */}
            <div className="row">
                {/* Tipo Unidad Inmobiliaria */}
                <div className="input-field col s6 m3">
                    <select
                        name="tipo_unidad_inmobiliaria"
                        value={formData.tipo_unidad_inmobiliaria}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    >
                        <option value="" disabled>Seleccionar</option>
                        <option value="Departamento">Departamento</option>
                        <option value="Estacionamiento">Estacionamiento</option>
                        <option value="Deposito">Deposito</option>
                        <option value="Estacionamiento-Deposito">Estacionamiento-Deposito</option>
                        <option value="Local-Comercial">Local-Comercial</option>
                        {/* Agrega las opciones que necesites */}
                    </select>
                    <label>Tipo Unidad</label>
                </div>

                {/* Número de Unidad Inmobiliaria */}
                <div className="input-field col s6 m2">
                    <input
                        type="text"
                        name="numero_unidad_inmobiliaria"
                        value={formData.numero_unidad_inmobiliaria}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    />
                    <label
                        className={formData.numero_unidad_inmobiliaria ? 'active' : ''}
                    >
                        Nº Unidad Inm.
                    </label>
                </div>

                {/* Tipo Nivel */}
                <div className="input-field col s6 m2">
                    <select
                        name="tipo_nivel"
                        value={formData.tipo_nivel}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    >
                        <option value="" disabled>Seleccionar</option>
                        <option value="PISO">PISO</option>
                        <option value="SOTANO">SOTANO</option>
                    </select>
                    <label>Tipo Nivel</label>
                </div>


                {/* Nivel */}
                <div className="input-field col s6 m2">
                    <input
                        type="text"
                        name="nivel"
                        value={formData.nivel}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    />
                    <label className={formData.nivel ? 'active' : ''}>Nivel</label>
                </div>

            </div>
            <div className="row">
                {/* Área Ocupada */}
                <div className="input-field col s12 m3">
                    <input
                        type="number"
                        step="any"
                        name="area_ocupada"
                        value={formData.area_ocupada}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    />
                    <label className={formData.area_ocupada ? 'active' : ''}>
                        Área Ocupada (m²)
                    </label>
                </div>

                {/* Área Techada */}
                <div className="input-field col s12 m3">
                    <input
                        type="number"
                        step="any"
                        name="area_techada"
                        value={formData.area_techada}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    />
                    <label className={formData.area_techada ? 'active' : ''}>
                        Área Techada (m²)
                    </label>
                </div>


                {/* Orden */}
                <div className="input-field col s12 m2">
                    <input
                        type="text"
                        name="orden"
                        value={formData.orden}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    />
                    <label className={formData.orden ? 'active' : ''}>Orden</label>
                </div>

                {/* Estado */}
                <div className="input-field col s12 m3">
                    <select
                        name="estado"
                        value={formData.estado}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    >
                        <option value="" disabled>Seleccionar</option>
                        <option value="ACTIVO">ACTIVO</option>
                        <option value="INACTIVO">INACTIVO</option>
                    </select>
                    <label>Estado</label>
                </div>
            </div>

            <div className="row">
                {/* Fecha Registro (solo lectura) */}
                <div className="input-field col s12 m3">
                    <input
                        type="text"
                        name="fecha_registro_formateada"
                        value={formData.fecha_registro_formateada}
                        onChange={handleInputChange}
                        disabled
                    />
                    <label className={formData.fecha_registro_formateada ? 'active' : ''}>
                        F. Registro
                    </label>
                </div>
            </div>
        </div>
    );
};

export default Unidad_inmobiliariaForm;
