import { combineReducers } from 'redux';
import authReducer from '../features/auth/authSlice';
import seguridadReducer from '../features/seguridad/seguridadSlice';
import recibosReducer from '../features/mis_recibos/recibosSlice';
import edificioReducer from '../features/edificio/edificioSlice';
import edificiosReducer from '../features/mis-edificios/edificiosSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import edificioSeleccionado from '../features/edificio/edificioSlice';
import depas_cargadosReducer from '../features/carga-edificio/depas-cargadosSlice';
import propiedades_cargadasReducer from '../features/carga-edificio/propiedades-cargadasSlice';
import config_unidades_facturacion_cargadasReducer from '../features/carga-edificio/config-unidades-facturacion-cargadasSlice';
import config_unidad_facturacionReducer from '../features/edificio/config_unidad_facturacionSlice';
import estacionamientos_cargadosReducer from '../features/carga-edificio/estacionamientos-cargadosSlice';
import depositos_cargadosReducer from '../features/carga-edificio/depositos-cargadosSlice';
import departamentoReducer from '../features/edificio/departamentoSlice';
import estacionamientoReducer from '../features/edificio/estacionamientoSlice';
import depositoReducer from '../features/edificio/depositoSlice';
import propiedadReducer from '../features/edificio/propiedadSlice';
import reunionReducer from '../features/reuniones/reunionSlice';
import activoReducer from '../features/activos/activoSlice';
import cuenta_bancoReducer from '../features/banco/cuenta_bancoSlice';
import movimiento_cuentaReducer from '../features/banco/movimiento_cuentaSlice';
import carga_crepReducer from '../features/banco/carga_crepSlice';
import movimiento_crepReducer from '../features/banco/movimiento_crepSlice';
import proceso_conciliacionReducer from '../features/banco/proceso_conciliacionSlice';
import conciliacionReducer from '../features/banco/conciliacionSlice';
import plantilla_activoReducer from '../features/activos/plantilla_activoSlice';
import asistenciaReducer from '../features/reuniones/asistenciaSlice';
import publicacionReducer from '../features/publicaciones/publicacionSlice';
import preferenciaReducer from '../features/preferencias/preferenciaSlice';
import unidad_facturacionReducer from '../features/edificio/unidad_facturacionSlice';
import facturacion_individualReducer from '../features/facturacion_concepto/facturacion_individualSlice';
import requerimientoReducer from '../features/requerimientos/requerimientoSlice';
import categoria_requerimientoReducer from '../features/requerimientos/categoria_requerimientoSlice';
import unidades_facturacion_cargadasReducer from '../features/carga-edificio/unidades-facturacion-cargadasSlice';
import unidad_inmobiliariaReducer from '../features/edificio/unidad_inmobiliariaSlice';
import unidades_inmobiliarias_cargadasReducer from '../features/carga-edificio/unidades-inmobiliarias-cargadasSlice';
import residenteReducer from '../features/edificio/residenteSlice';
import leadReducer from '../features/publicaciones/leadSlice';
import contenidoReducer from '../features/contenido/contenidoSlice';
import facturacion_masivaReducer from '../features/facturacion_concepto/facturacion_masivaSlice';
import residentes_cargadosReducer from '../features/carga-edificio/residentes-cargadosSlice';
import personaReducer from '../features/edificio/personaSlice';
import personas_cargadasReducer from '../features/carga-edificio/personas-cargadasSlice';
import planillas_recibosReducer from '../features/planilla_recibos/planillas_recibosSlice';
import reservaReducer from '../features/mis-areas-comunes/reservaSlice';
import justificacionReducer from '../features/reuniones/justificacionSlice';
import area_comunReducer from '../features/mis-areas-comunes/area_comunSlice';
import lecturas_aguaReducer from '../features/facturacion-agua/lecturas_aguaSlice';
import lectura_aguaReducer from '../features/facturacion-agua/lectura_aguaSlice';
import suministrosReducer from '../features/edificio/suministrosSlice';
import suministroReducer from '../features/edificio/suministroSlice';
import encuestaReducer from '../features/encuestas/encuestaSlice';
import facturaciones_aguaReducer from '../features/facturacion-agua/facturaciones_aguaSlice';
import facturacion_aguaReducer from '../features/facturacion-agua/facturacion_aguaSlice';
import conceptos_facturacionReducer from '../features/conceptos-facturacion/conceptos_facturacionSlice';
import conceptoReducer from '../features/conceptos-facturacion/conceptoSlice';
import planillaReducer from '../features/planilla_recibos/planillaSlice';
import zona_seguraReducer from '../features/zonas_seguras/zona_seguraSlice';
import protocoloReducer from '../features/protocolos/protocoloSlice';
import contacto_referenciaReducer from '../features/contactos-referencia/contacto_referenciaSlice';
import medicamentoReducer from '../features/medicamentos/medicamentoSlice';
import grupoReducer from '../features/medicamentos/grupoSlice';
import respuestaReducer from '../features/encuestas/respuestaSlice';
import persona_asistenciaReducer from '../features/personas-atencion/persona_asistenciaSlice';

const appReducer = combineReducers({
  auth: authReducer,
  seguridad: seguridadReducer,
  recibos: recibosReducer,
  edificio: edificioReducer,
  edificios: edificiosReducer,
  dashboard: dashboardReducer,
  edificioSeleccionado: edificioSeleccionado,
  depas_cargados: depas_cargadosReducer,
  propiedades_cargadas: propiedades_cargadasReducer,
  propiedad: propiedadReducer,
  lead: leadReducer,
  contenido: contenidoReducer,
  cuenta_banco: cuenta_bancoReducer,
  movimiento_cuenta: movimiento_cuentaReducer,
  carga_crep: carga_crepReducer,
  movimiento_crep: movimiento_crepReducer,
  proceso_conciliacion: proceso_conciliacionReducer,
  conciliacion: conciliacionReducer, 
  respuesta: respuestaReducer,
  config_unidades_facturacion_cargadas: config_unidades_facturacion_cargadasReducer,
  config_unidad_facturacion: config_unidad_facturacionReducer,
  unidades_inmobiliarias_cargadas: unidades_inmobiliarias_cargadasReducer,
  unidad_inmobiliaria: unidad_inmobiliariaReducer,
  unidad_facturacion: unidad_facturacionReducer,
  unidades_facturacion_cargadas: unidades_facturacion_cargadasReducer,
  facturacion_individual: facturacion_individualReducer,
  requerimiento: requerimientoReducer,
  reunion: reunionReducer,
  activo: activoReducer,
  plantilla_activo: plantilla_activoReducer,
  asistencia:asistenciaReducer,
  categoria_requerimiento: categoria_requerimientoReducer,
  grupo: grupoReducer,
  publicacion: publicacionReducer,
  preferencia: preferenciaReducer,
  estacionamientos_cargados: estacionamientos_cargadosReducer,
  residentes_cargados: residentes_cargadosReducer,
  personas_cargadas: personas_cargadasReducer,
  zona_segura: zona_seguraReducer,
  departamento: departamentoReducer,
  persona_asistencia: persona_asistenciaReducer,
  estacionamiento: estacionamientoReducer,
  residente: residenteReducer,
  medicamento: medicamentoReducer,
  persona: personaReducer,
  depositos_cargados: depositos_cargadosReducer,
  deposito: depositoReducer,
  conceptos_facturacion: conceptos_facturacionReducer,
  concepto: conceptoReducer,
  planillas_recibos: planillas_recibosReducer,
  lecturas_agua: lecturas_aguaReducer,
  lectura_agua: lectura_aguaReducer,
  suministros: suministrosReducer,
  suministro: suministroReducer,
  encuesta: encuestaReducer,
  reserva: reservaReducer,
  facturacion_masiva: facturacion_masivaReducer,
  justificacion: justificacionReducer,
  protocolo: protocoloReducer,
  contacto_referencia: contacto_referenciaReducer,
  area_comun: area_comunReducer,
  facturaciones_agua: facturaciones_aguaReducer,
  facturacion_agua: facturacion_aguaReducer,
  planilla: planillaReducer,
  
});

const rootReducer = (state, action) => {
  // 🔥 Si detectamos que auth expiró, limpiamos todo el store
  if (
    state?.auth?.isAuthenticated === false &&
    state?.auth?.status === 'idle'
  ) {
    console.log("🧹 Veamos JSON.stringify(state?.auth): "+JSON.stringify(state?.auth));
    console.log("🧹 Limpiando todo el estado Redux por expiración");
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;