import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  get_unidad_inmobiliaria_x_unidad,
  // ...
} from './unidad_inmobiliariaThunk'; // Ajusta ruta
import { clearUnidad_inmobiliariaSeleccionada } from './unidad_inmobiliariaSlice';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';

// Este modal recibe:
// - isOpen: bool para abrir/cerrar
// - onClose: callback para cerrar
// - onAddPropiedad: callback para agregar la unidad a la lista en el padre
// - buildingId: el id del edificio actual, para enviarlo al backend

const BuscarUnidadInmobiliariaModal = ({
  isOpen,
  onClose,
  onAddPropiedad,
  buildingId
}) => {
  const dispatch = useDispatch();

  // Campos para buscar
  const [tipoUnidad, setTipoUnidad] = useState('Departamento');
  const [numeroUnidad, setNumeroUnidad] = useState('');

  // Estado del slice de unidad_inmobiliaria
  const { unidad_inmobiliaria, status_get_unidad_inmobiliaria_x_unidad, error_get_unidad_inmobiliaria_x_unidad } = useSelector(
    (state) => state.unidad_inmobiliaria
  );

  // Al abrir, inicializamos el select de Materialize
  useEffect(() => {
    if (isOpen) {
      const elems = document.querySelectorAll('select');
      M.FormSelect.init(elems);
    }
  }, [isOpen]);

  // Al cerrar, limpiamos la unidad seleccionada
  useEffect(() => {
    if (!isOpen) {
      dispatch(clearUnidad_inmobiliariaSeleccionada());
    }
  }, [isOpen, dispatch]);

  const handleBuscar = () => {
    if (!buildingId || !tipoUnidad || !numeroUnidad) return;
    dispatch(
      get_unidad_inmobiliaria_x_unidad({
        buildingId,
        tipo_unidad_inmobiliaria: tipoUnidad,
        numero_unidad_inmobiliaria: numeroUnidad,
      })
    );
  };

  const handleAgregar = () => {
    if (unidad_inmobiliaria) {
      // Llamamos al callback para que el padre agregue a "propiedades"
      onAddPropiedad({
        id: unidad_inmobiliaria.id,
        tipo_unidad_inmobiliaria: unidad_inmobiliaria.tipo_unidad_inmobiliaria,
        numero_unidad_inmobiliaria: unidad_inmobiliaria.numero_unidad_inmobiliaria,
        area_ocupada: unidad_inmobiliaria.area_ocupada,
        // ... otros campos que desees
      });
      // Reseteamos
      setTipoUnidad('Departamento');
      setNumeroUnidad('');
      onClose();
    }
  };

  const handleCerrar = () => {
    setTipoUnidad('Departamento');
    setNumeroUnidad('');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0, left: 0,
        width: '100%', height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          background: '#fff',
          borderRadius: '8px',
          padding: '1.5rem',
          width: '400px',
          maxWidth: '90%',
        }}
      >
        <h5>Buscar Unidad Inmobiliaria</h5>

        {/* Select para tipoUnidad */}
        <div className="input-field">
          <select
            value={tipoUnidad}
            onChange={(e) => setTipoUnidad(e.target.value)}
          >
            <option value="Departamento">Departamento</option>
            <option value="Estacionamiento">Estacionamiento</option>
            <option value="Deposito">Deposito</option>
            <option value="Estacionamiento-Deposito">Estacionamiento-Deposito</option>
            <option value="Local-Comercial">Local-Comercial</option>
          </select>
        </div>

        {/* Input para numeroUnidad */}
        <div className="input-field">
          <input
            type="text"
            value={numeroUnidad}
            onChange={(e) => setNumeroUnidad(e.target.value)}
          />
          <label className={numeroUnidad ? 'active' : ''}>
            N° Unidad Inmobiliaria
          </label>
        </div>

        <button
          className="btn"
          onClick={handleBuscar}
          style={{ marginRight: '1rem' }}
        >
          Buscar
        </button>
        <button className="btn grey" onClick={handleCerrar}>
          Cerrar
        </button>

        {/* Manejo de estados */}
        {status_get_unidad_inmobiliaria_x_unidad === 'loading' && <p>Cargando...</p>}
        {status_get_unidad_inmobiliaria_x_unidad === 'failed' && (
          <p style={{ color: 'red' }}>Error: {error_get_unidad_inmobiliaria_x_unidad}</p>
        )}
        {status_get_unidad_inmobiliaria_x_unidad === 'succeeded' && unidad_inmobiliaria && (
          <div style={{ marginTop: '1rem' }}>
            <strong>Unidad Encontrada:</strong>
            <p>Tipo: {unidad_inmobiliaria.tipo_unidad_inmobiliaria}</p>
            <p>Número: {unidad_inmobiliaria.numero_unidad_inmobiliaria}</p>
            <p>Área Ocupada: {unidad_inmobiliaria.area_ocupada}</p>
            <button
              className="btn green"
              onClick={handleAgregar}
              style={{ marginTop: '0.5rem' }}
            >
              Agregar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BuscarUnidadInmobiliariaModal;
