import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';

  export const get_unidades_facturacion_x_edificio = createAsyncThunk('deposito/get_unidades_facturacion_x_edificio', async (codigo_edificio, thunkAPI) => {    
    
    try { 
        console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.get(`/edificios/${codigo_edificio}/unidades-facturacion`);
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const unidades_facturacion = response.data;
            
        return unidades_facturacion ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  export const update_unidad_facturacion = createAsyncThunk('unidad_facturacion/update_unidad_facturacion', async ({ idUnidad_facturacion, unidad_facturacionData }, thunkAPI) => {
    //console.log("ejecutando thunk update_unidad_facturacion con idUnidad_facturacion: " + idUnidad_facturacion);
  
    try {
  
      const response = await apiClient.put(`/unidades-facturacion/${idUnidad_facturacion}`, unidad_facturacionData);
  
      const unidad_facturacion = response.data;
  
      // Despachar acción para resetear el estado de fetching
      //thunkAPI.dispatch(resetStateGetUnidad_facturacions(unidad_facturacion.buildingId));// Resetear el estado para refetch de unidad_facturacions
  
  
      return unidad_facturacion;
  
    } catch (error) {
      console.log("error.response.data " + error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
  
  export const save_unidad_facturacion = createAsyncThunk('unidad_facturacion/save_unidad_facturacion', async (unidad_facturacionData, thunkAPI) => {
  
    try {
      console.log("antes de invocación de post creacion de unidad_facturacion")
      const response = await apiClient.post(`/unidades-facturacion`, unidad_facturacionData);
  
      const unidad_facturacion = response.data;
  
      // Despachar acción para resetear el estado de fetching
      //thunkAPI.dispatch(resetStateGetUnidad_facturacions(unidad_facturacion.buildingId));// Resetear el estado para refetch de unidad_facturacions
  
      return unidad_facturacion;
  
    } catch (error) {
      console.log("error.response.data " + error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
  
  export const anular_unidad_facturacion = createAsyncThunk('unidad_facturacion/anular_unidad_facturacion', async ({ idUnidad_facturacion, usuarioAnulacion }, thunkAPI) => {
    //console.log("ejecutando thunk anular_unidad_facturacion con idUnidad_facturacion: " + idUnidad_facturacion);
  
    try {
  
      const response = await apiClient.patch(`/unidades-facturacion/${idUnidad_facturacion}/anular`, { usuarioAnulacion });
  
      const unidad_facturacion = response.data;
  
      // Despachar acción para resetear el estado de fetching
      //thunkAPI.dispatch(resetStateGetUnidad_facturacions(unidad_facturacion.buildingId));// Resetear el estado para refetch de unidad_facturacions
  
      return unidad_facturacion;
  
    } catch (error) {
      console.log("error.response.data " + error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
  
  export const get_unidad_facturacion_x_unidad  = createAsyncThunk('unidad_facturacion/get_unidad_facturacion_x_unidad', async ({ buildingId, codigo_unidad_facturacion }, thunkAPI) => {
    console.log("ejecutando thunk get_unidad_facturacion_x_unidad con buildingId: " + buildingId + " codigo_unidad_facturacion: "+codigo_unidad_facturacion );
  
    try {
  
      //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
      //parametro pasado por path
  
      const response = await apiClient.get('/unidades-facturacion/unidad',{
        params: { 
          buildingId, 
          codigo_unidad_facturacion 
        }
    });
  
      //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
      const unidad_facturacion = response.data;
  
      return unidad_facturacion;
    } catch (error) {
      console.log("error.response.data " + error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });

  export const get_unidad_facturacion = createAsyncThunk('unidad_facturacion/get_unidad_facturacion', async (id, thunkAPI) => {
    console.log("ejecutando thunk get_unidad_facturacion con id: " + id);
  
    try {
  
      //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
      //parametro pasado por path
      const response = await apiClient.get(`/unidades-facturacion/${id}`);
      //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
      const unidad_facturacion = response.data;
  
      return unidad_facturacion;
    } catch (error) {
      console.log("error.response.data " + error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
