import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_residentes_x_edificio } from './residenteThunk';
import M from 'materialize-css';
import { formatDate02 } from '../../utils/formateo';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const Residentes_edificio = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useMaterializeTooltips();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);  
  const edificio = useSelector((state) => state.edificio.edificio);
  const residentes = useSelector((state) => state.residente.residentes);
  const fetchStatus = useSelector((state) => state.residente.fetchStatus);    
  const error = useSelector((state) => state.residente.error);
  const [filtro, setFiltro] = useState('');

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
    M.AutoInit(); 
    if (fetchStatus === 'idle' && edificio.id) {
      dispatch(get_residentes_x_edificio(edificio.id));
    }
  }, [isAuthenticated, navigate, fetchStatus, edificio, dispatch]);

  const handleRefresh = () => {    
    dispatch(get_residentes_x_edificio(edificio.id));
  };

  const formatearResidentes = (residentes) => {
    return Array.isArray(residentes) ? residentes.map(residente => ({
      ...residente,
      fecha_registro_formato01: formatDate02(residente.timestamp_registro),
    })) : [];
  };

  const residentesFormateados = formatearResidentes(residentes);

  const handleFilterChange = (e) => {    
    setFiltro(e.target.value);
  };

  const residentesFiltrados = residentesFormateados.filter((residente) => {
    const filterLowerCase = filtro.toLowerCase();
    return residente.perfil.toLowerCase().includes(filterLowerCase) ||
           residente.tipo_documento.toLowerCase().includes(filterLowerCase) ||
           residente.numero_documento.toLowerCase().includes(filterLowerCase) ||
           residente.titular.toLowerCase().includes(filterLowerCase) ||
           residente.email.toLowerCase().includes(filterLowerCase) ||
           residente.nro_celular.toLowerCase().includes(filterLowerCase) ||
           residente.tipo_usuario.toLowerCase().includes(filterLowerCase) ||
           residente.usuario.toLowerCase().includes(filterLowerCase) ||
           residente.roles.toLowerCase().includes(filterLowerCase) ||
           residente.fecha_registro_formato01.toString().includes(filterLowerCase);
  });

  // Calcular total por perfil
  const resumenPorPerfil = residentesFormateados.reduce((acc, residente) => {
    const perfil = residente.perfil;
    acc[perfil] = (acc[perfil] || 0) + 1;
    return acc;
  }, {});

  if (fetchStatus === 'loading') {
    return <div>Loading...</div>;
  }

  return (    
    <div className="container">
      <h5>Residentes</h5>
      <a className="btn green tooltipped" data-position="top" data-tooltip="Refrescar vista" onClick={handleRefresh}>
        <i className="material-icons left">refresh</i>Refrescar
      </a>
      <a className="btn orange tooltipped" data-position="top" data-tooltip="Cargar Depas" onClick={() => navigate('/carga-residentes')}>
        <i className="material-icons left">cloud_upload</i>Cargar Residentes
      </a>        
      <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/edificio')}>
        <i className="material-icons left">arrow_back</i>
      </a>        

      <h5>Resumen de Residentes por Perfil</h5>
      <table className="striped responsive-table">
        <thead>
          <tr>
            <th>Perfil</th>
            <th>Cantidad</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(resumenPorPerfil).map(([perfil, cantidad]) => (
            <tr key={perfil}>
              <td>{perfil}</td>
              <td>{cantidad}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_residentes_cargados"><i className="material-icons">search</i> Filtrar</label>
        <input type="text" id="filtro_tabla_residentes_cargados" 
               value={filtro}
               onChange={handleFilterChange} />           
      </div>

      <table className="striped responsive-table tabla_residentes_cargados">
        <thead>
          <tr>
            <th>Edificio</th>
            <th>Perfil</th>
            <th>TipDoc</th>
            <th>NumDoc</th>
            <th>Titular</th>
            <th>Email</th>
            <th>Celular</th>
            <th>TipUsua.</th>
            <th>Usuario</th>
            <th>Roles</th>
            <th>Carga</th>
            <th>Id. carga</th>
            <th>Registro</th>
          </tr>
        </thead>
        <tbody id="table-body-residentes-cargados">                    
          {residentesFiltrados.map((residente) => (
            <tr key={residente.id}>
              <td>{residente.codigo_edificio}</td>
              <td>{residente.perfil}</td>
              <td>{residente.tipo_documento}</td>
              <td>{residente.numero_documento}</td>
              <td>{residente.titular}</td>
              <td>{residente.email}</td>
              <td>{residente.nro_celular}</td>
              <td>{residente.tipo_usuario}</td>
              <td>{residente.usuario}</td>
              <td>{residente.roles}</td>
              <td>{residente.usuario_carga}</td>
              <td>{residente.uid_carga}</td>
              <td>{residente.fecha_registro_formato01}</td>
            </tr>
          ))}
        </tbody>
      </table>    
    </div>
  );
};

export default Residentes_edificio;
