import { createSlice } from '@reduxjs/toolkit';
import { get_cuenta_banco, get_cuentas_banco} from './cuenta_bancoThunk';

const initialState= {
  cuenta_banco: null,
  cuentas_banco: [],
  cuenta_bancoSeleccionada: null,
  status: 'idle',
  error: null,
  status_get_cuenta_banco: 'idle',
  error_get_cuenta_banco: null,    
  status_get_cuentas_banco: 'idle',
  error_get_cuentas_banco: null,
}

const cuenta_bancoSlice = createSlice({
  name: 'cuenta_banco',
  initialState,
  reducers: {
    setCuenta_bancoSeleccionada: (state, action) => {
      state.cuenta_bancoSeleccionada = action.payload;
      
    },
    clearCuenta_bancoSeleccionada(state) {
      state.cuenta_bancoSeleccionada = null;
      state.cuenta_banco = null;
    },    
    resetState: () => initialState, // 🔥 Reiniciar todo el estado al estado inicial
    clearCuenta_bancos: (state) => {
      state.cuentas_banco = []; // Limpiar cuenta_bancos antes de una nueva consulta
      state.status_get_cuentas_banco = 'idle';
      state.error_get_cuentas_banco = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_cuenta_banco.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_cuenta_banco.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.cuenta_banco = action.payload;

      })
      .addCase(get_cuenta_banco.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })
      
      .addCase(get_cuentas_banco.pending, (state) => {
        state.status_get_cuentas_banco = 'loading';
        
      })
      .addCase(get_cuentas_banco.fulfilled, (state, action) => {
        state.status_get_cuentas_banco = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.cuentas_banco = action.payload;

      })
      .addCase(get_cuentas_banco.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_cuentas_banco = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_cuentas_banco = action.payload || 'Failed to fetch building';
      })      

      
  },
});

export const { setCuenta_bancoSeleccionada, clearCuenta_bancoSeleccionada, clearCuenta_bancos } = cuenta_bancoSlice.actions;
export const selectCuenta_bancosById = (state, idCuenta_banco) =>
    state.cuenta_banco.cuenta_bancos.find((cuenta_banco) => cuenta_banco.id === idCuenta_banco);
export const { resetState } = cuenta_bancoSlice.actions;
export default cuenta_bancoSlice.reducer;
