import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import M from 'materialize-css';
import { get_unidades_inmobiliarias } from './unidad_inmobiliariaThunk';
import { formatDate02 } from '../../utils/formateo';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const Unidades_inmobiliarias = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useMaterializeTooltips();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const edificio = useSelector((state) => state.edificio.edificio);
  const unidades_inmobiliarias = useSelector(
    (state) => state.unidad_inmobiliaria.unidades_inmobiliarias
  );
  const status_get_unidades_inmobiliarias = useSelector(
    (state) => state.unidad_inmobiliaria.status_get_unidades_inmobiliarias
  );
  const usuario = useSelector((state) => state.auth.usuario);

  const error = useSelector((state) => state.unidad_inmobiliaria.error);

  const [filtro, setFiltro] = useState('');

  const { esSuperUsuario, esAdministrador } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
    M.AutoInit();

    if (status_get_unidades_inmobiliarias === 'idle' && edificio.id) {
      dispatch(get_unidades_inmobiliarias(edificio.id));
    }
  }, [
    isAuthenticated,
    navigate,
    status_get_unidades_inmobiliarias,
    edificio,
    dispatch,
  ]);

  if (status_get_unidades_inmobiliarias === 'loading') {
    return <div>Loading...</div>;
  }

  if (!edificio.id) {
    return <div>No se ha seleccionado un edificio.</div>;
  }

  const handleRefresh = () => {
    if (edificio) {
      dispatch(get_unidades_inmobiliarias(edificio.id));
    }
  };

  const formatearUnidades_inmobiliarias = (unidades) => {
    return Array.isArray(unidades)
      ? unidades.map((unidad) => ({
        ...unidad,
        fecha_registro_formato01: formatDate02(unidad.timestamp_registro),
      }))
      : [];
  };

  const unidades_inmobiliariasFormateados = formatearUnidades_inmobiliarias(
    unidades_inmobiliarias
  );

  const handleFilterChange = (e) => {
    setFiltro(e.target.value);
  };

  const unidades_inmobiliariasFiltrados = unidades_inmobiliariasFormateados.filter((unidad) => {
    const filterLowerCase = filtro.toLowerCase();
    return (
      unidad.tipo_unidad_inmobiliaria.toLowerCase().includes(filterLowerCase) ||
      unidad.numero_unidad_inmobiliaria.toLowerCase().includes(filterLowerCase) ||
      unidad.nivel.toString().toLowerCase().includes(filterLowerCase) ||
      unidad.tipo_nivel.toString().toLowerCase().includes(filterLowerCase) ||
      unidad.area_ocupada.toString().toLowerCase().includes(filterLowerCase) ||
      unidad.area_techada.toString().toLowerCase().includes(filterLowerCase) ||
      unidad.fecha_registro_formato01.toString().includes(filterLowerCase)
    );
  });

  // Calcular el total de Area_ocupada
  const totalAreaOcupada = unidades_inmobiliariasFormateados.reduce(
    (acc, unidad) => acc + (unidad.area_ocupada || 0),
    0
  );

  // Función para formatear números con separador de miles y dos decimales
  const formatearNumero = (numero) => {
    return numero.toLocaleString('es-PE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // Resumen por tipo de unidad inmobiliaria
  const resumenPorTipo = unidades_inmobiliariasFormateados.reduce((acc, unidad) => {
    const tipo = unidad.tipo_unidad_inmobiliaria;
    acc[tipo] = acc[tipo] ? acc[tipo] + 1 : 1;
    return acc;
  }, {});

  // Calcular total de unidades
  const totalUnidades = Object.values(resumenPorTipo).reduce(
    (acc, cantidad) => acc + cantidad,
    0
  );

  return (
    <div className="container" style={{ textAlign: 'center', marginTop: '2rem' }}>
      <h5>Unidades Inmobiliarias</h5>
      <div className="row" style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
        <div className="col s12 m3">
          <a
            className="btn green tooltipped"
            data-position="top"
            data-tooltip="Refrescar vista"
            onClick={handleRefresh}
            style={{ margin: '0.5rem' }}
          >
            <i className="material-icons left">refresh</i>Refrescar
          </a>
        </div>
        {esSuperUsuario && (
          <div className="col s12 m3">
            <a
              className="btn orange tooltipped"
              data-position="top"
              data-tooltip="Cargar Unid. Inmobiliarias"
              onClick={() => navigate('/carga-unidades-inmobiliarias')}
              style={{ margin: '0.5rem' }}
            >
              <i className="material-icons left">cloud_upload</i>Cargar Unid. Inmobil.
            </a>
          </div>
        )}
        <div className="col s12 m3">
          <a
            className="btn blue tooltipped"
            data-position="top"
            data-tooltip="Volver"
            onClick={() => navigate('/edificio')}
            style={{ margin: '0.5rem' }}
          >
            <i className="material-icons left">arrow_back</i>
          </a>
        </div>
      </div>

      {/* Resumen */}
      <h5>Resumen por Tipo de Unidad Inmobiliaria</h5>
      <div style={{ maxWidth: '500px', margin: '0 auto' }}>
        <table className="striped responsive-table" style={{ marginBottom: '2rem' }}>
          <thead>
            <tr>
              <th>Tipo de Unidad</th>
              <th>Cantidad</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(resumenPorTipo).map(([tipo, cantidad]) => (
              <tr key={tipo}>
                <td>{tipo}</td>
                <td>{cantidad}</td>
              </tr>
            ))}
            <tr>
              <td><strong>Total</strong></td>
              <td><strong>{totalUnidades}</strong></td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Filtro */}
      <div className="col s12 m12 input-field" style={{ maxWidth: '400px', margin: '0 auto' }}>
        <label htmlFor="filtro_tabla_unidades_inmobiliarias_cargados">
          <i className="material-icons">search</i> Filtrar
        </label>
        <input
          type="text"
          id="filtro_tabla_unidades_inmobiliarias_cargados"
          value={filtro}
          onChange={handleFilterChange}
          style={{ marginBottom: '1rem' }}
        />
      </div>

      {/* Contenedor con scroll vertical y horizontal */}
      <div
        style={{
          width: '100%',
          maxHeight: '400px',
          overflowX: 'auto',
          overflowY: 'auto',
          margin: '0 auto',
        }}
      >
        <table
          className="striped responsive-table tabla_unidades_inmobiliarias_cargados"
          style={{ margin: '0 auto', minWidth: '800px' }}
        >
          <thead>
            <tr>
              <th>Edificio</th>
              <th>TipUnid.</th>
              <th>NumeroUnid.</th>
              <th>Nivel</th>
              <th>Tip_niv</th>
              <th>Area_ocupada</th>
              <th>Area_techada</th>
              <th>usuario</th>
              <th>Id. carga</th>
              <th>Registro</th>
            </tr>
          </thead>
          <tbody id="table-body-unidades_inmobiliarias-cargados">
            {/* Fila de total de area ocupada */}
            <tr>
              <td colSpan="5"><strong>Total Area Ocupada</strong></td>
              <td><strong>{formatearNumero(totalAreaOcupada)}</strong></td>
              <td colSpan="4"></td>
            </tr>
            {unidades_inmobiliariasFiltrados.map((unidad) => (
              <tr key={unidad.id}>
                <td>{unidad.codigo_edificio}</td>
                <td>{unidad.tipo_unidad_inmobiliaria}</td>
                <td>
                  {/* Enlace para consultar la unidad inmobiliaria */}
                  <a
                    href="#!"
                    className="blue-text text-darken-2"
                    onClick={() => navigate(`/unidad_inmobiliaria/view/${unidad.id}`)}
                  >
                    {unidad.numero_unidad_inmobiliaria}
                  </a>
                </td>
                <td>{unidad.nivel}</td>
                <td>{unidad.tipo_nivel}</td>
                <td>{unidad.area_ocupada}</td>
                <td>{unidad.area_techada}</td>
                <td>{unidad.usuario_carga}</td>
                <td>{unidad.uid_carga}</td>
                <td>{unidad.fecha_registro_formato01}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Unidades_inmobiliarias;
