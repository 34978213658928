// src/utils/authSession.js
let logoutVoluntario = false;

export const setLogoutVoluntario = (value) => {
  logoutVoluntario = value;
};

export const getLogoutVoluntario = () => logoutVoluntario;

export const clearLogoutVoluntario = () => {
  logoutVoluntario = false;
};
