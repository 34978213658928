import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { selectConcept } from './conceptos_facturacionSlice';
import { get_conceptos_facturacion } from './conceptos_facturacionThunk';
import './SelectConceptsModal.css';

const SelectConceptsModal = ({ isOpen, onRequestClose }) => {
  const dispatch = useDispatch();
  const conceptos_facturacion = useSelector((state) => 
    Array.isArray(state.conceptos_facturacion.conceptos_facturacion) 
      ? state.conceptos_facturacion.conceptos_facturacion 
      : []
  );

  const selectedConcepts = useSelector((state) => 
    Array.isArray(state.conceptos_facturacion.selectedConcepts) 
      ? state.conceptos_facturacion.selectedConcepts 
      : []
  );
  
  const edificio = useSelector(state => state.edificio.edificio);
  const [filtro, setFiltro] = useState('');

  useEffect(() => {
    if (isOpen && edificio?.id) {
      dispatch(get_conceptos_facturacion(edificio.id));
    }
  }, [isOpen, dispatch, edificio?.id]);

  const handleSelectConcept = (concept) => {
    const nuevoConceptoPlanilla = {
      ...concept, // copiar todos los campos existentes del concepto
      idconcepto: concept.id,
      nro_linea: Date.now(), // agregar nro_linea único
      importe: concept.importe_defecto, // asegurar que se use importe_defecto como importe inicial
      nro_suministro: concept.nro_suministro || '',
      unidades_exoneradas: concept.unidades_exoneradas || [],
      
    };

    dispatch(selectConcept(nuevoConceptoPlanilla));
    onRequestClose();
  };

  const conceptosFiltrados = conceptos_facturacion.filter(concepto => {
    const filtroLower = filtro.toLowerCase();

     // Excluir conceptos ya seleccionados
     const yaSeleccionado = selectedConcepts.some(selConcept => selConcept.idconcepto === concepto.id);

     return (
      !yaSeleccionado &&
      (concepto.nombre?.toLowerCase().includes(filtroLower) ||
       concepto.tipo_facturacion?.toLowerCase().includes(filtroLower))
    );
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Seleccionar Conceptos"
      ariaHideApp={false}
      className="select-concepts-modal"
      overlayClassName="select-concepts-overlay"
    >
      {/* Header del Modal */}
      <div className="select-concepts-header">
        <h5>Seleccionar Conceptos de Facturación</h5>
        <button className="select-concepts-close-btn" onClick={onRequestClose}>✖</button>
      </div>

      {/* Filtro de búsqueda */}
      <div className="select-concepts-filter">
        <label htmlFor="filter">
          <i className="material-icons">search</i> Filtrar:
        </label>
        <input
          type="text"
          id="filter"
          placeholder="Buscar concepto..."
          value={filtro}
          onChange={(e) => setFiltro(e.target.value)}
        />
      </div>

      {/* Contenedor de la tabla con scroll */}
      <div className="select-concepts-table-container">
        <table className="select-concepts-table">
          <thead>
            <tr>
              <th>Concepto</th>
              <th>Tipo</th>
              <th>Seleccionar</th>
            </tr>
          </thead>
          <tbody>
            {conceptosFiltrados.length > 0 ? (
              conceptosFiltrados.map((concept, index) => (
                <tr key={index}>
                  <td>{concept.nombre}</td>
                  <td>{concept.tipo_facturacion}</td>
                  <td>
                    <button className="select-concepts-btn" onClick={() => handleSelectConcept(concept)}>
                      Seleccionar
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">No se encontraron conceptos.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default SelectConceptsModal;
