import { createSlice } from '@reduxjs/toolkit';
import {
  get_proceso_conciliacion, get_procesos_conciliacion, get_procesos_conciliacion_admin, update_proceso_conciliacion, save_proceso_conciliacion
  , anular_proceso_conciliacion, conciliar_crep, conciliar_crep_recibos
} from './proceso_conciliacionThunk';

const initialState = {
  proceso_conciliacion: null,
  procesos_conciliacion: [],
  proceso_conciliacionSeleccionada: null,
  proceso_conciliacionData: null,
  registros_conciliados: 0,
  registros_no_conciliados: 0,
  status: 'idle',
  error: null,
  status_get_procesos_conciliacion: 'idle',
  error_get_procesos_conciliacion: null,
  status_conciliar_crep: 'idle',
  error_conciliar_crep: null,

  
  status_conciliar_crep_recibos: 'idle',
  error_conciliar_crep_recibos: null,

  status_get_procesos_conciliacion_admin: 'idle',
  error_get_procesos_conciliacion_admin: null,
  status_update_proceso_conciliacion: 'idle',
  error_update_proceso_conciliacion: null,
  status_save_proceso_conciliacion: 'idle',
  error_save_proceso_conciliacion: null,
  status_anular_proceso_conciliacion: 'idle',
  error_anular_proceso_conciliacion: null,
}

const proceso_conciliacionSlice = createSlice({
  name: 'proceso_conciliacion',
  initialState,
  reducers: {
    setProceso_conciliacionSeleccionada: (state, action) => {
      state.proceso_conciliacionSeleccionada = action.payload;

    },
    clearProceso_conciliacionSeleccionada(state) {
      state.proceso_conciliacionSeleccionada = null;
      state.proceso_conciliacion = null;
    },
    resetStateGetProcesos_conciliacion: () => {
      return {
        status_get_procesos_conciliacion: 'idle',
      };
    },

    resetState: () => initialState, // 🔥 Reiniciar todo el estado al estado inicial
    clearProcesos_conciliacion: (state) => {
      state.procesos_conciliacion = []; // Limpiar procesos_conciliacion antes de una nueva consulta
      state.status_get_procesos_conciliacion_admin = 'idle';
      state.status_get_procesos_conciliacion_admin = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_proceso_conciliacion.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_proceso_conciliacion.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.proceso_conciliacion = action.payload;

      })
      .addCase(get_proceso_conciliacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })

      .addCase(update_proceso_conciliacion.pending, (state) => {
        state.status_update_proceso_conciliacion = 'loading';
      })
      .addCase(update_proceso_conciliacion.fulfilled, (state, action) => {
        state.status_update_proceso_conciliacion = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.proceso_conciliacion = action.payload.proceso_conciliacion;

      })
      .addCase(update_proceso_conciliacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_proceso_conciliacion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_proceso_conciliacion = action.payload || 'Failed to fetch building';
      })

      .addCase(save_proceso_conciliacion.pending, (state) => {
        state.status_save_proceso_conciliacion = 'loading';
      })
      .addCase(save_proceso_conciliacion.fulfilled, (state, action) => {
        state.status_save_proceso_conciliacion = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.proceso_conciliacion = action.payload.proceso_conciliacion;

      })
      .addCase(save_proceso_conciliacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_proceso_conciliacion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_proceso_conciliacion = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_proceso_conciliacion.pending, (state) => {
        state.status_save_proceso_conciliacion = 'loading';
      })
      .addCase(anular_proceso_conciliacion.fulfilled, (state, action) => {
        state.status_anular_proceso_conciliacion = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.proceso_conciliacion = action.payload.proceso_conciliacion;

      })
      .addCase(anular_proceso_conciliacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_proceso_conciliacion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_proceso_conciliacion = action.payload || 'Failed to fetch building';
      })
      .addCase(get_procesos_conciliacion.pending, (state) => {
        state.status_get_procesos_conciliacion = 'loading';

      })
      .addCase(get_procesos_conciliacion.fulfilled, (state, action) => {
        state.status_get_procesos_conciliacion = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.procesos_conciliacion = action.payload;

      })
      .addCase(get_procesos_conciliacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_procesos_conciliacion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_procesos_conciliacion = action.payload || 'Failed to fetch building';
      })

      .addCase(get_procesos_conciliacion_admin.pending, (state) => {
        state.status_get_procesos_conciliacion_admin = 'loading';

      })
      .addCase(get_procesos_conciliacion_admin.fulfilled, (state, action) => {
        state.status_get_procesos_conciliacion_admin = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.procesos_conciliacion = action.payload;

      })
      .addCase(get_procesos_conciliacion_admin.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_procesos_conciliacion_admin = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_procesos_conciliacion_admin = action.payload || 'Failed to fetch building';
      })
      
      .addCase(conciliar_crep_recibos.pending, (state) => {
        state.status_conciliar_crep_recibos = 'loading';
      })
      .addCase(conciliar_crep_recibos.fulfilled, (state, action) => {
        state.status_conciliar_crep_recibos = 'succeeded';
        state.registros_conciliados = action.payload.registros_conciliados;
        state.registros_no_conciliados = action.payload.registros_no_conciliados;
      })
      .addCase(conciliar_crep_recibos.rejected, (state, action) => {
        state.status_conciliar_crep_recibos = 'failed';
        state.error_conciliar_crep_recibos = action.payload || 'Error en la conciliación';
      })

      .addCase(conciliar_crep.pending, (state) => {
        state.status_conciliar_crep = 'loading';
      })
      .addCase(conciliar_crep.fulfilled, (state, action) => {
        state.status_conciliar_crep = 'succeeded';
        state.registros_conciliados = action.payload.registros_conciliados;
        state.registros_no_conciliados = action.payload.registros_no_conciliados;
      })
      .addCase(conciliar_crep.rejected, (state, action) => {
        state.status_conciliar_crep = 'failed';
        state.error_conciliar_crep = action.payload || 'Error en la conciliación';
      });

  },
});

export const { setProceso_conciliacionSeleccionada, clearProceso_conciliacionSeleccionada, clearProcesos_conciliacion } = proceso_conciliacionSlice.actions;
export const selectProcesos_conciliacionById = (state, idProceso_conciliacion) =>
  state.proceso_conciliacion.procesos_conciliacion.find((proceso_conciliacion) => proceso_conciliacion.id === idProceso_conciliacion);
export const { resetStateGetProcesos_conciliacion, resetState } = proceso_conciliacionSlice.actions;
export default proceso_conciliacionSlice.reducer;
