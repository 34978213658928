import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import M from 'materialize-css';
import './menuPage.css';
import useMaterializeTooltips from '../utils/useMaterializeTooltips';
import { useNavigate } from 'react-router-dom';

const Menu = () => {
  //const { usuario } = useSelector((state) => state.auth);  // Estado de autenticación
  // 🔥 Aseguramos que `auth` siempre tenga un valor válido, incluso después de logout
  const authState = useSelector((state) => state.auth) || { usuario: null };
  const { usuario } = authState;  // Extraer usuario de authState sin errores
  useMaterializeTooltips();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const edificioSeleccionado = useSelector((state) => state.edificio.edificioSeleccionado);

console.log("isAuthenticated "+isAuthenticated)
  const location = useLocation();  // Detecta la ruta actual

  const navigate = useNavigate();
/*
  useEffect(() => {
    if (!authState?.isAuthenticated) {
      M.toast({ html: "Tu sesión ha expirado. Por favor inicia sesión nuevamente.", classes: "orange darken-2" });
      console.log("🔐 Sesión expirada, redirigiendo a logout...");
      navigate('/logout');
    }
  }, [authState?.isAuthenticated]);
*/

  useEffect(() => {
    M.Tooltip.init(document.querySelectorAll('.tooltipped'));
    M.Dropdown.init(document.querySelectorAll('.dropdown-trigger'));
    M.Sidenav.init(document.querySelectorAll('.sidenav'));
  }, []);


  useEffect(() => {
    // Inicializar Sidenav
    const sidenavElems = document.querySelectorAll('.sidenav');
    let sidenavInstances = M.Sidenav.init(sidenavElems);

    return () => {
      // Destruir las instancias del Sidenav solo si existen
      sidenavInstances.forEach(instance => {
        if (instance && typeof instance.destroy === 'function') {
          try {
            instance.destroy();
          } catch (error) {
            console.warn("Error al destruir el sidenav:", error);
          }
        }
      });
    };
  }, []);

  const closeSidenav = () => {
    const sidenavElems = document.querySelectorAll('.sidenav');
    sidenavElems.forEach(sidenavElem => {
      const sidenavInstance = M.Sidenav.getInstance(sidenavElem);
      if (sidenavInstance && sidenavInstance.close) {
        sidenavInstance.close();
      }
    });
  };

  // Ocultar menús en caso de login o sesión activa
  //const ocultarMenus = usuario || location.pathname === '/login';
  const ocultarMenus = !!usuario;

  return (
    <div id="menu">
      <nav>
        <div className="nav-wrapper blue darken-1">
          <Link to="/" className="brand-logo hide-on-med-and-down text-large" id="tituloPagina">
            <i className="material-icons">cloud</i>Tantaq
          </Link>

          <Link to="/" className="brand-logo hide-on-large-only" id="tituloPagina_short">
            <i className="material-icons">cloud</i>
            <span className="tituloSmallDevices">Tantaq</span>
          </Link>

          <Link to="#" data-target="mobile-demo" className="sidenav-trigger">
            <i className="material-icons">menu</i>
          </Link>

          <ul className="right hide-on-med-and-down">
            {/* Ocultar estos menús si el usuario está autenticado o en login */}
            {!ocultarMenus && (
              <>
                <li id="menu_beneficios" className="menusitos menu_inicio">
                  <Link to="/beneficios">Beneficios</Link>
                </li>
                <li id="menu_como_funciona" className="menusitos menu_inicio">
                  <Link to="/como-funciona">¿Cómo funciona?</Link>
                </li>
                <li id="menu_preguntas" className="menusitos menu_inicio">
                  <Link to="/preguntas">Preguntas</Link>
                </li>
                <li id="menu_ser_recaudador" className="menusitos menu_inicio">
                  <Link to="/ser-recaudador">Ser Recaudador</Link>
                </li>
                <li id="menu_contactanos" className="menusitos menu_inicio">
                  <Link to="/contactanos">Contáctanos</Link>
                </li>
              </>
            )}

            {/* Mostrar Login SOLO si el usuario no está autenticado
            {!usuario && (
              <li id="menu_login" className="menusitos menu_inicio">
                <Link to="/login">Login</Link>
              </li>
            )} */}
            {!isAuthenticated && (
              <li id="menu_login"><Link to="/login">Login</Link></li>
            )}

            {/* Opciones basadas en autenticación */}
            {location.pathname === '/forgot-password' && (
              <li>
                <Link to="/" className="tooltipped" data-position="bottom" data-tooltip="Login">
                  <i className="material-icons">account_circle</i>
                </Link>
              </li>
            )}

            {!usuario && location.pathname !== '/forgot-password' && (
              <li>
                <Link to="/forgot-password" className="tooltipped" data-position="bottom" data-tooltip="Recuperar Password">
                  <i className="material-icons">vpn_key</i>
                </Link>
              </li>
            )}
            {/* 
            {usuario && (
              <li>
                <Link to="/logout" className="tooltipped" data-position="bottom" data-tooltip="Logout">
                  <i className="material-icons">lock_open</i>
                </Link>
              </li>
            )}
            */}

            {isAuthenticated && edificioSeleccionado && usuario && (
              <>
                <li className="tooltipped" data-position="bottom" data-tooltip="Dashboard">
                  <Link to="/dashboard">
                    <i className="material-icons left">dashboard</i>
                  </Link>
                </li>
                <li className="tooltipped" data-position="bottom" data-tooltip="Mi cuenta">
                  <Link to="/mi-cuenta">{`${edificioSeleccionado.codigo} - ${usuario.codigo_usuario}`}</Link>
                </li>
                <li>
                  <Link to="/logout" className="tooltipped" data-position="bottom" data-tooltip="Logout">
                    <i className="material-icons">lock_open</i>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>

      {/* Menú lateral (Sidenav) para móviles */}
      <ul className="sidenav" id="mobile-demo">
        {isAuthenticated && (
          <li>
            <Link to="/logout" onClick={closeSidenav} className="tooltipped" data-position="bottom" data-tooltip="Logout">
              Logout<i className="material-icons">lock_open</i>
            </Link>
          </li>
        )}
        {!ocultarMenus && (
          <>
            <li id="menu_lat_beneficios" className="menusitos menu_inicio">
              <Link to="/beneficios" onClick={closeSidenav}>Beneficios</Link>
            </li>
            <li id="menu_lat_como_funciona" className="menusitos menu_inicio">
              <Link to="/como-funciona" onClick={closeSidenav}>¿Cómo funciona?</Link>
            </li>
            <li id="menu_lat_preguntas" className="menusitos menu_inicio">
              <Link to="/preguntas" onClick={closeSidenav}>Preguntas</Link>
            </li>
            <li id="menu_lat_ser_recaudador" className="menusitos menu_inicio">
              <Link to="/ser-recaudador" onClick={closeSidenav}>Ser Recaudador</Link>
            </li>
            <li id="menu_lat_contactanos" className="menusitos menu_inicio">
              <Link to="/contactanos" onClick={closeSidenav}>Contáctanos</Link>
            </li>
          </>
        )}

        {!isAuthenticated && (
          <li id="menu_lat_login" className="menusitos menu_inicio">
            <Link to="/login" onClick={closeSidenav}>Login</Link>
          </li>
        )}
        {isAuthenticated && edificioSeleccionado && usuario && (
          <>
            <li className="tooltipped" data-position="bottom" data-tooltip="Dashboard">
              <Link to="/dashboard" onClick={closeSidenav}>
                <i className="material-icons left">dashboard</i>Dashboard
              </Link>
            </li>
            <li className="tooltipped" onClick={closeSidenav} data-position="bottom" data-tooltip="Mi cuenta">
              <Link to="/mi-cuenta">{`${edificioSeleccionado.codigo} - ${usuario.codigo_usuario}`}</Link>
            </li>
          </>
        )}

      </ul>
    </div>
  );
};

export default Menu;