// frontend/src/redux/slices/facturacion_masivaSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { 
    get_facturacion_masiva, 
    get_facturaciones_masivas, 
    update_facturacion_masiva, 
    save_facturacion_masiva, 
    anular_facturacion_masiva 
} from './facturacion_masivaThunk';

const facturacion_masivaSlice = createSlice({
    name: 'facturacion_masiva',
    initialState: {
        facturacion_masiva: null,
        facturaciones_masivas: [],
        facturacion_masivaSeleccionada: null,
        status: 'idle',
        error: null,
        status_get_facturaciones_masivas: 'idle',
        error_get_facturaciones_masivas: null,
        status_update_facturacion_masiva: 'idle',
        error_update_facturacion_masiva: null,
        status_save_facturacion_masiva: 'idle',
        error_save_facturacion_masiva: null,
        status_anular_facturacion_masiva: 'idle',
        error_anular_facturacion_masiva: null,
    },
    reducers: {
        setFacturacion_masivaSeleccionada: (state, action) => {
            state.facturacion_masivaSeleccionada = action.payload;
        },
        clearFacturacion_masivaSeleccionada(state) {
            state.facturacion_masivaSeleccionada = null;
            state.facturacion_masiva = null;
        },
        resetStateGetFacturaciones_masivas: (state) => {
            state.status_get_facturaciones_masivas = 'idle';
            state.error_get_facturaciones_masivas = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // Obtener una facturación individual
            .addCase(get_facturacion_masiva.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(get_facturacion_masiva.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.facturacion_masiva = action.payload;
            })
            .addCase(get_facturacion_masiva.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Failed to fetch facturacion individual';
            })

            // Obtener todas las facturaciones individuales
            .addCase(get_facturaciones_masivas.pending, (state) => {
                state.status_get_facturaciones_masivas = 'loading';
                state.error_get_facturaciones_masivas = null;
            })
            .addCase(get_facturaciones_masivas.fulfilled, (state, action) => {
                state.status_get_facturaciones_masivas = 'succeeded';
                state.facturaciones_masivas = action.payload;
            })
            .addCase(get_facturaciones_masivas.rejected, (state, action) => {
                state.status_get_facturaciones_masivas = 'failed';
                state.error_get_facturaciones_masivas = action.payload || 'Failed to fetch facturaciones individuales';
            })

            // Actualizar una facturación individual
            .addCase(update_facturacion_masiva.pending, (state) => {
                state.status_update_facturacion_masiva = 'loading';
            })
            .addCase(update_facturacion_masiva.fulfilled, (state, action) => {
                state.status_update_facturacion_masiva = 'succeeded';
                state.facturacion_masiva = action.payload.facturacion_masiva; 
            })
            .addCase(update_facturacion_masiva.rejected, (state, action) => {
                state.status_update_facturacion_masiva = 'failed';
                state.error_update_facturacion_masiva = action.payload || 'Failed to update facturacion individual';
            })

            // Guardar una nueva facturación individual
            .addCase(save_facturacion_masiva.pending, (state) => {
                state.status_save_facturacion_masiva = 'loading';
            })
            .addCase(save_facturacion_masiva.fulfilled, (state, action) => {
                state.status_save_facturacion_masiva = 'succeeded';
                state.facturacion_masiva = action.payload.facturacion_masiva;
                state.facturaciones_masivas.push(action.payload.facturacion_masiva);
            })
            .addCase(save_facturacion_masiva.rejected, (state, action) => {
                state.status_save_facturacion_masiva = 'failed';
                state.error_save_facturacion_masiva = action.payload || 'Failed to save facturacion individual';
            })

            // Anular una facturación individual
            .addCase(anular_facturacion_masiva.pending, (state) => {
                state.status_anular_facturacion_masiva = 'loading';
            })
            .addCase(anular_facturacion_masiva.fulfilled, (state, action) => {
                state.status_anular_facturacion_masiva = 'succeeded';
                state.facturacion_masiva = action.payload.facturacion_masiva;
            })
            .addCase(anular_facturacion_masiva.rejected, (state, action) => {
                state.status_anular_facturacion_masiva = 'failed';
                state.error_anular_facturacion_masiva = action.payload || 'Failed to anular facturacion individual';
            })      
        },
      });

export const { 
    setFacturacion_masivaSeleccionada, 
    clearFacturacion_masivaSeleccionada, 
    resetStateGetFacturaciones_masivas 
} = facturacion_masivaSlice.actions;

export const selectFacturaciones_masivasById = (state, idFacturacion_masiva) =>
    state.facturacion_masiva.facturaciones_masivas.find((facturacion_masiva) => facturacion_masiva.id === idFacturacion_masiva);

export default facturacion_masivaSlice.reducer;
