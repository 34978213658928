import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetPlanillas } from './planillas_recibosSlice';

export const update_planilla = createAsyncThunk('planilla/update_planilla', async ({ idPlanilla, planillaData }, thunkAPI) => {
  //console.log("ejecutando thunk update_planilla con idPlanilla: " + idPlanilla);

  try {

    const response = await apiClient.put(`/planillas-recibos/${idPlanilla}`, planillaData);

    const planilla = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetPlanillas(planilla.buildingId));// Resetear el estado para refetch de planillas


    return planilla;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const recrear_recibos = createAsyncThunk('planilla/recrear_recibos', async (idPlanilla, thunkAPI) => {
  console.log("ejecutando thunk recrear_recibos con idPlanilla: " + idPlanilla);

  try {

    const response = await apiClient.post(`/planillas-recibos/${idPlanilla}/recrear-recibos`);

    const planilla = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetPlanillas(planilla.buildingId));// Resetear el estado para refetch de planillas


    return planilla;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const update_facturacion_agua_planilla = createAsyncThunk('planilla/update_facturacion_agua_planilla', async (idPlanilla, thunkAPI) => {
  console.log("ejecutando thunk update_facturacion_agua_planilla con idPlanilla: " + idPlanilla);

  try {

    const response = await apiClient.post(`/planillas-recibos/${idPlanilla}/update-facturacion-agua`);

    const planilla = response.data;    

    return planilla;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_planilla = createAsyncThunk('planilla/save_planilla', async (planillaData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/planillas-recibos`, planillaData);

    const planilla = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetPlanillas(planilla.buildingId));// Resetear el estado para refetch de planillas

    return planilla;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const recrear_recibo_individual = createAsyncThunk('planilla/recrear_recibo_individual', async (planillaData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/planillas-recibos/generar-recibo-individual`, planillaData);

    const recibo = response.data;

    // Despachar acción para resetear el estado de fetching
    //thunkAPI.dispatch(resetStateGetPlanillas(planilla.buildingId));// Resetear el estado para refetch de planillas

    return recibo;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_planilla = createAsyncThunk('planilla/anular_planilla', async ({ idPlanilla, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_planilla con idPlanilla: " + idPlanilla);

  try {

    const response = await apiClient.patch(`/planillas-recibos/${idPlanilla}/anular`, { usuarioAnulacion });

    const planilla = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetPlanillas(planilla.buildingId));// Resetear el estado para refetch de planillas

    return planilla;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_planilla = createAsyncThunk('planilla/get_planilla', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_planilla con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/planillas-recibos/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const planilla = response.data;

    return planilla;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
