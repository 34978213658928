// src/components/MisAsistenciasPage.js

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_asistencias_x_usuario } from './asistenciaThunk';
import M from 'materialize-css';
import { Link } from 'react-router-dom';
import {formatFriendlyDate} from '../../utils/formateo';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const MisAsistenciasPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    useMaterializeTooltips();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const asistencias = useSelector((state) => state.asistencia.asistencias);
    const usuario = useSelector((state) => state.auth.usuario);
    const status_get_asistencias_x_usuario = useSelector((state) => state.asistencia.status_get_asistencias_x_usuario);
    const error_get_asistencias_x_usuario = useSelector((state) => state.asistencia.error_get_asistencias_x_usuario);
    const edificio = useSelector((state) => state.edificio.edificio);
    const [filtro, setFiltro] = useState('');

    useEffect(() => {
        if (edificio?.id) {
            dispatch(get_asistencias_x_usuario({ buildingId: edificio.id, codigo_usuario: usuario.id }));
        }
    }, [edificio, usuario, dispatch]);


    const handleFilterChange = (e) => {
        setFiltro(e.target.value);
    };


    // Función de filtrado mejorada
    const asistenciasFiltrados = Array.isArray(asistencias) ? asistencias.filter((asistencia) => {
        const hora_asistencia = formatFriendlyDate(asistencia.createdAtFormatted) || '';

        const filterLowerCase = filtro.toLowerCase();

        return (
            (asistencia.tipo_reunion || '').toLowerCase().includes(filterLowerCase) ||
            (asistencia.asunto || '').toLowerCase().includes(filterLowerCase) ||
            (asistencia.fecha_reunion || '').toLowerCase().includes(filterLowerCase) ||
            (asistencia.hora_inicio || '').toLowerCase().includes(filterLowerCase) ||
            (asistencia.modalidad || '').toLowerCase().includes(filterLowerCase) ||
            (hora_asistencia || '').toLowerCase().includes(filterLowerCase) ||
            (asistencia.token || '').toLowerCase().includes(filterLowerCase)
        );
    }) : [];


    if (status_get_asistencias_x_usuario === 'loading') {
        return <div>Loading...</div>;
    }

    if (status_get_asistencias_x_usuario === 'failed') {
        return <div>Error: {error_get_asistencias_x_usuario}</div>;
    }


    return (
        <div className="container">
            <div className="card">
                <div className="card-content">
                    <span className="card-title">Mis Asistencias </span>
                    <div className="card-action">

                        <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate(-1)}>
                            <i className="material-icons left">arrow_back</i>
                        </a>
                    </div>

                    <h5>Listado</h5>
                    <div className="col s12 m12 input-field">
                        <label htmlFor="filtro_tabla_mis_asistencias_propietario"><i className="material-icons">search</i> Filtrar</label>
                        <input
                            type="text"
                            id="filtro_tabla_mis_asistencias_propietario"
                            value={filtro}
                            onChange={handleFilterChange}
                        />
                    </div>
                    <table className="striped responsive-table tabla_mis_asistencias_propietario">
                        <thead>
                            <tr>
                                <th>Tipo</th>
                                <th>Fecha</th>
                                <th>Hora</th>
                                <th>Asunto</th>
                                <th>Modalidad</th>                                
                                <th>Asistencia</th>
                                <th>Token</th>
                            </tr>
                        </thead>
                        <tbody id="table-body-mis-asistencias_propietario">
                            {asistenciasFiltrados.map((asistencia) => (
                                <tr key={asistencia.id}>
                                    <td>{asistencia.tipo_reunion}</td>
                                    <td>{asistencia.fecha_reunion}</td>
                                    <td>{asistencia.hora_inicio}</td>
                                    <td>{asistencia.asunto}</td>
                                    <td>{asistencia.modalidad}</td>
                                    <td>{formatFriendlyDate(asistencia.createdAtFormatted)}</td>
                                    <td>{asistencia.token}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default MisAsistenciasPage;