import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetConciliaciones } from './conciliacionSlice';


export const update_conciliacion = createAsyncThunk('conciliacion/update_conciliacion', async ({ idConciliacion, conciliacionData }, thunkAPI) => {
  //console.log("ejecutando thunk update_conciliacion con idConciliacion: " + idConciliacion);

  try {

    const response = await apiClient.put(`/conciliaciones/${idConciliacion}`, conciliacionData);

    const conciliacion = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetConciliaciones(conciliacion.buildingId));// Resetear el estado para refetch de conciliaciones

    return conciliacion;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const conciliacion_masivo = createAsyncThunk('conciliacion/conciliacion_masivo', async (conciliacionData, thunkAPI) => {

    try {
  
      const response = await apiClient.post(`/conciliaciones/conciliacion_masivo`, conciliacionData);
  
      const conciliacion = response.data;
  
      // Despachar acción para resetear el estado de fetching
      thunkAPI.dispatch(resetStateGetConciliaciones(conciliacion.buildingId));// Resetear el estado para refetch de conciliaciones
  
      return conciliacion;
  
    } catch (error) {
      console.log("error.response.data " + error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
export const save_conciliacion = createAsyncThunk('conciliacion/save_conciliacion', async (conciliacionData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/conciliaciones`, conciliacionData);

    const conciliacion = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetConciliaciones(conciliacion.buildingId));// Resetear el estado para refetch de conciliaciones

    return conciliacion;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_conciliacion = createAsyncThunk('conciliacion/anular_conciliacion', async ({ idConciliacion, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_conciliacion con idConciliacion: " + idConciliacion);

  try {

    const response = await apiClient.patch(`/conciliaciones/${idConciliacion}/anular`, { usuarioAnulacion });

    const conciliacion = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetConciliaciones(conciliacion.buildingId));// Resetear el estado para refetch de conciliaciones

    return conciliacion;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_conciliacion = createAsyncThunk('conciliacion/get_conciliacion', async (id, thunkAPI) => {
  console.log("ejecutando thunk get_conciliacion con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/conciliaciones/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const conciliacion = response.data;

    return conciliacion;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_conciliaciones = createAsyncThunk('conciliacion/get_conciliaciones', async (codigo_unidad_facturacion, thunkAPI) => {
  console.log("ejecutando thunk get_conciliaciones con codigo_unidad_facturacion: "+codigo_unidad_facturacion);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/conciliaciones',{
        params: {codigo_unidad_facturacion}
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const conciliaciones = response.data;
          
      return conciliaciones ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const get_conciliaciones_admin = createAsyncThunk('conciliacion/get_conciliaciones_admin', async ({ tipo_area_comun, fecha_inicio, fecha_fin, estado_pago, estado, codigo_unidad_facturacion }, thunkAPI) => {
  console.log("ejecutando thunk get_conciliaciones_admin con codigo_unidad_facturacion: "+codigo_unidad_facturacion);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/conciliaciones/admin',{
        params: {
          tipo_area_comun,
          fecha_inicio,
          fecha_fin,
          estado_pago,
          estado,
          codigo_unidad_facturacion,
        },
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const conciliaciones = response.data;
          
      return conciliaciones ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
