import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetFacturaciones_individuales, resetState } from './facturacion_individualSlice';


export const update_facturacion_individual = createAsyncThunk('facturacion_individual/update_facturacion_individual', async ({ idFacturacion_individual, facturacion_individualData }, thunkAPI) => {
  //console.log("ejecutando thunk update_facturacion_individual con idFacturacion_individual: " + idFacturacion_individual);

  try {

    const response = await apiClient.put(`/facturaciones-individuales/${idFacturacion_individual}`, facturacion_individualData);

    const facturacion_individual = response.data;

    // Despachar acción para resetear el estado de fetching
    //thunkAPI.dispatch(resetStateGetFacturaciones_individuales(facturacion_individual.buildingId));// Resetear el estado para refetch de facturaciones_individuales
    thunkAPI.dispatch(resetState)
    
    return facturacion_individual;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_facturacion_individual = createAsyncThunk('facturacion_individual/save_facturacion_individual', async (facturacion_individualData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/facturaciones-individuales`, facturacion_individualData);

    const facturacion_individual = response.data;

    // Despachar acción para resetear el estado de fetching
    //thunkAPI.dispatch(resetStateGetFacturaciones_individuales(facturacion_individual.buildingId));// Resetear el estado para refetch de facturaciones_individuales
    thunkAPI.dispatch(resetState)

    return facturacion_individual;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_facturacion_individual = createAsyncThunk('facturacion_individual/anular_facturacion_individual', async ({ idFacturacion_individual, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_facturacion_individual con idFacturacion_individual: " + idFacturacion_individual);

  try {

    const response = await apiClient.patch(`/facturaciones-individuales/${idFacturacion_individual}/anular`, { usuarioAnulacion });

    const facturacion_individual = response.data;

    // Despachar acción para resetear el estado de fetching
    //thunkAPI.dispatch(resetStateGetFacturaciones_individuales(facturacion_individual.buildingId));// Resetear el estado para refetch de facturaciones_individuales
    thunkAPI.dispatch(resetState)

    return facturacion_individual;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_facturacion_individual = createAsyncThunk('facturacion_individual/get_facturacion_individual', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_facturacion_individual con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/facturaciones-individuales/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const facturacion_individual = response.data;

    return facturacion_individual;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_facturaciones_individuales = createAsyncThunk('facturacion_individual/get_facturaciones_individuales', async ({ buildingId, periodo, tipo_concepto, codigo_unidad_facturacion, tipo_facturacion }, thunkAPI) => {
  console.log("ejecutando thunk get_facturaciones_individuales con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/facturaciones-individuales',{
        params: { 
          buildingId, 
          periodo, 
          tipo_concepto, 
          codigo_unidad_facturacion, 
          tipo_facturacion 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const facturaciones_individuales = response.data;
          
      return facturaciones_individuales ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});

