import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';

export const get_recibos_x_depa = createAsyncThunk('recibos/get_recibos_x_depa', async (codigo_unidad_facturacion, thunkAPI) => {
    console.log("ejecutando thunk get_recibos_x_depa con "+codigo_unidad_facturacion);
    
    try { 
        //console.log("ejecutando thunk codigo_inmobiliario: "+codigo_inmobiliario);
        //const response = await apiClient.get('/recibos?codigo_unidad_facturacion=${codigo_unidad_facturacion}');
        
        const response = await apiClient.get('/recibos',{
            params: {codigo_unidad_facturacion}
        });
        
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const recibos = response.data;
            
        return recibos ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  export const update_recibo = createAsyncThunk('recibo/update_recibo', async ({ idRecibo, reciboData }, thunkAPI) => {
    //console.log("ejecutando thunk update_recibo con idRecibo: " + idRecibo);
  
    try {
  
      const response = await apiClient.put(`/recibos/${idRecibo}`, reciboData);
  
      const recibo = response.data;
  
      return recibo;
  
    } catch (error) {
      console.log("error.response.data " + error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
  
  export const save_recibo = createAsyncThunk('recibo/save_recibo', async (reciboData, thunkAPI) => {
  
    try {
  
      const response = await apiClient.post(`/recibos`, reciboData);
  
      const recibo = response.data;
  
      return recibo;
  
    } catch (error) {
      console.log("error.response.data " + error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
  
  export const anular_recibo = createAsyncThunk('recibo/anular_recibo', async ({ idRecibo, usuarioAnulacion }, thunkAPI) => {
    //console.log("ejecutando thunk anular_recibo con idRecibo: " + idRecibo);
  
    try {
  
      const response = await apiClient.patch(`/recibos/${idRecibo}/anular`, { usuarioAnulacion });
  
      const recibo = response.data;
  
      return recibo;
  
    } catch (error) {
      console.log("error.response.data " + error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
  
  export const get_recibo = createAsyncThunk('recibo/get_recibo', async (id, thunkAPI) => {
    //console.log("ejecutando thunk get_recibo con id: " + id);
  
    try {
  
      //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
      //parametro pasado por path
      const response = await apiClient.get(`/recibos/${id}`);
      //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
      const recibo = response.data;
  
      return recibo;
    } catch (error) {
      console.log("error.response.data " + error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
  
  export const get_recibos_x_planilla = createAsyncThunk('recibo/get_recibos_x_planilla', async (idPlanilla, thunkAPI) => {
      console.log("ejecutando thunk get_recibos_x_planilla con idPlanilla: " + idPlanilla);
    
      try {
    
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        //parametro pasado por path
        const response = await apiClient.get(`/planillas-recibos/${idPlanilla}/recibos`);
        
        //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
        const recibo = response.data;
    
        return recibo;
      } catch (error) {
        console.log("error.response.data " + error.response.data);
        return thunkAPI.rejectWithValue(error.response.data);
      }
    });
  
    export const get_recibos_admin = createAsyncThunk('recibo/get_recibos_admin', async ({ buildingId, periodo_inicio, periodo_fin, estado, estado_pago, codigo_unidad_facturacion }, thunkAPI) => {
      console.log("ejecutando thunk get_recibos_admin con codigo_unidad_facturacion: "+codigo_unidad_facturacion);
      
      try { 
          //console.log("ejecutando thunk buildingId: "+buildingId);      
          const response = await apiClient.get('/recibos/admin',{
            params: {
              buildingId,
              periodo_inicio,
              periodo_fin,
              estado,
              estado_pago,
              codigo_unidad_facturacion,
            },
        });
          //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
          const recibos = response.data;
              
          return recibos ;
        } catch (error) {
          console.log("error.response.data "+error.response.data );
          return thunkAPI.rejectWithValue(error.response.data);
        }
    });

    export const enviar_correo_recibo = createAsyncThunk('recibos/enviar_correo_recibo', async (envioCorreoData, thunkAPI) => {
    
      try {
    
        const response = await apiClient.post(`/recibos/enviar-correo`, envioCorreoData);
    
        const resultado = response.data;
    
        // Despachar acción para resetear el estado de fetching
        //thunkAPI.dispatch(resetStateGetPlanillas(planilla.buildingId));// Resetear el estado para refetch de planillas
    
        return resultado;
    
      } catch (error) {
        console.log("error.response.data " + error.response.data);
        return thunkAPI.rejectWithValue(error.response.data);
      }
    });
