// src/components/MisReservasAdmin.js

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from "date-fns";
import { get_reservas_admin } from './reservaThunk';
import { Link } from 'react-router-dom';
import ExcelJS from 'exceljs'; // 📥 Librería para exportar Excel
import { saveAs } from 'file-saver'; // 📥 Librería para descargar archivos
import './MisReservasAdmin.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import M from 'materialize-css/dist/js/materialize.min.js'; // Se mantiene solo para M.toast
import { clearReservas } from './reservaSlice';

import { formatDate, formatCurrency, formatPeriod, formatDate02, formatearPeriodo_MesAnio01, formatearImporte01 } from '../../utils/formateo';
import { getEstadoPagoButtonClass, getEstadoPagoIcon, downloadPDF } from '../../utils/util';
import {
    TIPO_AREA_COMUN_GIMNASIO, TIPO_AREA_COMUN_PARRILLA, TIPO_AREA_COMUN_SALA_YOGA
    , TIPO_AREA_COMUN_SUM, RESERVA_ESTADO_PAGO_Pendiente, RESERVA_ESTADO_PAGO_PorConfirmar,
    RESERVA_ESTADO_SEPARADO, RESERVA_ESTADO_RESERVADO, RESERVA_ESTADO_ANULADO, RESERVA_ESTADO_RECHAZADO
} from '../../constants/constants';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const MisReservasAdmin = ({ tipo_area_comun }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    useMaterializeTooltips();

    registerLocale('es', es);

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const reservas = useSelector((state) => state.reserva.reservas);
    const usuario = useSelector((state) => state.auth.usuario);
    const status = useSelector((state) => state.reserva.status_get_reservas_admin);
    const error = useSelector((state) => state.reserva.error_get_reservas_admin);
    const [filtro, setFiltro] = useState('');

    const [fechaInicio, setFechaInicio] = useState(new Date());
    const [fechaFin, setFechaFin] = useState(new Date());
    const [estadoPago, setEstadoPago] = useState("");
    const [estadoReserva, setEstadoReserva] = useState("");
    const [codigoUnidadFacturacion, setCodigoUnidadFacturacion] = useState("");

    useEffect(() => {
        //console.log("formData actualizado:", JSON.stringify(formData));

        // Inicializar los selects de Materialize
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields();
    }, [estadoPago, estadoReserva, dispatch, reservas]);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
        //M.AutoInit(); // Inicializa los componentes de Materialize CSS   
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        dispatch(clearReservas()); // 🔹 Limpiar reservas al cambiar de tipo de área común
    }, [tipo_area_comun, dispatch]);

    const handleBuscar = () => {
        if (!fechaInicio && !fechaFin && !estadoPago) {
            M.toast({ html: "Seleccione al menos un filtro para buscar.", classes: "red" });
            return;
        }

        // Convertimos las fechas a "YYYY-MM-DD"
        const fechaInicioFormatted = fechaInicio ? format(fechaInicio, "yyyy-MM-dd") : null;
        const fechaFinFormatted = fechaFin ? format(fechaFin, "yyyy-MM-dd") : null;

        dispatch(
            get_reservas_admin({
                tipo_area_comun,
                fecha_inicio: fechaInicioFormatted,
                fecha_fin: fechaFinFormatted,
                estado_pago: estadoPago !== "TODOS" ? estadoPago : null,
                estado: estadoReserva !== "TODOS" ? estadoReserva : null,
                codigo_unidad_facturacion: '',
            })
        );
    };


    const handleFilterChange = (e) => {
        setFiltro(e.target.value);
    };

    /** 🔹 Exportar datos a Excel */
    const handleExportExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Reservas');

        // Definir las columnas de Excel (Asegúrate de que todas coincidan con la UI)
        worksheet.columns = [
            { header: 'Área Común', key: 'area_comun', width: 20 },
            { header: 'Fecha Reserva', key: 'fecha_reserva', width: 15 },
            { header: 'Unidad Facturación', key: 'codigo_unidad_facturacion', width: 20 },
            { header: 'Estado', key: 'estado', width: 15 },
            { header: 'Estado Pago', key: 'estado_pago', width: 15 },
            { header: 'Codigo Alquiler', key: 'codigo_recaudacion_alquiler', width: 15 },
            { header: 'Registro', key: 'createdAtFormatted', width: 15 },
            { header: 'Propietario', key: 'propietarioNombre', width: 20 },
            { header: 'Turno', key: 'turno', width: 10 },
            { header: 'Banco Alq.', key: 'banco_alquiler', width: 15 },
            { header: 'Fecha Pago Alq.', key: 'fecha_abono_alquiler', width: 15 },
            { header: 'Hora Pago Alq.', key: 'hora_abono_alquiler', width: 15 },
            { header: 'Oper. Alq.', key: 'numero_operacion_alquiler', width: 15 },
            { header: 'Abono Alq.', key: 'importe_abono_alquiler', width: 15 },
            { header: 'Banco Gar.', key: 'banco_garantia', width: 15 },
            { header: 'Codigo Garantia', key: 'codigo_recaudacion_garantia', width: 15 },
            { header: 'Fecha Pago Gar.', key: 'fecha_abono_garantia', width: 15 },
            { header: 'Hora Pago Gar.', key: 'hora_abono_garantia', width: 15 },
            { header: 'Fecha Pago Gar.', key: 'fecha_abono_garantia', width: 15 },
            { header: 'Oper. Gar.', key: 'numero_operacion_garantia', width: 15 },
            { header: 'Abono Gar.', key: 'importe_abono_garantia', width: 15 },
            { header: 'Banco Dev.', key: 'banco_devolucion', width: 15 },
            { header: 'Cuenta Dev.', key: 'cuenta_devolucion', width: 20 },
            { header: 'DNI Dev.', key: 'dni_titular', width: 15 },
            { header: 'Titular Dev.', key: 'titular_cuenta', width: 20 }
        ];

        // Agregar filas con datos
        reservasFiltradas.forEach((reserva) => {
            worksheet.addRow({
                area_comun: reserva.area_comun,
                fecha_reserva: reserva.fecha_reserva,
                codigo_unidad_facturacion: reserva.codigo_unidad_facturacion,
                estado: reserva.estado,
                estado_pago: reserva.estado_pago,
                codigo_recaudacion_alquiler: reserva.recaudacion_alquiler?.codigo_recaudacion_alquiler ?? '-',
                createdAtFormatted: reserva.createdAtFormatted ?? '-',
                propietarioNombre: reserva.propietarioNombre ?? '-',
                turno: reserva.turno?.turno ?? '-',
                banco_alquiler: reserva.recaudacion_alquiler?.banco_alquiler ?? '-',
                fecha_abono_alquiler: reserva.recaudacion_alquiler?.conciliacion_fecha_pago ?? '-',
                hora_abono_alquiler: reserva.recaudacion_alquiler?.conciliacion_hora_pago ?? '-',
                numero_operacion_alquiler: reserva.recaudacion_alquiler?.conciliacion_numero_operacion ?? '-',
                importe_abono_alquiler: reserva.recaudacion_alquiler?.conciliacion_importe ?? '-',
                codigo_recaudacion_garantia: reserva.recaudacion_garantia?.codigo_recaudacion_garantia ?? '-',
                banco_garantia: reserva.recaudacion_garantia?.banco_alquiler ?? '-',
                fecha_abono_garantia: reserva.recaudacion_garantia?.conciliacion_fecha_pago ?? '-',
                hora_abono_garantia: reserva.recaudacion_garantia?.conciliacion_hora_pago ?? '-',
                numero_operacion_garantia: reserva.recaudacion_garantia?.conciliacion_numero_operacion ?? '-',
                importe_abono_garantia: reserva.recaudacion_garantia?.conciliacion_importe ?? '-',
                banco_devolucion: reserva.recaudacion_garantia?.banco_devolucion ?? 'SIN_BANCO_DEV',
                cuenta_devolucion: reserva.recaudacion_garantia?.cuenta_devolucion ?? 'SIN_CTA_DEV',
                dni_titular: reserva.recaudacion_garantia?.dni_titular ?? 'SIN_DNI_DEV',
                titular_cuenta: reserva.recaudacion_garantia?.titular_cuenta ?? 'SIN_TIT_DEV'
            });
        });

        // Estilos de encabezado
        worksheet.getRow(1).font = { bold: true, color: { argb: 'FFFFFF' } };
        worksheet.getRow(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '007bff' }
        };

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Reservas.xlsx');
    };


    const handleOpenCalendar = () => {
        if (tipo_area_comun === TIPO_AREA_COMUN_SUM) {
            navigate('/calendario-reservas-sum-admin');
        }
        if (tipo_area_comun === TIPO_AREA_COMUN_PARRILLA) {
            navigate('/calendario-reservas-parrilla-admin');
        }
        if (tipo_area_comun === TIPO_AREA_COMUN_GIMNASIO) {
            navigate('/calendario-reservas-gym-admin');
        }
        if (tipo_area_comun === TIPO_AREA_COMUN_SALA_YOGA) {
            navigate('/calendario-reservas-yoga-admin');
        }
    };

    // Función de filtrado mejorada
    const reservasFiltradas = Array.isArray(reservas) ? reservas.filter((reserva) => {
        const costo_reservaFormateado = formatearImporte01(reserva.turno.costo, reserva.moneda) || '';
        const garantia_reservaFormateado = formatearImporte01(reserva.turno.garantia, reserva.moneda) || '';
        const aforo_reservaFormateado = reserva.turno.aforo !== undefined && reserva.turno.aforo !== null ? reserva.turno.aforo.toString() : '';
        const estado_reservaFormateado = reserva.estado || '';
        const estado_pago_formateado = reserva.estado_pago || '';

        const filterLowerCase = filtro.toLowerCase();

        return (
            (reserva.area_comun || '').toLowerCase().includes(filterLowerCase) ||
            (reserva.fecha_reserva || '').toLowerCase().includes(filterLowerCase) ||
            (reserva.turno.turno_hora_inicio || '').toLowerCase().includes(filterLowerCase) ||
            (reserva.turno.turno_hora_fin || '').toLowerCase().includes(filterLowerCase) ||
            (reserva.codigo_unidad_facturacion || '').toLowerCase().includes(filterLowerCase) ||
            (costo_reservaFormateado.toLowerCase().includes(filterLowerCase)) ||
            (garantia_reservaFormateado.toLowerCase().includes(filterLowerCase)) ||
            (aforo_reservaFormateado.toLowerCase().includes(filterLowerCase)) ||
            (estado_reservaFormateado.toLowerCase().includes(filterLowerCase)) ||
            (estado_pago_formateado.toLowerCase().includes(filterLowerCase))
        );
    }) : [];

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'failed') {
        return <div>Error: {error}</div>;
    }

    // Función auxiliar para obtener la ruta de visualización según tipo_area_comun
    const getReservaAdminViewPath = (tipo_area_comun, id) => {
        const rutas = {
            "SUM": `/reserva-admin-sum/view/${id}`,
            "PARRILLA": `/reserva-admin-parrilla/view/${id}`,
            "GIMNASIO": `/reserva-admin-gym/view/${id}`,
            "YOGA": `/reserva-admin-yoga/view/${id}`
        };
        return rutas[tipo_area_comun] || `/reserva/view/${id}`;
    };

    return (
        <div className="container">
            <div className="card">
                <div className="card-content">
                    <span className="card-title">Mis Reservas {tipo_area_comun}</span>
                    <div className="card-action">
                        {/*
            <a className="btn orange tooltipped" data-position="top" data-tooltip="Nueva Reserva" onClick={handleCrearReserva}>
              <i className="material-icons left">add_box</i>Crear Reserva
            </a>
            */}

                        {!(tipo_area_comun === TIPO_AREA_COMUN_GIMNASIO || tipo_area_comun === TIPO_AREA_COMUN_SALA_YOGA) && (
                            <a className="btn purple tooltipped" data-position="top" data-tooltip="Ver Calendario" onClick={handleOpenCalendar}>
                                <i className="material-icons left">calendar_today</i>Calendario
                            </a>
                        )}                    
                        <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate("/dashboard")}>
                            <i className="material-icons left">arrow_back</i>
                        </a>
                        <a className="btn purple tooltipped" data-position="top" data-tooltip="Nueva Reserva" onClick={() => navigate("/reserva-admin/create")}>
                            <i className="material-icons left">add</i>
                        </a>
                        <a className="btn green tooltipped" data-position="top" data-tooltip="Volver" onClick={handleExportExcel}>
                            <i className="material-icons left">file_download</i>Descargar Excel
                        </a>
                    </div>

                    {/* Filtros */}
                    <div className="row">
                        <div className="input-field col s6 m3">
                            <DatePicker
                                selected={fechaInicio}
                                name="fecha_inicio"
                                onChange={setFechaInicio}
                                dateFormat="dd/MM/yyyy"
                                locale="es"
                                className="datepicker"
                                placeholderText="Fecha Inicio"
                            />
                            <label className="active" htmlFor="fecha_inicio">Fecha Inicio:</label>
                        </div>

                        <div className="input-field col s6 m3">
                            <DatePicker
                                selected={fechaFin}
                                name="fecha_fin"
                                onChange={setFechaFin}
                                dateFormat="dd/MM/yyyy"
                                className="datepicker"
                                locale="es"
                                placeholderText="Fecha Fin"
                            />
                            <label className="active" htmlFor="fecha_fin">Fecha Fin:</label>
                        </div>

                        <div className="input-field col s6 m3">
                            <select value={estadoReserva} onChange={(e) => setEstadoReserva(e.target.value)}>
                                <option value="TODOS">TODOS</option>
                                <option value={RESERVA_ESTADO_SEPARADO}>{RESERVA_ESTADO_SEPARADO}</option>
                                <option value={RESERVA_ESTADO_RESERVADO}>{RESERVA_ESTADO_RESERVADO}</option>
                                <option value={RESERVA_ESTADO_RECHAZADO}>{RESERVA_ESTADO_RECHAZADO}</option>
                                <option value={RESERVA_ESTADO_ANULADO}>{RESERVA_ESTADO_ANULADO}</option>
                            </select>
                            <label>Estado</label>
                        </div>

                        <div className="input-field col s6 m3">
                            <select
                                name="estado_pago"
                                value={estadoPago} onChange={(e) => setEstadoPago(e.target.value)}
                            >
                                <option value="TODOS">TODOS</option>
                                <option value={RESERVA_ESTADO_PAGO_Pendiente}>{RESERVA_ESTADO_PAGO_Pendiente}</option>
                                <option value={RESERVA_ESTADO_PAGO_PorConfirmar}>{RESERVA_ESTADO_PAGO_PorConfirmar}</option>

                            </select>
                            <label htmlFor="estado_pago">Estado de Pago</label>
                        </div>


                    </div>
                    <div className="col s12 m4">
                        <button className="btn blue" onClick={handleBuscar}>
                            <i className="material-icons left">search</i>Buscar
                        </button>
                    </div>

                    <h5>Listado</h5>
                    <div className="col s12 m12 input-field">
                        <label htmlFor="filtro_tabla_mis_reservas_propietario"><i className="material-icons">search</i> Filtrar</label>
                        <input
                            type="text"
                            id="filtro_tabla_mis_reservas_propietario"
                            value={filtro}
                            onChange={handleFilterChange}
                        />
                    </div>
                    <div className="table-container">
                        <table className="striped responsive-table tabla_mis_reservas_propietario">
                            <thead>
                                <tr>
                                    <th>Área</th>
                                    <th>Reserva</th>
                                    <th>Inicio</th>
                                    <th>Fin</th>
                                    <th>Unid.</th>
                                    <th>Costo</th>
                                    <th>Garantía</th>
                                    <th>Aforo</th>
                                    <th>Estado</th>
                                    <th>Pago</th>
                                    {/* Nuevas columnas para Constancia de Pago y Fecha de Carga */}
                                    <th>Codigo Alquiler</th>
                                    <th>Pago Alquiler</th>
                                    <th>Carga Alquiler</th>
                                    <th>Codigo Garantia</th>
                                    <th>Pago Garantia</th>
                                    <th>Carga Garantia</th>
                                    <th>Registro</th>
                                    <th>Propietario</th>
                                    <th>Turno</th>
                                    <th>Banco Alq.</th>
                                    <th>Fecha Pago Alq.</th>
                                    <th>Hora Pago Alq.</th>
                                    <th>Oper. Alq.</th>
                                    <th>Abono Alq.</th>
                                    <th>Banco Gar.</th>
                                    <th>Fecha Pago Gar.</th>
                                    <th>Hora Pago Gar.</th>
                                    <th>Oper. Gar.</th>
                                    <th>Abono Gar.</th>
                                    <th>Banco Dev.</th>
                                    <th>Cuenta Dev.</th>
                                    <th>DNI Dev.</th>
                                    <th>Titular Dev.</th>
                                </tr>
                            </thead>
                            <tbody id="table-body-mis-reservas_propietario">
                                {reservasFiltradas.map((reserva) => (
                                    <tr key={reserva.id}>
                                        <td>{reserva.area_comun}</td>
                                        <td>
                                            <Link to={getReservaAdminViewPath(reserva.tipo_area_comun, reserva.id)}>
                                                {reserva.fecha_reserva}
                                            </Link>
                                        </td>
                                        <td>{reserva.turno.turno_hora_inicio}</td>
                                        <td>{reserva.turno.turno_hora_fin}</td>
                                        <td>{reserva.codigo_unidad_facturacion}</td>
                                        <td>{formatearImporte01(reserva.turno.costo, reserva.moneda)}</td>
                                        <td>{formatearImporte01(reserva.turno.garantia, reserva.moneda)}</td>
                                        <td>{reserva.turno.aforo}</td>
                                        <td>{reserva.estado}</td>
                                        <td>{reserva.estado_pago}</td   >
                                        {/* Nueva columna: Constancia de Pago */}
                                        <td>
                                            {reserva.recaudacion_alquiler?.codigo_recaudacion_alquiler ? (
                                                <span>{reserva.recaudacion_alquiler.codigo_recaudacion_alquiler}</span>
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </td>
                                        <td>
                                            {reserva.recaudacion_alquiler?.constancia_pago_alquiler_url ? (
                                                <button
                                                    type="button"
                                                    className="btn blue tooltipped"
                                                    data-position="top"
                                                    data-tooltip="Visualizar Constancia de Pago"
                                                    onClick={() => window.open(reserva.recaudacion_alquiler.constancia_pago_alquiler_url, '_blank')}
                                                >
                                                    <i className="material-icons left">visibility</i>Visualizar
                                                </button>
                                            ) : (
                                                <span>No cargada</span>
                                            )}
                                        </td>
                                        {/* Nueva columna: Fecha de Carga */}
                                        <td>
                                            {reserva.recaudacion_alquiler?.fecha_constancia_alquiler_formateada ? (
                                                <span>{reserva.recaudacion_alquiler.fecha_constancia_alquiler_formateada}</span>
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </td>
                                        <td>
                                            {reserva.recaudacion_garantia?.codigo_recaudacion_garantia ? (
                                                <span>{reserva.recaudacion_garantia.codigo_recaudacion_garantia}</span>
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </td>
                                        <td>
                                            {reserva.recaudacion_garantia?.constancia_pago_garantia_url ? (
                                                <button
                                                    type="button"
                                                    className="btn blue tooltipped"
                                                    data-position="top"
                                                    data-tooltip="Visualizar Constancia de Pago"
                                                    onClick={() => window.open(reserva.recaudacion_garantia.constancia_pago_garantia_url, '_blank')}
                                                >
                                                    <i className="material-icons left">visibility</i>Visualizar
                                                </button>
                                            ) : (
                                                <span>No cargada</span>
                                            )}
                                        </td>
                                        {/* Nueva columna: Fecha de Carga */}
                                        <td>
                                            {reserva.recaudacion_garantia?.fecha_constancia_garantia_formateada ? (
                                                <span>{reserva.recaudacion_garantia.fecha_constancia_garantia_formateada}</span>
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </td>
                                        <td>{reserva.createdAtFormatted}</td>
                                        <td>{reserva.propietarioNombre}</td>
                                        <td>{reserva.turno.turno}</td>
                                        <td>{reserva.recaudacion_alquiler?.banco_alquiler ?? '-'}</td>
                                        <td>{reserva.recaudacion_alquiler?.conciliacion_fecha_pago ?? '-'}</td>
                                        <td>{reserva.recaudacion_alquiler?.conciliacion_hora_pago ?? '-'}</td>
                                        <td>{reserva.recaudacion_alquiler?.conciliacion_numero_operacion ?? '-'}</td>
                                        <td>{reserva.recaudacion_alquiler?.conciliacion_importe ?? '-'}</td>
                                        <td>{reserva.recaudacion_garantia?.banco_alquiler ?? '-'}</td>
                                        <td>{reserva.recaudacion_garantia?.conciliacion_fecha_pago ?? '-'}</td>
                                        <td>{reserva.recaudacion_garantia?.conciliacion_hora_pago ?? '-'}</td>
                                        <td>{reserva.recaudacion_garantia?.conciliacion_numero_operacion ?? '-'}</td>
                                        <td>{reserva.recaudacion_garantia?.conciliacion_importe ?? '-'}</td>
                                        <td>{reserva.recaudacion_garantia?.banco_devolucion ?? 'SIN_BANCO_DEV'}</td>
                                        <td>{reserva.recaudacion_garantia?.cuenta_devolucion ?? 'SIN_CTA_DEV'}</td>
                                        <td>{reserva.recaudacion_garantia?.dni_titular ?? 'SIN_DNI_DEV'}</td>
                                        <td>{reserva.recaudacion_garantia?.titular_cuenta ?? 'SIN_TIT_DEV'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MisReservasAdmin;