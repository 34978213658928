import { createSlice } from '@reduxjs/toolkit';
import {
    get_movimiento_crep, get_movimientos_crep, get_movimientos_crep_admin, update_movimiento_crep, save_movimiento_crep
    , anular_movimiento_crep, conciliar_crep
} from './movimiento_crepThunk';

const initialState = {
    movimiento_crep: null,
    movimientos_crep: [],
    movimiento_crepSeleccionada: null,
    status: 'idle',
    error: null,
    status_conciliacion: 'idle',
    error_conciliacion: null,
    status_get_movimientos_crep: 'idle',
    error_get_movimientos_crep: null,
    status_get_movimientos_crep_admin: 'idle',
    error_get_movimientos_crep_admin: null,
    status_update_movimiento_crep: 'idle',
    error_update_movimiento_crep: null,
    status_save_movimiento_crep: 'idle',
    error_save_movimiento_crep: null,
    status_anular_movimiento_crep: 'idle',
    error_anular_movimiento_crep: null,
}

const movimiento_crepSlice = createSlice({
    name: 'movimiento_crep',
    initialState,
    reducers: {
        setMovimiento_crepSeleccionada: (state, action) => {
            state.movimiento_crepSeleccionada = action.payload;

        },
        clearMovimiento_crepSeleccionada(state) {
            state.movimiento_crepSeleccionada = null;
            state.movimiento_crep = null;
        },
        resetStateGetMovimientos_crep: () => {
            return {
                status_get_movimientos_crep: 'idle',
            };
        },

        resetState: () => initialState, // 🔥 Reiniciar todo el estado al estado inicial
        clearMovimientos_crep: (state) => {
            state.movimientos_crep = []; // Limpiar movimientos_crep antes de una nueva consulta
            state.status_get_movimientos_crep_admin = 'idle';
            state.status_get_movimientos_crep_admin = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(get_movimiento_crep.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(get_movimiento_crep.fulfilled, (state, action) => {
                state.status = 'succeeded';
                //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
                //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
                state.movimiento_crep = action.payload;

            })
            .addCase(get_movimiento_crep.rejected, (state, action) => {
                //console.log("ejecutando Slice failed rejected ");
                state.status = 'failed';
                //console.log("error action.error.message " + action.error.message);
                state.error = action.payload || 'Failed to fetch building';
            })

            .addCase(update_movimiento_crep.pending, (state) => {
                state.status_update_movimiento_crep = 'loading';
            })
            .addCase(update_movimiento_crep.fulfilled, (state, action) => {
                state.status_update_movimiento_crep = 'succeeded';
                //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
                //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
                state.movimiento_crep = action.payload.movimiento_crep;

            })
            .addCase(update_movimiento_crep.rejected, (state, action) => {
                //console.log("ejecutando Slice failed rejected ");
                state.status_update_movimiento_crep = 'failed';
                //console.log("error action.error.message " + action.error.message);
                state.error_update_movimiento_crep = action.payload || 'Failed to fetch building';
            })
            .addCase(save_movimiento_crep.pending, (state) => {
                state.status_save_movimiento_crep = 'loading';
            })
            .addCase(save_movimiento_crep.fulfilled, (state, action) => {
                state.status_save_movimiento_crep = 'succeeded';
                //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
                //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
                state.movimiento_crep = action.payload.movimiento_crep;

            })
            .addCase(save_movimiento_crep.rejected, (state, action) => {
                //console.log("ejecutando Slice failed rejected ");
                state.status_save_movimiento_crep = 'failed';
                //console.log("error action.error.message " + action.error.message);
                state.error_save_movimiento_crep = action.payload || 'Failed to fetch building';
            })
            .addCase(anular_movimiento_crep.pending, (state) => {
                state.status_save_movimiento_crep = 'loading';
            })
            .addCase(anular_movimiento_crep.fulfilled, (state, action) => {
                state.status_anular_movimiento_crep = 'succeeded';
                //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
                //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
                state.movimiento_crep = action.payload.movimiento_crep;

            })
            .addCase(anular_movimiento_crep.rejected, (state, action) => {
                //console.log("ejecutando Slice failed rejected ");
                state.status_anular_movimiento_crep = 'failed';
                //console.log("error action.error.message " + action.error.message);
                state.error_anular_movimiento_crep = action.payload || 'Failed to fetch building';
            })
            .addCase(get_movimientos_crep.pending, (state) => {
                state.status_get_movimientos_crep = 'loading';

            })
            .addCase(get_movimientos_crep.fulfilled, (state, action) => {
                state.status_get_movimientos_crep = 'succeeded';
                //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
                //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
                state.movimientos_crep = action.payload;

            })
            .addCase(get_movimientos_crep.rejected, (state, action) => {
                //console.log("ejecutando Slice failed rejected ");
                state.status_get_movimientos_crep = 'failed';
                //console.log("error action.error.message " + action.error.message);
                state.error_get_movimientos_crep = action.payload || 'Failed to fetch building';
            })

            .addCase(get_movimientos_crep_admin.pending, (state) => {
                state.status_get_movimientos_crep_admin = 'loading';

            })
            .addCase(get_movimientos_crep_admin.fulfilled, (state, action) => {
                state.status_get_movimientos_crep_admin = 'succeeded';
                //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
                //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
                state.movimientos_crep = action.payload;

            })
            .addCase(get_movimientos_crep_admin.rejected, (state, action) => {
                //console.log("ejecutando Slice failed rejected ");
                state.status_get_movimientos_crep_admin = 'failed';
                //console.log("error action.error.message " + action.error.message);
                state.error_get_movimientos_crep_admin = action.payload || 'Failed to fetch building';
            })

            .addCase(conciliar_crep.pending, (state) => {
                state.status_conciliacion = 'loading';
            })
            .addCase(conciliar_crep.fulfilled, (state) => {
                state.status_conciliacion = 'succeeded';
            })
            .addCase(conciliar_crep.rejected, (state, action) => {
                state.status_conciliacion = 'failed';
                state.error_conciliacion = action.payload || 'Error en la conciliación';
            });

    },
});

export const { setMovimiento_crepSeleccionada, clearMovimiento_crepSeleccionada, clearMovimientos_crep } = movimiento_crepSlice.actions;
export const selectMovimientos_crepById = (state, idMovimiento_crep) =>
    state.movimiento_crep.movimientos_crep.find((movimiento_crep) => movimiento_crep.id === idMovimiento_crep);
export const { resetStateGetMovimientos_crep, resetState } = movimiento_crepSlice.actions;
export default movimiento_crepSlice.reducer;
