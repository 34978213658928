import { createSlice } from '@reduxjs/toolkit';
import { get_conciliacion, get_conciliaciones, get_conciliaciones_admin, update_conciliacion, save_conciliacion
  , anular_conciliacion, conciliacion_masivo} from './conciliacionThunk';

const initialState= {
  conciliacion: null,
  conciliaciones: [],
  conciliacionSeleccionada: null,
  conciliacionData: null,
  status: 'idle',
  error: null,
  status_get_conciliaciones: 'idle',
  error_get_conciliaciones: null,    
  status_conciliacion_masivo: 'idle',
  error_conciliacion_masivo: null,    
  status_get_conciliaciones_admin: 'idle',
  error_get_conciliaciones_admin: null,
  status_update_conciliacion: 'idle',
  error_update_conciliacion: null,    
  status_save_conciliacion: 'idle',
  error_save_conciliacion: null,
  status_anular_conciliacion: 'idle',
  error_anular_conciliacion: null,
}

const conciliacionSlice = createSlice({
  name: 'conciliacion',
  initialState,
  reducers: {
    setConciliacionSeleccionada: (state, action) => {
      state.conciliacionSeleccionada = action.payload;
      
    },
    clearConciliacionSeleccionada(state) {
      state.conciliacionSeleccionada = null;
      state.conciliacion = null;
    },
    resetStateGetConciliaciones: () => {
        return {
            status_get_conciliaciones : 'idle',
        };
      },
     
    resetState: () => initialState, // 🔥 Reiniciar todo el estado al estado inicial
    clearConciliaciones: (state) => {
      state.conciliaciones = []; // Limpiar conciliaciones antes de una nueva consulta
      state.status_get_conciliaciones_admin = 'idle';
      state.status_get_conciliaciones_admin = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_conciliacion.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_conciliacion.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.conciliacion = action.payload;

      })
      .addCase(get_conciliacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })

      .addCase(update_conciliacion.pending, (state) => {
        state.status_update_conciliacion = 'loading';
      })
      .addCase(update_conciliacion.fulfilled, (state, action) => {
        state.status_update_conciliacion = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.conciliacion = action.payload.conciliacion;

      })
      .addCase(update_conciliacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_conciliacion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_conciliacion = action.payload || 'Failed to fetch building';
      })
      
      .addCase(conciliacion_masivo.pending, (state) => {
        state.status_conciliacion_masivo = 'loading';
      })
      .addCase(conciliacion_masivo.fulfilled, (state, action) => {
        state.status_conciliacion_masivo = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.conciliacionData = action.payload.conciliacionData;
      })
      .addCase(conciliacion_masivo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_conciliacion_masivo = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_conciliacion_masivo = action.payload || 'Failed to fetch building';
      })

      .addCase(save_conciliacion.pending, (state) => {
        state.status_save_conciliacion = 'loading';
      })
      .addCase(save_conciliacion.fulfilled, (state, action) => {
        state.status_save_conciliacion = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.conciliacion = action.payload.conciliacion;

      })
      .addCase(save_conciliacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_conciliacion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_conciliacion = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_conciliacion.pending, (state) => {
        state.status_save_conciliacion = 'loading';
      })
      .addCase(anular_conciliacion.fulfilled, (state, action) => {
        state.status_anular_conciliacion = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.conciliacion = action.payload.conciliacion;

      })
      .addCase(anular_conciliacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_conciliacion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_conciliacion = action.payload || 'Failed to fetch building';
      })
      .addCase(get_conciliaciones.pending, (state) => {
        state.status_get_conciliaciones = 'loading';
        
      })
      .addCase(get_conciliaciones.fulfilled, (state, action) => {
        state.status_get_conciliaciones = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.conciliaciones = action.payload;

      })
      .addCase(get_conciliaciones.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_conciliaciones = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_conciliaciones = action.payload || 'Failed to fetch building';
      })      

      .addCase(get_conciliaciones_admin.pending, (state) => {
        state.status_get_conciliaciones_admin = 'loading';
        
      })
      .addCase(get_conciliaciones_admin.fulfilled, (state, action) => {
        state.status_get_conciliaciones_admin = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.conciliaciones = action.payload;

      })
      .addCase(get_conciliaciones_admin.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_conciliaciones_admin = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_conciliaciones_admin = action.payload || 'Failed to fetch building';
      })   
  
  },
});

export const { setConciliacionSeleccionada, clearConciliacionSeleccionada, clearConciliaciones } = conciliacionSlice.actions;
export const selectConciliacionesById = (state, idConciliacion) =>
    state.conciliacion.conciliaciones.find((conciliacion) => conciliacion.id === idConciliacion);
export const { resetStateGetConciliaciones, resetState } = conciliacionSlice.actions;
export default conciliacionSlice.reducer;
