import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_persona_x_documento } from './personaThunk'; 
import { clearPersonaSeleccionada } from './personaSlice';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';


const BuscarPersonaModal = ({ isOpen, onClose, onAddPropietario }) => {
  const dispatch = useDispatch();

  // Campos para buscar
  const [tipoDocumento, setTipoDocumento] = useState('DNI');
  const [numeroDocumento, setNumeroDocumento] = useState('');

  // Para manejar estados y errores
  const { persona, fetchStatus, error } = useSelector((state) => state.persona);

  useEffect(() => {
    if (isOpen) {
      const elems = document.querySelectorAll('select');
      M.FormSelect.init(elems);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      // Si se cierra el modal, limpiamos
      dispatch(clearPersonaSeleccionada());
    }
  }, [isOpen, dispatch]);

  // Manejo de la búsqueda
  const handleBuscar = () => {
    if (!tipoDocumento || !numeroDocumento) return;
    dispatch(
      get_persona_x_documento({
        tipo_documento: tipoDocumento,
        numero_documento: numeroDocumento,
      })
    );
  };

  // Manejo de agregar al padre
  const handleAgregar = () => {
    if (persona) {
      // Llamamos al callback para que el padre agregue a "propietarios"
      onAddPropietario({
        id: persona.id,
        tipo_documento: persona.tipo_documento,
        numero_documento: persona.numero_documento,
        nombres: persona.titular,
      });
      // Cerrar e inicializar
      setTipoDocumento('DNI');
      setNumeroDocumento('');
      onClose();
    }
  };

  const handleCerrar = () => {
    // Limpiamos e invocamos onClose
    setTipoDocumento('DNI');
    setNumeroDocumento('');


    onClose();
  };

  if (!isOpen) return null; // Si no está abierto, no renderizamos nada

  return (
    <div
      style={{
        position: 'fixed',
        top: 0, left: 0,
        width: '100%', height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          background: '#fff',
          borderRadius: '8px',
          padding: '1.5rem',
          width: '400px',
          maxWidth: '90%',
        }}
      >
        <h5>Buscar Propietario</h5>
        <div className="input-field">
          <select
            value={tipoDocumento}
            onChange={(e) => setTipoDocumento(e.target.value)}
          >
            <option value="DNI">DNI</option>
            <option value="RUC">RUC</option>
            <option value="CEX">CEX</option>
            <option value="PAS">PAS</option>
            <option value="SD">SD</option>
          </select>
          
        </div>

        <div className="input-field">
          <input
            type="text"
            value={numeroDocumento}
            onChange={(e) => setNumeroDocumento(e.target.value)}
          />
          <label className={numeroDocumento ? 'active' : ''}>Número Documento</label>
        </div>

        <button className="btn" onClick={handleBuscar} style={{ marginRight: '1rem' }}>
          Buscar
        </button>
        <button className="btn grey" onClick={handleCerrar}>
          Cerrar
        </button>

        {/* Manejo de estados del fetch */}
        {fetchStatus === 'loading' && <p>Cargando...</p>}
        {fetchStatus === 'failed' && (
          <p style={{ color: 'red' }}>Error: {error}</p>
        )}
        {fetchStatus === 'succeeded' && persona && (
          <div style={{ marginTop: '1rem' }}>
            <strong>Persona Encontrada</strong>
            <p>Tipo Doc: {persona.tipo_documento}</p>
            <p>Número: {persona.numero_documento}</p>
            <p>Nombres: {persona.titular}</p>
            <button className="btn green" onClick={handleAgregar} style={{ marginTop: '0.5rem' }}>
              Agregar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BuscarPersonaModal;
