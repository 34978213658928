import { createSlice } from '@reduxjs/toolkit';
import { get_edificio_x_codigo, update_edificio } from './edificioThunk';

const initialState = {
  edificio: null,
  edificioSeleccionado: null,
  status: 'idle',
  error: null,
  status_update_edificio: 'idle',
  error_update_edificio: null,
}

const edificioSlice = createSlice({
  name: 'edificio',
  initialState,
  reducers: {
    setEdificioSeleccionado: (state, action) => {
      //console.log(" Actualizando redux de edificioSeleccionado por state.edificioSeleccionado "+JSON.stringify(state.edificioSeleccionado))
      state.edificioSeleccionado = action.payload;
      state.edificio = action.payload;
      //console.log("DESPUES act. redux de edificioSeleccionado por state.edificioSeleccionado "+JSON.stringify(state.edificioSeleccionado))

    },
    resetState: () => initialState, // 🔥 Reiniciar todo el estado al estado inicial
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_edificio_x_codigo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_edificio_x_codigo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.edificio = action.payload;

      })
      .addCase(get_edificio_x_codigo.rejected, (state, action) => {
        console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })

      .addCase(update_edificio.pending, (state) => {
        state.status_update_edificio = 'loading';
      })
      .addCase(update_edificio.fulfilled, (state, action) => {
        state.status_update_edificio = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.edificio = action.payload.edificio;

      })
      .addCase(update_edificio.rejected, (state, action) => {
        console.log("ejecutando Slice failed rejected ");
        state.status_update_edificio = 'failed';
        console.log("error action.error.message " + action.error.message);
        state.error_update_edificio = action.payload || 'Failed to fetch building';
      })

  },
});

export const { setEdificioSeleccionado, resetState } = edificioSlice.actions;
export default edificioSlice.reducer;
