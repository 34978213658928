import React, { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL, connectStorageEmulator } from 'firebase/storage';
import { storage } from '../../firebase';
import M from 'materialize-css'; // Importa Materialize CSS
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { TIPO_DOC_DNI, TIPO_DOC_CEX, TIPO_DOC_SD, TIPO_DOC_RUC, TIPO_DOC_PAS } from './../../constants/constants';

const Carga_unidades_facturacion = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const edificio = useSelector(state => state.edificioSeleccionado.edificio);
  const usuario = useSelector((state) => state.auth.usuario);

  useEffect(() => {
    // Inicializar Materialize JS components
    M.AutoInit();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;
    setUploading(true);
    setSuccess(null);
    setError(null);

    // Validar el archivo CSV antes de subirlo
    const validationResult = await validateCSVFile(file);
    if (!validationResult.valid) {
      setUploading(false);
      setError(validationResult.error);
      return;
    }

    const uniqueId = uuidv4();
    const fileName = `CARGA-UNID-FACTURACION-${edificio.codigo}-${uniqueId}.csv`;

    // Conectar al emulador de Storage si se está ejecutando en local
    if (window.location.hostname === 'localhost') {
      connectStorageEmulator(storage, 'localhost', 9199);
    }

    const storageRef = ref(storage, `CargaAportantes/${fileName}`);

    const metadata = {
      customMetadata: {
        buildingId: edificio.id,
        usuario_carga: usuario.id,
        uid_carga: uniqueId,
      }
    };

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Optionally handle progress
      },
      (error) => {
        setUploading(false);
        setError(error.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUploading(false);
          setSuccess('File uploaded successfully!');
          triggerCloudFunction(downloadURL);
        });
      }
    );
  };

  const triggerCloudFunction = async (downloadURL) => {
    // Lógica para desencadenar la Cloud Function (si es necesario)
  };

  // Función para validar el archivo CSV antes de subirlo
  const validateCSVFile = async (csvFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const text = event.target.result;
        const lines = text.split(/\r?\n/);
        //const lines = text.split(/\r?\n/).filter(line => line.trim() !== ''); // Filtra líneas vacías

        // Eliminar la última línea si está vacía
        if (lines.length > 1 && lines[lines.length - 1].trim() === '') {
          lines.pop();
        }
        // Detectar el delimitador en la primera línea
        const delimiters = [',', ';'];
        let delimiter = delimiters.find(d => lines[0].includes(d)) || ',';

        // Validar la cabecera
        const header = lines[0].split(delimiter);
        if (
          header[0].trim() !== 'Edificio' ||
          header[1].trim() !== 'Orden' || // Nueva validación para la columna "Orden"
          header[2].trim() !== 'Codigo_unidad_facturacion' ||
          header[3].trim() !== 'Tipo_doc_propietario_1' ||
          header[4].trim() !== 'Num_doc_propietario_1' ||
          header[5].trim() !== 'Tipo_doc_propietario_2' ||
          header[6].trim() !== 'Num_doc_propietario_2' ||
          header[7].trim() !== 'Unid_inmobiliaria_1' ||
          header[8].trim() !== 'Unid_inmobiliaria_2' ||
          header[9].trim() !== 'Unid_inmobiliaria_3' ||
          header[10].trim() !== 'Unid_inmobiliaria_4' ||
          header[11].trim() !== 'Unid_inmobiliaria_5' ||
          header[12].trim() !== 'Unid_inmobiliaria_6' ||
          header[13].trim() !== 'Unid_inmobiliaria_7' ||
          header[14].trim() !== 'Unid_inmobiliaria_8' ||
          header[15].trim() !== 'Unid_inmobiliaria_9' ||
          header[16].trim() !== 'Unid_inmobiliaria_10'
        ) {
          resolve({ valid: false, error: 'Cabecera inválida. Asegúrate de que la cabecera contenga las columnas "Edificio", "Orden", "Codigo_unidad_facturacion", "Tipo_doc_propietario_1", "Num_doc_propietario_1", "Tipo_doc_propietario_2", "Num_doc_propietario_2", "Unid_inmobiliaria_1", "Unid_inmobiliaria_2", "Unid_inmobiliaria_3", "Unid_inmobiliaria_4", "Unid_inmobiliaria_5", "Unid_inmobiliaria_6", "Unid_inmobiliaria_7", "Unid_inmobiliaria_8", "Unid_inmobiliaria_9", "Unid_inmobiliaria_10".' });
          return;
        }

        // Validar cada línea de datos
        console.log(" SEGUIMEINTO lines.length " + lines.length)
        for (let i = 1; i < lines.length; i++) {
          const line = lines[i].split(delimiter);
          //console.log(` ===> fila ${i} lines[i] `+lines[i])


          const codigoEdificio = line[0].trim();
          if (codigoEdificio !== edificio.codigo) {
            resolve({ valid: false, error: `El código de edificio en la fila ${i + 1} no coincide con el edificio actual ${edificio.codigo}.` });
            return;
          }

          const orden = line[1].trim(); // Validar la columna "Orden"
          if (!orden) {
            resolve({ valid: false, error: `El valor de "Orden" en la fila ${i + 1} está vacío.` });
            return;
          }

          const codigo_unidad_facturacion = line[2].trim();
          if (!codigo_unidad_facturacion) {
            resolve({ valid: false, error: `El código de unidad inmobiliaria en la fila ${i + 1} está vacío.` });
            return;
          }

          const tipo_doc_propietario_1 = line[3].trim();
          if (!tipo_doc_propietario_1) {
            resolve({ valid: false, error: `El tipo de documento de propietario1 en la fila ${i + 1} está vacío.` });
            return;
          }

          const num_doc_propietario_1 = line[4].trim();
          if (!num_doc_propietario_1) {
            resolve({ valid: false, error: `El número de documento de propietario1 en la fila ${i + 1} está vacío.` });
            return;
          }

          const validDocs = [TIPO_DOC_DNI, TIPO_DOC_CEX, TIPO_DOC_SD, TIPO_DOC_RUC, TIPO_DOC_PAS];
          const tipo_doc_propietario_2 = line[5].trim();
          const num_doc_propietario_2 = line[6].trim();

          const hasValidOwner = (tipo_doc_propietario_1 && num_doc_propietario_1 && validDocs.includes(tipo_doc_propietario_1)) ||
            (tipo_doc_propietario_2 && num_doc_propietario_2 && validDocs.includes(tipo_doc_propietario_2));

          if (!hasValidOwner) {
            resolve({ valid: false, error: `Datos de propietario inválidos o incompletos en la fila ${i + 1}.` });
            return;
          }

          const unid_inmobiliaria_1 = line[7].trim();
          const unid_inmobiliaria_2 = line[8].trim();
          const unid_inmobiliaria_3 = line[9].trim();
          const unid_inmobiliaria_4 = line[10].trim();
          const unid_inmobiliaria_5 = line[11].trim();
          const unid_inmobiliaria_6 = line[12].trim();
          const unid_inmobiliaria_7 = line[13].trim();
          const unid_inmobiliaria_8 = line[14].trim();
          const unid_inmobiliaria_9 = line[15].trim();
          const unid_inmobiliaria_10 = line[16].trim();

          const hasValidUnit = [unid_inmobiliaria_1, unid_inmobiliaria_2, unid_inmobiliaria_3, unid_inmobiliaria_4, unid_inmobiliaria_5, unid_inmobiliaria_6, unid_inmobiliaria_7, unid_inmobiliaria_8, unid_inmobiliaria_9, unid_inmobiliaria_10].some(u => u);

          if (!hasValidUnit) {
            resolve({ valid: false, error: `Debe existir al menos una unidad inmobiliaria en la fila ${i + 1}.` });
            return;
          }
        }

        resolve({ valid: true });
      };

      reader.onerror = (event) => {
        reject('Error al leer el archivo.');
      };

      reader.readAsText(csvFile);
    });
  };

  return (
    <div className="container">
      <h3>Subir Archivo CSV</h3>
      <div className="file-field input-field">
        <div className="btn">
          <span>Archivo</span>
          <input type="file" accept=".csv" onChange={handleFileChange} />
        </div>
        <div className="file-path-wrapper">
          <input className="file-path validate" type="text" />
        </div>
      </div>
      <button className="btn" onClick={handleUpload} disabled={uploading}>
        {uploading ? 'Subiendo...' : 'Subir'}
      </button>
      {uploading && (
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      )}
      {success && <p className="green-text">{success}</p>}
      {error && <p className="red-text">{error}</p>}
    </div>
  );
};

export default Carga_unidades_facturacion;
