// frontend/src/pages/ListaReunionesPage.js 
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { get_reuniones_x_edificio } from './reunionThunk';
import M from 'materialize-css';

import { 
    formatDate, 
    formatCurrency, 
    formatPeriod, 
    formatDate02, 
    formatearPeriodo_MesAnio01, 
    formatearImporte01 
} from '../../utils/formateo';
import { downloadPDF } from '../../utils/util';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const ListaReunionesPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useMaterializeTooltips();

    // Selectores del estado de Redux
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const reuniones = useSelector((state) => state.reunion.reuniones) || [];
    const usuario = useSelector((state) => state.auth.usuario);
    const status_get_reuniones_x_edificio = useSelector((state) => state.reunion.status_get_reuniones_x_edificio);
    const edificio = useSelector((state) => state.edificio.edificio);
    const error_get_reuniones_x_edificio = useSelector((state) => state.reunion.error_get_reuniones_x_edificio);
    

    const [filtro, setFiltro] = useState('');

    // Verificar autenticación y redireccionar si no está autenticado
    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    // Inicializar Materialize CSS y cargar reuniones
    useEffect(() => {
        M.AutoInit(); // Inicializa los componentes de Materialize CSS
        //console.log("ANTES status_get_reuniones_x_edificio:", status_get_reuniones_x_edificio);
        
        if (edificio?.id) {
                dispatch(get_reuniones_x_edificio(
                    {
                        buildingId: edificio.id
                    }
                )); 
            //}
        } else {
            console.log("Edificio, edificio.id o ambos no están definidos.");
        }
}, [edificio, dispatch]);

    if (!usuario) {
        return null; // Evita renderizar mientras se verifica la autenticación
    }

    const handleFilterChange = (e) => {    
        setFiltro(e.target.value.toLowerCase());
    };

    const reunionesFiltrados = reuniones.filter((reunion) => {
        const filter = filtro.trim();
        if (filter === '') return true; // Si el filtro está vacío, mostrar todos

        // Asegurar que los campos existen antes de aplicarle métodos
        const tipo_reunion = reunion.tipo_reunion?.toLowerCase() || '';
        const convocante = reunion.convocante?.toLowerCase() || '';
        const fecha_reunion = reunion.fecha_reunion?.toLowerCase() || '';
        const modalidad = reunion.modalidad?.toLowerCase() || '';
        const fecha_convocatoria = reunion.fecha_convocatoria?.toLowerCase() || '';
        const estado = reunion.estado?.toLowerCase() || '';

        return (
            tipo_reunion.includes(filter) ||
            convocante.includes(filter) ||
            fecha_reunion.includes(filter) ||
            modalidad.includes(filter) ||
            fecha_convocatoria.includes(filter) ||     
            estado.includes(filter)
        );
    });

    return (
        <div className="container">
            <div className="card">
                <div className="card-content">
                    <span className="card-title">Reuniones</span>
                    <div className="card-action">
                        <button 
                            className="btn orange tooltipped" 
                            data-position="top" 
                            data-tooltip="Nuevoa Reunión" 
                            onClick={() => navigate(`/reunion/create`)}
                        >
                            <i className="material-icons left">add_box</i>Crear Reunión
                        </button>
                        <button 
                            className="btn blue tooltipped" 
                            data-position="top" 
                            data-tooltip="Volver" 
                            onClick={() => navigate('/dashboard')}
                        >
                            <i className="material-icons left">arrow_back</i>Volver
                        </button>              
                    </div>
                    
                    <h5>Listado</h5>

                    <div className="input-field">
                        <i className="material-icons prefix">search</i>
                        <input 
                            type="text" 
                            id="filtro_tabla_mis_reuniones_propietario" 
                            value={filtro}
                            onChange={handleFilterChange} 
                            placeholder="Filtrar por cualquier campo" 
                        />
                    </div>

                    {status_get_reuniones_x_edificio === 'loading' && (
                        <div className="progress">
                            <div className="indeterminate"></div>
                        </div>
                    )}

                    {status_get_reuniones_x_edificio === 'failed' && (
                        <div className="card-panel red lighten-4 red-text text-darken-4">
                            <span>Error: {error_get_reuniones_x_edificio}</span>
                        </div>
                    )}

                    {status_get_reuniones_x_edificio === 'succeeded' && reunionesFiltrados.length === 0 && (
                        <div className="card-panel yellow lighten-4 yellow-text text-darken-4">
                            <span>No se encontraron reuniones que coincidan con el filtro.</span>
                        </div>
                    )}

                    {status_get_reuniones_x_edificio === 'succeeded' && reunionesFiltrados.length > 0 && (
                        <table className="striped responsive-table tabla_mis_reuniones_propietario">
                            <thead>
                                <tr>
                                    <th>Tipo</th>
                                    <th>Fecha</th>
                                    <th>Conv.</th>
                                    <th>Modalidad</th>
                                    <th>Fecha</th>
                                    <th>Estado</th>
                                    <th>Registrador</th>
                                </tr>
                            </thead>
                            <tbody id="table-body-mis-reuniones_propietario">                    
                                {reunionesFiltrados.map((reunion) => (
                                    <tr key={reunion.id}>                                        
                                        <td>{reunion.tipo_reunion}</td>
                                        <td>
                                            <Link to={`/reunion/view/${reunion.id}`}>
                                            {reunion.fecha_reunion}
                                            </Link>
                                        </td>                                        
                                        <td>{reunion.convocante}</td>
                                        <td>{reunion.modalidad}</td>                                        
                                        <td>{reunion.fecha_convocatoria}</td>
                                        <td>{reunion.estado}</td>
                                        <td>{reunion.usuarioRegistrador || 'N/A'}</td>                                        
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>   
            </div> 
        </div>
    );
};

export default ListaReunionesPage;
