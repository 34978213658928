import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { get_facturaciones_individuales } from './facturacion_individualThunk';
import M from 'materialize-css';
import DatePicker from 'react-datepicker';
import { TIPOS_CONCEPTO, TIPOS_FACTURACION } from '../../constants/constants';
import { formatDate, formatCurrency, formatPeriod, formatDate02, formatearPeriodo_MesAnio01, formatearImporte01 } from '../../utils/formateo';
import { parseFromYYYYMM, formatPeriodo } from '../../utils/util';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { format } from 'date-fns';
import ExcelJS from 'exceljs';

const MisFacturaciones_individuales = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useMaterializeTooltips();

    registerLocale('es', es);

    // Obtener el periodo actual en formato YYYY-MM
    //const periodoActual = formatPeriodo(new Date());

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const facturaciones_individuales = useSelector((state) => state.facturacion_individual.facturaciones_individuales) || [];
    const edificio = useSelector((state) => state.edificio.edificio);
    const status_get_facturaciones_individuales = useSelector((state) => state.facturacion_individual.status_get_facturaciones_individuales);
    const error_get_facturaciones_individuales = useSelector((state) => state.facturacion_individual.error_get_facturaciones_individuales);

    const [filtroPeriodo, setFiltroPeriodo] = useState(formatPeriodo(new Date()));
    const [filtroTipoConcepto, setFiltroTipoConcepto] = useState('');
    const [filtroCodigoUnidad, setFiltroCodigoUnidad] = useState('');
    const [filtroTipoFacturacion, setFiltroTipoFacturacion] = useState('');
    const [sumatoriaImportes, setSumatoriaImportes] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
            return;
        }
        M.AutoInit(); // Inicializa los componentes de Materialize CSS
    }, [isAuthenticated, navigate]);


    const handlePeriodoChange = (date) => {
        const periodoFormateado = formatPeriodo(date);
        console.log("periodoFormateado " + periodoFormateado);
        setFiltroPeriodo(periodoFormateado);
    };

    const handleExportExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Facturaciones Individuales');

        worksheet.columns = [
            { header: 'Periodo', key: 'periodo', width: 15 },
            { header: 'Unidad', key: 'codigo_unidad_facturacion', width: 20 },
            { header: 'Concepto', key: 'titulo_concepto', width: 30 },
            { header: 'Tipo Concepto', key: 'tipo_concepto', width: 20 },
            { header: 'Tipo Facturación', key: 'tipo_facturacion', width: 20 },
            { header: 'Importe', key: 'importe', width: 15 },
            { header: 'Estado', key: 'estado', width: 15 },
            { header: 'Registrador', key: 'usuarioRegistrador', width: 25 },
        ];

        facturaciones_individuales.forEach((facturacion) => {
            worksheet.addRow({
                periodo: facturacion.periodo,
                codigo_unidad_facturacion: facturacion.codigo_unidad_facturacion,
                titulo_concepto: facturacion.titulo_concepto,
                tipo_concepto: facturacion.tipo_concepto,
                tipo_facturacion: facturacion.tipo_facturacion,
                importe: formatearImporte01(facturacion.importe),
                estado: facturacion.estado,
                usuarioRegistrador: facturacion.usuarioRegistrador
            });
        });

        worksheet.getRow(1).font = { bold: true, color: { argb: 'FFFFFF' } };
        worksheet.getRow(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '007bff' }, // Azul
        };

        worksheet.eachRow((row) => {
            row.eachCell((cell) => {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'MisFacturacionesIndividuales.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handleBuscar = () => {
        if (!edificio?.id) {
            M.toast({ html: 'No hay un edificio seleccionado.', classes: 'red' });
            return;
        }

        setIsLoading(true);

        dispatch(get_facturaciones_individuales({
            buildingId: edificio.id,
            periodo: filtroPeriodo,
            tipo_concepto: filtroTipoConcepto,
            codigo_unidad_facturacion: filtroCodigoUnidad,
            tipo_facturacion: filtroTipoFacturacion
        })).finally(() => setIsLoading(false));
    };

    // Calcular la sumatoria de importes obtenidos
    useEffect(() => {
        console.log("DEBUG: Cargando facturaciones individuales...");
        console.log("Estado actual de facturaciones_individuales:", facturaciones_individuales);


        const totalImporte = facturaciones_individuales.reduce((sum, facturacion) => sum + parseFloat(facturacion.importe || 0), 0);
        setSumatoriaImportes(parseFloat(totalImporte.toFixed(2)));
    }, [facturaciones_individuales]);

    return (
        <div className="container">
            <div className="card">
                <div className="card-content">
                    <span className="card-title">Facturación Individual</span>
                    <div className="card-action">
                        <button className="btn orange" onClick={handleExportExcel}>
                            <i className="material-icons left">file_download</i>Descargar Excel
                        </button>
                        <button className="btn orange" onClick={() => navigate(`/facturacion-individual/create`)}>
                            <i className="material-icons left">add_box</i>Crear Facturación
                        </button>
                        <button className="btn blue" onClick={() => navigate('/facturaciones-masivas')}>
                            <i className="material-icons left">assignment</i>Facturaciones Masivas
                        </button>
                        <button className="btn blue" onClick={() => navigate('/dashboard')}>
                            <i className="material-icons left">arrow_back</i>Volver
                        </button>
                    </div>

                    {/* Filtros */}
                    <h5>Filtros</h5>
                    <div className="row">
                        {/* Periodo */}
                        <div className="input-field col s3">
                            <DatePicker
                                selected={parseFromYYYYMM(filtroPeriodo)}
                                onChange={handlePeriodoChange}
                                showMonthYearPicker
                                dateFormat="yyyy-MM"
                                name="periodo"
                                locale="es"
                            />
                            <label className="active" htmlFor="periodo">Periodo:</label>
                        </div>
                        {/* Tipo de Concepto */}
                        <div className="input-field col s3">
                            <select value={filtroTipoConcepto} onChange={(e) => setFiltroTipoConcepto(e.target.value)}>
                                <option value="">Todos</option>
                                {TIPOS_CONCEPTO.map((tipo) => (
                                    <option key={tipo.value} value={tipo.value}>{tipo.label}</option>
                                ))}
                            </select>
                            <label className="active">Tipo de Concepto</label>
                        </div>

                        {/* Código Unidad Facturación */}
                        <div className="input-field col s3">
                            <input type="text" value={filtroCodigoUnidad} onChange={(e) => setFiltroCodigoUnidad(e.target.value)} />
                            <label className="active">Unidad Fact.</label>
                        </div>

                        {/* Tipo de Facturación */}
                        <div className="input-field col s3">
                            <select value={filtroTipoFacturacion} onChange={(e) => setFiltroTipoFacturacion(e.target.value)}>
                                <option value="">Todos</option>
                                {TIPOS_FACTURACION.map((tipo) => (
                                    <option key={tipo.value} value={tipo.value}>{tipo.label}</option>
                                ))}
                            </select>
                            <label className="active">Tipo de Facturación</label>
                        </div>

                        {/* Botón de búsqueda */}
                        <div className="input-field col s3">
                            <button className="btn green" onClick={handleBuscar}>
                                <i className="material-icons left">search</i>Buscar
                            </button>
                        </div>
                    </div>

                    {/* Mostrar sumatoria de importes */}
                    <h6>Sumatoria de Importe: {formatearImporte01(sumatoriaImportes)}</h6>

                    {/* Mostrar estado de carga */}
                    {isLoading && (
                        <div className="progress">
                            <div className="indeterminate"></div>
                        </div>
                    )}

                    {/* Mostrar error si ocurre */}
                    {error_get_facturaciones_individuales && (
                        <div className="card-panel red lighten-4 red-text text-darken-4">
                            <span>Error: {error_get_facturaciones_individuales}</span>
                        </div>
                    )}

                    {/* Tabla de resultados */}
                    {facturaciones_individuales.length > 0 ? (
                        <table className="striped responsive-table">
                            <thead>
                                <tr>
                                    <th>Periodo</th>
                                    <th>Unid. Fact.</th>
                                    <th>Concepto</th>
                                    <th>Tipo</th>
                                    <th>Facturación</th>
                                    <th>Importe</th>
                                    <th>Estado</th>
                                    <th>Registrador</th>
                                </tr>
                            </thead>
                            <tbody>
                                {facturaciones_individuales?.map((facturacion, index) => (
                                    facturacion ? (
                                        <tr key={facturacion.id || index}>
                                            <td>{facturacion.periodo || "N/A"}</td>
                                            <td>
                                                <Link to={`/facturacion-individual/view/${facturacion.id}`}>
                                                    {facturacion.codigo_unidad_facturacion || "N/A"}
                                                </Link>
                                            </td>
                                            <td>{facturacion.titulo_concepto || "N/A"}</td>
                                            <td>{facturacion.tipo_concepto || "N/A"}</td>
                                            <td>{facturacion.tipo_facturacion || "N/A"}</td>
                                            <td>{facturacion.importe ? formatearImporte01(facturacion.importe) : "0.00"}</td>
                                            <td>{facturacion.estado || "N/A"}</td>
                                            <td>{facturacion.usuarioRegistrador || "N/A"}</td>
                                        </tr>
                                    ) : null
                                ))}
                            </tbody>
                        </table>
                    ) : <p>No se encontraron resultados.</p>}
                </div>
            </div>
        </div >
    );
};

export default MisFacturaciones_individuales;
