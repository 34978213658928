import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';


  export const get_personas_x_edificio = createAsyncThunk('persona/get_personas_x_edificio', async (codigo_edificio, thunkAPI) => {    
    
    try { 
        console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.get(`/edificios/${codigo_edificio}/personas`);
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const personas = response.data;
            
        return personas ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  
export const update_persona = createAsyncThunk('persona/update_persona', async ({ idPersona, personaData }, thunkAPI) => {
  //console.log("ejecutando thunk update_persona con idPersona: " + idPersona);

  try {

    const response = await apiClient.put(`/personas/${idPersona}`, personaData);

    const persona = response.data;

    // Despachar acción para resetear el estado de fetching
    //thunkAPI.dispatch(resetStateGetPersonas(persona.buildingId));// Resetear el estado para refetch de personas


    return persona;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_persona = createAsyncThunk('persona/save_persona', async (personaData, thunkAPI) => {

  try {
    console.log("antes de invocación de post creacion de persona")
    const response = await apiClient.post(`/personas`, personaData);

    const persona = response.data;

    // Despachar acción para resetear el estado de fetching
    //thunkAPI.dispatch(resetStateGetPersonas(persona.buildingId));// Resetear el estado para refetch de personas

    return persona;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_persona = createAsyncThunk('persona/anular_persona', async ({ idPersona, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_persona con idPersona: " + idPersona);

  try {

    const response = await apiClient.patch(`/personas/${idPersona}/anular`, { usuarioAnulacion });

    const persona = response.data;

    // Despachar acción para resetear el estado de fetching
    //thunkAPI.dispatch(resetStateGetPersonas(persona.buildingId));// Resetear el estado para refetch de personas

    return persona;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_persona = createAsyncThunk('persona/get_persona', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_persona con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/personas/${id}`);
    
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const persona = response.data;

    return persona;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_persona_x_documento  = createAsyncThunk('persona/get_persona_x_documento', async ({ tipo_documento, numero_documento }, thunkAPI) => {
  //console.log("ejecutando thunk get_persona con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path

    const response = await apiClient.get('/personas/documento',{
      params: { 
        tipo_documento, 
        numero_documento 
      }
  });

    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const persona = response.data;

    return persona;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
