import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_personas_x_edificio } from './personaThunk';
import { crear_usuarios_residentes } from '../seguridad/seguridadThunk';
import M from 'materialize-css';
import { formatDate02 } from '../../utils/formateo';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const Personas_edificio = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useMaterializeTooltips();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const edificio = useSelector((state) => state.edificio.edificio);
  const personas = useSelector((state) => state.persona.personas);
  const fetchStatus = useSelector((state) => state.persona.fetchStatus);
  const error = useSelector((state) => state.persona.error);
  const [filtro, setFiltro] = useState('');
  const usuario = useSelector((state) => state.auth.usuario);
  const { error_crear_usuarios_residentes } = useSelector((state) => state.seguridad);

 const {esSuperUsuario, esAdministrador} = useSelector((state) => state.auth);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
    M.AutoInit();
    if (fetchStatus === 'idle' && edificio.id) {
      dispatch(get_personas_x_edificio(edificio.id));
    }
  }, [isAuthenticated, navigate, fetchStatus, edificio, dispatch]);

  const handleRefresh = () => {
    dispatch(get_personas_x_edificio(edificio.id));
  };

  const crearUsuarios = async () => {
    const jsonRequestBody = {
      buildingId: edificio.id,
      usuario_registrador: usuario.id,
      titular_registrador: usuario.id,
    };
    const resultAction = await dispatch(crear_usuarios_residentes(jsonRequestBody));
    if (crear_usuarios_residentes.fulfilled.match(resultAction)) {
      M.toast({ html: 'Usuarios creados exitosamente.', classes: 'green' });
    } else if (crear_usuarios_residentes.rejected.match(resultAction)) {
      const errorMessage = error_crear_usuarios_residentes || '-Ocurrió un error al crear Usuarios';
      M.toast({ html: errorMessage, classes: 'red' });
      return;
    } else {
      console.log('Eentro por ELSE ' + JSON.stringify(resultAction.payload));
    }
  };

  const formatearPersonas = (personas) => {
    return Array.isArray(personas)
      ? personas.map((persona) => ({
          ...persona,
          fecha_registro_formato01: formatDate02(persona.timestamp_registro),
        }))
      : [];
  };

  const personasFormateados = formatearPersonas(personas);

  const handleFilterChange = (e) => {
    setFiltro(e.target.value);
  };

  const personasFiltrados = personasFormateados.filter((persona) => {
    const filterLowerCase = filtro.toLowerCase();
    return (
      persona.tipo_documento.toLowerCase().includes(filterLowerCase) ||
      persona.numero_documento.toLowerCase().includes(filterLowerCase) ||
      persona.titular.toLowerCase().includes(filterLowerCase) ||
      persona.email.toLowerCase().includes(filterLowerCase) ||
      persona.nro_celular.toLowerCase().includes(filterLowerCase) ||
      persona.tipo_usuario.toLowerCase().includes(filterLowerCase) ||
      persona.usuario.toLowerCase().includes(filterLowerCase) ||
      persona.fecha_registro_formato01.toString().includes(filterLowerCase)
    );
  });

  // Calcular total por perfil
  const resumenPorPerfil = personasFormateados.reduce((acc, persona) => {
    if (Array.isArray(persona.edificios) && persona.edificios.length > 0) {
      persona.edificios.forEach((edif) => {
        const perfil = edif.perfil;
        if (perfil) {
          acc[perfil] = (acc[perfil] || 0) + 1;
        } else {
          acc['Sin Perfil'] = (acc['Sin Perfil'] || 0) + 1;
        }
      });
    } else {
      acc['Sin Edificios'] = (acc['Sin Edificios'] || 0) + 1;
    }
    return acc;
  }, {});

  if (fetchStatus === 'loading') {
    return <div>Loading...</div>;
  }

  return (
    <div className="container" style={{ textAlign: 'center', marginTop: '2rem' }}>
      <h5>Personas</h5>
      <div className="row" style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
        <div className="col s12 m2">
          <a
            className="btn green tooltipped"
            data-position="top"
            data-tooltip="Refrescar vista"
            onClick={handleRefresh}
            style={{ margin: '0.5rem' }}
          >
            <i className="material-icons left">refresh</i>Refrescar
          </a>
        </div>
        {esSuperUsuario && (
        <div className="col s12 m6">
          <a
            className="btn orange tooltipped"
            data-position="top"
            data-tooltip="Cargar Personas"
            onClick={() => navigate('/carga-personas')}
            style={{ margin: '0.5rem' }}
          >
            <i className="material-icons left">cloud_upload</i>Cargar Personas
          </a>
        </div>
        )}
        {esSuperUsuario && (
        <div className="col s12 m6">
          <a
            className="btn orange tooltipped"
            data-position="top"
            data-tooltip="Crear Usuarios"
            onClick={crearUsuarios}
            style={{ margin: '0.5rem' }}
          >
            <i className="material-icons left">burst_mode</i>Crear Usuarios
          </a>
        </div>
        )}
        <div className="col s12 m2">
          <a
            className="btn blue tooltipped"
            data-position="top"
            data-tooltip="Volver"
            onClick={() => navigate('/edificio')}
            
          >
            <i className="material-icons left">arrow_back</i>
          </a>
        </div>
      </div>

      <h5>Resumen de Personas por Perfil</h5>
      <div style={{ maxWidth: '500px', margin: '0 auto' }}>
        <table className="striped responsive-table" style={{ marginBottom: '2rem' }}>
          <thead>
            <tr>
              <th>Perfil</th>
              <th>Cantidad</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(resumenPorPerfil).map(([perfil, cantidad]) => (
              <tr key={perfil}>
                <td>{perfil}</td>
                <td>{cantidad}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="col s12 m12 input-field" style={{ maxWidth: '400px', margin: '0 auto' }}>
        <label htmlFor="filtro_tabla_personas_cargados">
          <i className="material-icons">search</i> Filtrar
        </label>
        <input
          type="text"
          id="filtro_tabla_personas_cargados"
          value={filtro}
          onChange={handleFilterChange}
          style={{ marginBottom: '1rem' }}
        />
      </div>

      {/* Contenedor con scroll vertical y horizontal */}
      <div
        style={{
          width: '100%',
          maxHeight: '400px',
          overflowX: 'auto',
          overflowY: 'auto',
          margin: '0 auto',
        }}
      >
        <table
          className="striped responsive-table tabla_personas_cargados"
          style={{ margin: '0 auto', minWidth: '800px' }}
        >
          <thead>
            <tr>
              <th>TipDoc</th>
              <th>NumDoc</th>
              <th>Titular</th>
              <th>Email</th>
              <th>Celular</th>
              <th>TipUsua.</th>
              <th>Usuario</th>
              <th>Carga</th>
              <th>Id. carga</th>
              <th>Registro</th>
            </tr>
          </thead>
          <tbody id="table-body-personas-cargados">
            {personasFiltrados.map((persona) => (
              <tr key={persona.id}>
                <td>{persona.tipo_documento}</td>
                <td>
                  <a
                    href="#!"
                    className="blue-text text-darken-2"
                    onClick={() => navigate(`/persona/view/${persona.id}`)}
                  >
                    {persona.numero_documento}
                  </a>
                </td>
                <td>{persona.titular}</td>
                <td>{persona.email}</td>
                <td>{persona.nro_celular}</td>
                <td>{persona.tipo_usuario}</td>
                <td>{persona.usuario}</td>
                <td>{persona.usuario_carga}</td>
                <td>{persona.uid_carga}</td>
                <td>{persona.fecha_registro_formato01}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Personas_edificio;
