import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
//import { setEdificioSeleccionado } from '../edificio/edificioSlice';
//import { setUnidad_facturacionSeleccionado } from '../edificio/unidad_facturacionSlice';
import './SelectBuildingUnitModal.css';
import M from 'materialize-css';
//import { setEdificioSeleccionado, setUnidad_facturacionSeleccionado } from '../auth/authSlice';
import { setEdificioSeleccionado } from '../edificio/edificioSlice';
import { setUnidad_facturacionSeleccionado } from '../edificio/unidad_facturacionSlice';
import { setRolesUsuario } from '../auth/authSlice';
import { ROL_SUPER, ROL_ADMINISTRADOR } from '../../constants/constants';

Modal.setAppElement('#root');

const SelectBuildingUnitModal = ({ isOpen, onRequestClose }) => {
    const dispatch = useDispatch();

    // Obtener edificios y unidades de facturación desde Redux
    const edificios = useSelector((state) => state.auth.edificios) || [];
    const unidades_facturacion = useSelector((state) => state.auth.unidades_facturacion) || [];

    //console.log(" ANTES edificios "+JSON.stringify(edificios))

    //console.log(" DESPUES edificios "+JSON.stringify(edificios))

    // Estados para seleccionar un edificio y una unidad
    const [selectedEdificio, setSelectedEdificio] = useState('');
    const [selectedUnidadFacturacion, setSelectedUnidadFacturacion] = useState('');

    // Reiniciar selecciones cuando el modal se abre
    useEffect(() => {
        console.log(" VALOR isOpen: " + isOpen);
        if (isOpen) {
            console.log(" VALOR edificios.length: " + edificios.length);
            setSelectedEdificio(edificios.length > 0 ? edificios[0].id : '');
            console.log(" VALOR unidades_facturacion.length: " + unidades_facturacion.length);
            setSelectedUnidadFacturacion(unidades_facturacion.length > 0 ? unidades_facturacion[0].id : '');

            setTimeout(() => {
                let elems = document.querySelectorAll('select');
                M.FormSelect.init(elems);
            }, 100);
        }
    }, [isOpen, edificios, unidades_facturacion]);

    // Función para manejar la selección del edificio
    const handleEdificioChange = (e) => {
        const selected = edificios.find(ed => ed.id === e.target.value);
        setSelectedEdificio(selected?.id || '');
    };

    // Función para manejar la selección de la unidad de facturación
    const handleUnidadChange = (e) => {
        const selected = unidades_facturacion.find(uf => uf.id === e.target.value);
        setSelectedUnidadFacturacion(selected?.id || '');
    };

    // Confirmar la selección y cerrar el modal
    const handleSelectAndProceed = () => {
        if (selectedEdificio) {
            dispatch(setEdificioSeleccionado(edificios.find(ed => ed.id === selectedEdificio)));

            // Evaluar roles de usuario en el edificio seleccionado
            const roles = selectedEdificio?.roles || [];
            dispatch(setRolesUsuario({
                esSuperUsuario: roles.includes(ROL_SUPER),
                esAdministrador: roles.includes(ROL_ADMINISTRADOR)
            }));

        }
        if (selectedUnidadFacturacion) {
            dispatch(setUnidad_facturacionSeleccionado(unidades_facturacion.find(uf => uf.id === selectedUnidadFacturacion)));
        }
        onRequestClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Seleccionar Edificio y Unidad"
            className="custom-modal"
            overlayClassName="custom-overlay"
        >
            <h4 className="center blue-text">Seleccionar Edificio y Unidad</h4>

            {/* Selección de Edificio */}
            {edificios.length > 1 && (
                <div className="input-field col s12 m6 offset-m3">
                    <select value={selectedEdificio} onChange={handleEdificioChange}>
                        {edificios.map((edificio) => (
                            <option key={edificio.id} value={edificio.id}>{edificio.nombre}</option>
                        ))}
                    </select>
                    <label>Edificio</label>
                </div>
            )}
            {/* Selección de Unidad de Facturación */}
            {unidades_facturacion.length > 1 && (
                <div className="input-field col s12 m6 offset-m3">
                    <select value={selectedUnidadFacturacion} onChange={handleUnidadChange}>
                        {unidades_facturacion.map((unidad) => (
                            <option key={unidad.id} value={unidad.id}>
                                {unidad.codigo_unidad_facturacion}
                            </option>
                        ))}
                    </select>
                    <label>Unidad de Facturación</label>
                </div>
            )}

            <div className="center">
                <button
                    className="btn-large blue darken-3"
                    disabled={!selectedEdificio}
                    onClick={handleSelectAndProceed}
                >
                    Confirmar Selección
                </button>
            </div>
        </Modal>
    );
};

export default SelectBuildingUnitModal;
