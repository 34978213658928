import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUsuario } from '../auth/authThunk';
import { setEdificioSeleccionado } from '../edificio/edificioSlice';
import { setUnidad_facturacionSeleccionado } from '../edificio/unidad_facturacionSlice';
import { setRolesUsuario } from '../auth/authSlice';
import { setPersonaSeleccionada } from '../edificio/personaSlice';
import SelectBuildingUnitModal from './SelectBuildingUnitModal'; // 🔹 Importamos el modal
import './LoginPage.css';
import M from "materialize-css/dist/js/materialize.min.js";
import { ROL_SUPER, ROL_ADMINISTRADOR } from '../../constants/constants';

const Login = () => {
  const [codigo_usuario, setCodigo_usuario] = useState('');
  const [password, setPassword] = useState('');
  const [loging, setLoging] = useState(false);
  const [showSelectionModal, setShowSelectionModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //const { error, edificios, unidades_facturacion, edificio_seleccionado, unidad_facturacion_seleccionada } = useSelector((state) => state.auth);

  const edificios = useSelector((state) => state.edificio.edificios);
  const error = useSelector((state) => state.auth.error);
  const edificio_seleccionado = useSelector((state) => state.edificio.edificioSeleccionado);
  const unidades_facturacion = useSelector((state) => state.unidad_facturacion.unidades_facturacion);
  const unidad_facturacion_seleccionada = useSelector((state) => state.unidad_facturacion.unidad_facturacionSeleccionado);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const authStatus = useSelector((state) => state.auth.status);


  // 🔥 Evita errores si `state.auth` es undefined
  //const authState = useSelector((state) => state.edificio) || {};  
  //const { error, edificios = [], unidades_facturacion = [], edificio_seleccionado, unidad_facturacion_seleccionada } = authState;

  /*
    useEffect(() => {
      dispatch(signOutUsuario());
      dispatch(resetEdificio()); // 🔥 Resetea `edificioSlice`
      dispatch(resetPersona()); // 🔥 Resetea `personaSlice`        
      dispatch(resetUnidadFacturacion()); // 🔥 Resetea `unidad_facturacionSlice`
      dispatch(resetAreasComunes()); // 🔥 Resetea `area_counSlice`
      dispatch(resetReservas()); // 🔥 Resetea `reservaSlice`       
  
    }, [dispatch]);
  */
  useEffect(() => {
    if (edificio_seleccionado) {
      //console.log("✅ Edificio seleccionado actualizado:", JSON.stringify(edificio_seleccionado));
    }
  }, [edificio_seleccionado]);

  useEffect(() => {
    if (unidad_facturacion_seleccionada) {
      //console.log("✅ Unidad de facturación seleccionada actualizada:", JSON.stringify(unidad_facturacion_seleccionada));
    }
  }, [unidad_facturacion_seleccionada]);
  /*
    useEffect(() => {
      console.log(" REDIRIGE A DASHBOARD edificio_seleccionado " + JSON.stringify(edificio_seleccionado))
      console.log(" REDIRIGE A DASHBOARD unidad_facturacion_seleccionada " + JSON.stringify(unidad_facturacion_seleccionada))
      console.log(" REDIRIGE A DASHBOARD unidades_facturacion.length " + unidades_facturacion.length)
      if (edificio_seleccionado && (unidad_facturacion_seleccionada || unidades_facturacion.length === 0)) {
        //console.log("🚀 Redirigiendo a /dashboard...");
        navigate('/dashboard');
      }
    }, [edificio_seleccionado, unidad_facturacion_seleccionada, unidades_facturacion, navigate]);
  */
  useEffect(() => {
    console.log("🔍 authStatus:", authStatus);
    console.log("🔍 isAuthenticated:", isAuthenticated);
    console.log("🔍 edificio_seleccionado:", edificio_seleccionado);
    console.log("🔍 unidad_facturacion_seleccionada:", unidad_facturacion_seleccionada);
    console.log("🔍 unidades_facturacion.length:", unidades_facturacion.length);

    if (
      authStatus === 'succeeded' &&
      isAuthenticated &&
      edificio_seleccionado &&
      (unidad_facturacion_seleccionada || unidades_facturacion.length === 0)
    ) {
      console.log("🚀 Redirigiendo a /dashboard...");
      navigate('/dashboard');
    }
  }, [authStatus, isAuthenticated, edificio_seleccionado, unidad_facturacion_seleccionada, unidades_facturacion, navigate]);


  const handleLogin = async (e) => {
    e.preventDefault();
    setLoging(true);

    const credencial = { codigo_usuario, password };

    try {
      const resultAction = await dispatch(loginUsuario(credencial));

      if (loginUsuario.fulfilled.match(resultAction)) {
        console.log("🟢 Login exitoso, verificando selección...");
        /*
        console.log("🟢 resultAction.payload: "+ JSON.stringify(resultAction.payload));
        const { passwordExpired, message } = resultAction.payload;

        if (passwordExpired) {
          M.toast({ html: message, classes: "red darken-1" });
          navigate("/change-password");
          return;
        }
*/

        const { edificios, unidades_facturacion, persona, usuario } = resultAction.payload;

        dispatch(setPersonaSeleccionada(persona));

        //console.log("🟢 edificios exitoso: " + JSON.stringify(edificios));
        if (edificios.length === 1) {
          dispatch(setEdificioSeleccionado(edificios[0]));

          // Evaluar roles de usuario en el edificio único
          //console.log(" ROLES  edificios[0] "+JSON.stringify( usuario.edificios[0]))
          const roles = usuario.edificios[0]?.roles || [];
          //console.log(" ROLES roles "+roles)
          dispatch(setRolesUsuario({
            esSuperUsuario: roles.includes(ROL_SUPER),
            esAdministrador: roles.includes(ROL_ADMINISTRADOR)
          }));

        }

        if (unidades_facturacion.length === 1) {
          dispatch(setUnidad_facturacionSeleccionado(unidades_facturacion[0]));
        }

        if (edificios.length > 1 || unidades_facturacion.length > 1) {
          console.log("🟠 Mostrando modal de selección...");
          setShowSelectionModal(true);
        } else if (unidades_facturacion.length === 0 && edificios.length === 1) {
          console.log("🟢 Usuario administrador sin unidades. Redirigiendo...");
          navigate('/dashboard');
        }
      } else {
        console.log("LOGIN NO EXITOSO ")
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoging(false);
    }
  };

  return (
    <div id="cont_login" className="login">
      {loging && (
        <div className="login-overlay">
          <div className="login-spinner-container">
            <div className="login-lds-ring"><div></div><div></div><div></div><div></div></div>
            <p>Iniciando sesión...</p>
          </div>
        </div>
      )}
      <form onSubmit={handleLogin} className="formulario">
        <h5 className="center">Iniciar Sesión</h5>
        <div className="input-field">
          <i className="material-icons prefix">person</i>
          <input
            id="cont_login_usuario"
            name="cont_login_usuario"
            value={codigo_usuario}
            type="text"
            onChange={(e) => setCodigo_usuario(e.target.value)}
            required
          />
          <label htmlFor="cont_login_usuario">Usuario</label>
        </div>
        <div className="input-field">
          <i className="material-icons prefix">vpn_key</i>
          <input
            id="cont_login_password"
            name="cont_login_password"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <label htmlFor="cont_login_password">Contraseña</label>
        </div>
        <button type="submit" className="waves-light btn" disabled={loging} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {loging ? (
            <>
              <span>Loging...</span>
            </>
          ) : (
            'Login'
          )}
        </button>
        {error && <p className="red-text center">{error}</p>}
      </form>

      {/* Modal de Selección de Edificio y Unidad de Facturación */}
      <SelectBuildingUnitModal
        isOpen={showSelectionModal}
        onRequestClose={() => setShowSelectionModal(false)}
      />
    </div>
  );
};

export default Login;
