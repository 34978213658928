import { createSlice } from '@reduxjs/toolkit';
import { get_personas_x_edificio, get_persona, update_persona, save_persona, anular_persona, get_persona_x_documento } from './personaThunk';

const initialState= {
  persona: null,
  personas: [],
  personaSeleccionada: null,
  fetchStatus: 'idle',
  error: null,
  status_get_persona: 'idle',
  error_get_persona: null,
  status_update_persona: 'idle',
  error_update_persona: null,
  status_save_persona: 'idle',
  error_save_persona: null,
  status_anular_persona: 'idle',
  error_anular_persona: null,

}

const personaSlice = createSlice({
  name: 'persona',
  initialState,
  reducers: {
    setPersonaSeleccionada: (state, action) => {
      state.personaSeleccionada = action.payload;
    },
    clearPersonaSeleccionada(state) {
      state.personaSeleccionada = null;
      state.persona = null;
    },
    resetState: () => initialState, // 🔥 Reiniciar todo el estado al estado inicial
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_persona.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_persona.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.persona = action.payload;

      })
      .addCase(get_persona.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })

      // Thunk: get_persona_x_documento
      .addCase(get_persona_x_documento.pending, (state) => {
        state.fetchStatus = 'loading';
        state.error = null;
        state.persona = null;
      })
      .addCase(get_persona_x_documento.fulfilled, (state, action) => {
        state.fetchStatus = 'succeeded';
        state.persona = action.payload; // la persona encontrada
      })
      .addCase(get_persona_x_documento.rejected, (state, action) => {
        state.fetchStatus = 'failed';
        state.error =
          action.payload?.message || action.payload || 'Error al obtener persona';
      })

      .addCase(get_personas_x_edificio.pending, (state) => {
        state.fetchStatus = 'loading';
      })
      .addCase(get_personas_x_edificio.fulfilled, (state, action) => {
        state.fetchStatus = 'succeeded';
        //console.log("Depas cargados JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.personas = action.payload;

      })
      .addCase(get_personas_x_edificio.rejected, (state, action) => {
        console.log("ejecutando Slice failed rejected ");
        state.fetchStatus = 'failed';
        console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch receipts';
      })

      .addCase(update_persona.pending, (state) => {
        state.status_update_persona = 'loading';
      })
      .addCase(update_persona.fulfilled, (state, action) => {
        state.status_update_persona = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.persona = action.payload.persona;

      })
      .addCase(update_persona.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_persona = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_persona = action.payload || 'Failed to fetch building';
      })
      .addCase(save_persona.pending, (state) => {
        state.status_save_persona = 'loading';
      })
      .addCase(save_persona.fulfilled, (state, action) => {
        state.status_save_persona = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.persona = action.payload.persona;

      })
      .addCase(save_persona.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_persona = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_persona = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_persona.pending, (state) => {
        state.status_save_persona = 'loading';
      })
      .addCase(anular_persona.fulfilled, (state, action) => {
        state.status_anular_persona = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.persona = action.payload.persona;

      })
      .addCase(anular_persona.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_persona = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_persona = action.payload || 'Failed to fetch building';
      })

  },
});

export const selectPersonaById = (state, personaId) =>
  state.persona.departamentos.find((persona) => persona.id === personaId);
export const { setPersonaSeleccionada, clearPersonaSeleccionada, resetState } = personaSlice.actions;
export default personaSlice.reducer;
