// Calendario_reservasSUM.js 
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import { setArea_comunSeleccionada } from './area_comunSlice';
import { get_reservas_x_area_comun } from './reservaThunk';
import './calendarioReservasPage.css'; // Asegúrate de crear y actualizar este archivo
import M from 'materialize-css/dist/js/materialize.min.js';
import { TIPO_AREA_COMUN_PARRILLA } from '../../constants/constants';
import { get_areas_comunes, get_areas_comunes_sum } from './area_comunThunk';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const Calendario_reservasSUM = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useMaterializeTooltips();
    const { selectedArea } = location.state || {};

    console.log(" OJO AQUI selectedArea: "+ selectedArea);
    
    const [areaComun, setAreaComun] = useState(selectedArea || '');
    const [fechaSeleccionada, setFechaSeleccionada] = useState(new Date());
    const [turnos, setTurnos] = useState([]);
    const [reservas_periodo, setReservas_periodo] = useState([]);

    const edificioSeleccionado = useSelector(state => state.edificio.edificio);
    const buildingId = edificioSeleccionado.id; // ID del edificio
    const isAdminPath = window.location.pathname.includes("-admin");
    // Obtener áreas comunes desde Redux
    const areasComun = useSelector((state) => state.area_comun.areas_comunes_sum);

    // Obtener reservas desde Redux
    const reservas = useSelector((state) => state.reserva.reservas);
    const { status_get_reservas_x_area_comun, error_get_reservas_x_area_comun } = useSelector((state) => state.reserva);

    useEffect(() => {
        // Inicializar tooltips después de que reservas_periodo cambie
        var elems = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(elems);
    }, [reservas_periodo]);

    useEffect(() => {
        // Obtener todas las áreas comunes si no están ya cargadas
        if (areasComun.length === 0 && buildingId) {
            dispatch(get_areas_comunes_sum({ buildingId }));            
        }
    }, [areasComun, buildingId, dispatch]);

    useEffect(() => {
        console.log(" USEEFFECT buildingId: "+buildingId +" areaComun: "+areaComun + " areasComun "+JSON.stringify(areasComun) )
        if (areasComun.length > 0 && !areaComun && buildingId) {
            // Seleccionar automáticamente el primer área común
            const firstArea = areasComun[0];
            console.log(" ==> USEEFFECT firstArea: "+JSON.stringify(firstArea) )
        
            setAreaComun(firstArea.nombre);
            setTurnos(firstArea.turnos);

            // Obtener reservas para el primer área común
            dispatch(setArea_comunSeleccionada(firstArea.nombre));
            dispatch(get_reservas_x_area_comun({buildingId, area_comun: firstArea.nombre}));

            M.updateTextFields();
        }
    }, [areasComun, areaComun, buildingId, dispatch]);

    useEffect(() => {
        if (areaComun && areasComun.length > 0 && buildingId) {
            // Actualizar el Área Común seleccionada en el estado global
            dispatch(setArea_comunSeleccionada(areaComun));

            // Obtener reservas para el Área Común seleccionada
            console.log(" ANTES 1 areaComun: "+areaComun);
            dispatch(get_reservas_x_area_comun({buildingId, area_comun: areaComun}));

            // Encontrar el Área Común seleccionada para obtener sus turnos
            const areaSeleccionada = areasComun.find(area => area.nombre === areaComun);
            if (areaSeleccionada && areaSeleccionada.turnos) {
                setTurnos(areaSeleccionada.turnos);
            } else {
                setTurnos([]); // Si no hay turnos definidos
            }
        }
    }, [areaComun, areasComun, buildingId, dispatch]);

    useEffect(() => {
        console.log("Reservas obtenidas:", reservas);
        if (reservas) {
            setReservas_periodo(reservas);
        }
    }, [reservas]);

    useEffect(() => {
        // Inicializar el select de Materialize cuando las áreas comunes cambien
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [areasComun]);

    const handleAreaChange = (e) => {
        const nuevaArea = e.target.value;
        setAreaComun(nuevaArea);
    };

    const handleDateChange = (date) => {
        setFechaSeleccionada(date);
    };

    const handleRetornar = () => {
        //dispatch(clearReservaSeleccionada());
        navigate(-1);
    };

    // Nueva función para manejar el clic en un turno disponible
    const handleTurnoClick = (turno, date) => { 

        if(isAdminPath){
            return; 
        }
        // Verificar que el turno esté disponible
        if (!turno.disponible) return;
    
        console.log("handleTurnoClick fechaSeleccionada:", date); // Añadido
    
        // Preparar los datos para la navegación
        const dataToPass = {
            area_comun: areaComun,
            fecha_reserva: date,
            turno: turno
        };
    
        console.log("handleTurnoClick Navegando con datos:", JSON.stringify(dataToPass)); // Añadido
    
        // Navegar a la página de creación de reserva con los datos preseleccionados
        navigate('/reserva-sum/create', { state: dataToPass });
    };

    const renderTileContent = ({ date, view }) => {
        if (view === 'month') {
            const fechaISO = date.toISOString().split('T')[0];
            
            // Verificar si la fecha está en el pasado
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const selectedDate = new Date(date);
            selectedDate.setHours(0, 0, 0, 0);
            
            const isToday = selectedDate.getTime() === today.getTime();

            const isPastDate = selectedDate < today;

            if (isPastDate) {
                // No renderizar contenido ni tooltip para fechas pasadas
                return null;
            }

            // Filtrar reservas del día actual
            const reservasDelDia = reservas_periodo.filter(reserva => reserva.fecha_reserva === fechaISO);
            
            // Crear una lista de turnos con su disponibilidad
            const turnosConDisponibilidad = turnos.map(turno => {
                // Verificar si hay una reserva para este turno en esta fecha
                const reservaExistente = reservasDelDia.find(reserva => 
                    reserva.turno.turno_hora_inicio === turno.turno_hora_inicio &&
                    reserva.turno.turno_hora_fin === turno.turno_hora_fin &&
                    reserva.estado === 'SEPARADO'
                );

                return {
                    ...turno,
                    disponible: !reservaExistente
                };
            });

            // Detectar tamaño de pantalla
            const isMobile = window.innerWidth <= 600;

            return (
                <div className="tile-content">
                    {isMobile ? (                        
                        turnosConDisponibilidad.map((turno, index) => (
                            turno.disponible ? (
                                <div
                                    key={index}
                                    className={`turno-circle disponible tooltipped`}
                                    data-tooltip={`Turno: ${turno.descripcion}`}
                                    onClick={() => handleTurnoClick(turno, date)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    
                                </div>
                            ) : (
                                <div
                                    key={index}
                                    className={`turno-circle ocupado tooltipped`}
                                    data-tooltip={`Turno: ${turno.descripcion}`}
                                >
                                   
                                </div>
                            )
                        ))

                    ) : (
                        turnosConDisponibilidad.map((turno, index) => (
                            turno.disponible ? (
                                <div
                                    key={index}
                                    className={`turno turno-spacing disponible tooltipped`}
                                    data-tooltip={`Turno: ${turno.descripcion}`}
                                    onClick={() => handleTurnoClick(turno, date)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {turno.descripcion}
                                </div>
                            ) : (
                                <div
                                    key={index}
                                    className={`turno turno-spacing ocupado tooltipped`}
                                    data-tooltip={`Turno: ${turno.turno_hora_inicio} - ${turno.turno_hora_fin}`}
                                >
                                    {turno.turno_hora_inicio} - {turno.turno_hora_fin}
                                </div>
                            )
                        ))
                    )}
                </div>
            );
        }
    };

    const handleDayClick = (date) => {
        // Verificar si la fecha seleccionada es válida (hoy o futuro)
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Establecer la hora a medianoche para comparar solo fechas

        const selectedDate = new Date(date);
        selectedDate.setHours(0, 0, 0, 0);

        if (selectedDate < today) {
            // Fecha pasada, no hacer nada o mostrar un mensaje
            M.toast({ html: 'No puedes seleccionar una fecha pasada.', classes: 'red' });
            return;
        }

        const dataToPass = { selectedDate: date, selectedArea: areaComun };
        console.log("Navegando con datos de día:", dataToPass); // Añadido
        navigate('/reserva-sum/create', { state: dataToPass });
    };

    return (
        <div className="container calendario-container">
            <h4 className="center-align">Calendario de Reservas {areaComun}</h4>
            
            {/* Fila que contiene el selector de Área Común y la leyenda */}
            <div className="row valign-wrapper">
                {/* Selector de Área Común */}
                <div className="input-field col s12 m8 l6">
                    <select value={areaComun} onChange={handleAreaChange}>
                        {/* Eliminado el marcador de posición */}
                        {areasComun.map(area => (
                            <option key={area.id} value={area.nombre}>{area.nombre}</option>
                        ))}
                    </select>
                    <label>Área Común</label>
                </div>

                {/* Leyenda de Disponibilidad */}
                <div className="col s12 m4 l6 legend-container">
                    <div className="legend">
                        <div className="legend-item">
                            <div className="legend-color disponible"></div>
                            <span className="legend-label">Disponible</span>
                        </div>
                        <div className="legend-item">
                            <div className="legend-color ocupado"></div>
                            <span className="legend-label">Reservado</span>
                        </div>
                        <div className="legend-item">
                            <div className="legend-color pasado"></div>
                            <span className="legend-label">No Disponible</span>
                        </div>
                    </div>
                </div>

                {/* Botón "Volver" */}
                <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={handleRetornar}>
                    <i className="material-icons left">arrow_back</i>Volver
                </a>
            </div>

            {/* Calendario */}
            {areaComun && turnos.length > 0 && (
                <div className="row">
                    <div className="col s12">
                        <div className="calendar-wrapper">
                            <Calendar
                                onChange={handleDateChange}
                                value={fechaSeleccionada}
                                tileContent={renderTileContent}
                                //onClickDay={handleDayClick} // Activar la selección de días
                                locale="es-ES" // Establecer la localización en español
                                className="responsive-calendar"
                                minDate={new Date()} // Establecer la fecha mínima a hoy
                                tileDisabled={({ date, view }) => {
                                    // Deshabilitar fechas anteriores a hoy
                                    if (view === 'month') {
                                        const today = new Date();
                                        today.setHours(0, 0, 0, 0);
                                        const selectedDate = new Date(date);
                                        selectedDate.setHours(0, 0, 0, 0);
                                        return selectedDate < today;
                                    }
                                    return false;
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}

            {/* Mostrar mensaje de carga o error */}
            {status_get_reservas_x_area_comun === 'loading' && (
                <div className="row">
                    <div className="col s12 center-align">
                        <div className="preloader-wrapper small active">
                            <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                        <p>Cargando reservas...</p>
                    </div>
                </div>
            )}

            {status_get_reservas_x_area_comun === 'failed' && (
                <div className="row">
                    <div className="col s12 center-align">
                        <span className="red-text">Error: {error_get_reservas_x_area_comun}</span>
                    </div>
                </div>
            )}
        </div>
    );

};

export default Calendario_reservasSUM;
