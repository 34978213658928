import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_facturaciones_agua } from './facturaciones_aguaThunk';
import M from 'materialize-css';
import { Link } from 'react-router-dom';

import { formatDate, formatCurrency, formatPeriod, formatDate02, formatearPeriodo_MesAnio01, formatearImporte01 } from '../../utils/formateo';
import { getEstadoPagoButtonClass, getEstadoPagoIcon, downloadPDF } from '../../utils/util';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const MisFacturaciones_agua = () => {

  const navigate = useNavigate();
  useMaterializeTooltips();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const facturaciones_agua = useSelector((state) => state.facturaciones_agua.facturaciones_agua);
  const usuario = useSelector((state) => state.auth.usuario);
  const fetchStatus = useSelector((state) => state.facturaciones_agua.fetchStatus);
  const edificio = useSelector(state => state.edificio.edificio);
  const error = useSelector((state) => state.facturaciones_agua.error);
  const [filtro, setFiltro] = useState('');
  const { esSuperUsuario, esAdministrador } = useSelector((state) => state.auth);

  //console.log("en mis facturaciones_agua isAuthenticated: "+isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
      //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    //console.log("fetchStatus: "+ fetchStatus);
    console.log("fetchStatus " + fetchStatus + " edificio.buildingId: " + edificio.id);
    if (fetchStatus === 'idle' && edificio.id) {
      console.log("entro y va a llamar a get_facturaciones_agua ");
      dispatch(get_facturaciones_agua(edificio.id));

    }


  }, [usuario, navigate, fetchStatus, edificio, dispatch]);


  if (!usuario) {
    return null; // Return null while redirecting
  }

  /*
    const formatearRecibos = (facturaciones_agua) => {
      return facturaciones_agua.map(lectura_agua => ({
        ...lectura_agua,
        fecha_emision_formato01: formatDate(lectura_agua.fecha_emision),
        fecha_corte_formato01: formatDate(lectura_agua.fecha_corte),
        periodo: formatPeriod(lectura_agua.fecha_emision),
        importe_recibo_formato01: formatCurrency(lectura_agua.importe_recibo),
      }));
    };
  */

  //const facturaciones_aguaFormateados = formatearRecibos(facturaciones_agua);

  const handleFilterChange = (e) => {
    setFiltro(e.target.value);

  };

  //const facturaciones_aguaFiltrados = facturaciones_aguaFormateados.filter((planilla) => {
  const facturaciones_aguaFiltrados = Array.isArray(facturaciones_agua) ? facturaciones_agua.filter((facturacion_agua) => {
    //console.log("Esta entrando ? facturacion_agua: "+ JSON.stringify(facturacion_agua));
    const periodoFormateado = formatearPeriodo_MesAnio01(facturacion_agua.periodo);
    const importe_recibo_suministroFormateado = formatearImporte01(facturacion_agua.importe_recibo_suministro, facturacion_agua.moneda)
    const importe_total_facturarFormateado = formatearImporte01(facturacion_agua.importe_total_facturar, facturacion_agua.moneda)
    const fechaCreacionFormateado = formatDate02(facturacion_agua.fechaCreacion);

    const filterLowerCase = filtro.toLowerCase();

    return facturacion_agua.nro_suministro.toLowerCase().includes(filterLowerCase) ||
      periodoFormateado.toLowerCase().includes(filterLowerCase) ||
      importe_recibo_suministroFormateado.toLowerCase().includes(filterLowerCase) ||
      importe_total_facturarFormateado.toLowerCase().includes(filterLowerCase) ||
      facturacion_agua.consumo_unidades_facturacion.toString().includes(filterLowerCase) ||
      facturacion_agua.factor_agua.toString().includes(filterLowerCase) ||
      facturacion_agua.estado.includes(filterLowerCase) ||
      fechaCreacionFormateado.includes(filterLowerCase) ||
      facturacion_agua.usuarioRegistrador.includes(filterLowerCase)
      ;
  })
    : [];


  if (fetchStatus === 'loading') {
    return <div>Loading...</div>;
  }

  if (fetchStatus === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container">
      <div className="card">
        <div className="card-content">
          <span className="card-title">Facturaciones de Agua</span>
          <div className="card-action">
            {esSuperUsuario && (
              <a className="btn orange tooltipped" data-position="top" data-tooltip="Nueva Facturación" onClick={() => navigate('/facturacion-agua/create')}>
                <i className="material-icons left">add_box</i>Crear Facturación de Agua
              </a>
            )}
            <a className="btn orange tooltipped" data-position="top" data-tooltip="Lecturas de Agua" onClick={() => navigate('/lecturas-agua')}>
              <i className="material-icons left">add_box</i>Lecturas de Agua
            </a>
            <a className="btn orange tooltipped" data-position="top" data-tooltip="Lecturas Rápida" onClick={() => navigate('/lectura-rapida-contometro')}>
              <i className="material-icons left">add_box</i>Lectura Rápida
            </a>
            <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/dashboard')}>
              <i className="material-icons left">arrow_back</i>
            </a>
          </div>


          <h5>Listado</h5>
          <div className="col s12 m12 input-field">
            <label htmlFor="filtro_tabla_mis_facturaciones_agua_propietario">
              <i className="material-icons">search</i> Filtrar
            </label>
            <input
              type="text"
              id="filtro_tabla_mis_facturaciones_agua_propietario"
              value={filtro}
              onChange={handleFilterChange}
            />
          </div>

          <div style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '500px', maxWidth: '100%' }}>
            <table className="striped responsive-table tabla_mis_facturaciones_agua_propietario">
              <thead>
                <tr>
                  <th>Suministro</th>
                  <th>Periodo</th>
                  <th>Recibo</th>
                  <th>Saldo</th>
                  <th>Consumo(m³)</th>
                  <th>Factor</th>
                  <th>Estado</th>
                  <th>Creación</th>
                  <th>Registrador</th>
                </tr>
              </thead>
              <tbody id="table-body-mis-facturaciones_agua_propietario">
                {facturaciones_aguaFiltrados.map((facturacion_agua) => (
                  <tr key={facturacion_agua.id}>
                    <td>{facturacion_agua.nro_suministro}</td>
                    <td>
                      {esSuperUsuario ? (
                        <Link to={`/facturacion-agua/view/${facturacion_agua.id}`}>
                          {formatearPeriodo_MesAnio01(facturacion_agua.periodo)}
                        </Link>
                      ) : (
                        formatearPeriodo_MesAnio01(facturacion_agua.periodo)
                      )}
                    </td>
                    <td>{formatearImporte01(facturacion_agua.importe_recibo_suministro, facturacion_agua.moneda)}</td>
                    <td>{formatearImporte01(facturacion_agua.importe_total_facturar, facturacion_agua.moneda)}</td>
                    <td>{facturacion_agua.consumo_unidades_facturacion}</td>
                    <td>{facturacion_agua.factor_agua}</td>
                    <td>{facturacion_agua.estado}</td>
                    <td>{formatDate02(facturacion_agua.fechaCreacion)}</td>
                    <td>{facturacion_agua.usuarioRegistrador}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MisFacturaciones_agua;