import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Cleave from 'cleave.js/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import { get_unidades_facturacion_x_edificio } from '../edificio/unidad_facturacionThunk';
import { get_conceptos_facturacion } from '../conceptos-facturacion/conceptos_facturacionThunk';
import {
    save_facturacion_masiva,
    get_facturacion_masiva,
    update_facturacion_masiva,
    anular_facturacion_masiva
} from './facturacion_masivaThunk';
import './facturacion_masivaFormPage.css';
import { TIPOS_CONCEPTO, TIPOS_FACTURACION, TIPOS_CUOTA, TIPOS_SUMINISTRO, CRITERIOS_ASIGNACION_CUOTA, FUENTES_DATOS, MONEDAS } from '../../constants/constants';
import { parseFromYYYYMM, formatPeriodo } from '../../utils/util';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';
import BuscarUnidadFacturacionModal from '../edificio/BuscaUnidadFacturacionModal';

const Facturacion_masivaForm = ({ mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useMaterializeTooltips();
    const { id: idFacturacion_masiva } = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    registerLocale('es', es);

    const [isEditing, setIsEditing] = useState(mode !== 'view');
    const [pastedValues, setPastedValues] = useState('');
    const codigo_usuario = useSelector((state) => state.auth.usuario.id);
    const edificioSeleccionado = useSelector(state => state.edificio.edificio);
    const buildingId = edificioSeleccionado.id;
    const codigo_edificio = edificioSeleccionado.codigo;
    const facturacion_masiva = useSelector(state => state.facturacion_masiva.facturacion_masiva);
    const conceptos_facturacion = useSelector(state => state.conceptos_facturacion.conceptos_facturacion) || [];
    const unidades_facturacion = useSelector(state => state.unidad_facturacion.unidades_facturacion) || [];
    const [formData, setFormData] = useState({
        periodo: formatPeriodo(new Date()),
        idconcepto_facturacion: '',
        tipo_concepto: '',
        tipo_facturacion: '',
        titulo_concepto: '',
        moneda: { codigo: 'PEN', simbolo: 'S/' },
        importe: 0,
        codigos_unidades: '',
        facturacion_masiva_detalles: [],
        total_monto: 0,
        cantidad_unidades: 0
    });

    useEffect(() => {
        dispatch(get_unidades_facturacion_x_edificio(edificioSeleccionado.id));
        dispatch(get_conceptos_facturacion(edificioSeleccionado.id));
        if (mode !== 'create') {
            dispatch(get_facturacion_masiva(idFacturacion_masiva));
        }
    }, [dispatch, edificioSeleccionado, idFacturacion_masiva, mode]);



    useEffect(() => {
        if (facturacion_masiva && mode !== 'create') {
            setFormData({
                ...facturacion_masiva,
                total_monto: facturacion_masiva.facturacion_masiva_detalles?.reduce((sum, detalle) => sum + (detalle.estadoRegistro === 'Éxito' ? facturacion_masiva.importe : 0), 0)
            });
        }
    }, [facturacion_masiva, mode]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleCleaveChange = (value) => {
        const numericValue = parseFloat(value.replace(/[^0-9.]/g, '')) || 0;
        setFormData(prev => ({
            ...prev,
            importe: numericValue,
            total_monto: numericValue * prev.cantidad_unidades // Actualiza el total según el importe
        }));

    };

    const handleBlurRecalcularTotal = () => {
        setFormData(prev => ({
            ...prev,
            total_monto: prev.facturacion_masiva_detalles.reduce((sum, detalle) => sum + (parseFloat(detalle.importe) || 0), 0)
        }));
    };

    const handlePaste = (e) => {
        const pastedData = e.target.value;
        setPastedValues(pastedData);
        const codigosArray = pastedData.split('\n').map(codigo => codigo.trim()).filter(codigo => codigo !== '');

        const selectedConcepto = conceptos_facturacion.find(concepto => concepto.id === formData.idconcepto_facturacion);
        //const importeDefecto = selectedConcepto ? selectedConcepto.importe_defecto.toFixed(2) : '0.00';
        const importeDefecto = selectedConcepto ? parseFloat(selectedConcepto.importe_defecto) || 0 : 0;
        const tituloDefecto = selectedConcepto ? selectedConcepto.titulo : '';


        const nuevosDetalles = codigosArray.map(codigo => {
            const unidadEncontrada = unidades_facturacion.find(u => u.codigo_unidad_facturacion === codigo);
            return {
                codigo_unidad_facturacion: codigo,
                nombres_propietarios: unidadEncontrada ? unidadEncontrada.nombres_propietarios : 'Desconocido',
                importe: importeDefecto,
                titulo_concepto: tituloDefecto,
                estadoRegistro: unidadEncontrada ? 'Pendiente' : 'Error',
                mensajeError: unidadEncontrada ? '' : 'Código no encontrado',
                porEliminar: false
            };
        });

        setFormData(prev => ({
            ...prev,
            facturacion_masiva_detalles: [...nuevosDetalles],
            cantidad_unidades: nuevosDetalles.length,
            //total_monto: nuevosDetalles.length * prev.importe
            //total_monto: nuevosDetalles.length * parseFloat(prev.importe || 0)
            total_monto: nuevosDetalles.reduce((sum, detalle) => sum + detalle.importe, 0)
        }));
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleRemoveDetalle = (index) => {
        setFormData(prev => {
            const nuevosDetalles = [...prev.facturacion_masiva_detalles];
            nuevosDetalles[index].porEliminar = !nuevosDetalles[index].porEliminar;
            return {
                ...prev,
                facturacion_masiva_detalles: nuevosDetalles
            };
        });
    };

    const handleAddUnidad = (unidad) => {
        setFormData(prev => ({
            ...prev,
            facturacion_masiva_detalles: [
                ...prev.facturacion_masiva_detalles,
                { codigo_unidad_facturacion: unidad.codigo_unidad_facturacion, nombres_propietarios: unidad.nombres_propietarios, estadoRegistro: "Pendiente", mensajeError: "", porEliminar: false }
            ],
            cantidad_unidades: prev.facturacion_masiva_detalles.length + 1
        }));
    };

    const handleSave = () => {
        const tieneErrores = formData.facturacion_masiva_detalles.some(detalle => detalle.estadoRegistro === 'Error');

        if (tieneErrores) {
            M.toast({ html: 'No se puede guardar. Existen registros con errores.', classes: 'red' });
            return;
        }

        const detallesActualizados = formData.facturacion_masiva_detalles.map(detalle => ({
            ...detalle,
            estadoRegistro: detalle.porEliminar ? "Anulado" : detalle.estadoRegistro
        }));

        const data = {
            ...formData,
            facturacion_masiva_detalles: detallesActualizados,
            buildingId: edificioSeleccionado.id,
            usuarioRegistrador: codigo_usuario
        };

        if (mode === 'create') {
            dispatch(save_facturacion_masiva(data));
        } else {
            dispatch(update_facturacion_masiva({ idFacturacion_masiva, facturacion_masivaData: data }));
        }

        navigate('/facturaciones-masivas');
    };

    const handleAnular = () => {
        if (window.confirm("¿Estás seguro de que quieres anular esta factura? Esta acción no se puede deshacer.")) {
            dispatch(anular_facturacion_masiva({ idFacturacion_masiva, usuarioAnulacion: codigo_usuario }));
            M.toast({ html: 'Factura anulada correctamente', classes: 'rounded' });
            navigate('/facturaciones-masivas');
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        if (mode === 'create') {
            navigate('/facturaciones-masivas');
        }
    };

    useEffect(() => {
        // Actualiza los campos de texto de Materialize cuando el componente se monta
        M.updateTextFields();
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [isEditing]);

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields();
    }, [formData]);

    const handlePeriodoChange = (date) => {
        const periodoFormateado = formatPeriodo(date); // Convierte la fecha seleccionada a "MM-YYYY"
        console.log("periodoFormateado " + periodoFormateado);
        setFormData((prev) => ({ ...prev, periodo: periodoFormateado }));
    };

    const handleIdconceptoChange = (e) => {
        const selectedIdconcepto = e.target.value;
        setFormData(prev => ({ ...prev, idconcepto_facturacion: selectedIdconcepto }));

        console.log("selectedIdconcepto: " + selectedIdconcepto);

        // Encontrar el turno seleccionado para obtener sus atributos
        const selectedConcepto = conceptos_facturacion.find(concepto_facturacion => concepto_facturacion.id === selectedIdconcepto);

        if (selectedConcepto) {
            setFormData(prev => ({
                ...prev,
                idconcepto_facturacion: selectedConcepto.id,
                concepto: selectedConcepto.nombre,
                tipo_concepto: selectedConcepto.tipo_concepto,
                tipo_facturacion: selectedConcepto.tipo_facturacion,
                titulo_concepto: selectedConcepto.titulo,
                //importe: selectedConcepto.importe_defecto !== undefined ? selectedConcepto.importe_defecto.toFixed(2) : '0.00',
                importe: selectedConcepto.importe_defecto !== undefined ? parseFloat(selectedConcepto.importe_defecto.toFixed(2)) : 0,
                moneda: selectedConcepto.moneda,
            }));

        } else {
            console.log("Concepto no encontrado o no existe");
            setFormData(prev => ({
                ...prev,
                concepto: "",
                tipo_concepto: "",
                tipo_facturacion: "",
                titulo_concepto: "",
                importe: 0,
                moneda: "",
            }));
        }
    };
    const handleDetalleChange = (index, field, value) => {
        setFormData(prev => {

            const nuevosDetalles = [...prev.facturacion_masiva_detalles];

            // Convertir el importe a número
            if (field === "importe") {
                value = typeof value === "string" ? parseFloat(value.replace(/[^0-9.]/g, '')) || 0 : parseFloat(value) || 0;
            }

            nuevosDetalles[index][field] = value;

            // Recalcular total_monto asegurando que los valores sean numéricos
            const nuevoTotalMonto = nuevosDetalles.reduce((sum, detalle) => sum + (parseFloat(detalle.importe) || 0), 0);

            return { ...prev, facturacion_masiva_detalles: nuevosDetalles, total_monto: nuevoTotalMonto };
        });
    };
    return (
        <div className="container">
            <div className="card">
                <div className="card-content">
                    <div className="card-action">
                        {isEditing ? (
                            <>
                                <button className="btn green tooltipped" data-position="top" data-tooltip="Guardar cambios" onClick={handleSave}>
                                    <i className="material-icons left">save</i>Guardar
                                </button>
                                <button className="btn red tooltipped" data-position="top" data-tooltip="Cancelar edición" onClick={handleCancel}>
                                    <i className="material-icons left">cancel</i>Cancelar
                                </button>
                            </>
                        ) : (
                            <>
                                <button className="btn green tooltipped" data-position="top" data-tooltip="Editar detalles" onClick={handleEdit} >
                                    <i className="material-icons left">edit</i>Editar
                                </button>
                                <button className="btn orange tooltipped" data-tooltip="Anular Facturación Individual" onClick={handleAnular}>
                                    <i className="material-icons left">delete</i>Anular
                                </button>
                            </>
                        )}
                        <button className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/facturaciones-masivas')}>
                            <i className="material-icons left">arrow_back</i>Volver
                        </button>
                    </div>
                    <span className="card-title">
                        {mode === 'create' ? 'Crear Facturación Masiva' : mode === 'edit' ? 'Editar Facturación Masiva' : 'Detalles de la Facturación Masiva'}
                    </span>
                    <div className="row">
                        <div className="input-field col s6 m2">
                            <DatePicker
                                selected={parseFromYYYYMM(formData.periodo)}
                                onChange={handlePeriodoChange}
                                showMonthYearPicker
                                dateFormat="MM/yyyy"
                                name="periodo"
                                disabled={!isEditing}
                                locale="es" // Asegúrate de importar el locale si usas uno
                            />
                            <label className="active" htmlFor="periodo">Período:</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <select
                                name="idconcepto_facturacion"
                                value={formData.idconcepto_facturacion || ''}
                                onChange={handleIdconceptoChange}
                                disabled={!isEditing}
                            >
                                <option value="" disabled>Selecciona un concepto</option>
                                {Array.isArray(conceptos_facturacion) && conceptos_facturacion.map(s => (
                                    <option key={s.id} value={s.id}>
                                        {s.nombre}
                                    </option>
                                ))}
                            </select>
                            <label>Concepto</label>
                        </div>
                        <div className="input-field col s6 m2">
                            <input
                                type="text"
                                name="tipo_concepto"
                                value={formData.tipo_concepto || ''}
                                onChange={handleInputChange}
                                disabled={true}
                            />
                            <label htmlFor="tipo_concepto" className="active">Tipo</label>
                        </div>
                        <div className="input-field col s6 m2">
                            <input
                                type="text"
                                name="tipo_facturacion"
                                value={formData.tipo_facturacion || ''}
                                onChange={handleInputChange}
                                disabled={true}
                            />
                            <label className="active" htmlFor="tipo_facturacion">Facturación</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s6 m2">
                            <Cleave
                                name="importe"
                                value={formData.importe}
                                onChange={(e) => handleCleaveChange(e.target.value)}
                                options={{
                                    numeral: true,
                                    numeralThousandsGroupStyle: 'thousand',
                                    numeralDecimalMark: '.',
                                    numeralDecimalScale: 2,
                                    prefix: formData.moneda.simbolo + ' '
                                }}
                                disabled={!isEditing}
                            />
                            <label>Importe</label>
                        </div>
                        <div className="input-field col s12 m3">
                            <input
                                type="text"
                                name="titulo_concepto"
                                value={formData.titulo_concepto || ''}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                            <label className="active" htmlFor="titulo_concepto">Título concepto</label>
                        </div>
                    </div>
                    <div className="row">
                        <p>Cantidad de unidades facturadas: {formData.cantidad_unidades}</p>
                        <p>Total monto: {parseFloat(formData.total_monto || 0).toFixed(2)}</p>
                    </div>
                    <div className="row">
                        <textarea
                            placeholder="Pega los códigos de unidad de facturación aquí"
                            value={pastedValues}
                            onChange={handlePaste}
                        ></textarea>
                    </div>
                    {isEditing && (
                        <div className="row">
                            <button className="btn blue" onClick={() => setIsModalOpen(true)}>
                                <i className="material-icons left">add</i>Agregar Unidad
                            </button>
                        </div>
                    )}
                    <div className="row">
                        <table className="striped">
                            <thead>
                                <tr>
                                    <th>Código Unidad</th>
                                    <th>Propietario</th>
                                    <th>Importe</th>
                                    <th>Título</th>
                                    <th>Estado</th>
                                    <th>Mensaje Error</th>
                                    {isEditing && <th>Acción</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {formData.facturacion_masiva_detalles.map((detalle, index) => (
                                    <tr key={index}>
                                        <td>{detalle.codigo_unidad_facturacion}</td>
                                        <td>{detalle.nombres_propietarios || 'Desconocido'}</td>
                                        <td>
                                            <Cleave
                                                value={detalle.importe}
                                                onChange={(e) => handleDetalleChange(index, 'importe', e.target.value)}
                                                onBlur={handleBlurRecalcularTotal}
                                                options={{
                                                    numeral: true,
                                                    numeralThousandsGroupStyle: 'thousand',
                                                    numeralDecimalMark: '.',
                                                    numeralDecimalScale: 2
                                                }}
                                                disabled={!isEditing}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={detalle.titulo_concepto}
                                                onChange={(e) => handleDetalleChange(index, 'titulo_concepto', e.target.value)}
                                                disabled={!isEditing}
                                            />
                                        </td>
                                        <td className={detalle.estadoRegistro === 'Éxito' ? 'green-text' : 'red-text'}>
                                            {detalle.estadoRegistro}
                                        </td>
                                        <td>{detalle.mensajeError}</td>
                                        {isEditing && (
                                            <td>
                                                <button className="btn red" onClick={() => handleRemoveDetalle(index)}>
                                                    <i className="material-icons">{detalle.porEliminar ? 'undo' : 'delete'}</i>
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* Modal para buscar unidades */}
                <BuscarUnidadFacturacionModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    handleAddUnidadExonerada={handleAddUnidad}
                    buildingId={edificioSeleccionado.id}
                />
            </div>
        </div>
    );
};

export default Facturacion_masivaForm;
