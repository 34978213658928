// frontend/src/pages/MisFacturaciones_masivas.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { get_facturaciones_masivas } from './facturacion_masivaThunk';
import M from 'materialize-css';

import { formatDate, formatCurrency, formatPeriod, formatDate02, formatearPeriodo_MesAnio01, formatearImporte01 } from '../../utils/formateo';
import { downloadPDF } from '../../utils/util';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const MisFacturaciones_masivas = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useMaterializeTooltips();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const facturaciones_masivas = useSelector((state) => state.facturacion_masiva.facturaciones_masivas) || [];
    const usuario = useSelector((state) => state.auth.usuario);
    const status_get_facturaciones_masivas = useSelector((state) => state.facturacion_masiva.status_get_facturaciones_masivas);
    const edificio = useSelector((state) => state.edificio.edificio);
    const error_get_facturaciones_masivas = useSelector((state) => state.facturacion_masiva.error_get_facturaciones_masivas);
    const error = useSelector((state) => state.facturacion_masiva.error);

    const [filtro, setFiltro] = useState('');

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
            return;
        }

        M.AutoInit(); // Inicializa los componentes de Materialize CSS

        if (status_get_facturaciones_masivas === 'idle' && edificio?.id) {
            console.log("Cargando facturaciones masivas para edificio ID:", edificio.id);
            dispatch(get_facturaciones_masivas(edificio.id));
        }
    }, [isAuthenticated, edificio, status_get_facturaciones_masivas, dispatch]);

    if (!usuario) {
        return null; // Evita renderizar mientras se verifica la autenticación
    }

    const handleFilterChange = (e) => {    
        setFiltro(e.target.value.toLowerCase());
    };

    const facturaciones_masivasFiltrados = facturaciones_masivas.filter((facturacion_masiva) => {
        const filter = filtro.trim();
        if (filter === '') return true; // Si el filtro está vacío, mostrar todos

        // Asegurar que los campos existen antes de aplicarle métodos
        const periodo = facturacion_masiva.periodo?.toLowerCase() || '';
        const codigo_unidad_facturacion = facturacion_masiva.codigo_unidad_facturacion?.toLowerCase() || '';
        const concepto = facturacion_masiva.concepto?.toLowerCase() || '';
        const tipo_concepto = facturacion_masiva.tipo_concepto?.toLowerCase() || '';
        const tipo_facturacion = facturacion_masiva.tipo_facturacion?.toLowerCase() || '';
        const total_monto = facturacion_masiva.importe?.toString().toLowerCase() || '';
        const importe = facturacion_masiva.importe?.toString().toLowerCase() || '';        
        const estado = facturacion_masiva.estado?.toLowerCase() || '';

        return (
            periodo.includes(filter) ||
            codigo_unidad_facturacion.includes(filter) ||
            concepto.includes(filter) ||
            total_monto.includes(filter) ||
            tipo_concepto.includes(filter) ||
            tipo_facturacion.includes(filter) ||
            importe.includes(filter) ||
            estado.includes(filter)
        );
    });

    return (
        <div className="container">
            <div className="card">
                <div className="card-content">
                    <span className="card-title">Facturación Masiva</span>
                    <div className="card-action">
                        <button 
                            className="btn orange tooltipped" 
                            data-position="top" 
                            data-tooltip="Nueva Facturación Masiva" 
                            onClick={() => navigate(`/facturacion-masiva/create`)}
                        >
                            <i className="material-icons left">add_box</i>Crear Facturación
                        </button>
                        <button 
                            className="btn blue tooltipped" 
                            data-position="top" 
                            data-tooltip="Volver" 
                            onClick={() => navigate('/dashboard')}
                        >
                            <i className="material-icons left">arrow_back</i>Volver
                        </button>              
                    </div>
                    
                    <h5>Listado</h5>

                    <div className="input-field">
                        <i className="material-icons prefix">search</i>
                        <input 
                            type="text" 
                            id="filtro_tabla_mis_facturaciones_masivas_propietario" 
                            value={filtro}
                            onChange={handleFilterChange} 
                            placeholder="Filtrar por cualquier campo" 
                        />
                    </div>

                    {status_get_facturaciones_masivas === 'loading' && (
                        <div className="progress">
                            <div className="indeterminate"></div>
                        </div>
                    )}

                    {status_get_facturaciones_masivas === 'failed' && (
                        <div className="card-panel red lighten-4 red-text text-darken-4">
                            <span>Error: {error_get_facturaciones_masivas}</span>
                        </div>
                    )}

                    {status_get_facturaciones_masivas === 'succeeded' && facturaciones_masivasFiltrados.length === 0 && (
                        <div className="card-panel yellow lighten-4 yellow-text text-darken-4">
                            <span>No se encontraron facturaciones que coincidan con el filtro.</span>
                        </div>
                    )}

                    {status_get_facturaciones_masivas === 'succeeded' && facturaciones_masivasFiltrados.length > 0 && (
                        <table className="striped responsive-table tabla_mis_facturaciones_masivas_propietario">
                            <thead>
                                <tr>
                                    <th>Periodo</th>
                                    <th>Concepto</th>
                                    <th>Tipo</th>
                                    <th>Facturación</th>
                                    <th>Total Monto</th>
                                    <th>Importe</th>
                                    <th>Estado</th>
                                    <th>Registrador</th>
                                </tr>
                            </thead>
                            <tbody id="table-body-mis-facturaciones_masivas_propietario">                    
                                {facturaciones_masivasFiltrados.map((facturacion_masiva) => (
                                    <tr key={facturacion_masiva.id}>
                                        <td>{facturacion_masiva.periodo}</td>
                                        <td>
                                            <Link to={`/facturacion-masiva/view/${facturacion_masiva.id}`}>
                                                {facturacion_masiva.concepto}
                                            </Link>
                                        </td>
                                        <td>{facturacion_masiva.tipo_concepto}</td>
                                        <td>{facturacion_masiva.tipo_facturacion}</td>
                                        <td>{formatearImporte01(facturacion_masiva.total_monto, facturacion_masiva.moneda)}</td>
                                        <td>{formatearImporte01(facturacion_masiva.importe, facturacion_masiva.moneda)}</td>
                                        <td>{facturacion_masiva.estado}</td>
                                        <td>{facturacion_masiva.usuarioRegistrador}</td>                                        
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>   
            </div> 
        </div>
    );
};

export default MisFacturaciones_masivas;
