import { createSlice } from '@reduxjs/toolkit';
import { get_carga_crep, get_cargas_crep, get_cargas_crep_admin, update_carga_crep, save_carga_crep
  , anular_carga_crep, carga_crep_masivo} from './carga_crepThunk';

const initialState= {
  carga_crep: null,
  cargas_crep: [],
  carga_crepSeleccionada: null,
  carga_crepData: null,
  status: 'idle',
  error: null,
  status_get_cargas_crep: 'idle',
  error_get_cargas_crep: null,    
  status_carga_crep_masivo: 'idle',
  error_carga_crep_masivo: null,    
  status_get_cargas_crep_admin: 'idle',
  error_get_cargas_crep_admin: null,
  status_update_carga_crep: 'idle',
  error_update_carga_crep: null,    
  status_save_carga_crep: 'idle',
  error_save_carga_crep: null,
  status_anular_carga_crep: 'idle',
  error_anular_carga_crep: null,
}

const carga_crepSlice = createSlice({
  name: 'carga_crep',
  initialState,
  reducers: {
    setCarga_crepSeleccionada: (state, action) => {
      state.carga_crepSeleccionada = action.payload;
      
    },
    clearCarga_crepSeleccionada(state) {
      state.carga_crepSeleccionada = null;
      state.carga_crep = null;
    },
    resetStateGetCargas_crep: () => {
        return {
            status_get_cargas_crep : 'idle',
        };
      },
     
    resetState: () => initialState, // 🔥 Reiniciar todo el estado al estado inicial
    clearCargas_crep: (state) => {
      state.cargas_crep = []; // Limpiar cargas_crep antes de una nueva consulta
      state.status_get_cargas_crep_admin = 'idle';
      state.status_get_cargas_crep_admin = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_carga_crep.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_carga_crep.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.carga_crep = action.payload;

      })
      .addCase(get_carga_crep.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })

      .addCase(update_carga_crep.pending, (state) => {
        state.status_update_carga_crep = 'loading';
      })
      .addCase(update_carga_crep.fulfilled, (state, action) => {
        state.status_update_carga_crep = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.carga_crep = action.payload.carga_crep;

      })
      .addCase(update_carga_crep.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_carga_crep = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_carga_crep = action.payload || 'Failed to fetch building';
      })
      
      .addCase(carga_crep_masivo.pending, (state) => {
        state.status_carga_crep_masivo = 'loading';
      })
      .addCase(carga_crep_masivo.fulfilled, (state, action) => {
        state.status_carga_crep_masivo = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.carga_crepData = action.payload.carga_crepData;
      })
      .addCase(carga_crep_masivo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_carga_crep_masivo = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_carga_crep_masivo = action.payload || 'Failed to fetch building';
      })

      .addCase(save_carga_crep.pending, (state) => {
        state.status_save_carga_crep = 'loading';
      })
      .addCase(save_carga_crep.fulfilled, (state, action) => {
        state.status_save_carga_crep = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.carga_crep = action.payload.carga_crep;

      })
      .addCase(save_carga_crep.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_carga_crep = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_carga_crep = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_carga_crep.pending, (state) => {
        state.status_save_carga_crep = 'loading';
      })
      .addCase(anular_carga_crep.fulfilled, (state, action) => {
        state.status_anular_carga_crep = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.carga_crep = action.payload.carga_crep;

      })
      .addCase(anular_carga_crep.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_carga_crep = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_carga_crep = action.payload || 'Failed to fetch building';
      })
      .addCase(get_cargas_crep.pending, (state) => {
        state.status_get_cargas_crep = 'loading';
        
      })
      .addCase(get_cargas_crep.fulfilled, (state, action) => {
        state.status_get_cargas_crep = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.cargas_crep = action.payload;

      })
      .addCase(get_cargas_crep.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_cargas_crep = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_cargas_crep = action.payload || 'Failed to fetch building';
      })      

      .addCase(get_cargas_crep_admin.pending, (state) => {
        state.status_get_cargas_crep_admin = 'loading';
        
      })
      .addCase(get_cargas_crep_admin.fulfilled, (state, action) => {
        state.status_get_cargas_crep_admin = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.cargas_crep = action.payload;

      })
      .addCase(get_cargas_crep_admin.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_cargas_crep_admin = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_cargas_crep_admin = action.payload || 'Failed to fetch building';
      })   
  
  },
});

export const { setCarga_crepSeleccionada, clearCarga_crepSeleccionada, clearCargas_crep } = carga_crepSlice.actions;
export const selectCargas_crepById = (state, idCarga_crep) =>
    state.carga_crep.cargas_crep.find((carga_crep) => carga_crep.id === idCarga_crep);
export const { resetStateGetCargas_crep, resetState } = carga_crepSlice.actions;
export default carga_crepSlice.reducer;
