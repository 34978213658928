// src/components/MisReservas.js

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_reservas_x_depa_tipo_area_comun } from './reservaThunk';
import { clearReservas } from './reservaSlice';
import M from 'materialize-css';
import { Link } from 'react-router-dom';
import './MisReservas.css';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

import { formatDate, formatCurrency, formatPeriod, formatDate02, formatearPeriodo_MesAnio01, formatearImporte01 } from '../../utils/formateo';
import { getEstadoPagoButtonClass, getEstadoPagoIcon, downloadPDF } from '../../utils/util';
import { TIPO_AREA_COMUN_GIMNASIO, TIPO_AREA_COMUN_PARRILLA, TIPO_AREA_COMUN_SALA_YOGA, TIPO_AREA_COMUN_SUM } from '../../constants/constants';

const MisReservas = ({ tipo_area_comun }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useMaterializeTooltips();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const reservas = useSelector((state) => state.reserva.reservas);
  const usuario = useSelector((state) => state.auth.usuario);
  const status_get_reservas_x_depa_tipo_area_comun = useSelector((state) => state.reserva.status_get_reservas_x_depa_tipo_area_comun);
  const unidad_facturacionSeleccionado = useSelector((state) => state.unidad_facturacion.unidad_facturacionSeleccionado);
  const error_get_reservas_x_depa_tipo_area_comun = useSelector((state) => state.reserva.error_get_reservas_x_depa_tipo_area_comun);
  const [filtro, setFiltro] = useState('');


  useEffect(() => {
    // 🔹 Limpiar reservas cuando cambia el tipo de área común
    dispatch(clearReservas());
  }, [tipo_area_comun, dispatch]);

  useEffect(() => {
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    if (unidad_facturacionSeleccionado && unidad_facturacionSeleccionado.buildingId) {
      dispatch(get_reservas_x_depa_tipo_area_comun(
        {
          buildingId: unidad_facturacionSeleccionado.buildingId,
          codigo_unidad_facturacion: unidad_facturacionSeleccionado.codigo_unidad_facturacion,
          tipo_area_comun: tipo_area_comun
        }
      ));
    }
  }, [unidad_facturacionSeleccionado, tipo_area_comun, dispatch]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS   
  }, [isAuthenticated, navigate]);

  if (!usuario) {
    return null; // Retorna null mientras se redirige
  }

  const handleFilterChange = (e) => {
    setFiltro(e.target.value);
  };

  const handleCrearReserva = () => {

    if (tipo_area_comun === TIPO_AREA_COMUN_SUM) {
      navigate('/reserva-sum/create');
    }
    if (tipo_area_comun === TIPO_AREA_COMUN_PARRILLA) {
      navigate('/reserva-parrilla/create');
    }
    if (tipo_area_comun === TIPO_AREA_COMUN_GIMNASIO) {
      navigate('/reserva-gym/create');
    }
    if (tipo_area_comun === TIPO_AREA_COMUN_SALA_YOGA) {
      navigate('/reserva-yoga/create');
    }


  };


  const handleOpenCalendar = () => {
    if (tipo_area_comun === TIPO_AREA_COMUN_SUM) {
      navigate('/calendario-reservas-sum');
    }
    if (tipo_area_comun === TIPO_AREA_COMUN_PARRILLA) {
      navigate('/calendario-reservas-parrilla');
    }
    if (tipo_area_comun === TIPO_AREA_COMUN_GIMNASIO) {
      navigate('/calendario-reservas-gym');
    }
    if (tipo_area_comun === TIPO_AREA_COMUN_SALA_YOGA) {
      navigate('/calendario-reservas-yoga');
    }
  };

  // Función de filtrado mejorada
  const reservasFiltrados = Array.isArray(reservas) ? reservas.filter((reserva) => {
    const costo_reservaFormateado = formatearImporte01(reserva.turno.costo, reserva.moneda) || '';
    const garantia_reservaFormateado = formatearImporte01(reserva.turno.garantia, reserva.moneda) || '';
    const aforo_reservaFormateado = reserva.turno.aforo !== undefined && reserva.turno.aforo !== null ? reserva.turno.aforo.toString() : '';
    const estado_reservaFormateado = reserva.estado || '';
    const estado_pago_formateado = reserva.estado_pago || '';

    const filterLowerCase = filtro.toLowerCase();

    return (
      (reserva.area_comun || '').toLowerCase().includes(filterLowerCase) ||
      (reserva.fecha_reserva || '').toLowerCase().includes(filterLowerCase) ||
      (reserva.turno.turno_hora_inicio || '').toLowerCase().includes(filterLowerCase) ||
      (reserva.turno.turno_hora_fin || '').toLowerCase().includes(filterLowerCase) ||
      (costo_reservaFormateado.toLowerCase().includes(filterLowerCase)) ||
      (garantia_reservaFormateado.toLowerCase().includes(filterLowerCase)) ||
      (aforo_reservaFormateado.toLowerCase().includes(filterLowerCase)) ||
      (estado_reservaFormateado.toLowerCase().includes(filterLowerCase)) ||
      (estado_pago_formateado.toLowerCase().includes(filterLowerCase))
    );
  }) : [];

  if (status_get_reservas_x_depa_tipo_area_comun === 'loading') {
    return <div>Loading...</div>;
  }

  if (status_get_reservas_x_depa_tipo_area_comun === 'failed') {
    return <div>Error: {error_get_reservas_x_depa_tipo_area_comun}</div>;
  }

  // Función auxiliar para obtener la ruta de visualización según tipo_area_comun
  const getReservaViewPath = (tipo_area_comun, id) => {
    const rutas = {
      "SUM": `/reserva-sum/view/${id}`,
      "PARRILLA": `/reserva-parrilla/view/${id}`,
      "GIMNASIO": `/reserva-gym/view/${id}`,
      "YOGA": `/reserva-yoga/view/${id}`
    };
    return rutas[tipo_area_comun] || `/reserva/view/${id}`;
  };

  return (
    <div className="container">
      <div className="card">
        <div className="card-content">
          <span className="card-title">Mis Reservas {tipo_area_comun}</span>
          <div className="card-action">

            <a className="btn orange tooltipped" data-position="top" data-tooltip="Nueva Reserva" onClick={handleCrearReserva}>
              <i className="material-icons left">add_box</i>Crear Reserva
            </a>
            {!(tipo_area_comun === TIPO_AREA_COMUN_GIMNASIO || tipo_area_comun === TIPO_AREA_COMUN_SALA_YOGA) && (
              <a className="btn purple tooltipped" data-position="top" data-tooltip="Ver Calendario" onClick={handleOpenCalendar}>
                <i className="material-icons left">calendar_today</i>Calendario
              </a>
            )}
            <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate("/dashboard")}>
              <i className="material-icons left">arrow_back</i>
            </a>
          </div>

          <h5>Listado</h5>
          <div className="col s12 m12 input-field">
            <label htmlFor="filtro_tabla_mis_reservas_propietario"><i className="material-icons">search</i> Filtrar</label>
            <input
              type="text"
              id="filtro_tabla_mis_reservas_propietario"
              value={filtro}
              onChange={handleFilterChange}
            />
          </div>
          <div className="table-container">
            <table className="striped responsive-table tabla_mis_reservas_propietario">
              <thead>
                <tr>
                  <th>Área</th>
                  <th>Reserva</th>
                  <th>Inicio</th>
                  <th>Fin</th>
                  <th>Costo</th>
                  <th>Garantía</th>
                  <th>Aforo</th>
                  <th>Estado</th>
                  <th>Pago</th>
                  {/* Nuevas columnas para Constancia de Pago y Fecha de Carga */}
                  <th>Pago Alquiler</th>
                  <th>Carga Alquiler</th>
                  <th>Pago Garantia</th>
                  <th>Carga Garantia</th>
                </tr>
              </thead>
              <tbody id="table-body-mis-reservas_propietario">
                {reservasFiltrados.map((reserva) => (
                  <tr key={reserva.id}>
                    <td>{reserva.area_comun}</td>
                    <td>
                      <Link to={getReservaViewPath(reserva.tipo_area_comun, reserva.id)}>
                        {reserva.fecha_reserva}
                      </Link>
                    </td>
                    <td>{reserva.turno.turno_hora_inicio}</td>
                    <td>{reserva.turno.turno_hora_fin}</td>
                    <td>{formatearImporte01(reserva.turno.costo, reserva.moneda)}</td>
                    <td>{formatearImporte01(reserva.turno.garantia, reserva.moneda)}</td>
                    <td>{reserva.turno.aforo}</td>
                    <td>{reserva.estado}</td>
                    <td>{reserva.estado_pago}</td>
                    {/* Nueva columna: Constancia de Pago */}
                    <td>
                      {reserva.recaudacion_alquiler?.constancia_pago_alquiler_url ? (
                        <button
                          type="button"
                          className="btn blue tooltipped"
                          data-position="top"
                          data-tooltip="Visualizar Constancia de Pago"
                          onClick={() => window.open(reserva.recaudacion_alquiler.constancia_pago_alquiler_url, '_blank')}
                        >
                          <i className="material-icons left">visibility</i>Visualizar
                        </button>
                      ) : (
                        <span>No cargada</span>
                      )}
                    </td>
                    {/* Nueva columna: Fecha de Carga */}
                    <td>
                      {reserva.recaudacion_alquiler?.fecha_constancia_alquiler_formateada ? (
                        <span>{reserva.recaudacion_alquiler.fecha_constancia_alquiler_formateada}</span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    <td>
                      {reserva.recaudacion_garantia?.constancia_pago_garantia_url ? (
                        <button
                          type="button"
                          className="btn blue tooltipped"
                          data-position="top"
                          data-tooltip="Visualizar Constancia de Pago"
                          onClick={() => window.open(reserva.recaudacion_garantia.constancia_pago_garantia_url, '_blank')}
                        >
                          <i className="material-icons left">visibility</i>Visualizar
                        </button>
                      ) : (
                        <span>No cargada</span>
                      )}
                    </td>
                    {/* Nueva columna: Fecha de Carga */}
                    <td>
                      {reserva.recaudacion_garantia?.fecha_constancia_garantia_formateada ? (
                        <span>{reserva.recaudacion_garantia.fecha_constancia_garantia_formateada}</span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MisReservas;