import { createSlice } from '@reduxjs/toolkit';
import {get_conceptos_facturacion} from './conceptos_facturacionThunk';

const initialState= {
  conceptos_facturacion: [],
  selectedConcepts: [],
  fetchStatus : 'idle',
  error: null,
}

const conceptos_facturacionSlice = createSlice({
    name: 'conceptos_facturacion',
    initialState,
    reducers: {
      resetStateGetConceptos: () => {
        return {
          fetchStatus : 'idle',
        };
      },
      selectConcept: (state, action) => {
        //console.log(" seleccionando Concepto "+action.payload);
        //console.log(" seleccionando JSON.stringify(action.payload) "+JSON.stringify(action.payload));
        //const concept = action.payload;
        
        // Añadir el concepto seleccionado a la lista
        state.selectedConcepts.push(action.payload);
      },
      removeConcept(state, action) {
        //console.log(" SE ELIMINARA Concepto "+ JSON.stringify(action.payload) + " de idconcepto "+ action.payload.idconcepto);
        state.selectedConcepts = state.selectedConcepts.filter(concept => concept.idconcepto !== action.payload.idconcepto);
      },
      clearSelectedConcepts(state) {
        console.log(" limpiara antes clearSelectedConcepts "+JSON.stringify(state.selectedConcepts));        
        state.selectedConcepts = [];        
        //console.log(" limpiara despues state.selectedConcepts "+JSON.stringify(state.selectedConcepts));        
      },
      updateSelectedConcepts(state, action) {
        //console.log(" limpiara antes updateSelectedConcepts "+JSON.stringify(action.payload));     
        state.selectedConcepts = action.payload;
      },
      updateConceptoImporte(state, action) {
        const { idconcepto, importe } = action.payload;
        const concepto = state.selectedConcepts.find((concepto) => concepto.idconcepto === idconcepto);
        if (concepto) {
          concepto.importe = importe;
        }
      },
      resetState: () => initialState, // 🔥 Reiniciar todo el estado al estado inicial
    },
    extraReducers: (builder) => {
      builder
          .addCase(get_conceptos_facturacion.pending, (state) => {
              state.fetchStatus  = 'loading';
          })
          .addCase(get_conceptos_facturacion.fulfilled, (state, action) => {
              state.fetchStatus  = 'succeeded';
              //console.log("32 JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.conceptos_facturacion = action.payload;

          })
          .addCase(get_conceptos_facturacion.rejected, (state, action) => {
            //console.log("ejecutando Slice failed rejected ");
              state.fetchStatus  = 'failed';              
              //console.log("error action.error.message "+action.error.message );
              state.error = action.payload || 'Failed to fetch receipts';
          });
    },
  });
    
  export const selectConcepto_facturacionById = (state, concepto_facturacionId) =>
    state.conceptos_facturacion.conceptos_facturacion.find((concepto_facturacion) => concepto_facturacion.id === concepto_facturacionId);
  
  export const { resetStateGetConceptos, selectConcept, removeConcept, clearSelectedConcepts, updateSelectedConcepts, updateConceptoImporte, resetState  } = conceptos_facturacionSlice.actions;

  export default conceptos_facturacionSlice.reducer;
