import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetAreas_comunes } from './area_comunSlice';
import { TIPO_AREA_COMUN_GIMNASIO, TIPO_AREA_COMUN_PARRILLA, TIPO_AREA_COMUN_SALA_YOGA, TIPO_AREA_COMUN_SUM } from '../../constants/constants';

export const update_area_comun = createAsyncThunk('area_comun/update_area_comun', async ({ idArea_comun, area_comunData }, thunkAPI) => {
  //console.log("ejecutando thunk update_area_comun con idArea_comun: " + idArea_comun);

  try {

    const response = await apiClient.put(`/areas-comunes/${idArea_comun}`, area_comunData);

    const area_comun = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetAreas_comunes(area_comun.buildingId));// Resetear el estado para refetch de areas_comunes

    return area_comun;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_area_comun = createAsyncThunk('area_comun/save_area_comun', async (area_comunData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/areas-comunes`, area_comunData);

    const area_comun = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetAreas_comunes(area_comun.buildingId));// Resetear el estado para refetch de areas_comunes

    return area_comun;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_area_comun = createAsyncThunk('area_comun/anular_area_comun', async ({ idArea_comun, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_area_comun con idArea_comun: " + idArea_comun);

  try {

    const response = await apiClient.patch(`/areas-comunes/${idArea_comun}/anular`, { usuarioAnulacion });

    const area_comun = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetAreas_comunes(area_comun.buildingId));// Resetear el estado para refetch de areas_comunes

    return area_comun;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_area_comun = createAsyncThunk('area_comun/get_area_comun', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_area_comun con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/areas-comunes/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const area_comun = response.data;

    return area_comun;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_areas_comunes = createAsyncThunk('area_comun/get_areas_comunes', async ({ buildingId, tipo_area_comun }, thunkAPI) => {
  console.log("ejecutando thunk get_areas_comunes con buildingId: "+buildingId + " tipo_area_comun: "+tipo_area_comun);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/areas-comunes',{
        params: { 
            buildingId, 
            tipo_area_comun 
          }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const areas_comunes = response.data;
          
      return areas_comunes ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});


export const get_areas_comunes_sum = createAsyncThunk('area_comun/get_areas_comunes_sum', async ({ buildingId}, thunkAPI) => {
  console.log("ejecutando thunk get_areas_comunes_sum con buildingId: "+buildingId );
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/areas-comunes',{
        params: { 
            buildingId, 
            tipo_area_comun: TIPO_AREA_COMUN_SUM 
          }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const areas_comunes = response.data;
          
      return areas_comunes ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const get_areas_comunes_parrilla = createAsyncThunk('area_comun/get_areas_comunes_parrilla', async ({ buildingId}, thunkAPI) => {
  console.log("ejecutando thunk get_areas_comunes_parrilla con buildingId: "+buildingId );
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/areas-comunes',{
        params: { 
            buildingId, 
            tipo_area_comun: TIPO_AREA_COMUN_PARRILLA 
          }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const areas_comunes = response.data;
          
      return areas_comunes ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const get_areas_comunes_gym = createAsyncThunk('area_comun/get_areas_comunes_gym', async ({ buildingId}, thunkAPI) => {
  console.log("ejecutando thunk get_areas_comunes_gym con buildingId: "+buildingId );
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/areas-comunes',{
        params: { 
            buildingId, 
            tipo_area_comun: TIPO_AREA_COMUN_GIMNASIO 
          }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const areas_comunes = response.data;
          
      return areas_comunes ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const get_areas_comunes_yoga = createAsyncThunk('area_comun/get_areas_comunes_yoga', async ({ buildingId}, thunkAPI) => {
  console.log("ejecutando thunk get_areas_comunes_yoga con buildingId: "+buildingId );
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/areas-comunes',{
        params: { 
            buildingId, 
            tipo_area_comun: TIPO_AREA_COMUN_SALA_YOGA 
          }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const areas_comunes = response.data;
          
      return areas_comunes ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
