import React, { useEffect, useState  } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {get_personas_cargadas_x_edificio, mover_personas_cargadas} from './personas-cargadasThunk';
import M from 'materialize-css';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';
import {formatDate02} from '../../utils/formateo';


const Personas_cargados = () => {
  useMaterializeTooltips();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);  
  const edificio = useSelector(state => state.edificio.edificio);
  const codigo_edificio = edificio?.id;
  //const Personas_cargados = useSelector((state) => state.Personas_cargados.Personas_cargados);
  const { personas_cargadas, status, error } = useSelector((state) => state.personas_cargadas);
  const usuario = useSelector((state) => state.auth.usuario);
  //const fetchStatus  = useSelector((state) => state.personas_cargadas.fetchStatus );    
  //const error = useSelector((state) => state.personas_cargadas.error);
  const [filtro, setFiltro] = useState('');
  
  //console.log("en mis recibos isAuthenticated: "+isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
        //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    //console.log("status.get_personas_cargadas_x_edificio: "+ status.get_personas_cargadas_x_edificio);
    //console.log("codigo_edificio: "+ codigo_edificio);
    if (status.get_personas_cargadas_x_edificio  === 'idle' && codigo_edificio) {
      dispatch(get_personas_cargadas_x_edificio(codigo_edificio));
      
    }


  }, [isAuthenticated, navigate, status.get_personas_cargadas_x_edificio , codigo_edificio, dispatch]);

  const handleRefresh = () => {
    dispatch(get_personas_cargadas_x_edificio(codigo_edificio));
  };

  const formatearPersonas = (personas) => {
    if (!Array.isArray(personas)) {
      return []; // Retorna un arreglo vacío si depas no es un arreglo
    }

    return personas.map(persona => ({
      ...persona,
      fecha_carga_formato01: formatDate02(persona.timestamp_carga),
    }));
  };

  const personasFormateados = formatearPersonas(personas_cargadas);

  const handleFilterChange = (e) => {    
    setFiltro(e.target.value);
    
  };

  const personasFiltrados = Array.isArray(personasFormateados) ? personasFormateados.filter((persona) => {    
    const filterLowerCase = filtro.toLowerCase();
    return (        
        persona.tipo_documento?.toLowerCase().includes(filterLowerCase) ||
        persona.numero_documento?.toLowerCase().includes(filterLowerCase) ||
        persona.titular?.toLowerCase().includes(filterLowerCase) ||
        persona.email?.toLowerCase().includes(filterLowerCase) ||
        persona.celular?.toLowerCase().includes(filterLowerCase) ||
        persona.tipo_usuario?.toLowerCase().includes(filterLowerCase) ||
        persona.usuario?.toLowerCase().includes(filterLowerCase) ||
        persona.fecha_carga_formato01?.toString().includes(filterLowerCase) ||
        persona.mensaje_error_carga?.toString().includes(filterLowerCase) ||
        persona.validacion_carga.toLowerCase()?.includes(filterLowerCase)
    );
}) : [];

  const handleMoverpersonasValidos = async () => {
    let bodyRequest={
        buildingId: codigo_edificio,
        usuario_proceso: usuario.id
    };
    dispatch(mover_personas_cargadas(bodyRequest));    
    //dispatch(fetchDepartments()); // Actualiza la lista después de mover los departamentos válidos
    navigate('/personas');
  };
  

  if (status.get_personas_cargadas_x_edificio  === 'loading') {
    return <div>Loading...</div>;
  }

  if (status.get_personas_cargadas_x_edificio  === 'failed') {
    return <div>Error: {error.get_personas_cargadas_x_edificio}</div>;
  }

  return (    
    <div className="container">
        <h5>personas cargadas</h5>
        <div className="row ">
        <div className="col s12 m3">

        <a className="btn tooltipped" data-position="top" data-tooltip="Refrescar vista" onClick={handleRefresh}>
        <i className="material-icons left">refresh</i>Refrescar
      </a>
        </div>
        <div className="col s12 m3">
        <a className="btn tooltipped" data-position="top" data-tooltip="Registrar" onClick={handleMoverpersonasValidos}>
        Registrar
      </a>
        </div>
        </div>
        <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_personas_cargadas"><i className="material-icons">search</i> Filtrar</label>
            <input type="text" id="filtro_tabla_personas_cargadas" 
             value={filtro}
          onChange={handleFilterChange} />           
        </div>
    <table className="striped responsive-table tabla_personas_cargadas">
      <thead>
        <tr>
          <th>TipDoc</th>
          <th>NumDoc</th>
          <th>Titular</th>
          <th>Email</th>
          <th>Celular</th>
          <th>TipUsua.</th>
          <th>Usuario</th>
          <th>Validacion</th>
          <th>Mensaje</th>
          <th>usuario</th>
          <th>Id. carga</th>
          <th>Fecha_carga</th>
        </tr>
      </thead>
      <tbody id="table-body-personas-cargados">                    
        {personasFiltrados.map((persona) => (
          <tr key={persona.id}>
            <td>{persona.tipo_documento}</td>
            <td>{persona.numero_documento}</td>
            <td>{persona.titular}</td>
            <td>{persona.email}</td>
            <td>{persona.celular}</td>
            <td>{persona.tipo_usuario}</td>
            <td>{persona.usuario}</td>
            <td>{persona.validacion_carga}</td>
            <td>{persona.mensaje_error_carga}</td>
            <td>{persona.usuario_carga}</td>
            <td>{persona.uid_carga}</td>
            <td>{persona.fecha_carga_formato01}</td>
          </tr>
          ))}
      
      </tbody>
    </table>    
    </div>
  );
};

export default Personas_cargados;
