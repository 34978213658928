import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetProcesos_conciliacion } from './proceso_conciliacionSlice';



export const update_proceso_conciliacion = createAsyncThunk('proceso_conciliacion/update_proceso_conciliacion', async ({ idCarga_crep, proceso_conciliacionData }, thunkAPI) => {
  //console.log("ejecutando thunk update_proceso_conciliacion con idCarga_crep: " + idCarga_crep);

  try {

    const response = await apiClient.put(`/procesos_conciliacion/${idCarga_crep}`, proceso_conciliacionData);

    const proceso_conciliacion = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetProcesos_conciliacion(proceso_conciliacion.buildingId));// Resetear el estado para refetch de procesos_conciliacion

    return proceso_conciliacion;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const conciliar_crep_recibos = createAsyncThunk(
  'proceso_conciliacion/conciliar_crep_recibos',
  async ({ usuario, tipo_conciliacion }, thunkAPI) => {
      try {
          const response = await apiClient.post('/procesos_conciliacion/conciliar_crep_recibos', { usuario, tipo_conciliacion });
          return response.data;
      } catch (error) {
          return thunkAPI.rejectWithValue(error.response.data);
      }
  }
);
export const conciliar_crep = createAsyncThunk(
    'proceso_conciliacion/conciliar_crep',
    async ({ usuario, tipo_conciliacion }, thunkAPI) => {
        try {
            const response = await apiClient.post('/procesos_conciliacion/conciliar_crep', { usuario, tipo_conciliacion });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
export const save_proceso_conciliacion = createAsyncThunk('proceso_conciliacion/save_proceso_conciliacion', async (proceso_conciliacionData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/procesos_conciliacion`, proceso_conciliacionData);

    const proceso_conciliacion = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetProcesos_conciliacion(proceso_conciliacion.buildingId));// Resetear el estado para refetch de procesos_conciliacion

    return proceso_conciliacion;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_proceso_conciliacion = createAsyncThunk('proceso_conciliacion/anular_proceso_conciliacion', async ({ idCarga_crep, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_proceso_conciliacion con idCarga_crep: " + idCarga_crep);

  try {

    const response = await apiClient.patch(`/procesos_conciliacion/${idCarga_crep}/anular`, { usuarioAnulacion });

    const proceso_conciliacion = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetProcesos_conciliacion(proceso_conciliacion.buildingId));// Resetear el estado para refetch de procesos_conciliacion

    return proceso_conciliacion;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_proceso_conciliacion = createAsyncThunk('proceso_conciliacion/get_proceso_conciliacion', async (id, thunkAPI) => {
  console.log("ejecutando thunk get_proceso_conciliacion con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/procesos_conciliacion/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const proceso_conciliacion = response.data;

    return proceso_conciliacion;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_procesos_conciliacion = createAsyncThunk('proceso_conciliacion/get_procesos_conciliacion', async (codigo_unidad_facturacion, thunkAPI) => {
  console.log("ejecutando thunk get_procesos_conciliacion con codigo_unidad_facturacion: "+codigo_unidad_facturacion);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/procesos_conciliacion',{
        params: {codigo_unidad_facturacion}
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const procesos_conciliacion = response.data;
          
      return procesos_conciliacion ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const get_procesos_conciliacion_admin = createAsyncThunk('proceso_conciliacion/get_procesos_conciliacion_admin', async ({ tipo_area_comun, fecha_inicio, fecha_fin, estado_pago, estado, codigo_unidad_facturacion }, thunkAPI) => {
  console.log("ejecutando thunk get_procesos_conciliacion_admin con codigo_unidad_facturacion: "+codigo_unidad_facturacion);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/procesos_conciliacion/admin',{
        params: {
          tipo_area_comun,
          fecha_inicio,
          fecha_fin,
          estado_pago,
          estado,
          codigo_unidad_facturacion,
        },
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const procesos_conciliacion = response.data;
          
      return procesos_conciliacion ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
