// frontend/src/redux/slices/facturacion_individualSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { 
    get_facturacion_individual, 
    get_facturaciones_individuales, 
    update_facturacion_individual, 
    save_facturacion_individual, 
    anular_facturacion_individual 
} from './facturacion_individualThunk';

const initialState= {
    facturacion_individual: null,
    facturaciones_individuales: [],
    facturacion_individualSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_facturaciones_individuales: 'idle',
    error_get_facturaciones_individuales: null,
    status_update_facturacion_individual: 'idle',
    error_update_facturacion_individual: null,
    status_save_facturacion_individual: 'idle',
    error_save_facturacion_individual: null,
    status_anular_facturacion_individual: 'idle',
    error_anular_facturacion_individual: null,
}

const facturacion_individualSlice = createSlice({
    name: 'facturacion_individual',
    initialState,
    reducers: {
        setFacturacion_individualSeleccionada: (state, action) => {
            state.facturacion_individualSeleccionada = action.payload;
        },
        clearFacturacion_individualSeleccionada(state) {
            state.facturacion_individualSeleccionada = null;
            state.facturacion_individual = null;
        },
        resetStateGetFacturaciones_individuales: (state) => {
            state.status_get_facturaciones_individuales = 'idle';
            state.error_get_facturaciones_individuales = null;
        },
        resetState: () => initialState, // 🔥 Reiniciar todo el estado al estado inicial
    },
    extraReducers: (builder) => {
        builder
            // Obtener una facturación individual
            .addCase(get_facturacion_individual.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(get_facturacion_individual.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.facturacion_individual = action.payload;
            })
            .addCase(get_facturacion_individual.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Failed to fetch facturacion individual';
            })

            // Obtener todas las facturaciones individuales
            .addCase(get_facturaciones_individuales.pending, (state) => {
                state.status_get_facturaciones_individuales = 'loading';
                state.error_get_facturaciones_individuales = null;
            })
            .addCase(get_facturaciones_individuales.fulfilled, (state, action) => {
                state.status_get_facturaciones_individuales = 'succeeded';
                state.facturaciones_individuales = action.payload;
            })
            .addCase(get_facturaciones_individuales.rejected, (state, action) => {
                state.status_get_facturaciones_individuales = 'failed';
                state.error_get_facturaciones_individuales = action.payload || 'Failed to fetch facturaciones individuales';
            })

            // Actualizar una facturación individual
            .addCase(update_facturacion_individual.pending, (state) => {
                state.status_update_facturacion_individual = 'loading';
            })
            .addCase(update_facturacion_individual.fulfilled, (state, action) => {
                state.status_update_facturacion_individual = 'succeeded';
                state.facturacion_individual = action.payload.facturacion_individual; 
            })
            .addCase(update_facturacion_individual.rejected, (state, action) => {
                state.status_update_facturacion_individual = 'failed';
                state.error_update_facturacion_individual = action.payload || 'Failed to update facturacion individual';
            })

            // Guardar una nueva facturación individual
            .addCase(save_facturacion_individual.pending, (state) => {
                state.status_save_facturacion_individual = 'loading';
            })
            .addCase(save_facturacion_individual.fulfilled, (state, action) => {
                state.status_save_facturacion_individual = 'succeeded';
                state.facturacion_individual = action.payload.facturacion_individual;
                //state.facturaciones_individuales.push(action.payload.facturacion_individual);
            })
            .addCase(save_facturacion_individual.rejected, (state, action) => {
                state.status_save_facturacion_individual = 'failed';
                state.error_save_facturacion_individual = action.payload || 'Failed to save facturacion individual';
            })

            // Anular una facturación individual
            .addCase(anular_facturacion_individual.pending, (state) => {
                state.status_anular_facturacion_individual = 'loading';
            })
            .addCase(anular_facturacion_individual.fulfilled, (state, action) => {
                state.status_anular_facturacion_individual = 'succeeded';
                state.facturacion_individual = action.payload.facturacion_individual;
            })
            .addCase(anular_facturacion_individual.rejected, (state, action) => {
                state.status_anular_facturacion_individual = 'failed';
                state.error_anular_facturacion_individual = action.payload || 'Failed to anular facturacion individual';
            })      
        },
      });

export const { 
    setFacturacion_individualSeleccionada, 
    clearFacturacion_individualSeleccionada, 
    resetStateGetFacturaciones_individuales,
    resetState
} = facturacion_individualSlice.actions;

export const selectFacturaciones_individualesById = (state, idFacturacion_individual) =>
    state.facturacion_individual.facturaciones_individuales.find((facturacion_individual) => facturacion_individual.id === idFacturacion_individual);

export default facturacion_individualSlice.reducer;
