import { createSlice } from '@reduxjs/toolkit';
import { get_area_comun, get_areas_comunes, update_area_comun, save_area_comun, anular_area_comun, get_areas_comunes_sum, get_areas_comunes_parrilla, get_areas_comunes_yoga, get_areas_comunes_gym } from './area_comunThunk';

const initialState = {
  area_comun: null,
  areas_comunes: [],

  areas_comunes_parrilla: [],
  status_get_areas_comunes_parrilla: 'idle',
  error_get_areas_comunes_parrilla: null,

  areas_comunes_sum: [],
  status_get_areas_comunes_sum: 'idle',
  error_get_areas_comunes_sum: null,

  areas_comunes_gym: [],
  status_get_areas_comunes_gym: 'idle',
  error_get_areas_comunes_gym: null,

  areas_comunes_yoga: [],
  status_get_areas_comunes_yoga: 'idle',
  error_get_areas_comunes_yoga: null,

  area_comunSeleccionada: null,
  tipo_area_comunSeleccionada: '',
  status: 'idle',
  error: null,
  status_get_areas_comunes: 'idle',
  error_get_areas_comunes: null,
  status_update_area_comun: 'idle',
  error_update_area_comun: null,
  status_save_area_comun: 'idle',
  error_save_area_comun: null,
  status_anular_area_comun: 'idle',
  error_anular_area_comun: null,
}

const area_comunSlice = createSlice({
  name: 'area_comun',
  initialState,
  reducers: {
    setArea_comunSeleccionada: (state, action) => {
      state.area_comunSeleccionada = action.payload;

    },
    setTipo_area_comunSeleccionada: (state, action) => {
      state.tipo_area_comunSeleccionada = action.payload;

    },
    clearArea_comunSeleccionada(state) {
      state.area_comunSeleccionada = null;
      state.area_comun = null;
    },
    resetStateGetAreas_comunes: () => {
      return {
        status_get_areas_comunes: 'idle',
      };
    },
    setAreas_comunes: (state, action) => {
      state.areas_comunes = action.payload;  // ✅ Se define para permitir limpiar o actualizar áreas comunes
    },
    resetState: () => initialState, // 🔥 Reiniciar todo el estado al estado inicial   
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_area_comun.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_area_comun.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.area_comun = action.payload;

      })
      .addCase(get_area_comun.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })
      .addCase(update_area_comun.pending, (state) => {
        state.status_update_area_comun = 'loading';
      })
      .addCase(update_area_comun.fulfilled, (state, action) => {
        state.status_update_area_comun = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.area_comun = action.payload.area_comun;

      })
      .addCase(update_area_comun.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_area_comun = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_area_comun = action.payload || 'Failed to fetch building';
      })
      .addCase(save_area_comun.pending, (state) => {
        state.status_save_area_comun = 'loading';
      })
      .addCase(save_area_comun.fulfilled, (state, action) => {
        state.status_save_area_comun = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.area_comun = action.payload.area_comun;

      })
      .addCase(save_area_comun.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_area_comun = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_area_comun = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_area_comun.pending, (state) => {
        state.status_save_area_comun = 'loading';
      })
      .addCase(anular_area_comun.fulfilled, (state, action) => {
        state.status_anular_area_comun = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.area_comun = action.payload.area_comun;

      })
      .addCase(anular_area_comun.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_area_comun = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_area_comun = action.payload || 'Failed to fetch building';
      })
      .addCase(get_areas_comunes.pending, (state) => {
        state.status_get_areas_comunes = 'loading';

      })
      .addCase(get_areas_comunes.fulfilled, (state, action) => {
        state.status_get_areas_comunes = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.areas_comunes = action.payload;

      })
      .addCase(get_areas_comunes.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_areas_comunes = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_areas_comunes = action.payload || 'Failed to fetch building';
      })

      .addCase(get_areas_comunes_sum.pending, (state) => {
        state.status_get_areas_comunes_sum = 'loading';

      })
      .addCase(get_areas_comunes_sum.fulfilled, (state, action) => {
        state.status_get_areas_comunes_sum = 'succeeded';
        state.areas_comunes_sum = action.payload;

      })
      .addCase(get_areas_comunes_sum.rejected, (state, action) => {
        state.status_get_areas_comunes_sum = 'failed';
        state.error_get_areas_comunes_sum = action.payload || 'Failed to fetch building';
      })

      .addCase(get_areas_comunes_parrilla.pending, (state) => {
        state.status_get_areas_comunes_parrilla = 'loading';

      })
      .addCase(get_areas_comunes_parrilla.fulfilled, (state, action) => {
        state.status_get_areas_comunes_parrilla = 'succeeded';
        state.areas_comunes_parrilla = action.payload;

      })
      .addCase(get_areas_comunes_parrilla.rejected, (state, action) => {
        state.status_get_areas_comunes_parrilla = 'failed';
        state.error_get_areas_comunes_parrilla = action.payload || 'Failed to fetch building';
      })

      .addCase(get_areas_comunes_gym.pending, (state) => {
        state.status_get_areas_comunes_gym = 'loading';

      })
      .addCase(get_areas_comunes_gym.fulfilled, (state, action) => {
        state.status_get_areas_comunes_gym = 'succeeded';
        state.areas_comunes_gym = action.payload;

      })
      .addCase(get_areas_comunes_gym.rejected, (state, action) => {
        state.status_get_areas_comunes_gym = 'failed';
        state.error_get_areas_comunes_gym = action.payload || 'Failed to fetch building';
      })

      .addCase(get_areas_comunes_yoga.pending, (state) => {
        state.status_get_areas_comunes_yoga = 'loading';

      })
      .addCase(get_areas_comunes_yoga.fulfilled, (state, action) => {
        state.status_get_areas_comunes_yoga = 'succeeded';
        state.areas_comunes_yoga = action.payload;

      })
      .addCase(get_areas_comunes_yoga.rejected, (state, action) => {
        state.status_get_areas_comunes_yoga = 'failed';
        state.error_get_areas_comunes_yoga = action.payload || 'Failed to fetch building';
      })

  },
});

export const { setArea_comunSeleccionada, clearArea_comunSeleccionada } = area_comunSlice.actions;
export const selectAreas_comunesById = (state, idArea_comun) =>
  state.area_comun.areas_comunes.find((area_comun) => area_comun.id === idArea_comun);
export const { resetStateGetAreas_comunes, resetState, setTipo_area_comunSeleccionada, setAreas_comunes } = area_comunSlice.actions;
export default area_comunSlice.reducer;
