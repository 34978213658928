import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./MiCuentaPage.css"; // Archivo de estilos
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const MiCuentaPage = () => {
    const navigate = useNavigate();
    useMaterializeTooltips();  
    // Obtener datos directamente desde Redux
    //const personaRedux = useSelector((state) => state.auth.persona_seleccionada);
    const personaRedux = useSelector((state) => state.persona.personaSeleccionada);
    //const edificio = useSelector((state) => state.auth.edificio_seleccionado);
    const edificio = useSelector((state) => state.edificio.edificioSeleccionado);
    //const unidadFacturacionRedux = useSelector((state) => state.auth.unidad_facturacion_seleccionada);
    const unidadFacturacionRedux = useSelector((state) => state.unidad_facturacion.unidad_facturacionSeleccionado);
    const { esSuperUsuario, esAdministrador } = useSelector((state) => state.auth);
console.log(" VEMOS esSuperUsuario "+esSuperUsuario + " esAdministrador "+esAdministrador)
    return (
        <div className="user-center-container">
            {/* Contenedor para título y botón de regreso */}
            <div className="header-container">
                <h4>Mi Cuenta</h4>
                <a
                    className="btn blue tooltipped btn-back"
                    data-position="top"
                    data-tooltip="Volver"
                    onClick={() => navigate('/dashboard')}
                >
                    <i className="material-icons left">arrow_back</i>
                </a>
            </div>

            {/* Información Personal */}
            <section className="user-info">
                <h3>Información Personal</h3>
                <p><strong>Titular:</strong> {personaRedux?.titular || "No disponible"}</p>
                <p><strong>Usuario:</strong> {personaRedux?.usuario || "No disponible"}</p>

                <div className="row">
                    <div><strong>Email:</strong> {personaRedux?.email || "No disponible"}</div>
                    <div><strong>Email 2:</strong> {personaRedux?.email_2 || "No registrado"}</div>
                </div>

                <div className="row">
                    <div><strong>Celular:</strong> {personaRedux?.nro_celular || "No disponible"}</div>
                    <div><strong>Celular 2:</strong> {personaRedux?.nro_celular_2 || "No registrado"}</div>
                </div>

                <div className="row">
                    <div><strong>Documento:</strong> {personaRedux?.tipo_documento || "N/A"}</div>
                    <div><strong>Número:</strong> {personaRedux?.numero_documento || "N/A"}</div>
                </div>
            </section>

            {/* Propiedades (Ocultas si es SUPER o ADMIN) */}
            {!esSuperUsuario && !esAdministrador && (
                <section className="properties">
                    <h3>Propiedades</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>Tipo</th>
                                <th>Número</th>
                                <th>Área Ocupada</th>
                            </tr>
                        </thead>
                        <tbody>
                            {unidadFacturacionRedux?.propiedades?.length > 0 ? (
                                unidadFacturacionRedux.propiedades.map((prop, index) => (
                                    <tr key={index}>
                                        <td>{prop.tipo_unidad_inmobiliaria || "N/A"}</td>
                                        <td>{prop.numero_unidad_inmobiliaria || "N/A"}</td>
                                        <td>{prop.area_ocupada ? `${prop.area_ocupada} m²` : "N/A"}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" style={{ textAlign: "center" }}>No hay propiedades registradas.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </section>
            )}
            {/* Unidad de Facturación (Oculta si es SUPER o ADMIN) */}
            {!esSuperUsuario && !esAdministrador && (
                <section className="unit-info">
                    <h3>Unidad de Facturación</h3>
                    <div className="row">
                        <div><strong>Código:</strong> {unidadFacturacionRedux?.codigo_unidad_facturacion || "No disponible"}</div>
                        <div><strong>Área Total:</strong> {unidadFacturacionRedux?.area_ocupada_total ? `${unidadFacturacionRedux.area_ocupada_total} m²` : "No disponible"}</div>
                    </div>
                    <div className="row">
                        <div><strong>% Participación:</strong> {unidadFacturacionRedux?.porcentaje_participacion ? `${unidadFacturacionRedux.porcentaje_participacion}%` : "No disponible"}</div>
                        <div><strong>% Participación 8 dec.:</strong> {unidadFacturacionRedux?.porcentaje_participacion_8dec || "No disponible"}</div>
                    </div>
                </section>
            )}
            {/* Edificio */}
            <section className="building-info">
                <h3>Edificio</h3>
                <p><strong>Nombre:</strong> {edificio?.nombre || "No disponible"}</p>
                <p><strong>Perfil:</strong> {edificio?.perfil || "No disponible"}</p>
                <p><strong>Roles:</strong> {edificio?.roles?.length > 0 ? edificio.roles.join(", ") : "No asignados"}</p>
                {/* Botón para cambiar contraseña */}
                <div className="row change-password-container">
                    <button className="btn blue" onClick={() => navigate('/change-password')}>
                        Cambiar Contraseña
                    </button>
                </div>
            </section>
        </div>
    );
};

export default MiCuentaPage;
