// src/components/MainLayout.js
import Menu from './menuPage';
import Footer from './footerPage';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js';

const MainLayout = ({ children, showFooter }) => {

  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
/*
  useEffect(() => {
    if (!auth?.isAuthenticated) {
      M.toast({ html: "Tu sesión ha expirado. Por favor inicia sesión nuevamente.", classes: "orange darken-2" });
      console.log("🔐 Sesión expirada, redirigiendo a logout...");
      navigate('/logout');
    }
  }, [auth?.isAuthenticated]);
*/

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Menu />
      <main style={{ flex: 1 }}>{children}</main>
      {showFooter && <Footer />}
    </div>
  );
};

export default MainLayout;
