import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Cleave from 'cleave.js/react';
import { get_suministros_x_tipo } from '../edificio/suministrosThunk';
import { get_lecturas_agua } from './lectura_aguaThunk'; // Thunk que trae las lecturas
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import { ESTADOS_LECTURA_AGUA, TIPOS_SUMINISTRO, MONEDAS } from '../../constants/constants';
import {
  anular_facturacion_agua,
  save_facturacion_agua,
  update_facturacion_agua,
  get_facturacion_agua
} from './facturacion_aguaThunk'; // Asegúrate de la ruta correcta
import './facturacion_aguaFormPage.css'; // Tu CSS personalizado, si lo tienes
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { parseFromYYYYMM, formatPeriodo } from '../../utils/util';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

registerLocale('es', es);

const Facturacion_aguaForm = ({ mode }) => {
  const { id: idFacturacion_agua } = useParams();
  const facturacion_agua = useSelector(state => state.facturacion_agua.facturacion_agua);
  const [isEditing, setIsEditing] = useState(mode !== 'view');
  useMaterializeTooltips();
  const [isLoadingLecturas, setIsLoadingLecturas] = useState(false);

  const usuario = useSelector((state) => state.auth.usuario);
  const codigo_usuario = usuario ? usuario.id : null;

  const edificioSeleccionado = useSelector((state) => state.edificio.edificio);
  const buildingId = edificioSeleccionado.id;
  const codigo_edificio = edificioSeleccionado.codigo;

  const { status_save_facturacion_agua, error_save_facturacion_agua, status_update_facturacion_agua, error_update_facturacion_agua } = useSelector((state) => state.facturacion_agua);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Obtener el periodo actual en formato YYYY-MM
  const periodoActual = formatPeriodo(new Date());

  const [formData, setFormData] = useState({
    nro_suministro: '',
    periodo: periodoActual,
    importe_recibo_suministro: 0,
    importe_descuento_agua: 0,
    importe_incremento_agua: 0,
    importe_total_facturar: 0,
    fecha_vencimiento_recibo: new Date(),
    consumo_unidades_facturacion: 0,
    factor_agua: 0,
    estado: ESTADOS_LECTURA_AGUA[0].label,
    moneda: { codigo: 'PEN', simbolo: 'S/' },
    lecturas: [],
  });
  useEffect(() => {
    var elems = document.querySelectorAll('select');
    M.FormSelect.init(elems);
    M.updateTextFields();
  }, []);
  const suministros = useSelector(state => state.suministros.suministros);

  // --- NUEVO: Estado para el filtro de lecturas ---
  const [lecturasFilter, setLecturasFilter] = useState('');

  // Obtener suministros y facturación al montar
  useEffect(() => {
    dispatch(get_suministros_x_tipo({ buildingId, tipo_suministro: TIPOS_SUMINISTRO[0].value }));
    if (mode !== 'create') {
      dispatch(get_facturacion_agua(idFacturacion_agua));
    }
  }, [dispatch, idFacturacion_agua, mode, buildingId]);

  // Actualizar importe_total_facturar cuando cambien los importes
  useEffect(() => {
    if (mode === 'view' || mode === 'edit') {
      const { importe_recibo_suministro, importe_descuento_agua, importe_incremento_agua, lecturas } = formData;

      //const total = importe_recibo_suministro - importe_descuento_agua + importe_incremento_agua;
      const total = parseFloat((importe_recibo_suministro - importe_descuento_agua + importe_incremento_agua).toFixed(2));

      // Calcular el consumo total sumando todas las lecturas
      //const consumoTotal = lecturas.reduce((sum, lectura) => sum + lectura.total_consumo_mes, 0);
      const consumoTotal = parseFloat(lecturas.reduce((sum, lectura) => sum + lectura.total_consumo_mes, 0).toFixed(2));

      // Calcular el factor_agua
      const factorAguaCalculado = consumoTotal > 0 ? parseFloat((total / consumoTotal).toFixed(2)) : 0;
      console.log(" OJO factorAguaCalculado: " + factorAguaCalculado)
      setFormData(prev => ({
        ...prev,
        importe_total_facturar: total,
        consumo_unidades_facturacion: consumoTotal, // Guardamos el consumo total
        factor_agua: factorAguaCalculado // Guardamos el factor_agua calculado
      }));

      setIsEditing(mode === 'edit');
    }
  }, [
    formData.importe_recibo_suministro,
    formData.importe_descuento_agua,
    formData.importe_incremento_agua,
    formData.lecturas, // Agregado para que se recalculen los valores si cambian las lecturas
    mode
  ]);
  // Actualizar factor_agua cuando importe_total_facturar o consumo_unidades_facturacion cambien
  /*
  useEffect(() => {
    const { importe_total_facturar, consumo_unidades_facturacion } = formData;
    const factor = consumo_unidades_facturacion > 0 ? importe_total_facturar / consumo_unidades_facturacion : 0;
    setFormData(prev => ({
      ...prev,
      factor_agua: parseFloat(factor.toFixed(2))
    }));
  }, [formData.importe_total_facturar, formData.consumo_unidades_facturacion]);
*/
  useEffect(() => {
    if (suministros) {
      if (suministros.length > 0 && !formData.nro_suministro) {
        setFormData(prev => ({
          ...prev,
          nro_suministro: suministros[0].numero
        }));
      }
      const elems = document.querySelectorAll('select');
      M.FormSelect.init(elems);
      M.updateTextFields();
    }
  }, [suministros]);

  const handleRetornar = () => {
    navigate('/facturaciones-agua');
  };

  const handleCancel = () => {
    navigate('/facturaciones-agua');
  };
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handlePeriodoChange = (date) => {
    const periodoFormateado = formatPeriodo(date);
    console.log("periodoFormateado " + periodoFormateado);
    setFormData(prev => ({ ...prev, periodo: periodoFormateado }));
  };

  const handleLoadLecturas = async () => {
    const { nro_suministro, periodo, importe_recibo_suministro, importe_descuento_agua, importe_incremento_agua } = formData;
    console.log("nro_suministro: " + nro_suministro + " periodo: " + periodo);

    if (!nro_suministro || !periodo) {
      M.toast({ html: 'Seleccione un medidor y un periodo.', classes: 'red' });
      return;
    }

    setIsLoadingLecturas(true); // Iniciar estado de carga

    const [year, month] = periodo.split('-').map(Number);
    const nuevoMes = month - 1 === 0 ? 12 : month - 1;
    const nuevoAnio = month - 1 === 0 ? year - 1 : year;
    const periodoAnteriorFormateado = `${nuevoAnio}-${nuevoMes.toString().padStart(2, '0')}`;

    try {
      //const lecturasActualResponse = await dispatch(get_lectura_agua_by_periodo({ periodo, buildingId }));
      const lecturasActualResponse = await dispatch(get_lecturas_agua({ buildingId, nro_suministro, periodo }));
      //const lecturasAnteriorResponse = await dispatch(get_lectura_agua_by_periodo({buildingId, nro_suministro, periodo: periodoAnteriorFormateado }));
      const lecturasAnteriorResponse = await dispatch(get_lecturas_agua({ buildingId, nro_suministro, periodo: periodoAnteriorFormateado }));

      //console.log("lecturasActualResponse "+ JSON.stringify(lecturasActualResponse))
      const lecturasActual = lecturasActualResponse.payload?.[0]?.lecturas || [];
      const lecturasAnterior = lecturasAnteriorResponse.payload?.[0]?.lecturas || [];

      //console.log("lecturasActual sss "+ JSON.stringify(lecturasActual))

      console.log("lecturasActual.length: " + lecturasActual.length);
      console.log("lecturasAnterior.length: " + lecturasAnterior.length);

      if (lecturasActual.length === 0) {
        //setCargaMensaje('No se encontraron lecturas para el periodo seleccionado.');
        M.toast({ html: 'No hay lecturas disponibles para este periodo.', classes: 'yellow' });
        setIsLoadingLecturas(false);
        return;
      }

      // Obtener las lecturas combinadas
      const lecturasCombinadas = lecturasActual.map((lecturaActual) => {
        const lecturaAnterior = lecturasAnterior.find(
          (la) => la.codigo_unidad_facturacion === lecturaActual.codigo_unidad_facturacion
        ) || {};

        const total_consumo_mes = parseFloat((lecturaActual.lectura - (lecturaAnterior.lectura || 0)).toFixed(2));
        console.log("total_consumo_mes: " + total_consumo_mes);

        return {
          codigo_unidad_facturacion: lecturaActual.codigo_unidad_facturacion,
          lectura_anterior: lecturaAnterior.lectura || 0,
          foto_anterior: lecturaAnterior.foto || '',
          lectura_actual: lecturaActual.lectura || 0,
          foto_actual: lecturaActual.foto || '',
          total_consumo_mes,
          cuota_agua: 0 // Se calculará después
        };
      });

      // Calcular consumo total
      //const consumoTotal = lecturasCombinadas.reduce((sum, item) => sum + item.total_consumo_mes, 0);
      const consumoTotal = parseFloat(lecturasCombinadas.reduce((sum, item) => sum + item.total_consumo_mes, 0).toFixed(2));

      // Calcular nuevo factor_agua
      //const totalFacturar = importe_recibo_suministro - importe_descuento_agua + importe_incremento_agua;
      const totalFacturar = parseFloat((importe_recibo_suministro - importe_descuento_agua + importe_incremento_agua).toFixed(2));
      const factorAguaCalculado = consumoTotal > 0 ? parseFloat((totalFacturar / consumoTotal).toFixed(2)) : 0;

      // Asignar cuota_agua a cada lectura
      const lecturasConCuota = lecturasCombinadas.map(lectura => ({
        ...lectura,
        cuota_agua: parseFloat((lectura.total_consumo_mes * factorAguaCalculado).toFixed(2))
      }));

      // Actualizar el estado con los valores correctos
      setFormData(prev => ({
        ...prev,
        lecturas: lecturasConCuota,
        consumo_unidades_facturacion: consumoTotal,
        factor_agua: factorAguaCalculado
      }));

    } catch (error) {
      console.error("Error al cargar las lecturas:", error);
      M.toast({ html: 'Error al cargar las lecturas.', classes: 'red' });
    } finally {
      setIsLoadingLecturas(false); // Finalizar estado de carga
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));
    setFormData(prev => {
      const newFormData = {
        ...prev,
        [name]: isNaN(numericValue) ? 0 : numericValue,
      };

      console.log(" VIENDO ");
      const totalFacturar = newFormData.importe_recibo_suministro - newFormData.importe_descuento_agua + newFormData.importe_incremento_agua;
      console.log(" totalFacturar: " + totalFacturar);
      const consumoTotal = newFormData.lecturas.reduce((sum, lectura) => sum + lectura.total_consumo_mes, 0);
      console.log(" consumoTotal: " + consumoTotal);
      const factorAgua = consumoTotal > 0 ? totalFacturar / consumoTotal : 0;
      console.log(" factorAgua: " + factorAgua);
      const lecturasActualizadas = newFormData.lecturas.map(lectura => ({
        ...lectura,
        cuota_agua: parseFloat((lectura.total_consumo_mes * factorAgua).toFixed(2)),
      }));
      const totalCuotaAgua = lecturasActualizadas.reduce((sum, lectura) => sum + lectura.cuota_agua, 0);
      console.log(" totalCuotaAgua: " + totalCuotaAgua);
      return {
        ...newFormData,
        importe_total_facturar: totalFacturar,
        consumo_unidades_facturacion: parseFloat(consumoTotal.toFixed(2)),
        factor_agua: parseFloat(factorAgua.toFixed(2)),
        total_cuota_agua: parseFloat(totalCuotaAgua.toFixed(2)),
        lecturas: lecturasActualizadas,
      };
    });
  };

  const handleSave = async () => {
    const updatedData = { ...formData, fecha_vencimiento_recibo: formatDate(formData.fecha_vencimiento_recibo), };
    if (isEditing) {
      if (mode === 'create') {
        const data = { ...updatedData, buildingId, usuarioRegistrador: codigo_usuario };
        const resultAction = dispatch(save_facturacion_agua(data));
        if (save_facturacion_agua.fulfilled.match(resultAction)) {
          M.toast({ html: 'Facturación de agua guardada exitosamente.', classes: 'green' });
        } else if (save_facturacion_agua.rejected.match(resultAction)) {
          const errorMessage = error_save_facturacion_agua || '-Ocurrió un error al guardar la facturación de agua.';
          M.toast({ html: errorMessage, classes: 'red' });
          return;
        }
      } else {
        const data = { ...updatedData, usuarioActualizacion: codigo_usuario };
        const resultAction = dispatch(update_facturacion_agua({ idFacturacion_agua, facturacion_aguaData: data }));
        if (update_facturacion_agua.fulfilled.match(resultAction)) {
          M.toast({ html: 'Facturación de agua actualizada exitosamente.', classes: 'green' });
        } else if (update_facturacion_agua.rejected.match(resultAction)) {
          const errorMessage = error_update_facturacion_agua || '-Ocurrió un error al actualizar la facturación de agua.';
          M.toast({ html: errorMessage, classes: 'red' });
          return;
        }
      }
    }
    navigate('/facturaciones-agua');
  };

   // Función para formatear fechas locales
   const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2); // getMonth() es 0-based
    const day = (`0${date.getDate()}`).slice(-2);
    return `${year}-${month}-${day}`;
};

  /**
 * Parsea una cadena de fecha 'YYYY-MM-DD' como fecha local.
 * @param {string} dateString - La cadena de fecha en formato 'YYYY-MM-DD'.
 * @returns {Date} - Objeto Date basado en la hora local.
 */
  const parseLocalDate = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // Meses en JavaScript son 0-based
  };

  useEffect(() => {
    if (facturacion_agua && (mode === 'view' || mode === 'edit')) {
      setFormData(prev => ({
        ...prev,
        consumo_unidades_facturacion: facturacion_agua.consumo_unidades_facturacion,
        factor_agua: facturacion_agua.factor_agua,
        importe_descuento_agua: facturacion_agua.importe_descuento_agua,
        importe_incremento_agua: facturacion_agua.importe_incremento_agua,
        importe_recibo_suministro: facturacion_agua.importe_recibo_suministro,
        importe_total_facturar: facturacion_agua.importe_total_facturar,
        nro_suministro: facturacion_agua.nro_suministro,
        periodo: facturacion_agua.periodo,
        total_cuota_agua: facturacion_agua.total_cuota_agua,
        nota: facturacion_agua.nota,
        //fecha_vencimiento_recibo: new Date(facturacion_agua.fecha_vencimiento_recibo),
        fecha_vencimiento_recibo: new Date(facturacion_agua.fecha_vencimiento_recibo),
        estado: facturacion_agua.estado,
        moneda: facturacion_agua.moneda,
        lecturas: facturacion_agua.lecturas.map(lectura_unidad_facturacion => ({
          codigo_unidad_facturacion: lectura_unidad_facturacion.codigo_unidad_facturacion,
          cuota_agua: lectura_unidad_facturacion?.cuota_agua || 0,
          foto_actual: lectura_unidad_facturacion?.foto_actual || null,
          foto_anterior: lectura_unidad_facturacion?.foto_anterior || null,
          lectura_actual: lectura_unidad_facturacion?.lectura_actual || 0,
          lectura_anterior: lectura_unidad_facturacion?.lectura_anterior || 0,
          total_consumo_mes: lectura_unidad_facturacion?.total_consumo_mes || 0,
        }))
      }));
      setIsEditing(mode === 'edit');
    }
  }, [facturacion_agua, mode]);

  const handleMonedaChange = (e) => {
    const selectedMoneda = MONEDAS.find(moneda => moneda.value === e.target.value);
    setFormData({
      ...formData,
      moneda: {
        codigo: selectedMoneda.value,
        simbolo: selectedMoneda.label
      }
    });
  };

  return (
    <div className="container facturacion-agua-form">
      <div>
        <div className="card">
          <div className="card-content">
            <div className="card-action">
              {isEditing ? (
                <>
                  <a className="btn green tooltipped" data-position="top" data-tooltip="Guardar cambios" onClick={handleSave}>
                    <i className="material-icons left">save</i>Guardar
                  </a>
                  <a className="btn red tooltipped" data-position="top" data-tooltip="Cancelar edición" onClick={handleCancel}>
                    <i className="material-icons left">cancel</i>Cancelar
                  </a>
                </>
              ) : (
                <a className="btn green tooltipped" data-position="top" data-tooltip="Editar detalles" onClick={handleEdit}>
                  <i className="material-icons left">edit</i>Editar
                </a>
              )}

              <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={handleRetornar}>
                <i className="material-icons left">arrow_back</i>
              </a>
            </div>

            <span className="card-title">{mode === 'create' ? 'Crear Facturación de Agua' : mode === 'edit' ? 'Editar Facturación de Agua' : 'Detalles de la Facturación de Agua'}</span>
            <form onSubmit={e => e.preventDefault()}>

              <div className="row">
                <div className="input-field col s12 m3 l4">
                  <select
                    name="nro_suministro"
                    value={formData.nro_suministro || ''}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  >
                    {suministros.map(s => (
                      <option key={s.id} value={s.numero}>{s.nombre} - {s.numero}</option>
                    ))}
                  </select>
                  <label>N° Suministro</label>
                </div>
                <div className="input-field col s6 m2">
                  <DatePicker
                    selected={parseFromYYYYMM(formData.periodo)}
                    onChange={handlePeriodoChange}
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                    name="periodo"
                    disabled={!isEditing}
                    locale="es"
                  />
                  <label className="active" htmlFor="periodo">Periodo:</label>
                </div>

                <div className="input-field col s6 m1">
                  <select name="moneda" value={formData.moneda.codigo} onChange={handleMonedaChange} disabled={!isEditing}>
                    {MONEDAS.map(moneda => (
                      <option key={moneda.value} value={moneda.value}>{moneda.label}</option>
                    ))}
                  </select>
                  <label htmlFor="moneda">Monedas:</label>
                </div>
                <div className="input-field col s6 m2">
                  <input
                    type="text"
                    name="estado"
                    value={formData.estado || ''}
                    disabled={true}
                  />
                  <label htmlFor="estado" className="active">Estado</label>
                </div>
              </div>
              <div className="row">
                <div className="input-field col s3 m3">
                  <Cleave
                    placeholder="0.00"
                    name="importe_recibo_suministro"
                    value={formData.importe_recibo_suministro}
                    options={{ numeral: true, numeralDecimalMark: '.', numeralThousandsGroupStyle: 'thousand' }}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                  <label className="active" htmlFor="importe_recibo_suministro">Importe Recibo Suministro</label>
                </div>

                <div className="input-field col s3  m3">
                  <Cleave
                    placeholder="0.00"
                    name="importe_descuento_agua"
                    value={formData.importe_descuento_agua}
                    options={{ numeral: true, numeralDecimalMark: '.', numeralThousandsGroupStyle: 'thousand' }}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                  <label className="active" htmlFor="importe_descuento_agua">Importe Descuento Agua</label>
                </div>
                <div className="input-field col s3  m3">
                  <Cleave
                    placeholder="0.00"
                    name="importe_incremento_agua"
                    value={formData.importe_incremento_agua}
                    options={{ numeral: true, numeralDecimalMark: '.', numeralThousandsGroupStyle: 'thousand' }}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                  <label className="active" htmlFor="importe_incremento_agua">Importe Incremento Agua</label>
                </div>
                <div className="input-field col s3  m3">
                  <Cleave
                    placeholder="0.00"
                    name="importe_total_facturar"
                    value={formData.importe_total_facturar}
                    options={{ numeral: true, numeralDecimalMark: '.', numeralThousandsGroupStyle: 'thousand' }}
                    disabled
                  />
                  <label className="active" htmlFor="importe_total_facturar">Importe Total a Facturar</label>
                </div>
              </div>

              <div className="row">

                <div className="input-field col s3  m3">
                  <DatePicker
                    selected={formData.fecha_vencimiento_recibo}
                    onChange={date => setFormData(prev => ({ ...prev, fecha_vencimiento_recibo: date }))}
                    dateFormat="dd/MM/yyyy"
                    name="fecha_vencimiento_recibo"
                    locale="es"
                    disabled={!isEditing}
                  />
                  <label className="active" htmlFor="fecha_vencimiento_recibo">Fecha de Vencimiento</label>
                </div>
                <div className="input-field col s3  m3">
                  <Cleave
                    placeholder="0.00"
                    name="consumo_unidades_facturacion"
                    value={formData.consumo_unidades_facturacion}
                    options={{ numeral: true, numeralDecimalMark: '.', numeralThousandsGroupStyle: 'thousand' }}
                    disabled
                  />
                  <label className="active" htmlFor="consumo_unidades_facturacion">Total consumo(m³)</label>
                </div>
                <div className="input-field col s4  m3">
                  <Cleave
                    placeholder="0.00"
                    name="factor_agua"
                    value={formData.factor_agua}
                    options={{ numeral: true, numeralDecimalMark: '.', numeralThousandsGroupStyle: 'thousand' }}
                    disabled
                  />
                  <label className="active" htmlFor="factor_agua">Factor Agua</label>
                </div>
                <div className="input-field col s4  m3">
                  <Cleave
                    placeholder="0.00"
                    name="total_cuota_agua"
                    value={formData.total_cuota_agua}
                    options={{ numeral: true, numeralDecimalMark: '.', numeralThousandsGroupStyle: 'thousand' }}
                    disabled
                  />
                  <label className="active" htmlFor="total_cuota_agua">Total Cuota Agua</label>
                </div>
              </div>

              <button className="btn" onClick={handleLoadLecturas} disabled={!isEditing}>
                {isLoadingLecturas ? (
                  <span>
                    <i className="material-icons left">autorenew</i>Cargando...
                  </span>
                ) : (
                  <span>
                    <i className="material-icons left">refresh</i>Cargar Lecturas
                  </span>
                )}
              </button>
              {isLoadingLecturas && (
                <div className="row center-align">
                  <div className="preloader-wrapper small active">
                    <div className="spinner-layer spinner-blue">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>
                      <div className="gap-patch">
                        <div className="circle"></div>
                      </div>
                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                  <p>Cargando lecturas, por favor espere...</p>
                </div>
              )}
              <h3>Lecturas de Agua</h3>

              {/* Filtro de búsqueda para la tabla de lecturas */}
              <div className="input-field" style={{ maxWidth: '400px', margin: '0 auto' }}>
                <label htmlFor="lecturasFilter">
                  <i className="material-icons">search</i> Filtrar Lecturas
                </label>
                <input
                  type="text"
                  id="lecturasFilter"
                  value={lecturasFilter}
                  onChange={(e) => setLecturasFilter(e.target.value)}
                />
              </div>

              {/* Contenedor con scroll horizontal y vertical para la tabla de lecturas */}
              <div
                style={{
                  width: '100%',
                  maxHeight: '400px',
                  overflowX: 'auto',
                  overflowY: 'auto',
                  margin: '0 auto',
                }}
              >
                <table>
                  <thead>
                    <tr>
                      <th>Unidad Facturación</th>
                      <th>Lectura Anterior</th>
                      <th>Foto Anterior</th>
                      <th>Lectura Actual</th>
                      <th>Foto Actual</th>
                      <th>Consumo Mes (m³)</th>
                      <th>Cuota Agua</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.lecturas
                      .filter(lectura => {
                        const codigo = lectura.codigo_unidad_facturacion ? lectura.codigo_unidad_facturacion.toLowerCase() : '';
                        const lecturaAnt = lectura.lectura_anterior !== undefined ? lectura.lectura_anterior.toString().toLowerCase() : '';
                        const lecturaAct = lectura.lectura_actual !== undefined ? lectura.lectura_actual.toString().toLowerCase() : '';
                        const consumo = lectura.total_consumo_mes !== undefined ? lectura.total_consumo_mes.toString().toLowerCase() : '';
                        const cuota = lectura.cuota_agua !== undefined ? lectura.cuota_agua.toString().toLowerCase() : '';
                        const filterText = lecturasFilter.toLowerCase();
                        return (
                          codigo.includes(filterText) ||
                          lecturaAnt.includes(filterText) ||
                          lecturaAct.includes(filterText) ||
                          consumo.includes(filterText) ||
                          cuota.includes(filterText)
                        );
                      })
                      .map((lectura, index) => (
                        <tr key={index}>
                          <td>{lectura.codigo_unidad_facturacion}</td>
                          <td>{lectura.lectura_anterior}</td>
                          <td>
                            {lectura.foto_anterior ? (
                              <img src={lectura.foto_anterior} alt="Foto Anterior" width="50" />
                            ) : null}
                          </td>
                          <td>{lectura.lectura_actual}</td>
                          <td>
                            {lectura.foto_actual ? (
                              <img src={lectura.foto_actual} alt="Foto Actual" width="50" />
                            ) : null}
                          </td>
                          <td>{lectura.total_consumo_mes.toFixed(2)}</td>
                          <td>{lectura.cuota_agua.toFixed(2)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facturacion_aguaForm;
