import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_unidades_facturacion_x_edificio } from './unidad_facturacionThunk';
import M from 'materialize-css';
import { formatDate02 } from '../../utils/formateo';
import ExcelJS from 'exceljs';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const Unidades_facturacion_edificio = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useMaterializeTooltips();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const edificio = useSelector((state) => state.edificio.edificio);
  const codigo_edificio = edificio?.id;
  const codigo_edificio_corto = edificio?.codigo;
  const usuario = useSelector((state) => state.auth.usuario);

  //const unidades_facturacion = useSelector((state) => state.unidad_facturacion.unidades_facturacion);
  //const fetchStatus = useSelector((state) => state.unidad_facturacion.fetchStatus);
  //const error = useSelector((state) => state.unidad_facturacion.error);
  const { unidades_facturacion = [], fetchStatus, error } = useSelector(
    (state) => state.unidad_facturacion
  );
  const { esSuperUsuario, esAdministrador } = useSelector((state) => state.auth);
  const [filtro, setFiltro] = useState('');

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
    M.AutoInit();

    if (fetchStatus === 'idle' && codigo_edificio) {
      console.log("📌 Dispatching get_unidades_facturacion_x_edificio con codigo_edificio:", codigo_edificio);
      dispatch(get_unidades_facturacion_x_edificio(codigo_edificio));
    } else {
      console.warn("⚠️ No se ejecutó el dispatch porque codigo_edificio es:", codigo_edificio);
    }

  }, [isAuthenticated, navigate, fetchStatus, codigo_edificio, dispatch]);

  const handleRefresh = () => {
    if (codigo_edificio) {
      dispatch(get_unidades_facturacion_x_edificio(codigo_edificio));
    }
  };

  // Formateo de la data
  const formatearUnidades_facturacion = (unidades_facturacion) => {
    if (!Array.isArray(unidades_facturacion)) {
      return [];
    }

    return unidades_facturacion.map((unidad) => ({
      ...unidad,
      fecha_registro_formato01: formatDate02(unidad.timestamp_registro),
      // Si necesitas formatear el timestamp_carga, puedes hacerlo acá también
      timestamp_carga_formateado: formatDate02(unidad.timestamp_carga),
    }));
  };

  const unidades_facturacionFormateados = formatearUnidades_facturacion(unidades_facturacion);

  // Filtro
  const handleFilterChange = (e) => {
    setFiltro(e.target.value);
  };

  const unidades_facturacionFiltrados = Array.isArray(unidades_facturacionFormateados)
    ? unidades_facturacionFormateados.filter((unidad) => {
      const filterLowerCase = filtro.toLowerCase();
      return (
        unidad.codigo_unidad_facturacion.toLowerCase().includes(filterLowerCase) ||
        unidad.resumen_propietarios.toLowerCase().includes(filterLowerCase) ||
        unidad.resumen_propiedades.toLowerCase().includes(filterLowerCase) ||
        unidad.area_ocupada_total.toString().toLowerCase().includes(filterLowerCase) ||
        unidad.porcentaje_participacion.toString().toLowerCase().includes(filterLowerCase) ||
        unidad.validacion_carga.toLowerCase().includes(filterLowerCase) ||
        unidad.mensaje_error_carga.toLowerCase().includes(filterLowerCase)
      );
    })
    : [];

  // Manejo de estados de carga
  /*
  if (fetchStatus === 'loading') {
    return <div>Loading...</div>;
  }

  if (fetchStatus === 'failed') {
    return <div>Error: {error}</div>;
  }
*/
  // Cálculos de totales
  const totalAreaOcupada = unidades_facturacionFiltrados.reduce(
    (sum, unidad) => sum + parseFloat(unidad.area_ocupada_total || 0), 0
  );
  const totalPorcentajeParticipacion = unidades_facturacionFiltrados.reduce(
    (sum, unidad) => sum + parseFloat(unidad.porcentaje_participacion || 0), 0
  );
  const totalUnidadesFacturacion = unidades_facturacionFiltrados.length;

  // Función para formatear números con separador de miles y decimales
  const formatNumber = (number) => {
    return Number(number).toLocaleString('es-PE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // Exportar a Excel
  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Unidades Facturación');

    // Definir columnas
    worksheet.columns = [
      { header: 'Código Edificio', key: 'codigo_edificio', width: 20 },
      { header: 'Código Unidad Facturación', key: 'codigo_unidad_facturacion', width: 30 },
      { header: 'Resumen Propietarios', key: 'resumen_propietarios', width: 30 },
      { header: 'Resumen Propiedades', key: 'resumen_propiedades', width: 30 },
      { header: 'Área Ocupada Total', key: 'area_ocupada_total', width: 20 },
      { header: 'Porcentaje Participación', key: 'porcentaje_participacion', width: 20 },
      { header: 'Fecha de Registro', key: 'fecha_registro_formato01', width: 20 },
    ];

    // Agregar datos
    unidades_facturacionFiltrados.forEach((unidad) => {
      worksheet.addRow({
        codigo_edificio: unidad.codigo_edificio,
        codigo_unidad_facturacion: unidad.codigo_unidad_facturacion,
        resumen_propietarios: unidad.resumen_propietarios,
        resumen_propiedades: unidad.resumen_propiedades,
        area_ocupada_total: unidad.area_ocupada_total,
        porcentaje_participacion: unidad.porcentaje_participacion,
        fecha_registro_formato01: unidad.fecha_registro_formato01,
      });
    });

    // Estilos de cabecera
    const headerRow = worksheet.getRow(1);
    headerRow.font = { bold: true };
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' }, // Color amarillo
    };

    // Bordes en las celdas
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });

    // Generar archivo Excel en buffer
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);

    // Descargar
    const a = document.createElement('a');
    a.href = url;
    a.download = 'unidades_facturacion.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="container" style={{ textAlign: 'center', marginTop: '2rem' }}>
      <h5>Unidades de facturación</h5>

      {/* Botones de acción */}
      <div className="row" style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
        <div className="col s12 m3">
          <button
            className="btn green tooltipped"
            data-position="top"
            data-tooltip="Refrescar vista"
            onClick={handleRefresh}
            style={{ margin: '0.5rem' }}
          >
            <i className="material-icons left">refresh</i>Refrescar
          </button>
        </div>
        {esSuperUsuario && (
          <div className="col s12 m3">
            <button
              className="btn orange tooltipped"
              data-position="top"
              data-tooltip="Cargar Unid. Facturación"
              onClick={() => navigate('/carga-unidades-facturacion')}
              style={{ margin: '0.5rem' }}
            >
              <i className="material-icons left">cloud_upload</i>Cargar Unid. Facturación
            </button>
          </div>
        )}
        <div className="col s12 m3">
          <button
            className="btn blue tooltipped"
            data-position="top"
            data-tooltip="Volver"
            onClick={() => navigate('/edificio')}
            style={{ margin: '0.5rem' }}
          >
            <i className="material-icons left">arrow_back</i>
          </button>
        </div>
        <div className="col s12 m3">
          <button className="btn" onClick={handleExport} style={{ margin: '0.5rem' }}>
            <i className="material-icons left">file_download</i>Descargar Excel
          </button>
        </div>
      </div>

      {/* Filtro */}
      <div className="col s12 m12 input-field" style={{ maxWidth: '400px', margin: '0 auto' }}>
        <label htmlFor="filtro_tabla_unidades_facturacion_cargados">
          <i className="material-icons">search</i> Filtrar
        </label>
        <input
          type="text"
          id="filtro_tabla_unidades_facturacion_cargados"
          value={filtro}
          onChange={handleFilterChange}
          style={{ marginBottom: '1rem' }}
        />
      </div>

      {/* Contenedor con scroll horizontal/vertical */}
      <div
        style={{
          width: '100%',
          maxHeight: '400px',
          overflowX: 'auto',
          overflowY: 'auto',
          margin: '0 auto',
        }}
      >
        <table
          className="striped responsive-table tabla_unidades_facturacion_cargados"
          style={{ margin: '0 auto', minWidth: '900px' }}
        >
          <thead>
            <tr>
              <th>Edificio</th>
              <th>Unid.Fact.</th>
              <th>Propietarios</th>
              <th>Unid. Inmobiliarias</th>
              <th>Area ocupada</th>
              <th>% Particip.</th>
              <th>Validación</th>
              <th>Mensaje</th>
              <th>Usuario</th>
              <th>Id. carga</th>
              <th>Fecha carga</th>
            </tr>
          </thead>
          <tbody id="table-body-unidades_facturacion-cargados">
            {/* Fila de totales */}
            <tr>
              <td><strong>Total</strong></td>
              {/* Cantidad total de unidades */}
              <td><strong>{totalUnidadesFacturacion}</strong></td>
              <td colSpan="2"></td>
              {/* Total área ocupada */}
              <td><strong>{formatNumber(totalAreaOcupada)}</strong></td>
              {/* Total % participación */}
              <td><strong>{formatNumber(totalPorcentajeParticipacion)}</strong></td>
              {/* Resto de columnas vacías */}
              <td colSpan="5"></td>
            </tr>

            {unidades_facturacionFiltrados.map((unidad) => (
              <tr key={unidad.id}>
                <td>{unidad.codigo_edificio}</td>
                {/* Enlace en la columna "codigo_unidad_facturacion" */}
                <td>
                  <a
                    href="#!"
                    className="blue-text text-darken-2"
                    onClick={() => navigate(`/unidad_facturacion/view/${unidad.id}`)}
                  >
                    {unidad.codigo_unidad_facturacion}
                  </a>
                </td>
                <td>{unidad.resumen_propietarios}</td>
                <td>{unidad.resumen_propiedades}</td>
                <td>{unidad.area_ocupada_total}</td>
                <td>{unidad.porcentaje_participacion}</td>
                <td>{unidad.validacion_carga}</td>
                <td>{unidad.mensaje_error_carga}</td>
                <td>{unidad.usuario_carga}</td>
                <td>{unidad.uid_carga}</td>
                <td>{unidad.timestamp_carga_formateado}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Unidades_facturacion_edificio;
