import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from "date-fns";
import { get_recibos_admin } from './recibosThunk';
import './MisRecibosAdmin.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import M from 'materialize-css/dist/js/materialize.min.js';
import {
    RECIBO_ESTADO_PAGO_Pendiente, RECIBO_ESTADO_PAGO_Confirmado
} from '../../constants/constants';
import { formatCurrency, formatPeriod } from '../../utils/formateo';
import ExcelJS from 'exceljs';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';


const MisRecibosAdmin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useMaterializeTooltips();

    registerLocale('es', es);

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const recibos = useSelector((state) => state.recibos.recibos);
    const status = useSelector((state) => state.recibos.status_get_recibos_admin);
    const error = useSelector((state) => state.recibos.error_get_recibos_admin);
    const edificioSeleccionado = useSelector((state) => state.edificio.edificioSeleccionado);
    const buildingId = edificioSeleccionado.id; // ID del edificio desde la URL

    const [codigoUnidadFacturacion, setCodigoUnidadFacturacion] = useState("");
    const [estadoPago, setEstadoPago] = useState("");
    const [periodoInicio, setPeriodoInicio] = useState(new Date());
    const [periodoFin, setPeriodoFin] = useState(new Date());
    const [filtro, setFiltro] = useState('');

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields();
    }, [estadoPago, codigoUnidadFacturacion, dispatch, recibos]);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const handleExportExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Recibos');

        // Definir columnas
        worksheet.columns = [
            { header: 'Código Unidad', key: 'codigo_unidad_facturacion', width: 20 },
            { header: 'Periodo', key: 'periodo_mes_anio', width: 15 },
            { header: 'Monto', key: 'total_mes', width: 15 },
            { header: 'Estado de Pago', key: 'estado_pago', width: 20 },
        ];

        // Agregar datos
        recibosFiltrados.forEach((recibo) => {
            worksheet.addRow({
                codigo_unidad_facturacion: recibo.codigo_unidad_facturacion,
                periodo_mes_anio: recibo.periodo_mes_anio,
                total_mes: recibo.total_mes,
                estado_pago: recibo.estado_pago,
            });
        });

        // Estilos de cabecera
        worksheet.getRow(1).font = { bold: true, color: { argb: 'FFFFFF' } };
        worksheet.getRow(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '007bff' }, // Azul
        };

        // Agregar bordes a las celdas
        worksheet.eachRow((row) => {
            row.eachCell((cell) => {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
            });
        });

        // Crear archivo Excel
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);

        // Descargar archivo
        const a = document.createElement('a');
        a.href = url;
        a.download = 'MisRecibos.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handleBuscar = () => {
        if (!codigoUnidadFacturacion && !estadoPago && !periodoInicio && !periodoFin) {
            M.toast({ html: "Seleccione al menos un filtro para buscar.", classes: "red" });
            return;
        }

        const periodoInicioFormatted = periodoInicio ? format(periodoInicio, "yyyy-MM") : null;
        const periodoFinFormatted = periodoFin ? format(periodoFin, "yyyy-MM") : null;

        dispatch(
            get_recibos_admin({
                buildingId: buildingId,
                periodo_inicio: periodoInicioFormatted,
                periodo_fin: periodoFinFormatted,
                estado: 'TODOS',
                estado_pago: estadoPago !== "TODOS" ? estadoPago : '',
                codigo_unidad_facturacion: codigoUnidadFacturacion || '',
            })
        );
    };

    const handleFilterChange = (e) => {
        setFiltro(e.target.value);
    };

    const recibosFiltrados = Array.isArray(recibos) ? recibos.filter((recibo) => {
        return (
            recibo.codigo_unidad_facturacion?.toLowerCase().includes(filtro.toLowerCase()) ||
            formatCurrency(recibo.total_mes).includes(filtro) ||
            recibo.periodo_mes_anio?.includes(filtro) ||
            recibo.estado_pago?.toLowerCase().includes(filtro.toLowerCase())
        );
    }) : [];


    return (
        <div className="container">
            <div className="card">
                <div className="card-content">
                    <span className="card-title">Mis Recibos</span>
                    <div className="card-action">

                        <a className="btn orange tooltipped" data-position="top" data-tooltip="Descargar Excel" onClick={handleExportExcel} >
                            <i className="material-icons left">file_download</i>Descargar Excel
                        </a>
                        <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate(-1)}>
                            <i className="material-icons left">arrow_back</i>
                        </a>
                    </div>
                    <div className="row">
                        <div className="input-field col s6 m2">
                            <DatePicker
                                selected={periodoInicio}
                                onChange={setPeriodoInicio}
                                dateFormat="yyyy-MM"
                                showMonthYearPicker
                                locale="es"
                            />
                            <label className="active">Periodo Inicio</label>
                        </div>
                        <div className="input-field col s6 m2">
                            <DatePicker
                                selected={periodoFin}
                                onChange={setPeriodoFin}
                                dateFormat="yyyy-MM"
                                showMonthYearPicker
                                locale="es"
                            />
                            <label className="active">Periodo Fin</label>
                        </div>
                        <div className="input-field col s6 m2">
                            <select value={estadoPago} onChange={(e) => setEstadoPago(e.target.value)}>
                                <option value="TODOS">TODOS</option>
                                <option value={RECIBO_ESTADO_PAGO_Pendiente}>{RECIBO_ESTADO_PAGO_Pendiente}</option>
                                <option value={RECIBO_ESTADO_PAGO_Confirmado}>{RECIBO_ESTADO_PAGO_Confirmado}</option>
                            </select>
                            <label>Estado de Pago</label>
                        </div>
                        <div className="input-field col s6 m2">
                            <input
                                type="text"
                                value={codigoUnidadFacturacion}
                                onChange={(e) => setCodigoUnidadFacturacion(e.target.value)}
                                placeholder="Unidad"
                            />
                        </div>


                        <div className="col s12 m4">
                            <button className="btn blue" onClick={handleBuscar}>Buscar</button>
                        </div>
                    </div>
                    <div className="col s12 m12 input-field">
                        <input type="text" value={filtro} onChange={handleFilterChange} placeholder="Filtrar..." />
                    </div>
                    <div className="table-container">
                        {status === 'loading' ? <div>Loading...</div> : (
                            <table className="striped responsive-table">
                                <thead>
                                    <tr>
                                        <th>Unidad</th>
                                        <th>Periodo</th>
                                        <th>Monto</th>
                                        <th>Estado de Pago</th>
                                        <th>PDF</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recibosFiltrados.map((recibo) => (
                                        <tr key={recibo.id}>
                                            <td>{recibo.codigo_unidad_facturacion}</td>
                                            <td>
                                                <span
                                                    className="blue-text text-darken-2"
                                                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                    onClick={() => navigate(`/recibo-admin/${recibo.id}`)}
                                                >
                                                    {recibo.periodo_mes_anio}
                                                </span>
                                            </td>
                                            <td>{formatCurrency(recibo.total_mes)}</td>
                                            <td>{recibo.estado_pago}</td>
                                            <td>
                                                {recibo.url_pdf ? (
                                                    <a
                                                        href={recibo.url_pdf}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="tooltipped"
                                                        data-tooltip="Ver PDF"
                                                    >
                                                        <i className="material-icons">picture_as_pdf</i>
                                                    </a>
                                                ) : (
                                                    <span className="grey-text">No disponible</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MisRecibosAdmin;
