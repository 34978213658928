import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// The Firebase Admin SDK to access Firestore.
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';


if (typeof browser === "undefined") {
  window.browser = {};
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//npx create-react-app app-react
//npm start
//npm install react-router-dom
//npm install materialize-css@next
//npm install firebase
//npm install axios
//npm install redux react-redux @reduxjs/toolkit
//npm install redux-persist
//npm install redux-persist-expire
//npm install react-router-dom@latest
//npm install date-fns
//npm install react-datepicker
//npm install react-modal
//npm install cleave.js
//npm install xlsx
//npm install file-saver
//npm install react-to-print
//npm install jspdf html2canvas
//npm install exceljs
//npm install react-calendar
//npm install react-slick slick-carousel
//npm install
//npm install react-color
//npm install slate slate-react slate-history
//npm install quill@latest
//npm install react-quill

//to deploy
//npm run build
//firebase deploy
