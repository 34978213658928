import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetFacturaciones_masivas } from './facturacion_masivaSlice';


export const update_facturacion_masiva = createAsyncThunk('facturacion_masiva/update_facturacion_masiva', async ({ idFacturacion_individual, facturacion_masivaData }, thunkAPI) => {
  //console.log("ejecutando thunk update_facturacion_masiva con idFacturacion_individual: " + idFacturacion_individual);

  try {

    const response = await apiClient.put(`/facturaciones-masivas/${idFacturacion_individual}`, facturacion_masivaData);

    const facturacion_masiva = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetFacturaciones_masivas(facturacion_masiva.buildingId));// Resetear el estado para refetch de facturaciones_masivas

    return facturacion_masiva;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_facturacion_masiva = createAsyncThunk('facturacion_masiva/save_facturacion_masiva', async (facturacion_masivaData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/facturaciones-masivas`, facturacion_masivaData);

    const facturacion_masiva = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetFacturaciones_masivas(facturacion_masiva.buildingId));// Resetear el estado para refetch de facturaciones_masivas

    return facturacion_masiva;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_facturacion_masiva = createAsyncThunk('facturacion_masiva/anular_facturacion_masiva', async ({ idFacturacion_individual, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_facturacion_masiva con idFacturacion_individual: " + idFacturacion_individual);

  try {

    const response = await apiClient.patch(`/facturaciones-masivas/${idFacturacion_individual}/anular`, { usuarioAnulacion });

    const facturacion_masiva = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetFacturaciones_masivas(facturacion_masiva.buildingId));// Resetear el estado para refetch de facturaciones_masivas

    return facturacion_masiva;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_facturacion_masiva = createAsyncThunk('facturacion_masiva/get_facturacion_masiva', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_facturacion_masiva con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/facturaciones-masivas/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const facturacion_masiva = response.data;

    return facturacion_masiva;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_facturaciones_masivas = createAsyncThunk('facturacion_masiva/get_facturaciones_masivas', async ({ buildingId, estado }, thunkAPI) => {
  console.log("ejecutando thunk get_facturaciones_masivas con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/facturaciones-masivas',{
        params: { 
          buildingId, 
          estado 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const facturaciones_masivas = response.data;
          
      return facturaciones_masivas ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});

