import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectReciboById } from './recibosSlice';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import { formatDate, formatCurrency, formatPeriod } from '../../utils/formateo';
import './ReciboDetalle.css';
import { useNavigate } from 'react-router-dom';

const ReciboDetalle = () => {
  const { id } = useParams();
  const recibo = useSelector((state) => selectReciboById(state, id));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    M.AutoInit();
  }, []);

  function formatDate(isoString) {
    const date = new Date(isoString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }

  const handleUpdate = () => {
    M.toast({ html: 'Funcionalidad de actualización no implementada aún.', classes: 'rounded' });
  };

  if (!recibo) {
    return (
      <div className="container center-align" style={{ marginTop: '50px' }}>
        <h5>Recibo no encontrado</h5>
      </div>
    );
  } 

  return (
    <div className="container" style={{ marginTop: '30px' }}>
      {/* Datos del Recibo */}
      <div className="card">
        <div className="card-content">
          <div className="row valign-wrapper" style={{ marginBottom: '0px' }}>
            <div className="col s6 left-align">
              <button
                className="btn-flat"
                onClick={() => navigate(-1)}
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
              >
                <i className="material-icons left" style={{ lineHeight: '32px' }}>arrow_back</i>Regresar
              </button>
            </div>
            <div className="col s6 right-align">
              <h5 style={{ margin: 0, fontWeight: 600 }}>Datos del Recibo</h5>
            </div>
          </div>

          <div className="row" style={{ marginBottom: '10px' }}>
            <div className="input-field col s12 m2">
              <label className="active">Unidad</label>
              <input type="text" value={recibo.codigo_unidad_facturacion || 'N/A'} readOnly />
            </div>
            <div className="input-field col s12 m5">
              <label className="active">Propietario(s)</label>
              <input type="text" value={recibo.nombres_propietarios || 'N/A'} readOnly />
            </div>
            <div className="input-field col s12 m3">
              <label className="active">Nro. Recibo</label>
              <input type="text" value={recibo.numero_recibo || 'N/A'} readOnly />
            </div>
            <div className="input-field col s12 m2">
              <label className="active">Periodo</label>
              <input type="text" value={recibo.periodo_mes_anio || 'N/A'} readOnly />
            </div>
          </div>

          <div className="row" style={{ marginBottom: '10px' }}>
            <div className="input-field col s12 m5">
              <label className="active">Propiedades</label>
              <input type="text" value={recibo.resumen_propiedades || 'N/A'} readOnly />
            </div>
            <div className="input-field col s12 m2">
              <label className="active">Porc. Part.</label>
              <input type="text" value={`${recibo.porcentaje_participacion || 0}%`} readOnly />
            </div>
            <div className="input-field col s12 m2">
              <label className="active">Emisión</label>
              <input type="text" value={formatDate(recibo.fecha_emision)} readOnly />
            </div>
            <div className="input-field col s12 m2">
              <label className="active">Vencimiento</label>
              <input type="text" value={formatDate(recibo.fecha_vencimiento)} readOnly />
            </div>
          </div>

          {/* Conceptos Facturados */}
          <div className="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span className="card-title">Conceptos Facturados</span>
            {recibo.url_pdf && (
              <a
                href={recibo.url_pdf}
                className="btn-small blue lighten-1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="material-icons left">picture_as_pdf</i> Ver PDF
              </a>
            )}
          </div>

          {recibo.conceptos?.length > 0 ? (
            <table className="striped compact-table">
              <thead>
                <tr>
                  <th>Descripción</th>
                  <th>Importe</th>
                </tr>
              </thead>
              <tbody>
                {recibo.conceptos.map((concepto, index) => (
                  <tr key={index}>
                    <td>{concepto.titulo}</td>
                    <td className="importe-monto">{formatCurrency(concepto.importe)}</td>
                  </tr>
                ))}
                <tr className="blue lighten-5">
                  <td><strong>Total del Recibo</strong></td>
                  <td className="importe-monto"><strong>{formatCurrency(recibo.total_mes)}</strong></td>
                </tr>
              </tbody>
            </table>
          ) : (
            <p>No hay conceptos registrados para este recibo.</p>
          )}
        </div>
      </div>

      {/* Sección de Pago si existe conciliacion_pago */}
      {recibo.conciliacion_pago && (
        <div className="card green lighten-5" style={{ marginTop: '20px' }}>
          <div className="card-content">
            <span className="card-title">Pago del Recibo</span>
            <div className="row">
              <div className="input-field col s6 m3">
                <label className="active">N° Operación</label>
                <input type="text" value={recibo.conciliacion_pago.conciliacion_numero_operacion} readOnly />
              </div>
              <div className="input-field col s6 m3">
                <label className="active">Importe Pagado</label>
                <input type="text" value={formatCurrency(recibo.conciliacion_pago.conciliacion_importe)} readOnly />
              </div>
              <div className="input-field col s6 m3">
                <label className="active">Estado</label>
                <input type="text" value={recibo.conciliacion_pago.conciliacion_estado} readOnly />
              </div>
            </div>
            <div className="row">
              <div className="input-field col s6 m3">
                <label className="active">Fecha de Pago</label>
                <input type="text" value={formatDate(recibo.conciliacion_pago.conciliacion_fecha_pago)} readOnly />
              </div>
              <div className="input-field col s6 m3">
                <label className="active">Hora de Pago</label>
                <input type="text" value={recibo.conciliacion_pago.conciliacion_hora_pago} readOnly />
              </div>
              <div className="input-field col s6 m3">
                <label className="active">Canal</label>
                <input type="text" value={recibo.conciliacion_pago.conciliacion_canal} readOnly />
              </div>
            </div>
          </div>
        </div>
      )
      }

      {/* Acciones 
      
      <div className="row center-align">
        <button className="btn blue" onClick={handleUpdate}>
          <i className="material-icons left">edit</i>Actualizar
        </button>
        <button
          className="btn orange"
          style={{ marginLeft: '10px' }}
          onClick={() =>
            M.toast({ html: 'Funcionalidad de Reclamo no implementada aún.', classes: 'rounded' })
          }
        >
          <i className="material-icons left">report_problem</i>Reclamo
        </button>
        <button
          className="btn green"
          style={{ marginLeft: '10px' }}
          onClick={() =>
            M.toast({ html: 'Funcionalidad de Pago no implementada aún.', classes: 'rounded' })
          }
        >
          <i className="material-icons left">payment</i>Pagar
        </button>
      </div>
      */}
    </div>
  

  );
};

export default ReciboDetalle;
