import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js';

// Thunks hipotéticos (ajusta a tu necesidad)
import {
    get_persona,       // Para obtener una persona por su id
    update_persona,    // Para actualizar la persona
    save_persona       // Para crear una nueva persona
} from './personaThunk';

const PersonaForm = ({ mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: personaId } = useParams(); // Se obtiene el 'id' de la URL

    // Determinamos si el formulario está en modo edición o solo lectura
    const [isEditing, setIsEditing] = useState(mode !== 'view');

    // Persona que guardamos/mostramos en el formulario
    const personaRedux = useSelector((state) => state.persona.persona);
    const fetchStatus = useSelector((state) => state.persona.fetchStatus);

    // Datos del formulario
    const [formData, setFormData] = useState({
        tipo_documento: '',
        numero_documento: '',
        titular: '',
        email: '',
        email_2: '',
        nro_celular: '',
        usuario: '',
        fecha_registro_formateada: '',
        estado: '',
    });

    // Inicializamos Materialize
    useEffect(() => {
        M.AutoInit();
    }, []);

    // Si no es creación, llamamos a get_persona para cargar los datos existentes
    useEffect(() => {
        if (mode !== 'create' && personaId) {
            dispatch(get_persona(personaId));
        }
    }, [dispatch, personaId, mode]);

    // Cuando llega la persona desde Redux, llenamos el formulario (modo view/edit)
    useEffect(() => {
        if (personaRedux && (mode === 'view' || mode === 'edit')) {
            setFormData({
                tipo_documento: personaRedux.tipo_documento || '',
                numero_documento: personaRedux.numero_documento || '',
                titular: personaRedux.titular || '',
                email: personaRedux.email || '',
                email_2: personaRedux.email_2 || '',
                nro_celular: personaRedux.nro_celular || '',
                usuario: personaRedux.usuario || '',
                fecha_registro_formateada: personaRedux.fecha_registro_formateada || '',
                estado: personaRedux.estado || '',
            });
            setIsEditing(false);
        }
    }, [personaRedux, mode]);

    // Actualiza Materialize al cambiar el formData
    useEffect(() => {
        M.updateTextFields();
        M.FormSelect.init(document.querySelectorAll('select'));
    }, [formData]);

    useEffect(() => {
        M.updateTextFields();
        M.FormSelect.init(document.querySelectorAll('select'));
    }, [isEditing]);

    // Manejadores de eventos del formulario
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Guardar los cambios
    const handleSave = async () => {
        if (mode === 'create') {
            // Crear
            await dispatch(save_persona(formData));
            // Permanecer en esta página en modo view (ya que no tenemos un id para ver)
            // Podrías navegar a la nueva persona si el backend devuelve la ID.
        } else {
            // Actualizar
            await dispatch(update_persona({ idPersona: personaId, personaData: formData }));
            // Re-consultar la persona para ver datos actualizados
            dispatch(get_persona(personaId));
        }
        // Tras guardar, pasamos a modo 'view', sin navegar atrás
        setIsEditing(false);
    };

    // Habilitar modo edición
    const handleEdit = () => {
        setIsEditing(true);
    };

    // Cancelar / volver
    const handleCancel = () => {
        // Si estamos creando, se puede volver a la lista
        // Si estamos editando, vuelve a la lista o la pantalla anterior
        navigate(-1);
    };

    if (fetchStatus === 'loading') {
        return <div>Cargando datos...</div>;
    }

    return (
        <div className="container">
            {/* Acciones */}
            <div className="card-action" style={{ marginBottom: '1rem' }}>
                {isEditing ? (
                    <>
                        <button className="btn green" onClick={handleSave} style={{ marginRight: '1rem' }}>
                            <i className="material-icons left">save</i>Guardar
                        </button>
                        <button className="btn red" onClick={handleCancel} style={{ marginRight: '1rem' }}>
                            <i className="material-icons left">cancel</i>Cancelar
                        </button>
                    </>
                ) : (
                    // Si está en modo vista (view), mostramos el botón Editar
                    <button className="btn green" onClick={handleEdit} style={{ marginRight: '1rem' }}>
                        <i className="material-icons left">edit</i>Editar
                    </button>
                )}
                <button className="btn blue" onClick={handleCancel}>
                    <i className="material-icons left">arrow_back</i>Volver
                </button>
            </div>

            <h4>
                {mode === 'create'
                    ? 'Registrar Persona'
                    : isEditing
                        ? 'Editar Persona'
                        : 'Detalle de Persona'}
            </h4>

            {/* Formulario */}
            <div className="row">
                {/* Tipo Documento */}
                <div className="input-field col s6 m2">
                    <select
                        name="tipo_documento"
                        value={formData.tipo_documento}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    >
                        <option value="" disabled>Seleccionar</option>
                        <option value="DNI">DNI</option>
                        <option value="RUC">RUC</option>
                        <option value="CEX">CEX</option>
                        <option value="PAS">PAS</option>
                        <option value="SD">SD</option>
                    </select>
                    <label>Doc.</label>
                </div>

                {/* Número Documento */}
                <div className="input-field col s6 m2">
                    <input
                        type="text"
                        name="numero_documento"
                        value={formData.numero_documento}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    />
                    <label className={formData.numero_documento ? 'active' : ''}>
                        Número
                    </label>
                </div>
                {/* Titular */}
                <div className="input-field col s12 m8">
                    <input
                        type="text"
                        name="titular"
                        value={formData.titular}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    />
                    <label className={formData.titular ? 'active' : ''}>Titular</label>
                </div>
            </div>

            <div className="row">
                {/* Email */}
                <div className="input-field col s12 m4">
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    />
                    <label className={formData.email ? 'active' : ''}>Email</label>
                </div>
                {/* Celular */}
                <div className="input-field col s12 m3">
                    <input
                        type="text"
                        name="nro_celular"
                        value={formData.nro_celular}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    />
                    <label className={formData.nro_celular ? 'active' : ''}>Nro Celular</label>
                </div>
                {/* Email_2 */}
                <div className="input-field col s12 m5">
                    <input
                        type="email"
                        name="email_2"
                        value={formData.email_2}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    />
                    <label className={formData.email_2 ? 'active' : ''}>Email 2</label>
                </div>
            </div>

            <div className="row">
                {/* Usuario */}
                <div className="input-field col s12 m3">
                    <input
                        type="text"
                        name="usuario"
                        value={formData.usuario}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    />
                    <label className={formData.usuario ? 'active' : ''}>Usuario</label>
                </div>
                {/* Estado */}
                <div className="input-field col s12 m3">
                    <select
                        name="estado"
                        value={formData.estado}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    >
                        <option value="" disabled>Seleccionar</option>
                        <option value="ACTIVO">ACTIVO</option>
                        <option value="INACTIVO">INACTIVO</option>
                    </select>
                    <label>Estado</label>
                </div>

                {/* Fecha Registro (solo lectura) */}
                <div className="input-field col s12 m3">
                    <input
                        type="text"
                        name="fecha_registro"
                        value={formData.fecha_registro_formateada}
                        onChange={handleInputChange}
                        disabled
                    />
                    <label className={formData.fecha_registro_formateada ? 'active' : ''}>Registro</label>
                </div>
            </div>
        </div>
    );
};

export default PersonaForm;
