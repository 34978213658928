import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_concepto, update_concepto, save_concepto, anular_concepto } from './conceptoThunk';
import { useNavigate, useParams } from 'react-router-dom';
import { TIPOS_CONCEPTO, TIPOS_FACTURACION, TIPOS_CUOTA, TIPOS_SUMINISTRO, CRITERIOS_ASIGNACION_CUOTA, FUENTES_DATOS, MONEDAS } from '../../constants/constants';
import Cleave from 'cleave.js/react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './conceptoFormPage.css';
import { get_suministros_x_tipo } from '../edificio/suministrosThunk';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

import BuscarUnidadFacturacionModal from '../edificio/BuscaUnidadFacturacionModal';

const ConceptoForm = ({ mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: idConcepto } = useParams();
    useMaterializeTooltips();
    
    const [isEditing, setIsEditing] = useState(mode !== 'view');
    const [showModalUnidad, setShowModalUnidad] = useState(false);
    const [unidadesExoneradas, setUnidadesExoneradas] = useState([]);

    const edificioSeleccionado = useSelector((state) => state.edificio.edificio);
    const buildingId = edificioSeleccionado.id;
    const codigo_edificio = edificioSeleccionado.codigo;
    const concepto = useSelector(state => state.concepto.concepto);
    const codigo_usuario = useSelector((state) => state.auth.usuario.id);

    const defaultValues = {
        tipo_concepto: TIPOS_CONCEPTO[0].value,
        tipo_facturacion: TIPOS_FACTURACION[0].value,
        tipo_cuota: TIPOS_CUOTA[0].value,
        criterio_asignacion_cuota: CRITERIOS_ASIGNACION_CUOTA[0].value,
        fuente_datos: FUENTES_DATOS[0].value,
        moneda: { codigo: 'PEN', simbolo: 'S/' },
        importe_defecto: 0,
        nro_suministro: ''
    };

    const [formData, setFormData] = useState({
        nombre: '',
        titulo: '',
        descripcion: '',
        ...defaultValues
    });

    useEffect(() => {
        if (concepto && (mode === 'view' || mode === 'edit')) {
            setFormData({
                nombre: concepto.nombre,
                titulo: concepto.titulo,
                descripcion: concepto.descripcion,
                tipo_concepto: concepto.tipo_concepto,
                tipo_facturacion: concepto.tipo_facturacion,
                tipo_cuota: concepto.tipo_cuota,
                criterio_asignacion_cuota: concepto.criterio_asignacion_cuota,
                fuente_datos: concepto.fuente_datos,
                moneda: concepto.moneda,
                importe_defecto: concepto.importe_defecto !== undefined ? concepto.importe_defecto.toFixed(2) : '0.00',
                nro_suministro: concepto.nro_suministro || ''
            });
            setUnidadesExoneradas(concepto.unidades_exoneradas || []);
            setIsEditing(false);
        }
    }, [concepto, mode]);

    //const suministros = useSelector(state => state.suministros.suministros);
    const suministros = useSelector(state => state.suministros.suministros) || [];

    useEffect(() => {

        dispatch(get_suministros_x_tipo({ buildingId, tipo_suministro: TIPOS_SUMINISTRO[0].value }));

        if (mode !== 'create') {
            dispatch(get_concepto(idConcepto));
        }
    }, [dispatch, idConcepto, mode]);

    useEffect(() => {
        if (suministros) {


            if (suministros.length > 0 && !formData.nro_suministro) {
                setFormData(prev => ({
                    ...prev,
                    nro_suministro: suministros[0].numero // Inicializa con el primer medidor disponible
                }));
            }

            var elems = document.querySelectorAll('select');
            M.FormSelect.init(elems);
            M.updateTextFields();
        }
    }, [suministros]);

    useEffect(() => {
        // Actualiza los campos de texto de Materialize cuando el componente se monta
        M.updateTextFields();
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [isEditing]);

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [TIPOS_CONCEPTO]);

    useEffect(() => {
        if (!isEditing) {
            setFormData(prevState => ({
                ...prevState,
                ...defaultValues
            }));
        }
    }, [isEditing]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => {
            const newFormData = { ...prev, [name]: value };
            // Si el tipo_concepto cambia y no es CONSUMO_AGUA, limpiar nro_suministro
            console.log("name " + name + " value " + value);
            if (name === 'tipo_concepto' && value !== 'CONSUMO_AGUA') {
                newFormData.nro_suministro = '';
            }
            return newFormData;
        });
    };

    const handleCleaveChange = (value) => {

        // Remove the currency symbol and any non-numeric characters (like commas) from the value
        const numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));

        setFormData(prev => ({
            ...prev,
            importe_defecto: isNaN(numericValue) ? 0 : numericValue
        }));
    };

    const handleSave = async () => {
        if (isEditing) {
            const dataPrevia = {
                ...formData,
                importe_defecto: parseFloat(formData.importe_defecto),
                unidades_exoneradas: unidadesExoneradas
            };

            if (mode === 'create') {
                const data = {
                    ...dataPrevia,
                    buildingId,
                    codigo_edificio,
                    usuarioRegistrador: codigo_usuario
                };
                dispatch(save_concepto(data));
            } else {
                const data = { ...dataPrevia, usuarioActualizacion: codigo_usuario };
                dispatch(update_concepto({ idConcepto, conceptoData: dataPrevia }));
            }
        }

        navigate('/conceptos-facturacion');
    };

    const handleAddUnidad = () => {
        if (!isEditing) return;
        setShowModalUnidad(true); // Abre el modal de búsqueda de unidad inmobiliaria
    };

    // Callback que recibe la unidad inmobiliaria encontrada
    const handleAddUnidadExonerada = (unidadEncontrada) => {
        if (unidadesExoneradas.some(u => u.codigo_unidad_facturacion === unidadEncontrada.codigo_unidad_facturacion)) {
            M.toast({ html: 'Unidad ya está en la lista.', classes: 'red' });
            return;
        }
        setUnidadesExoneradas([...unidadesExoneradas, unidadEncontrada]);
    };

    const handleRemoveUnidadExonerada = (codigo) => {
        setUnidadesExoneradas(prev => prev.filter(unidad => unidad.codigo_unidad_facturacion !== codigo));
    };

    const handleEdit = () => {
        setIsEditing(true);
    };
    useEffect(() => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [formData.tipo_concepto]);
    const handleAnular = () => {
        if (window.confirm("¿Estás seguro de que quieres anular este concepto de facturación? Esta acción no se puede deshacer.")) {
            dispatch(anular_concepto({ idConcepto, usuarioAnulacion: codigo_usuario }));
            M.toast({ html: 'Concepto anulado correctamente', classes: 'rounded' });
            navigate('/conceptos-facturacion');
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        if (mode === 'create') {
            navigate('/conceptos-facturacion');
        }
    };

    const handleMonedaChange = (e) => {
        const selectedMoneda = MONEDAS.find(moneda => moneda.value === e.target.value);
        setFormData({
            ...formData,
            moneda: {
                codigo: selectedMoneda.value,
                simbolo: selectedMoneda.label
            }
        });
    };

    return (
        <div className="container">
            <div>
                <div className="card">
                    <div className="card-content">
                        <div className="card-action">
                            {isEditing ? (
                                <>
                                    <a className="btn green tooltipped" data-position="top" data-tooltip="Guardar cambios" onClick={handleSave}>
                                        <i className="material-icons left">save</i>Guardar
                                    </a>
                                    <a className="btn red tooltipped" data-position="top" data-tooltip="Cancelar edición" onClick={handleCancel}>
                                        <i className="material-icons left">cancel</i>Cancelar
                                    </a>
                                </>
                            ) : (
                                <>
                                    <a className="btn green tooltipped" data-position="top" data-tooltip="Editar detalles" onClick={handleEdit} >
                                        <i className="material-icons left">edit</i>Editar
                                    </a>
                                    <a className="btn orange tooltipped" data-tooltip="Anular Concepto" onClick={handleAnular}>
                                        <i className="material-icons left">delete</i>Anular
                                    </a>
                                </>
                            )}
                            <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/conceptos-facturacion')}>
                                <i className="material-icons left">arrow_back</i>
                            </a>
                        </div>
                        <span className="card-title">{mode === 'create' ? 'Crear Concepto' : mode === 'edit' ? 'Editar Concepto' : 'Detalles del Concepto'}</span>
                        <form onSubmit={e => e.preventDefault()}>
                            <div className="row">
                                <div className="input-field col s6 m3">
                                    <input
                                        type="text"
                                        name="nombre"
                                        value={formData.nombre || ''}
                                        onChange={handleInputChange}
                                        disabled={!isEditing}
                                    />
                                    <label htmlFor="nombre" className="active">Nombre</label>
                                </div>
                                <div className="input-field col s6 m4">
                                    <input
                                        type="text"
                                        name="titulo"
                                        value={formData.titulo || ''}
                                        onChange={handleInputChange}
                                        disabled={!isEditing}
                                    />
                                    <label className="active" htmlFor="titulo">Titulo</label>
                                </div>
                                <div className="input-field col s6 m5">
                                    <textarea
                                        type="text"
                                        name="descripcion"
                                        value={formData.descripcion || ''}
                                        onChange={handleInputChange}
                                        disabled={!isEditing}
                                    />
                                    <label className="active" htmlFor="descripcion">Descripción</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s6 m4">
                                    <select name="tipo_concepto" value={formData.tipo_concepto} onChange={handleInputChange} disabled={!isEditing}>
                                        {TIPOS_CONCEPTO.map(tipo => (
                                            <option key={tipo.value} value={tipo.value}>{tipo.label}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="tipo_concepto">Tipo de Concepto</label>
                                </div>
                                <div className="input-field col s6 m3">
                                    <select name="tipo_facturacion" value={formData.tipo_facturacion} onChange={handleInputChange} disabled={!isEditing}>
                                        {TIPOS_FACTURACION.map(tipo => (
                                            <option key={tipo.value} value={tipo.value}>{tipo.label}</option>
                                        ))}
                                    </select>
                                    <label>Tipo de Facturación</label>
                                </div>
                                {/*
                                <div className="input-field col s6 m3">
                                    <select name="tipo_cuota" value={formData.tipo_cuota} onChange={handleInputChange} disabled={!isEditing}>
                                        {TIPOS_CUOTA.map(tipo => (
                                            <option key={tipo.value} value={tipo.value}>{tipo.label}</option>
                                        ))}
                                    </select>
                                    <label>Tipo de Cuota</label>
                                </div>
                                */}
                            </div>
                            <div className="row">
                                <div className="input-field col s6 m4">
                                    <select name="criterio_asignacion_cuota" value={formData.criterio_asignacion_cuota} onChange={handleInputChange} disabled={!isEditing}>
                                        {CRITERIOS_ASIGNACION_CUOTA.map(criterio => (
                                            <option key={criterio.value} value={criterio.value}>{criterio.label}</option>
                                        ))}
                                    </select>
                                    <label>Criterio Asignación Cuota</label>
                                </div>
                                <div className="input-field col s6 m3">
                                    <select name="fuente_datos" value={formData.fuente_datos} onChange={handleInputChange} disabled={!isEditing}>
                                        {FUENTES_DATOS.map(fuente => (
                                            <option key={fuente.value} value={fuente.value}>{fuente.label}</option>
                                        ))}
                                    </select>
                                    <label>Fuente de Datos</label>
                                </div>
                                <div className="input-field col s6 m1">
                                    <select name="moneda.codigo" value={formData.moneda.codigo} onChange={handleMonedaChange} disabled={!isEditing}>
                                        {MONEDAS.map(moneda => (
                                            <option key={moneda.value} value={moneda.value}>{moneda.label}</option>
                                        ))}
                                    </select>
                                    <label>Moneda</label>
                                </div>
                                <div className="input-field col s6 m4">
                                    <Cleave
                                        name="importe_defecto"
                                        value={formData.importe_defecto}
                                        onChange={(e) => handleCleaveChange(e.target.value)}
                                        options={{
                                            numeral: true,
                                            numeralThousandsGroupStyle: 'thousand',
                                            numeralDecimalMark: '.',
                                            numeralDecimalScale: 2,
                                            prefix: formData.moneda.simbolo + ' '
                                        }}
                                        disabled={!isEditing}
                                    />
                                    <label>Importe Defecto</label>
                                </div>
                                {formData.tipo_concepto === 'CONSUMO_AGUA' && (
                                    <div className="input-field col s6 m3">
                                        <select
                                            name="nro_suministro"
                                            value={formData.nro_suministro || ''}
                                            onChange={handleInputChange}
                                            disabled={!isEditing}
                                        >
                                            {suministros.length > 0 ? (
                                                suministros.map(s => (
                                                    <option key={s.id} value={s.numero}>{s.nombre} - {s.numero}</option>
                                                ))
                                            ) : (
                                                <option value="">No hay suministros disponibles</option>
                                            )}
                                        </select>
                                        <label>N° Suministro</label>
                                    </div>
                                )}
                                {/* Botón para agregar unidad exonerada */}
                                <div className="row">
                                    <div className="col s6">
                                        <button
                                            className="btn blue waves-effect waves-light btn-add-unidad"
                                            onClick={handleAddUnidad}
                                            disabled={!isEditing}
                                        >
                                            <i className="material-icons left">add</i> Agregar Unidad Exonerada
                                        </button>
                                    </div>
                                </div>
                                <table className="highlight">
                                    <thead>
                                        <tr>
                                            <th>Unidad de Facturación</th>
                                            <th>Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {unidadesExoneradas.length === 0 ? (
                                            <tr>
                                                <td colSpan="2" className="no-unidades">No hay unidades exoneradas</td>
                                            </tr>
                                        ) : (
                                            unidadesExoneradas.map((unidad, index) => (
                                                <tr key={index}>
                                                    <td>{unidad.codigo_unidad_facturacion}</td>
                                                    <td>
                                                        <button
                                                            className="btn btn-delete"
                                                            onClick={() => handleRemoveUnidadExonerada(unidad.codigo_unidad_facturacion)}
                                                            disabled={!isEditing}
                                                        >
                                                            <i className="material-icons">delete</i>Eliminar
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
                    <BuscarUnidadFacturacionModal
                        isOpen={showModalUnidad}
                        onClose={() => setShowModalUnidad(false)}
                        handleAddUnidadExonerada={handleAddUnidadExonerada}
                        buildingId={buildingId}
                    />
                </div>
            </div>
        </div>
    );
};

export default ConceptoForm;
