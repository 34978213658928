import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';


export const get_movimiento_cuenta = createAsyncThunk('movimiento_cuenta/get_movimiento_cuenta', async (id, thunkAPI) => {
    console.log("ejecutando thunk get_movimiento_cuenta con id: " + id);

    try {

        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        //parametro pasado por path
        const response = await apiClient.get(`/movimientos_cuenta/${id}`);
        //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
        const movimiento_cuenta = response.data;

        return movimiento_cuenta;
    } catch (error) {
        console.log("error.response.data " + error.response.data);
        return thunkAPI.rejectWithValue(error.response.data);
    }
});


export const get_movimientos_cuenta = createAsyncThunk('movimiento_cuenta/get_movimientos_cuenta', async ({ buildingId, cuenta_bancoId, fecha_inicio, fecha_fin, descripcion, tipo_movimiento }, thunkAPI) => {
    console.log("ejecutando thunk get_movimientos_cuenta con buildingId: " + buildingId);

    try {
        //console.log("ejecutando thunk buildingId: "+buildingId);      
        const response = await apiClient.get('/movimientos_cuenta', {
            params: {
                buildingId,
                cuenta_bancoId,
                fecha_inicio,
                fecha_fin,
                descripcion,
                tipo_movimiento
            },
        });
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const movimientos_cuenta = response.data;

        return movimientos_cuenta;
    } catch (error) {
        console.log("error.response.data " + error.response.data);
        return thunkAPI.rejectWithValue(error.response.data);
    }
});
