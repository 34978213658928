import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_planilla, update_planilla, save_planilla, anular_planilla, update_facturacion_agua_planilla, recrear_recibos } from './planillaThunk';
import { useNavigate, useParams, Link } from 'react-router-dom';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './planillaFormPage.css'; // Asegúrate de importar tu archivo CSS personalizado
import { TIPOS_PLANILLA, ESTADOS_PLANILLA, MONEDAS } from '../../constants/constants';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SelectConceptsModal from '../conceptos-facturacion/SelectConceptsModal';
import { removeConcept, clearSelectedConcepts, updateSelectedConcepts, updateConceptoImporte } from '../conceptos-facturacion/conceptos_facturacionSlice';
//import { removeConcept, updateSelectedConcepts, updateConceptoImporte } from '../conceptos-facturacion/conceptos_facturacionSlice';
import { get_conceptos_facturacion } from '../conceptos-facturacion/conceptos_facturacionThunk';
import { clearPlanillaSeleccionado } from '../planilla_recibos/planillaSlice';
import Cleave from 'cleave.js/react';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { parseFromYYYYMM, formatPeriodo } from '../../utils/util';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const PlanillaForm = ({ mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useMaterializeTooltips();

    registerLocale('es', es);

    //console.log(" VISTA "+JSON.stringify(useParams()));
    const { id: idPlanilla } = useParams();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const [isEditing, setIsEditing] = useState(mode !== 'view');
    const [isCreating, setIsCreating] = useState(mode === 'create');

    // Obtener el estado de los edificios de Redux    
    const edificios = useSelector((state) => state.auth.edificios);

    const edificioSeleccionado = useSelector((state) => state.edificio.edificio);
    const buildingId = edificioSeleccionado.id; // ID del edificio desde la URL
    const codigo_edificio = edificioSeleccionado.codigo; // ID del edificio desde la URL
    //const building = useSelector(state => state.building.currentBuilding);
    const planilla = useSelector(state => state.planilla.planilla);

    const { status_save_planilla, error_save_planilla
        , status_update_planilla, error_update_planilla
        , status_update_facturacion_agua_planilla, error_update_facturacion_agua_planilla
    } = useSelector((state) => state.planilla);

    //const codigo_usuario = useSelector((state) => state.auth.usuario.id);
    const usuario = useSelector((state) => state.auth.usuario);
    const codigo_usuario = usuario ? usuario.id : null;
  
        // Verifica qué tipo de dato está en Redux antes de asignarlo
const selectedConcepts = useSelector((state) => {
    console.log("Estado de Redux -> state.conceptos_facturacion:", state.conceptos_facturacion);
    console.log("selectedConcepts antes de asignar:", state.conceptos_facturacion.selectedConcepts);

    // Asegurar que sea un array, si es null o undefined, devolver un array vacío
    return Array.isArray(state.conceptos_facturacion.selectedConcepts) ? state.conceptos_facturacion.selectedConcepts : [];
});

    const [concepts, setConcepts] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        if (!isAuthenticated) {
            //console.log("no existe usuario");
            navigate('/');
        }

    }, [isAuthenticated, navigate, dispatch]);

    const formatNumber = (number) => {
        if (isNaN(number)) return '';
        return new Intl.NumberFormat('es-PE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(number);
    };

    const parseNumber = (formattedNumber) => {
        // Asegúrate de que el número está en formato de cadena
        if (typeof formattedNumber !== 'string') {
            return parseFloat(formattedNumber) || 0;
        }

        return parseFloat(formattedNumber.replace(/,/g, '')) || 0;
    };
    const handleSimular = () => {
        //setModalIsOpen(true);
    };

    const handleAddConcepts = () => {
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };


    useEffect(() => {
        // Calcula el monto total de los conceptos seleccionados
        const calculateTotalAmount = () => {
            if (Array.isArray(selectedConcepts)) {
                const total = selectedConcepts.reduce((sum, concept) => sum + parseNumber(concept.importe), 0);

                const totalRounded = parseFloat(total.toFixed(2));

                // Solo actualiza el estado si el total calculado es diferente al total actual
                if (totalRounded !== totalAmount) {
                    setTotalAmount(totalRounded);
                }
            }
        };

        calculateTotalAmount();
    }, [selectedConcepts, totalAmount, parseNumber]);


    useEffect(() => {
        console.log(" eVALIDANDO mode " + mode);
        if (isCreating) {
            console.log(" entro a previo Clear ");
            // Resetear el estado de selectedConcepts al crear una nueva planilla
            dispatch(clearSelectedConcepts());
            dispatch(clearPlanillaSeleccionado());
        }
    }, [isCreating, dispatch]);




const handleTraerTodosConceptos = async () => {
    try {
        console.log("Iniciando la carga de conceptos...");

        const result = await dispatch(get_conceptos_facturacion(buildingId));

        if (get_conceptos_facturacion.fulfilled.match(result)) {
            let conceptos = result.payload || [];

            console.log("Conceptos obtenidos:", conceptos);

            if (!Array.isArray(conceptos)) {
                console.error("❌ Error: La respuesta de get_conceptos_facturacion no es un array:", conceptos);
                M.toast({ html: 'Error: La estructura de datos es inválida.', classes: 'red' });
                return;
            }

            if (conceptos.length === 0) {
                console.warn("⚠️ No se encontraron conceptos.");
                M.toast({ html: 'No se encontraron conceptos.', classes: 'yellow' });
                return;
            }

            // 🔹 Convertimos "importe_defecto" a "importe"
            conceptos = conceptos.map(concepto => ({
                ...concepto,
                importe: concepto.importe_defecto !== undefined ? concepto.importe_defecto : 0
            }));

            // Actualiza Redux con los nuevos conceptos
            dispatch(updateSelectedConcepts(conceptos));

            // Espera brevemente para que el estado se actualice en Redux antes de forzar el re-render
            setTimeout(() => {
                console.log("✔️ Actualizando estado local con conceptos:", conceptos);
                setConcepts([...conceptos]); // Forzar re-render
            }, 100);

            M.toast({ html: 'Todos los conceptos han sido cargados correctamente.', classes: 'green' });
        } else {
            console.error("❌ Error en get_conceptos_facturacion:", result);
            M.toast({ html: 'Error al cargar los conceptos.', classes: 'red' });
        }
    } catch (error) {
        console.error("❌ Error inesperado en handleTraerTodosConceptos:", error);
        M.toast({ html: 'Error al cargar los conceptos.', classes: 'red' });
    }
};

// Mantén el estado local sincronizado con Redux
useEffect(() => {
    console.log("📌 Sincronizando selectedConcepts con el estado local:", selectedConcepts);

    if (Array.isArray(selectedConcepts)) {
        setConcepts([...selectedConcepts]);
    } else {
        console.error("❌ selectedConcepts no es un array en useEffect:", selectedConcepts);
        setConcepts([]); // Asegurar que siempre sea un array
    }
}, [selectedConcepts]);
    
    

    const defaultValues = {
        estado: ESTADOS_PLANILLA[0].value,             // Asume que 'COMUN' es un valor válido
        tipo_planilla: TIPOS_PLANILLA[0].value,  // Primer valor de tus options
        moneda: { codigo: 'PEN', simbolo: 'S/' }, // Moneda predeterminada
    };

    // Obtener el periodo actual al iniciar el componente
    const periodoActual = formatPeriodo(new Date());

    const [formData, setFormData] = useState({
        nota: '',
        total_planilla: 0,
        recibos_planilla: 0,
        periodo: periodoActual,
        fecha_emision: new Date(),
        fecha_vencimiento: new Date(),
        fecha_corte: new Date(),
        metodo_notificacion: [],
        ...defaultValues
    });

    useEffect(() => {
        if (isCreating) {


            if (edificioSeleccionado) {
                const totalRecibos = edificioSeleccionado.unidades_facturacion_asignadas || 0;

                setFormData((prevData) => ({
                    ...prevData,
                    recibos_planilla: totalRecibos,
                }));
            }
        }
    }, [edificioSeleccionado, isCreating]);

    useEffect(() => {
        if (planilla && idPlanilla) {
            console.log(" ESTA ENTRANDO POR AQUI EN LA CREACION?");
            setFormData({
                periodo: planilla.periodo,
                tipo_planilla: planilla.tipo_planilla,
                total_planilla: planilla.total_planilla,
                recibos_planilla: planilla.recibos_planilla,
                nota: planilla.nota,
                estado: planilla.estado,
                moneda: planilla.moneda,
                fecha_emision: planilla.fecha_emision,
                fecha_vencimiento: planilla.fecha_vencimiento,
                fecha_corte: planilla.fecha_corte
            });

            //setIsEditing(false); // View mode by default
            var elems = document.querySelectorAll('select');
            M.FormSelect.init(elems);
            M.updateTextFields();
        }
    }, [planilla, idPlanilla]);

    useEffect(() => {
        console.log(" OJO OJO mode " + mode);
        if (mode !== 'create') {
            if (planilla && planilla.conceptos_facturacion) {
                console.log(" OJO OJO enviando actualizar ");
                // Actualiza el estado de Redux con los conceptos de la planilla
                dispatch(updateSelectedConcepts(planilla.conceptos_facturacion));

            }
        }
    }, [planilla, mode, dispatch]);


    useEffect(() => {
        //console.log("AQUI ? OJO mode: " + mode + " idPlanilla: " + idPlanilla);
        if (mode !== 'create') {
            //console.log("traera planilla: ");
            dispatch(get_planilla(idPlanilla));
            //console.log("Luego de traera planilla: ");
        }
    }, [dispatch, idPlanilla, mode]);

    useEffect(() => {
        // Actualiza los campos de texto de Materialize cuando el componente se monta
        M.updateTextFields();
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [isEditing]);

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [TIPOS_PLANILLA]);  // Dependencia para asegurar que se reinicialice cuando las opciones cambian

    useEffect(() => {
        //if (!isEditing) {
        if (mode === 'create') {
            setFormData(prevState => ({
                ...prevState,
                ...defaultValues
            }));
        }
    }, [isEditing]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleDateChange = (name, date) => {
        setFormData(prev => ({ ...prev, [name]: date }));
    };
    const handlePeriodoChange = (date) => {
        const periodoFormateado = formatPeriodo(date); // Convierte la fecha seleccionada a YYYY-MM
        console.log("periodoFormateado " + periodoFormateado);
        setFormData((prev) => ({ ...prev, periodo: periodoFormateado }));
    };
    const handleMonedaChange = (e) => {
        const selectedMoneda = MONEDAS.find(moneda => moneda.value === e.target.value);
        setFormData({
            ...formData,
            moneda: {
                codigo: selectedMoneda.value,
                simbolo: selectedMoneda.label
            }
        });
    };


    const handleRecrearRecibos = async () => {
        console.log("entro Recrear recibos: isEditing: " + isEditing + " idPlanilla " + idPlanilla);
        if (!isEditing) {
            if (mode !== 'create') {
                console.log("Antes de despachar");
                const resultAction = await dispatch(recrear_recibos(idPlanilla));
                //dispatch(get_planillas_facturacion(buildingId)); // Refetch la lista de planillas
                if (recrear_recibos.fulfilled.match(resultAction)) {
                    console.log("Eentro por ok")
                    M.toast({ html: 'Planilla de recibos actualizada exitosamente.', classes: 'green' });
                } else if (recrear_recibos.rejected.match(resultAction)) {
                    const errorMessage = recrear_recibos || '-Ocurrió un error al actualizar la planilla.';
                    //M.toast({ html: resultAction.payload.error, classes: 'red' });
                    M.toast({ html: errorMessage, classes: 'red' });
                    return;
                } else {
                    console.log("Eentro por ELSE update " + JSON.stringify(resultAction.payload))
                }
            }
            dispatch(clearSelectedConcepts());
            dispatch(clearPlanillaSeleccionado());
        }

        navigate('/planillas-recibos')
    };
    const handleActualizarFacturacionAgua = async () => {
        console.log("entro Actualizar Agua: isEditing: " + isEditing);
        if (!isEditing) {
            if (mode !== 'create') {
                console.log("Antes de despachar");
                const resultAction = await dispatch(update_facturacion_agua_planilla(idPlanilla));
                //dispatch(get_planillas_facturacion(buildingId)); // Refetch la lista de planillas
                if (update_facturacion_agua_planilla.fulfilled.match(resultAction)) {
                    console.log("Eentro por ok")
                    M.toast({ html: 'Planilla de recibos actualizada exitosamente.', classes: 'green' });
                } else if (update_facturacion_agua_planilla.rejected.match(resultAction)) {
                    const errorMessage = error_update_facturacion_agua_planilla || '-Ocurrió un error al actualizar la planilla.';
                    //M.toast({ html: resultAction.payload.error, classes: 'red' });
                    M.toast({ html: errorMessage, classes: 'red' });
                    return;
                } else {
                    console.log("Eentro por ELSE update " + JSON.stringify(resultAction.payload))
                }
            }
            dispatch(clearSelectedConcepts());
            dispatch(clearPlanillaSeleccionado());
        }

        navigate('/planillas-recibos')
    };
    const handleSave = async () => {
        console.log('Guardando...', planilla);
        //const updatedData = { ...formData, total_planilla: totalAmount };

        // Construye el array de conceptos incluyendo unidades_exoneradas
        const conceptosActualizados = selectedConcepts.map(concepto => ({
            ...concepto,
            unidades_exoneradas: concepto.unidades_exoneradas || [] // Asegurar que existe el array
        }));

        // Prepara la estructura de datos para el backend
        const updatedData = {
            ...formData,
            total_planilla: totalAmount,
            conceptos_facturacion: conceptosActualizados
        };

        if (isEditing) {
            if (mode === 'create') {
                //const data = { ...formData, buildingId, codigo_edificio, usuarioRegistrador: codigo_usuario };
                const data = { ...updatedData, buildingId, codigo_edificio, usuarioRegistrador: codigo_usuario };
                const resultAction = await dispatch(save_planilla(data));
                //dispatch(get_planillas_facturacion(buildingId)); // Refetch la lista de planillas
                if (save_planilla.fulfilled.match(resultAction)) {
                    console.log("Eentro por ok")
                    M.toast({ html: 'Planilla de recibos guardada exitosamente.', classes: 'green' });
                } else if (save_planilla.rejected.match(resultAction)) {
                    const errorMessage = error_save_planilla || '-Ocurrió un error al guardar la planilla.';
                    //M.toast({ html: resultAction.payload.error, classes: 'red' });
                    M.toast({ html: errorMessage, classes: 'red' });
                    return;
                } else {
                    console.log("Eentro por ELSE " + JSON.stringify(resultAction.payload))
                }
            } else {

                //const data = { ...formData, usuarioActualizacion: codigo_usuario };
                const data = { ...updatedData, usuarioActualizacion: codigo_usuario };
                //dispatch(update_planilla({ idPlanilla, planillaData: formData }));
                const resultAction = await dispatch(update_planilla({ idPlanilla, planillaData: data }));
                //dispatch(get_planillas_facturacion(buildingId)); // Refetch la lista de planillas
                if (update_planilla.fulfilled.match(resultAction)) {
                    console.log("Eentro por ok")
                    M.toast({ html: 'Planilla de recibos actualizada exitosamente.', classes: 'green' });
                } else if (update_planilla.rejected.match(resultAction)) {
                    const errorMessage = error_update_planilla || '-Ocurrió un error al actualizar la planilla.';
                    //M.toast({ html: resultAction.payload.error, classes: 'red' });
                    M.toast({ html: errorMessage, classes: 'red' });
                    return;
                } else {
                    console.log("Eentro por ELSE update " + JSON.stringify(resultAction.payload))
                }

            }
            dispatch(clearSelectedConcepts());
            dispatch(clearPlanillaSeleccionado());
        }

        navigate('/planillas-recibos')
    };

    const handleEdit = () => {
        //navigate(`/planilla/edit/${idPlanilla}`);
        setIsEditing(true);

    };

    // Función para manejar la anulación
    const handleAnular = () => {
        // Diálogo de confirmación
        if (window.confirm("¿Estás seguro de que quieres anular este planilla de facturación? Esta acción no se puede deshacer.")) {
            console.log('OJO idPlanilla...', idPlanilla);
            dispatch(anular_planilla({ idPlanilla, usuarioAnulacion: codigo_usuario }));
            //dispatch(get_planillas_facturacion(buildingId)); // Refetch la lista de planillas
            M.toast({ html: 'Planilla anulado correctamente', classes: 'rounded' });

            dispatch(clearSelectedConcepts());
            dispatch(clearPlanillaSeleccionado());

            navigate('/planillas-recibos');
        }
    };

    const handleVerRecibos = () => {
        // Diálogo de confirmación

        navigate(`/recibos/view/${idPlanilla}`);

    };
    // Manejar el cambio en el monto del concepto
    const handleCleaveChange = (index, value) => {
        console.log("OJOTE index " + index + " value " + value);
        // Verifica que el índice sea válido y que `selectedConcepts` no esté vacío
        if (selectedConcepts && Array.isArray(selectedConcepts) && selectedConcepts.length > index && selectedConcepts[index]) {
            // Remove the currency symbol and any non-numeric characters (like commas) from the value
            const numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));
            console.log("OJOTE numericValue " + numericValue);

            // Verifica que el valor numérico sea válido antes de actualizar
            const updatedConcepts = [...selectedConcepts];
            updatedConcepts[index] = {
                ...updatedConcepts[index],
                importe: isNaN(numericValue) ? 0 : numericValue
            };
            console.log("OJOTE updatedConcepts[index].importe " + updatedConcepts[index].importe);
            dispatch(updateConceptoImporte({ idconcepto: updatedConcepts[index].idconcepto, importe: updatedConcepts[index].importe }));
            setConcepts(updatedConcepts); // Actualiza el estado            
            calculateTotalAmount(updatedConcepts); // Recalcula el total


        } else {
            console.error('El índice del concepto es inválido o el concepto no existe.');
        }
    };


    // Calcular el monto total
    const calculateTotalAmount = (updatedConcepts) => {
        const total = updatedConcepts.reduce((sum, concept) => sum + concept.importe, 0);
        const totalRounded = parseFloat(total.toFixed(2));
        console.log("OJOTE total " + totalRounded);
        setTotalAmount(totalRounded);
    }

    const handleRetornar = () => {

        dispatch(clearSelectedConcepts());
        dispatch(clearPlanillaSeleccionado());
        navigate('/planillas-recibos');
    };
    const handleCancel = () => {
        setIsEditing(false);

        dispatch(clearSelectedConcepts());
        dispatch(clearPlanillaSeleccionado());

        if (mode === 'create') {
            navigate('/planillas-recibos');
        }

        /*
        if (mode === 'edit') {
            setIsEditing(false);
            // Opcionalmente, restablece el formulario aquí
        } else if (mode === 'create') {
            // Limpia el formulario o redirige según corresponda
            navigate('/planillas-facturacion');
        }
        */
    };
    return (
        <div className="container">
            <div>
                <div className="card">
                    <div className="card-content">
                        <div className="card-action">
                            {isEditing ? (
                                <>
                                    <a className="btn green tooltipped" data-position="top" data-tooltip="Guardar cambios" onClick={handleSave}>
                                        <i className="material-icons left">save</i>Guardar
                                    </a>
                                    <a className="btn red tooltipped" data-position="top" data-tooltip="Cancelar edición" onClick={handleCancel}>
                                        <i className="material-icons left">cancel</i>Cancelar
                                    </a>
                                </>
                            ) : (
                                <>
                                    <a className="btn green tooltipped" data-position="top" data-tooltip="Editar detalles" onClick={handleEdit} >
                                        <i className="material-icons left">edit</i>Editar
                                    </a>
                                    <a className="btn orange tooltipped" data-tooltip="Anular Planilla" onClick={handleAnular}>
                                        <i className="material-icons left">delete</i>Anular
                                    </a>
                                </>
                            )}


                            <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={handleRetornar}>
                                <i className="material-icons left">arrow_back</i>
                            </a>
                        </div>

                        <span className="card-title">{mode === 'create' ? 'Crear Planilla' : mode === 'edit' ? 'Editar Planilla' : 'Detalles del Planilla'}</span>
                        <form onSubmit={e => e.preventDefault()}>

                            <div className="row">

                                <div className="input-field col s6 m3">
                                    <select name="tipo_planilla" value={formData.tipo_planilla} onChange={handleInputChange} disabled={!isEditing}>
                                        {TIPOS_PLANILLA.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="tipo_planilla">Tipo de Planilla:</label>
                                </div>
                                <div className="input-field col s6 m3">
                                    <input
                                        type="text"
                                        name="estado"
                                        value={formData.estado || ''}
                                        //onChange={handleInputChange}
                                        disabled={true}
                                    />
                                    <label htmlFor="estado" className="active">Estado</label>
                                </div>
                                <div className="input-field col s6 m1">
                                    <select name="moneda" value={formData.moneda.codigo} onChange={handleMonedaChange} disabled={!isEditing}>
                                        {MONEDAS.map(moneda => (
                                            <option key={moneda.value} value={moneda.value}>{moneda.label}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="moneda">Moneda</label>
                                </div>
                                <div className="input-field col s6 m2">
                                    <DatePicker
                                        selected={parseFromYYYYMM(formData.periodo)}
                                        onChange={handlePeriodoChange}
                                        showMonthYearPicker
                                        dateFormat="MM/yyyy"
                                        name="periodo"
                                        disabled={!isEditing}
                                        locale="es" // Establece el locale aquí
                                    />
                                    <label className="active" htmlFor="periodo">Periodo:</label>
                                </div>

                                <div className="input-field col s6 m2">
                                    <DatePicker
                                        selected={formData.fecha_emision}
                                        onChange={date => handleDateChange('fecha_emision', date)}
                                        name="fecha_emision"
                                        dateFormat="dd/MM/yyyy"
                                        locale="es"
                                        disabled={!isEditing}
                                    />
                                    <label className="active" htmlFor="fecha_emision">Emisión:</label>
                                </div>
                                <div className="input-field col s6 m2">
                                    <DatePicker
                                        selected={formData.fecha_vencimiento}
                                        onChange={date => handleDateChange('fecha_vencimiento', date)}
                                        name="fecha_vencimiento"
                                        dateFormat="dd/MM/yyyy"
                                        locale="es"
                                        disabled={!isEditing}
                                    />
                                    <label className="active" htmlFor="fecha_vencimiento">Vencimiento:</label>
                                </div>
                                <div className="input-field col s6 m2">
                                    <DatePicker
                                        selected={formData.fecha_corte}
                                        onChange={date => handleDateChange('fecha_corte', date)}
                                        name="fecha_corte"
                                        dateFormat="dd/MM/yyyy"
                                        locale="es"
                                        disabled={!isEditing}
                                    />
                                    <label className="active" htmlFor="fecha_corte">Corte:</label>
                                </div>

                                <div className="input-field col s6 m3">
                                    <input
                                        type="text"
                                        name="nota"
                                        value={formData.nota || ''}
                                        onChange={handleInputChange}
                                        disabled={!isEditing}
                                    />
                                    <label htmlFor="nota" className="active">Nota</label>
                                </div>
                                <div className="input-field col s6 m2">
                                    <Cleave
                                        placeholder="0.00"
                                        name="total_planilla"
                                        value={formData.total_planilla || 0}
                                        options={{ numeral: true, numeralDecimalMark: '.', numeralThousandsGroupStyle: 'thousand' }}
                                        readOnly
                                    />
                                    <label className="active" htmlFor="total_planilla">Total Planilla</label>
                                </div>
                                <div className="input-field col s6 m2">
                                    <input
                                        type="number"
                                        name="recibos_planilla"
                                        value={formData.recibos_planilla}
                                        readOnly
                                    />
                                    <label htmlFor="recibos_planilla" className="active">Total Recibos</label>
                                </div>

                            </div>
                            <div className="card-action">
                                {isEditing ? (
                                    <>
                                        <a className="btn green tooltipped" data-position="top" data-tooltip="Añadir Conceptos" onClick={handleAddConcepts}>
                                            <i className="material-icons left">save</i>Añadir Conceptos
                                        </a>
                                        {/*
                                        <a className="btn blue tooltipped" data-tooltip="Traer Todos los Conceptos" onClick={handleTraerTodosConceptos}>
                                            <i className="material-icons left">refresh</i>Traer Todos los Conceptos
                                        </a>*/}
                                    </>
                                ) : (
                                    <>
                                        <a className="btn orange tooltipped" data-tooltip="Recibos" onClick={handleVerRecibos}>
                                            <i className="material-icons left">compare_arrows</i>Recibos
                                        </a>
                                        <a className="btn orange tooltipped" data-tooltip="Actualizar Facturación Agua" onClick={handleActualizarFacturacionAgua}>
                                            <i className="material-icons left">compare_arrows</i>Act. Fact. Agua
                                        </a>
                                        <a className="btn orange tooltipped" data-tooltip="Recrear Recibos" onClick={handleRecrearRecibos}>
                                            <i className="material-icons left">compare_arrows</i>Recrear Recibos
                                        </a>
                                    </>
                                )}


                                <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={handleRetornar}>
                                    <i className="material-icons left">arrow_back</i>
                                </a>
                            </div>

                            <h3>Conceptos de Facturación Seleccionados</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Concepto</th>
                                        <th>Descripción</th>
                                        <th>Tipo</th>
                                        <th>Criterio</th>
                                        <th>Monto</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(Array.isArray(selectedConcepts) && selectedConcepts.length > 0) ? (
                                        selectedConcepts.map((concept, index) => (
                                            <tr key={index}>
                                                <td>{concept.tipo_concepto}</td>
                                                <td>{concept.titulo}</td>
                                                <td>{concept.tipo_facturacion}</td>
                                                <td>{concept.criterio_asignacion_cuota}</td>
                                                <td>
                                                    <Cleave
                                                        name="importe"
                                                        value={concept.importe}
                                                        onChange={(e) => handleCleaveChange(index, e.target.value)}
                                                        options={{
                                                            numeral: true,
                                                            numeralThousandsGroupStyle: 'thousand',
                                                            numeralDecimalMark: '.',
                                                            numeralDecimalScale: 2,
                                                            prefix: concept.moneda.simbolo + ' '
                                                        }}
                                                        disabled={!isEditing || !(concept.fuente_datos === "INGRESO_MANUAL") || (concept.criterio_asignacion_cuota === "FACTURACION_INDIVIDUAL")}
                                                    />
                                                </td>
                                                <td>
                                                    <button
                                                        onClick={() => dispatch(removeConcept(concept))}
                                                        disabled={!isEditing} // Deshabilita el botón si no está en modo de edición
                                                    >
                                                        Eliminar
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3">No hay conceptos seleccionados.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <h3>Total Monto: {formatNumber(totalAmount)}</h3>

                            <SelectConceptsModal
                                isOpen={modalIsOpen}
                                onRequestClose={handleCloseModal}
                            />

                        </form>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default PlanillaForm;
