import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';



export const get_cuenta_banco = createAsyncThunk('cuenta_banco/get_cuenta_banco', async (id, thunkAPI) => {
    console.log("ejecutando thunk get_cuenta_banco con id: " + id);

    try {

        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        //parametro pasado por path
        const response = await apiClient.get(`/cuentas_banco/${id}`);
        //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
        const cuenta_banco = response.data;

        return cuenta_banco;
    } catch (error) {
        console.log("error.response.data " + error.response.data);
        return thunkAPI.rejectWithValue(error.response.data);
    }
});


export const get_cuentas_banco = createAsyncThunk('cuenta_banco/get_cuentas_banco', async ({ buildingId, estado }, thunkAPI) => {
    console.log("ejecutando thunk get_cuentas_banco con buildingId: " + buildingId);

    try {
        //console.log("ejecutando thunk buildingId: "+buildingId);      
        const response = await apiClient.get('/cuentas_banco', {
            params: {
                buildingId,
                estado,
            },
        });
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const cuentas_banco = response.data;

        return cuentas_banco;
    } catch (error) {
        console.log("error.response.data " + error.response.data);
        return thunkAPI.rejectWithValue(error.response.data);
    }
});
