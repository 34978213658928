import { createSlice } from '@reduxjs/toolkit';
import {get_residentes_x_edificio} from './residenteThunk';

const residenteSlice = createSlice({
    name: 'residente',
    initialState: {
      residentes: [],
      fetchStatus : 'idle',
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
          .addCase(get_residentes_x_edificio.pending, (state) => {
              state.fetchStatus  = 'loading';
          })
          .addCase(get_residentes_x_edificio.fulfilled, (state, action) => {
              state.fetchStatus  = 'succeeded';
              //console.log("Depas cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.residentes = action.payload;

          })
          .addCase(get_residentes_x_edificio.rejected, (state, action) => {
            console.log("ejecutando Slice failed rejected ");
              state.fetchStatus  = 'failed';              
              console.log("error action.error.message "+action.error.message );
              state.error = action.payload || 'Failed to fetch receipts';
          });
    },
  });
    
  export const selectResidenteById = (state, residenteId) =>
    state.residente.departamentos.find((residente) => residente.id === residenteId);
  
  export default residenteSlice.reducer;
