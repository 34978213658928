import React, { useEffect, useState  } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {get_depas_x_edificio} from './departamentoThunk';
import M from 'materialize-css';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

import {formatDate02} from '../../utils/formateo';


const Depas_edificio = () => {
  useMaterializeTooltips();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);  
  const edificio = useSelector(state => state.edificio.edificio);
  //const codigo_edificio = edificio?.id;
  const departamentos = useSelector((state) => state.departamento.departamentos);
  const usuario = useSelector((state) => state.auth.usuario);
  const status_get_depas_x_edificio  = useSelector((state) => state.departamento.status_get_depas_x_edificio );    

  //const { departamentos, status_get_depas_x_edificio, error_get_depas_x_edificio } = useSelector((state) => state.departamento);

  const error = useSelector((state) => state.departamento.error);
  const [filtro, setFiltro] = useState('');
  
  //console.log("en mis recibos isAuthenticated: "+isAuthenticated);
  //console.log("status_get_depas_x_edificio: "+status_get_depas_x_edificio);
  //console.log("JSON.stringify(edificio): "+JSON.stringify(edificio));
  useEffect(() => {
    if (!isAuthenticated) {
        //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    //console.log("ANTES de traera datos status_get_depas_x_edificio: "+status_get_depas_x_edificio);
    if (status_get_depas_x_edificio  === 'idle' && edificio.id) {
      //console.log("traera datos ");
      dispatch(get_depas_x_edificio(edificio.id));
      
    }

  }, [isAuthenticated, navigate, status_get_depas_x_edificio , edificio, dispatch]);

  if (status_get_depas_x_edificio  === 'loading') {
    return <div>Loading...</div>;
  }
/*
  if (fetchStatus  === 'failed') {
    return <div>Error: {error.error}</div>;
    
  }
  */
  if (!edificio.id) {
    return <div>No se ha seleccionado un edificio.</div>;
  }
  
  const handleRefresh = () => {       
    if ( edificio) {
      dispatch(get_depas_x_edificio(edificio.id));
      
    }     
  };
  
  const formatearDepas = (depas) => {
    if (!Array.isArray(depas)) {
      return []; // Retorna un arreglo vacío si depas no es un arreglo
    }

    return depas.map(depa => ({
      ...depa,
      fecha_registro_formato01: formatDate02(depa.timestamp_registro),
    }));
  };
  
  const depasFormateados = formatearDepas(departamentos);

  const handleFilterChange = (e) => {    
    setFiltro(e.target.value);
    
  };

  const depasFiltrados = Array.isArray(depasFormateados) ? depasFormateados.filter((depa) => {
    
    const filterLowerCase = filtro.toLowerCase();
    return depa.tipo.includes(filterLowerCase) ||
        depa.numero.toLowerCase().includes(filterLowerCase) ||
        depa.nivel.toString().toLowerCase().includes(filterLowerCase) ||
        depa.tipo_nivel.toString().toLowerCase().includes(filterLowerCase) ||
        depa.area_ocupada.toString().toLowerCase().includes(filterLowerCase) ||
        depa.area_techada.toString().toLowerCase().includes(filterLowerCase) ||
        depa.fecha_registro_formato01.toString().includes(filterLowerCase)  
      ;
  })
  : [];
  
  return (    
    <div className="container">
        <h5>Depas</h5>
        <a className="btn green tooltipped" data-position="top" data-tooltip="Refrescar vista" onClick={() => handleRefresh()}>
              <i className="material-icons left">refresh</i>Refrescar
        </a>
        <a className="btn orange tooltipped" data-position="top" data-tooltip="Cargar Depas" onClick={() => navigate('/carga-depas')}>
              <i className="material-icons left">cloud_upload</i>Cargar Depas
        </a>        
        <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/edificio')}>
              <i className="material-icons left">arrow_back</i>
        </a>        
       
        <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_depas_cargados"><i className="material-icons">search</i> Filtrar</label>
            <input type="text" id="filtro_tabla_depas_cargados" 
             value={filtro}
          onChange={handleFilterChange} />           
        </div>
    <table className="striped responsive-table tabla_depas_cargados">
      <thead>
        <tr>
          <th>Edificio</th>
          <th>Tipo</th>
          <th>Depa.</th>
          <th>Nivel</th>
          <th>Tip_niv</th>          
          <th>Area_ocupada</th>
          <th>Area_techada</th>                    
          <th>usuario</th>
          <th>Id. carga</th>
          <th>Registro</th>
        </tr>
      </thead>
      <tbody id="table-body-depas-cargados">                    
        {depasFiltrados.map((depa) => (
          <tr key={depa.id}>
            <td>{depa.codigo_edificio}</td>
            <td>{depa.tipo}</td>
            <td>{depa.numero}</td>
            <td>{depa.nivel}</td>
            <td>{depa.tipo_nivel}</td>              
            <td>{depa.area_ocupada}</td>
            <td>{depa.area_techada}</td>
            <td>{depa.usuario_carga}</td>
            <td>{depa.uid_carga}</td>
            <td>{depa.fecha_registro_formato01}</td>
          </tr>
          ))}
      
      </tbody>
    </table>    
    </div>
  );
};

export default Depas_edificio;
