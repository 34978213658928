import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';

export const get_unidades_inmobiliarias_x_tipo = createAsyncThunk('unidades_inmobiliarias/get_unidades_inmobiliarias_x_tipo', async ({ buildingId, tipo_unidad_inmobiliaria }, thunkAPI) => {
  console.log("ejecutando thunk get_requerimientos_x_depa con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/unidades-inmobiliarias',{
        params: { 
          buildingId, 
          tipo_unidad_inmobiliaria
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const unidades_inmobiliarias = response.data;
          
      return unidades_inmobiliarias ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const get_unidades_inmobiliarias_habitables_x_depa = createAsyncThunk('unidades_inmobiliarias/get_unidades_inmobiliarias_habitables_x_depa', async ({ buildingId, codigo_unidad_facturacion }, thunkAPI) => {
  console.log("ejecutando thunk get_unidades_inmobiliarias_habitables_x_depa con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/unidades-inmobiliarias',{
        params: { 
          buildingId,
          habitable: "SI",
          codigo_unidad_facturacion
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const unidades_inmobiliarias = response.data;
          
      return unidades_inmobiliarias ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const get_unidades_inmobiliarias = createAsyncThunk('unidades_inmobiliarias/get_unidades_inmobiliarias', async (codigo_edificio, thunkAPI) => {    
    
    try { 
        console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.get(`/edificios/${codigo_edificio}/unidades-inmobiliarias`);
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const unidades_inmobiliarias = response.data;
            
        return unidades_inmobiliarias ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  export const update_unidad_inmobiliaria = createAsyncThunk('unidad_inmobiliaria/update_unidad_inmobiliaria', async ({ idUnidad_inmobiliaria, unidad_inmobiliariaData }, thunkAPI) => {
    //console.log("ejecutando thunk update_unidad_inmobiliaria con idUnidad_inmobiliaria: " + idUnidad_inmobiliaria);
  
    try {
  
      const response = await apiClient.put(`/unidades-inmobiliarias/${idUnidad_inmobiliaria}`, unidad_inmobiliariaData);
  
      const unidad_inmobiliaria = response.data;
  
      // Despachar acción para resetear el estado de fetching
      //thunkAPI.dispatch(resetStateGetUnidad_inmobiliarias(unidad_inmobiliaria.buildingId));// Resetear el estado para refetch de unidad_inmobiliarias
  
  
      return unidad_inmobiliaria;
  
    } catch (error) {
      console.log("error.response.data " + error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
  
  export const save_unidad_inmobiliaria = createAsyncThunk('unidad_inmobiliaria/save_unidad_inmobiliaria', async (unidad_inmobiliariaData, thunkAPI) => {
  
    try {
      console.log("antes de invocación de post creacion de unidad_inmobiliaria")
      const response = await apiClient.post(`/unidades-inmobiliarias`, unidad_inmobiliariaData);
  
      const unidad_inmobiliaria = response.data;
  
      // Despachar acción para resetear el estado de fetching
      //thunkAPI.dispatch(resetStateGetUnidad_inmobiliarias(unidad_inmobiliaria.buildingId));// Resetear el estado para refetch de unidad_inmobiliarias
  
      return unidad_inmobiliaria;
  
    } catch (error) {
      console.log("error.response.data " + error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
  
  export const anular_unidad_inmobiliaria = createAsyncThunk('unidad_inmobiliaria/anular_unidad_inmobiliaria', async ({ idUnidad_inmobiliaria, usuarioAnulacion }, thunkAPI) => {
    //console.log("ejecutando thunk anular_unidad_inmobiliaria con idUnidad_inmobiliaria: " + idUnidad_inmobiliaria);
  
    try {
  
      const response = await apiClient.patch(`/unidades-inmobiliarias/${idUnidad_inmobiliaria}/anular`, { usuarioAnulacion });
  
      const unidad_inmobiliaria = response.data;
  
      // Despachar acción para resetear el estado de fetching
      //thunkAPI.dispatch(resetStateGetUnidad_inmobiliarias(unidad_inmobiliaria.buildingId));// Resetear el estado para refetch de unidad_inmobiliarias
  
      return unidad_inmobiliaria;
  
    } catch (error) {
      console.log("error.response.data " + error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
  
  
  export const get_unidad_inmobiliaria = createAsyncThunk('unidad_inmobiliaria/get_unidad_inmobiliaria', async (id, thunkAPI) => {
    console.log("ejecutando thunk get_unidad_inmobiliaria con id: " + id);
  
    try {
  
      //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
      //parametro pasado por path
      const response = await apiClient.get(`/unidades-inmobiliarias/${id}`);
      //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
      const unidad_inmobiliaria = response.data;
  
      return unidad_inmobiliaria;
    } catch (error) {
      console.log("error.response.data " + error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
  
  export const get_unidad_inmobiliaria_x_unidad  = createAsyncThunk('unidad_inmobiliaria/get_unidad_inmobiliaria_x_unidad', async ({ buildingId, tipo_unidad_inmobiliaria, numero_unidad_inmobiliaria }, thunkAPI) => {
    //console.log("ejecutando thunk get_persona con id: " + id);
  
    try {
  
      //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
      //parametro pasado por path
  
      const response = await apiClient.get('/unidades-inmobiliarias/unidad',{
        params: { 
          buildingId, 
          tipo_unidad_inmobiliaria, 
          numero_unidad_inmobiliaria 
        }
    });
  
      //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
      const unidad_inmobiliaria = response.data;
  
      return unidad_inmobiliaria;
    } catch (error) {
      console.log("error.response.data " + error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });