import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Login from './features/login/LoginPage';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css'; // Importa Materialize CSS
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
//import {USUARIO_PERFIL_Recaudador, USUARIO_PERFIL_Aportante, , USUARIO_PERFIL_JuntaDirectiva, USUARIO_PERFIL_Conserje, USUARIO_PERFIL_Limpieza, USUARIO_PERFIL_Cochera} from './constants/constants';
import MisRecibos from './features/mis_recibos/MisRecibosPage';
import ReciboDetalle from './features/mis_recibos/ReciboDetallePage';
import EdificioForm from './features/edificio/edificioFormPage';
import EdificioIngreso from './features/edificio/edificioIngresoPage';
import Depas_edificio from './features/edificio/depas-edificioPage';
import Propiedades_edificio from './features/edificio/propiedades-edificioPage';
import Unidades_facturacion_edificio from './features/edificio/unidades-facturacion-edificioPage';
import Config_unidades_facturacion from './features/edificio/config-unidades-facturacionPage';
import Unidad_inmobiliariaForm from './features/edificio/unidad_inmobiliariaFormPage.js';
import Unidad_facturacionForm from './features/edificio/unidad_facturacionFormPage.js';
import Depositos_edificio from './features/edificio/depositos-edificioPage';
import Estacionamientos_edificio from './features/edificio/estacionamientos-edificioPage';
import Residentes_edificio from './features/edificio/residentes-edificioPage';
import Personas_edificio from './features/edificio/personas-edificioPage';
import PersonaForm from './features/edificio/personaFormPage';
import Carga_lista_departamentos from './features/carga-edificio/carga-lista-depasPage';
import Carga_lista_estacionamientos from './features/carga-edificio/carga-lista-estacionamientosPage';
import Carga_lista_residentes from './features/carga-edificio/carga-lista-residentesPage';
import Carga_lista_personas from './features/carga-edificio/carga-lista-personasPage';
import Carga_lista_depositos from './features/carga-edificio/carga-lista-depositosPage';
import Carga_lista_propiedades from './features/carga-edificio/carga-lista-propiedadesPage';
import Carga_lista_config_unidades_facturacion from './features/carga-edificio/carga-lista-config-unidades-facturacionPage';
import Carga_lista_unidades_inmobiliarias from './features/carga-edificio/carga-lista-unidades-inmobiliariasPage';
import Carga_lista_unidades_facturacion from './features/carga-edificio/carga-lista-unidades-facturacionPage';
import Unidades_inmobiliarias from './features/edificio/unidades-inmobiliariasPage';
import MisPlanillas_recibos from './features/planilla_recibos/MisPlanillas_recibosPage';
import MisReservas_sum from './features/mis-areas-comunes/MisReservasSUM';
import MisReservas from './features/mis-areas-comunes/MisReservas';
import MisFacturaciones_individuales from './features/facturacion_concepto/MisFacturaciones_individualesPage.js';
import MisRequerimientos from './features/requerimientos/MisRequerimientosPage';
import MisLecturas_agua from './features/facturacion-agua/MisLecturas_aguaPage';
import MisFacturaciones_agua from './features/facturacion-agua/MisFacturaciones_aguaPage';
import Lectura_aguaForm from './features/facturacion-agua/lectura_aguaFormPage';
import LecturaRapidaContometro from './features/facturacion-agua/LecturaRapidaContometro.js';
import Reserva_sumForm from './features/mis-areas-comunes/reserva_sumFormPage';
import ReservaForm from './features/mis-areas-comunes/reservaFormPage';
import Calendario_reservasPage from './features/mis-areas-comunes/calendarioReservasPage';
import Facturacion_aguaForm from './features/facturacion-agua/facturacion_aguaFormPage';
import MisConceptos_facturacion from './features/conceptos-facturacion/MisConceptos_facturacionPage';
import Logout from './features/login/logoutPage';
import RecuperarPassword from './features/login/recuperarPasswordPage';
import MisEdificios from './features/mis-edificios/MisEdificiosPage';
import ChangePassword from './features/login/cambiarPasswordPage';
import DashboardPage from './components/DashboardPage';
import MainLayout from './components/mainLayoutPage';
import RecibosPage from './features/planilla_recibos/recibosPage';
import ConceptoForm from './features/conceptos-facturacion/conceptoFormPage';
import PlanillaForm from './features/planilla_recibos/planillaFormPage';
import MisSuministros from './features/edificio/MisSuministrosPage';
import SuministroForm from './features/edificio/suministroFormPage';
import ListaZonas_seguras from './features/zonas_seguras/ListaZonas_segurasPage';
import MisZonas_seguras from './features/zonas_seguras/MisZonas_segurasPage';
import Zona_seguraForm from './features/zonas_seguras/zona_seguraFormPage';
import { TIPO_AREA_COMUN_SUM, TIPO_AREA_COMUN_PARRILLA, TIPO_AREA_COMUN_GIMNASIO, TIPO_AREA_COMUN_SALA_YOGA } from './constants/constants';
import Facturacion_individualForm from './features/facturacion_concepto/facturacion_individualFormPage.js';
import RequerimientoForm from './features/requerimientos/requerimientoFormPage';
import AdminProtocolos from './features/protocolos/AdminProtocolos.js';
import Mis_protocolos_emergencia from './features/protocolos/MisProtocolosEmergenciaPage.js';
import AdminMedicamentos from './features/medicamentos/AdminMedicamentosPage.js';
import MedicamentosPage from './features/medicamentos/MedicamentosPage.js';
import RegistroAsistenciaEspecialPage from './features/personas-atencion/RegistroAsistenciaEspecialPage.js';
import ListaPersonasAsistenciaPage from './features/personas-atencion/ListaPersonasAsistenciaPage.js';
import Persona_asistenciaForm from './features/personas-atencion/persona_asistenciaFormPage.js';
import ContactosReferenciaPage from './features/contactos-referencia/ContactosReferenciaPage.js';
import PreferencesPage from './features/preferencias/PreferencesPage.js';
import MisPublicacionesPage from './features/publicaciones/MisPublicacionesPage.js';
import PublicacionPage from './features/publicaciones/PublicacionPage.js';
import PublicacionDetallePage from './features/publicaciones/PublicacionDetallePage.js';
import MisPedidosPage from './features/publicaciones/MisPedidosPage.js';
import ListaReunionesPage from './features/reuniones/ListaReunionesPage.js';
import ReunionPage from './features/reuniones/ReunionPage.js';
import MisReunionesPage from './features/reuniones/MisReunionesPage.js';
import MisAsistenciasPage from './features/reuniones/MisAsistenciasPage.js';
import MisJustificacionesPage from './features/reuniones/MisJustificacionesPage.js';
import JustificacionFormPage from './features/reuniones/JustificacionFormPage.js';
import ListaEncuestasPage from './features/encuestas/ListaEncuestasPage.js';
import AdminEncuestaPage from './features/encuestas/AdminEncuestaPage.js';
import MisEncuestasPage from './features/encuestas/MisEncuestasPage.js';
import EncuestaFormPage from './features/encuestas/EncuestaFormPage.js';
import RespuestaFormPage from './features/encuestas/RespuestaFormPage.js';
import ListaContenidosPage from './features/contenido/listaContenidosPage.js';
import AdminContenidoPage from './features/contenido/AdminContenidoPage.js';
import MostrarContenidoPage from './features/contenido/MostrarContenidoPage.js';
import ActivosPage from './features/activos/ActivosPage.js';
import MiCuentaPage from './features/edificio/MiCuentaPage.js';
import ListaPlantillasActivoPage from './features/activos/ListaPlantillaActivosPage.js';
import PlanMantenimientoPage from './features/activos/PlanMantenimientoPage.js';
import Home from './features/home/homePage.js'
import Beneficios from './features/home/beneficiosPage';
import Como_funciona from './features/home/como-funcionaPage';
import Contactanos from './features/home/contactanosPage';
import Preguntas from './features/home/preguntasPage';
import Ser_recaudador from './features/home/ser-recaudadorPage';
import Calendario_reservasSUM from './features/mis-areas-comunes/calendarioReservasSUM.js';
import Calendario_reservasParrilla from './features/mis-areas-comunes/calendaioReservasParrilla.js';
import Calendario_reservasGYM from './features/mis-areas-comunes/calendarioReservasGYM.js';
import Calendario_reservasYoga from './features/mis-areas-comunes/calendarioReservasYoga.js';
import MisReservasAdmin from './features/mis-areas-comunes/MisReservasAdmin.js';
import PrintExample from './features/mis-areas-comunes/PrintExample.js';
import MisRecibosAdmin from './features/mis_recibos/MisRecibosAdmin.js';
import MovimientosBancarios from './features/banco/MovimientosBancariosPage.js';
import CargaCREPsPage from './features/banco/cargaCREPPage.js';
import MovimientosCREP from './features/banco/MovimientosCREPPage.js';
import MisFacturaciones_masivas from './features/facturacion_concepto/MisFacturaciones_masivasPage.js';
import Facturacion_masivaForm from './features/facturacion_concepto/facturacion_masivaFormPage.js';
import ReservaFormAdmin from './features/mis-areas-comunes/reservaFormAdmin.js';
import ReciboDetalleAdmin from './features/mis_recibos/ReciboDetalleAdminPage.js';
//import { API_URL, APP_NAME } from './constants';
import ProtectedRoute from "./components/ProtectedRoute";

function App() {

  return (
    <Router>
      <Routes>
        {/* Rutas Públicas */}
        <Route path="/" exact element={
          <MainLayout showFooter={true}>
            <Home />
          </MainLayout>} />
        <Route path="/home" exact element={
          <MainLayout showFooter={true}>
            <Home />
          </MainLayout>} />
        <Route path="/beneficios" exact element={
          <MainLayout showFooter={true}>
            <Beneficios />
          </MainLayout>} />

        <Route path="/como-funciona" exact element={
          <MainLayout showFooter={true}>
            <Como_funciona />
          </MainLayout>} />

        <Route path="/contactanos" exact element={
          <MainLayout showFooter={true}>
            <Contactanos />
          </MainLayout>} />

        <Route path="/preguntas" exact element={
          <MainLayout showFooter={true}>
            <Preguntas />
          </MainLayout>} />

        <Route path="/ser-recaudador" exact element={
          <MainLayout showFooter={true}>
            <Ser_recaudador />
          </MainLayout>} />
        <Route path="/ficus" exact element={
          <MainLayout showFooter={false}>
            <Login />
          </MainLayout>} />
        <Route path="/login" exact element={
          <MainLayout showFooter={false}>
            <Login />
          </MainLayout>} />
          <Route path="/forgot-password" exact element={
          <MainLayout showFooter={false}>
            <RecuperarPassword />
          </MainLayout>} />

          {/* Rutas Protegidas */}
        <Route path="/carga-creps" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><CargaCREPsPage /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/banco" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute>
            <MovimientosBancarios />
            </ProtectedRoute>
          </MainLayout>} />
        <Route path="/movimientos-crep" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><MovimientosCREP /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/mis-recibos-admin" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><MisRecibosAdmin /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/MisRecibos" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><MisRecibos /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/recibo/:id" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><ReciboDetalle /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/recibo-admin/:id" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><ReciboDetalleAdmin /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/edificio" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><EdificioForm /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/planillas-recibos" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><MisPlanillas_recibos /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/facturaciones-individuales" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><MisFacturaciones_individuales /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/facturacion-individual/create" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Facturacion_individualForm mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/facturacion-individual/edit/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Facturacion_individualForm mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/facturacion-individual/view/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Facturacion_individualForm mode="view" /></ProtectedRoute>
          </MainLayout>
        } />

        <Route path="/facturaciones-masivas" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><MisFacturaciones_masivas /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/facturacion-masiva/create" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Facturacion_masivaForm mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/facturacion-masiva/edit/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Facturacion_masivaForm mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/facturacion-masiva/view/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Facturacion_masivaForm mode="view" /></ProtectedRoute>
          </MainLayout>
        } />

        <Route path="/protocolos" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><AdminProtocolos /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/medicamentos-admin" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><AdminMedicamentos /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/mis-personas-vulnerables2" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><RegistroAsistenciaEspecialPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/mis-personas-vulnerables" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><ListaPersonasAsistenciaPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/mis-contactos-emergencia" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><ContactosReferenciaPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/mis-preferencias" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><PreferencesPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/encuestas" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <ListaEncuestasPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/mi-plan-mantenimiento" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><PlanMantenimientoPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/plantillas-activos" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><ListaPlantillasActivoPage /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/mi-cuenta" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <MiCuentaPage /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/activos" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><ActivosPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/contenidos" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><ListaContenidosPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/contenido/create" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <AdminContenidoPage mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/contenido/edit/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><AdminContenidoPage mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/contenido/view/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><AdminContenidoPage mode="view" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/contenido/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <MostrarContenidoPage mode="view" /></ProtectedRoute>
          </MainLayout>
        } />

        <Route path="/mis-encuestas" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><MisEncuestasPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/encuesta/complete/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <EncuestaFormPage mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/respuesta/view/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <RespuestaFormPage mode="view" /></ProtectedRoute>
          </MainLayout>
        } />


        <Route path="/encuesta/create" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <AdminEncuestaPage mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/encuesta/edit/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><AdminEncuestaPage mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/encuesta/view/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <AdminEncuestaPage mode="view" /></ProtectedRoute>
          </MainLayout>
        } />

        <Route path="/mis-justificaciones" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><MisJustificacionesPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/justificacion/create" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><JustificacionFormPage mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/justificacion/edit/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><JustificacionFormPage mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/justificacion/view/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <JustificacionFormPage mode="view" /></ProtectedRoute>
          </MainLayout>
        } />

        <Route path="/mis-asistencias" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><MisAsistenciasPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/mis-reuniones" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><MisReunionesPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/reuniones" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <ListaReunionesPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/reunion/:id" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <ReunionPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/reunion/create" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><ReunionPage mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reunion/edit/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <ReunionPage mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reunion/view/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <ReunionPage mode="view" /></ProtectedRoute>
          </MainLayout>
        } />

        <Route path="/mis-pedidos" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <MisPedidosPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/pedido" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <MisPedidosPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/mis-publicaciones" exact element={
          <MainLayout showFooter={false}>
          <ProtectedRoute>  <MisPublicacionesPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/publicacion/:id" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <PublicacionDetallePage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/publicacion/create" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <PublicacionPage mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/publicacion/edit/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <PublicacionPage mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/publicacion/view/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <PublicacionPage mode="view" /></ProtectedRoute>
          </MainLayout>
        } />

        <Route path="/persona-vulnerable/create" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Persona_asistenciaForm mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/persona-vulnerable/edit/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Persona_asistenciaForm mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/persona-vulnerable/view/:id" element={
          <MainLayout showFooter={false}>
          <ProtectedRoute>  <Persona_asistenciaForm mode="view" /></ProtectedRoute>
          </MainLayout>
        } />

        <Route path="/mis-medicamentos" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <MedicamentosPage /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/protocolos-emergencia" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Mis_protocolos_emergencia /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/zonas-seguras" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <ListaZonas_seguras /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/mis-zonas-seguras" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <MisZonas_seguras /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/zona-segura/create" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Zona_seguraForm mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/zona-segura/edit/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Zona_seguraForm mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/zona-segura/view/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Zona_seguraForm mode="view" /></ProtectedRoute>
          </MainLayout>
        } />

        <Route path="/requerimientos" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <MisRequerimientos /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/requerimiento/create" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><RequerimientoForm mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/requerimiento/edit/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><RequerimientoForm mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/requerimiento/view/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <RequerimientoForm mode="view" /></ProtectedRoute>
          </MainLayout>
        } />

        <Route path="/reservas-sum" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><MisReservas tipo_area_comun={TIPO_AREA_COMUN_SUM} /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/reservas-parrilla" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <MisReservas tipo_area_comun={TIPO_AREA_COMUN_PARRILLA} /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/reservas-gym" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <MisReservas tipo_area_comun={TIPO_AREA_COMUN_GIMNASIO} /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/reservas-yoga" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <MisReservas tipo_area_comun={TIPO_AREA_COMUN_SALA_YOGA} /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/reservas-sum-admin" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><MisReservasAdmin tipo_area_comun={TIPO_AREA_COMUN_SUM} /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/reservas-parrilla-admin" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><MisReservasAdmin tipo_area_comun={TIPO_AREA_COMUN_PARRILLA} /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/reservas-gym-admin" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <MisReservasAdmin tipo_area_comun={TIPO_AREA_COMUN_GIMNASIO} /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/reservas-yoga-admin" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <MisReservasAdmin tipo_area_comun={TIPO_AREA_COMUN_SALA_YOGA} /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/calendario-reservas" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Calendario_reservasPage /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/calendario-reservas-sum" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Calendario_reservasSUM /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/calendario-reservas-parrilla" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Calendario_reservasParrilla /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/calendario-reservas-gym" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Calendario_reservasGYM /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/calendario-reservas-yoga" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Calendario_reservasYoga /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/calendario-reservas-sum-admin" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Calendario_reservasSUM /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/calendario-reservas-parrilla-admin" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Calendario_reservasParrilla /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/calendario-reservas-gym-admin" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Calendario_reservasGYM /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/calendario-reservas-yoga-admin" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Calendario_reservasYoga /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/reserva-admin/create" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><ReservaFormAdmin mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reserva-admin/edit/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><ReservaFormAdmin mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reserva-admin/view/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><ReservaFormAdmin mode="view" /></ProtectedRoute>
          </MainLayout>
        } />

        <Route path="/reserva-sum/create" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <ReservaForm tipo_area_comun="SUM" mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reserva-yoga/create" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <ReservaForm tipo_area_comun="YOGA" mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reserva-yoga/edit/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <ReservaForm tipo_area_comun="YOGA" mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reserva-yoga/view/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <ReservaForm tipo_area_comun="YOGA" mode="view" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reserva-sum/edit/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><ReservaForm tipo_area_comun="SUM" mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reserva-sum/view/:id" element={
          <MainLayout showFooter={false}>
         <ProtectedRoute>   <ReservaForm tipo_area_comun="SUM" mode="view" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reserva-gym/create" element={
          <MainLayout showFooter={false}>
          <ProtectedRoute>  <ReservaForm tipo_area_comun="GIMNASIO" mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reserva-gym/edit/:id" element={
          <MainLayout showFooter={false}>
          <ProtectedRoute>  <ReservaForm tipo_area_comun="GIMNASIO" mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reserva-gym/view/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <ReservaForm tipo_area_comun="GIMNASIO" mode="view" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reserva-parrilla/create" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <ReservaForm tipo_area_comun="PARRILLA" mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reserva-parrilla/edit/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><ReservaForm tipo_area_comun="PARRILLA" mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reserva-parrilla/view/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><ReservaForm tipo_area_comun="PARRILLA" mode="view" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reserva-admin-yoga/view/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <ReservaForm tipo_area_comun="YOGA" mode="view" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reserva-admin-gym/view/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <ReservaForm tipo_area_comun="GYM" mode="view" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reserva-admin-sum/view/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <ReservaForm tipo_area_comun="SUM" mode="view" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/reserva-admin-parrilla/view/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <ReservaForm tipo_area_comun="PARRILLA" mode="view" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/lecturas-agua" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><MisLecturas_agua /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/lectura-agua/create" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Lectura_aguaForm mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/lectura-agua/edit/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Lectura_aguaForm mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/lectura-agua/view/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Lectura_aguaForm mode="view" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/suministros" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <MisSuministros /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/suministro/create" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><SuministroForm mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/suministro/edit/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><SuministroForm mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/suministro/view/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><SuministroForm mode="view" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/facturaciones-agua" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <MisFacturaciones_agua /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/facturacion-agua/create" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Facturacion_aguaForm mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/facturacion-agua/edit/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Facturacion_aguaForm mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/facturacion-agua/view/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Facturacion_aguaForm mode="view" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/planilla-recibos/create" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><PlanillaForm mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/planilla-recibos/edit/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <PlanillaForm mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/planilla-recibos/view/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <PlanillaForm mode="view" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/recibos/view/:idPlanilla" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <RecibosPage /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/conceptos-facturacion" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><MisConceptos_facturacion /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/concepto/create" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><ConceptoForm mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/concepto/edit/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <ConceptoForm mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/concepto/view/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><ConceptoForm mode="view" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/edificioIngreso" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><EdificioIngreso /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/departamentos" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Depas_edificio /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/carga-depas" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Carga_lista_departamentos /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/carga-estacionamientos" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Carga_lista_estacionamientos /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/estacionamientos" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Estacionamientos_edificio /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/residentes" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Residentes_edificio /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/personas" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Personas_edificio /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/persona/create" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><PersonaForm mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/persona/edit/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><PersonaForm mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/persona/view/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <PersonaForm mode="view" /></ProtectedRoute>
          </MainLayout>
        } />

        <Route path="/unidad_inmobiliaria/create" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Unidad_inmobiliariaForm mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/unidad_inmobiliaria/edit/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Unidad_inmobiliariaForm mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/unidad_inmobiliaria/view/:id" element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Unidad_inmobiliariaForm mode="view" /></ProtectedRoute>
          </MainLayout>
        } />


        <Route path="/unidades-facturacion" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Unidades_facturacion_edificio /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/unidad_facturacion/view/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Unidad_facturacionForm mode="view" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/unidad_facturacion/create" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Unidad_facturacionForm mode="create" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/unidad_facturacion/edit/:id" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Unidad_facturacionForm mode="edit" /></ProtectedRoute>
          </MainLayout>
        } />
        <Route path="/lectura-rapida-contometro" element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <LecturaRapidaContometro /></ProtectedRoute>
          </MainLayout>
        } />

        <Route path="/config-unidades-facturacion" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Config_unidades_facturacion /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/config-unidades-facturacion" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Config_unidades_facturacion /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/carga-config-unidades-facturacion" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Carga_lista_config_unidades_facturacion /></ProtectedRoute>
          </MainLayout>} />

        <Route path="/unidades-inmobiliarias" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Unidades_inmobiliarias /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/carga-unidades-inmobiliarias" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute> <Carga_lista_unidades_inmobiliarias /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/carga-unidades-facturacion" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Carga_lista_unidades_facturacion /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/carga-residentes" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Carga_lista_residentes /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/carga-personas" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><Carga_lista_personas /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/carga-depositos" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Carga_lista_depositos /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/depositos" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Depositos_edificio /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/carga-propiedades" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <Carga_lista_propiedades /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/propiedades" exact element={
          <MainLayout showFooter={false}>
          <ProtectedRoute>  <Propiedades_edificio /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/logout" exact element={
          <MainLayout showFooter={false}>
          <ProtectedRoute>  <Logout /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/logout" exact element={
          <MainLayout showFooter={false}>
          <ProtectedRoute>  <Logout /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/change-password" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><ChangePassword /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/dashboard" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute>
              <DashboardPage />
              </ProtectedRoute>
          </MainLayout>} />
        <Route path="/home" exact element={
          <MainLayout showFooter={false}>
            <ProtectedRoute><DashboardPage /></ProtectedRoute>
          </MainLayout>} />
        <Route path="/edificios" exact element={
          <MainLayout showFooter={false}>
           <ProtectedRoute> <MisEdificios /></ProtectedRoute>
          </MainLayout>} />

      </Routes>
    </Router>
  );

}

export default App;
