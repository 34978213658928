import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { TIPOS_SUMINISTRO, PROVEEDORES_SUMINISTRO } from '../../constants/constants';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import {
    get_suministro,
    save_suministro,
    update_suministro,
    anular_suministro
} from './suministroThunk'; // Ruta correcta al thunk

import { resetStateGetSuministros } from './suministrosSlice'; // 
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const SuministroForm = ({ mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useMaterializeTooltips();
    const { id: idSuministro } = useParams(); // Recibir el ID del suministro

    const suministro = useSelector(state => state.suministro.suministro); // Ajusta el selector

    const usuario = useSelector((state) => state.auth.usuario);
    const codigo_usuario = usuario ? usuario.id : null;

    const edificioSeleccionado = useSelector((state) => state.edificio.edificio);
    const buildingId = edificioSeleccionado.id; // ID del edificio desde la URL
    const codigo_edificio = edificioSeleccionado.codigo; // ID del edificio desde la URL

    const [isEditing, setIsEditing] = useState(mode !== 'view');
    const [isCreating, setIsCreating] = useState(mode === 'create');


    const [formData, setFormData] = useState({
        tipo_suministro: TIPOS_SUMINISTRO[0].label, 
        numero: '',
        nombre: '',
        nota: '',
        proveedor: PROVEEDORES_SUMINISTRO[0].label,
    });

    useEffect(() => {
        if (mode !== 'create') {
            dispatch(get_suministro(idSuministro));
        }
    }, [dispatch, idSuministro, mode]);

    useEffect(() => {
        if (suministro && (mode === 'view' || mode === 'edit')) {
            setFormData({
                tipo_suministro: suministro.tipo_suministro,
                numero: suministro.numero,
                nombre: suministro.nombre,
                nota: suministro.nota,
                proveedor: suministro.proveedor,
            });


        }
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields();
    }, [suministro, mode]);

    useEffect(() => {
        M.updateTextFields();
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [formData]);  // Dependencia para asegurar que se reinicialice cuando las opciones cambian

    useEffect(() => {
        // Actualiza los campos de texto de Materialize cuando el componente se monta
        M.updateTextFields();
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [isEditing]);

    const handleRetornar = () => {

        navigate('/suministros');
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSave = () => {

        if (isEditing) {
            if (mode === 'create') {
                const data = { ...formData, buildingId, codigo_edificio, usuarioRegistrador: codigo_usuario };
                dispatch(save_suministro(data));
            } else {
                const data = { ...formData, usuarioActualizacion: codigo_usuario };
                dispatch(update_suministro({ idSuministro, suministroData: data }));
            }

            dispatch(resetStateGetSuministros());
        }

        navigate('/suministros'); // Redirigir después de guardar
    };

  

    const handleCancel = () => {
        navigate('/suministros'); // Redirigir al listado de suministros
    };

    const handleAnular = () => {
        dispatch(anular_suministro(idSuministro));
        navigate('/suministros'); // Redirigir después de anular
    };
    const handleEdit = () => {

        setIsEditing(true);

    };
    
    return (
        <div className="container">
            <div>
                <div className="card">
                    <div className="card-content">
                        <div className="card-action">
                            {isEditing ? (
                                <>
                                    <a className="btn green tooltipped" data-position="top" data-tooltip="Guardar cambios" onClick={handleSave}>
                                        <i className="material-icons left">save</i>Guardar
                                    </a>
                                    <a className="btn red tooltipped" data-position="top" data-tooltip="Cancelar edición" onClick={handleCancel}>
                                        <i className="material-icons left">cancel</i>Cancelar
                                    </a>
                                </>
                            ) : (
                                <>
                                    <a className="btn green tooltipped" data-position="top" data-tooltip="Editar detalles" onClick={handleEdit} >
                                        <i className="material-icons left">edit</i>Editar
                                    </a>
                                </>
                            )}


                            <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={handleRetornar}>
                                <i className="material-icons left">arrow_back</i>
                            </a>
                        </div>

                        <span className="card-title">{mode === 'create' ? 'Crear Suministro' : mode === 'edit' ? 'Editar Suministro' : 'Detalles del Suministro'}</span>

                        <form onSubmit={e => e.preventDefault()}>
                        <div className="row">
                             <div className="input-field col s6 m3">
                                    <select name="tipo_suministro" value={formData.tipo_suministro} onChange={handleInputChange} disabled={!isEditing}>
                                        {TIPOS_SUMINISTRO.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="tipo_suministro">Tipo Suministro:</label>
                                </div>
                                <div className="input-field col s6 m2">
                                    <input
                                        type="text"
                                        name="numero"
                                        value={formData.numero || ''}
                                        onChange={handleInputChange}
                                        disabled={!isEditing}
                                    />
                                    <label htmlFor="numero" className="active">Número de Suministro:</label>
                                </div>
                                <div className="input-field col s6 m2">
                                    <input
                                        type="text"
                                        name="nombre"
                                        value={formData.nombre || ''}
                                        onChange={handleInputChange}
                                        disabled={!isEditing}
                                    />
                                    <label htmlFor="nombre" className="active">Nombre del Suministro:</label>
                                </div>
                                </div>
                                <div className="row">
                                <div className="input-field col s6 m3">
                                    <select name="proveedor" value={formData.proveedor} onChange={handleInputChange} disabled={!isEditing}>
                                        {PROVEEDORES_SUMINISTRO.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="proveedor">Proveedor:</label>
                                </div>
                                <div className="input-field col s6 m2">
                                    <input
                                        type="text"
                                        name="nota"
                                        value={formData.nota || ''}
                                        onChange={handleInputChange}
                                        disabled={!isEditing}
                                    />
                                    <label htmlFor="nota" className="active">Nota:</label>
                                </div>
                                </div>                      
                    </form>
                    </div>

</div>
</div>
</div>
);
};

export default SuministroForm;
