import { createSlice } from '@reduxjs/toolkit';
import { get_recibos_x_depa, get_recibos_admin, get_recibos_x_planilla, get_recibo, update_recibo
  , save_recibo, anular_recibo, enviar_correo_recibo } from './recibosThunk';

const initialState = {
  recibos: [],
  recibo: null,
  reciboSeleccionado: null,
  fetchStatus: 'idle',
  error: null,
  status_enviar_correo_recibo: 'idle',
  error_enviar_correo_recibo: null,
  status_get_recibos_admin: 'idle',
  error_get_recibos_admin: null,
  fetchStatus_get_recibos_x_planilla: 'idle',
  error_get_recibos_x_planilla: null,
  fetchStatus_get_recibos_x_depa: 'idle',
  error_get_recibos_x_depa: null,

}
const recibosSlice = createSlice({
  name: 'recibos',
  initialState,
  reducers: {
    setReciboSeleccionado: (state, action) => {
      state.reciboSeleccionado = action.payload;

    },
    clearReciboSeleccionado(state) {
      state.reciboSeleccionado = null;
      state.recibo = null;
    },
    resetState: () => initialState, // 🔥 Reiniciar todo el estado al estado inicial
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_recibos_x_depa.pending, (state) => {
        state.fetchStatus = 'loading';
      })
      .addCase(get_recibos_x_depa.fulfilled, (state, action) => {
        state.fetchStatus = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.recibos = action.payload;

      })
      .addCase(get_recibos_x_depa.rejected, (state, action) => {
        console.log("ejecutando Slice failed rejected ");
        state.fetchStatus = 'failed';
        console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch receipts';
      })

      .addCase(get_recibos_admin.pending, (state) => {
        state.status_get_recibos_admin = 'loading';

      })
      .addCase(get_recibos_admin.fulfilled, (state, action) => {
        state.status_get_recibos_admin = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.recibos = action.payload;

      })
      .addCase(get_recibos_admin.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_recibos_admin = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_recibos_admin = action.payload || 'Failed to fetch building';
      })

      .addCase(enviar_correo_recibo.pending, (state) => {
        state.status_enviar_correo_recibo = 'loading';

      })
      .addCase(enviar_correo_recibo.fulfilled, (state, action) => {
        state.status_enviar_correo_recibo = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.recibo = action.payload;

      })
      .addCase(enviar_correo_recibo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_enviar_correo_recibo = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_enviar_correo_recibo = action.payload || 'Failed to fetch building';
      })

      .addCase(get_recibos_x_planilla.pending, (state) => {
        state.fetchStatus_get_recibos_x_planilla = 'loading';
      })
      .addCase(get_recibos_x_planilla.fulfilled, (state, action) => {
        state.fetchStatus_get_recibos_x_planilla = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.recibos = action.payload;

      })
      .addCase(get_recibos_x_planilla.rejected, (state, action) => {
        console.log("ejecutando Slice failed rejected ");
        state.fetchStatus_get_recibos_x_planilla = 'failed';
        console.log("error action.error.message " + action.error.message);
        state.error_get_recibos_x_planilla = action.payload || 'Failed to fetch receipts';
      })

      .addCase(get_recibo.pending, (state) => {
        state.fetchStatus = 'loading';
      })
      .addCase(get_recibo.fulfilled, (state, action) => {
        state.fetchStatus = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.recibo = action.payload;

      })
      .addCase(get_recibo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.fetchStatus = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })

      .addCase(update_recibo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(update_recibo.fulfilled, (state, action) => {
        state.fetchStatus = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.recibo = action.payload.recibo;

      })
      .addCase(update_recibo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.fetchStatus = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })
      .addCase(save_recibo.pending, (state) => {
        state.fetchStatus = 'loading';
      })
      .addCase(save_recibo.fulfilled, (state, action) => {
        state.fetchStatus = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.recibo = action.payload.recibo;

      })
      .addCase(save_recibo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.fetchStatus = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_recibo.pending, (state) => {
        state.status_save_recibo = 'loading';
      })
      .addCase(anular_recibo.fulfilled, (state, action) => {
        state.fetchStatus = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.recibo = action.payload.recibo;

      })
      .addCase(anular_recibo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.fetchStatus = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })

  },
});

export const selectReciboById = (state, reciboId) =>
  state.recibos.recibos.find((recibo) => recibo.id === reciboId);
export const { resetState } = recibosSlice.actions;
export default recibosSlice.reducer;
