import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  get_unidad_facturacion_x_unidad,
  // ...
} from './unidad_facturacionThunk'; // Ajusta ruta
import { clearUnidad_facturacionSeleccionado } from './unidad_facturacionSlice';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './BuscarUnidadFacturacionModal.css';

// Este modal recibe:
// - isOpen: bool para abrir/cerrar
// - onClose: callback para cerrar
// - handleAddUnidadExonerada: callback para agregar la unidad a la lista en el padre
// - buildingId: el id del edificio actual, para enviarlo al backend

const BuscarUnidadFacturacionModal = ({
  isOpen,
  onClose,
  handleAddUnidadExonerada,
  buildingId
}) => {
  const dispatch = useDispatch();

  // Campos para buscar
  const [numeroUnidad, setNumeroUnidad] = useState('');

  // Estado del slice de unidad_facturacion
  const { unidad_facturacion, status_get_unidad_facturacion_x_unidad, error_get_unidad_facturacion_x_unidad } = useSelector(
    (state) => state.unidad_facturacion
  );

  // Al abrir, inicializamos el select de Materialize
  useEffect(() => {
    if (isOpen) {
      const elems = document.querySelectorAll('select');
      M.FormSelect.init(elems);
    }
  }, [isOpen]);

  // Al cerrar, limpiamos la unidad seleccionada
  useEffect(() => {
    if (!isOpen) {
      dispatch(clearUnidad_facturacionSeleccionado());
    }
  }, [isOpen, dispatch]);

  const handleBuscar = () => {
    console.log(" VER buildingId "+buildingId+ " numeroUnidad "+numeroUnidad)
    if (!buildingId || !numeroUnidad) return;
    dispatch(
      get_unidad_facturacion_x_unidad({
        buildingId,
        codigo_unidad_facturacion: numeroUnidad,
      })
    );
  };

  const handleAgregar = () => {
    if (unidad_facturacion) {
      // Llamamos al callback para que el padre agregue a "propiedades"
      handleAddUnidadExonerada({
        codigo_unidad_facturacion: unidad_facturacion.codigo_unidad_facturacion,
        nombres_propietarios: unidad_facturacion.nombres_propietarios,
        // ... otros campos que desees
      });
      // Reseteamos
      setNumeroUnidad('');
      onClose();
    }
  };

  const handleCerrar = () => {
    setNumeroUnidad('');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0, left: 0,
        width: '100%', height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          background: '#fff',
          borderRadius: '8px',
          padding: '1.5rem',
          width: '400px',
          maxWidth: '90%',
        }}
      >
        <h5>Buscar Unidad Facturación</h5>        

        {/* Input para numeroUnidad */}
        <div className="input-field">
          <input
            type="text"
            value={numeroUnidad}
            onChange={(e) => setNumeroUnidad(e.target.value)}
          />
          <label className={numeroUnidad ? 'active' : ''}>
            Unidad Facturación
          </label>
        </div>

        <button
          className="btn"
          onClick={handleBuscar}
          style={{ marginRight: '1rem' }}
        >
          Buscar
        </button>
        <button className="btn grey" onClick={handleCerrar}>
          Cerrar
        </button>

        {/* Manejo de estados */}
        {/* Manejo de estados */}
        {status_get_unidad_facturacion_x_unidad === 'loading' && <p className="modal-message">Cargando...</p>}
        {status_get_unidad_facturacion_x_unidad === 'failed' && error_get_unidad_facturacion_x_unidad && (
                    <p className="modal-error">Error: {typeof error_get_unidad_facturacion_x_unidad === 'string' 
                        ? error_get_unidad_facturacion_x_unidad 
                        : error_get_unidad_facturacion_x_unidad.message || "Ocurrió un error"}
                    </p>
                )}
                {status_get_unidad_facturacion_x_unidad === 'succeeded' && unidad_facturacion && (
                    <div className="modal-result">
                        <p><strong>Unidad:</strong> {unidad_facturacion.codigo_unidad_facturacion}</p>
                        <p><strong>Propietario(s):</strong> {unidad_facturacion.nombres_propietarios}</p>
                        <button className="btn green" onClick={handleAgregar}>Agregar</button>
                    </div>
                )}
       
      </div>
    </div>
  );
};

export default BuscarUnidadFacturacionModal;
