import React, { useState, useEffect, useRef } from 'react';
import { storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import M from 'materialize-css/dist/js/materialize.min.js'; // Importa Materialize para los toasts
import { guardar_lectura_depa } from './lectura_aguaThunk';
import { formatPeriodo } from '../../utils/util';
import { get_unidades_inmobiliarias } from '../edificio/unidad_inmobiliariaThunk';
import { get_suministros_x_tipo } from '../edificio/suministrosThunk';
import './LecturaRapidaContometro.css';
import { TIPOS_SUMINISTRO } from '../../constants/constants';

const LecturaRapidaContometro = () => {
    const dispatch = useDispatch();
    const [departamento, setDepartamento] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [periodo, setPeriodo] = useState(new Date());
    const [suministro, setSuministro] = useState('');
    const departamentoInputRef = useRef(null);

    const edificio = useSelector(state => state.edificio.edificio);
    const codigo_edificio = edificio?.codigo;
    const unidadesInmobiliarias = useSelector(state => state.unidad_inmobiliaria.unidades_inmobiliarias);
    const suministros = useSelector(state => state.suministros.suministros) || [];
    const statusGuardar = useSelector(state => state.lectura_agua.status_guardar_lectura_depa);
    const errorGuardar = useSelector(state => state.lectura_agua.error_guardar_lectura_depa);

    useEffect(() => {
        departamentoInputRef.current?.focus();
        if (edificio?.id) {
            dispatch(get_unidades_inmobiliarias(edificio.id));
            dispatch(get_suministros_x_tipo({ buildingId: edificio.id, tipo_suministro: TIPOS_SUMINISTRO[0].value }));
        }
    }, [dispatch, edificio]);

    useEffect(() => {
        if (suministros.length > 0 && !suministro) {
            setSuministro(suministros[0].numero);
        }
    }, [suministros]);

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            setSelectedFile(file);
            setPreviewImage(URL.createObjectURL(file));
        }
    };

    const showToast = (message, classes = 'blue') => {
        M.toast({ html: message, classes, displayLength: 4000 });
    };

    const uploadPhoto = async () => {
        if (!selectedFile || !departamento || !suministro) {
            showToast("Debe ingresar un número de departamento, seleccionar un suministro y subir una foto", 'red');
            return;
        }

        const departamentoValido = unidadesInmobiliarias?.some(unidad => unidad.numero_unidad_inmobiliaria === departamento);
        if (!departamentoValido) {
            showToast("El número de departamento ingresado no es válido", 'red');
            return;
        }

        showToast("Subiendo foto...", 'blue');

        const periodString = periodo.toISOString().slice(0, 7);
        const randomId = Math.random().toString(36).substring(2, 15);
        const prefijoEdificio = codigo_edificio.substring(0, 3);
        const filename = `contometros/${codigo_edificio}-${prefijoEdificio}${departamento}-${periodString}-${randomId}.png`;
        const storageRef = ref(storage, filename);
        const uploadTask = uploadBytesResumable(storageRef, selectedFile);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
            },
            (error) => {
                console.error("Error en la subida: ", error);
                showToast("Error al subir la foto.", 'red');
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                
                dispatch(guardar_lectura_depa({
                    buildingId: edificio.id,
                    codigo_edificio,
                    nro_suministro: suministro,
                    periodo: periodString,
                    tipo_unidad_inmobiliaria: "Departamento",
                    numero_unidad_inmobiliaria: departamento,                                                            
                    url_foto: downloadURL
                })).then((result) => {
                    if (result.error) {
                        showToast(`Error al guardar: ${errorGuardar}`, 'red');
                    } else {
                        showToast("Foto registrada correctamente", 'green');
                        limpiarCampos();
                    }
                });
            }
        );
    };

    const limpiarCampos = () => {
        setDepartamento('');
        setSelectedFile(null);
        setPreviewImage(null);
        setUploadProgress(0);
        departamentoInputRef.current?.focus();
    };

    return (
        <div className="lectura-rapida-contometro">
            <h2 className="title">Subir Fotos de Contómetros</h2>
            <div className="input-row">
                <div className="input-group half">
                    <label>Suministro</label>
                    <select value={suministro} onChange={(e) => setSuministro(e.target.value)} className="select-visible">
                        <option value="">Seleccione un suministro</option>
                        {suministros.map(s => (
                            <option key={s.id} value={s.numero}>{s.nombre} - {s.numero}</option>
                        ))}
                    </select>
                </div>
                <div className="input-group half">
                    <label>Periodo</label>
                    <DatePicker selected={periodo} onChange={(date) => setPeriodo(date)} showMonthYearPicker dateFormat="MM/yyyy" />
                </div>
            </div>
            <div className="input-group">
                <label>Departamento</label>
                <input
                    type="text"
                    ref={departamentoInputRef}
                    placeholder="Ingrese el número de departamento"
                    value={departamento}
                    onChange={(e) => setDepartamento(e.target.value)}
                />
            </div>
            <div className="input-group">
                <label>Subir Foto Contometro</label>
                <input type="file" accept="image/*" onChange={handleFileChange} capture="environment" />
            </div>
            {previewImage && (
                <div className="preview">
                    <h3>Vista Previa</h3>
                    <img src={previewImage} alt="Vista previa" />
                </div>
            )}
            <button className="upload-btn" onClick={uploadPhoto} disabled={statusGuardar === 'loading'}>
                {statusGuardar === 'loading' ? "Subiendo..." : "Regisrar Foto Contometro"}
            </button>
            {uploadProgress > 0 && (
                <div className="progress-container">
                    <progress value={uploadProgress} max="100" />
                    <p>{uploadProgress.toFixed(2)}%</p>
                </div>
            )}
        </div>
    );
};

export default LecturaRapidaContometro;
