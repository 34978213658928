// frontend/src/pages/MisRequerimientos.js 
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { get_requerimientos_x_depa } from './requerimientoThunk';
import M from 'materialize-css';

import { 
    formatDate, 
    formatCurrency, 
    formatPeriod, 
    formatDate02, 
    formatearPeriodo_MesAnio01, 
    formatearImporte01 
} from '../../utils/formateo';
import { downloadPDF } from '../../utils/util';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const MisRequerimientos = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useMaterializeTooltips();

    // Selectores del estado de Redux
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const requerimientos = useSelector((state) => state.requerimiento.requerimientos) || [];
    const usuario = useSelector((state) => state.auth.usuario);
    const status_get_requerimientos_x_depa = useSelector((state) => state.requerimiento.status_get_requerimientos_x_depa);
    const edificio = useSelector((state) => state.edificio.edificio);
    const error_get_requerimientos_x_depa = useSelector((state) => state.requerimiento.error_get_requerimientos_x_depa);
    const unidad_facturacionSeleccionado = useSelector((state) => state.unidad_facturacion.unidad_facturacionSeleccionado);

    const [filtro, setFiltro] = useState('');

    // Verificar autenticación y redireccionar si no está autenticado
    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    // Inicializar Materialize CSS y cargar requerimientos
    useEffect(() => {
        M.AutoInit(); // Inicializa los componentes de Materialize CSS
        //console.log("ANTES status_get_requerimientos_x_depa:", status_get_requerimientos_x_depa);
        
        if (unidad_facturacionSeleccionado && unidad_facturacionSeleccionado.buildingId && edificio?.id) {
                dispatch(get_requerimientos_x_depa(
                    {
                        buildingId: unidad_facturacionSeleccionado.buildingId, 
                        codigo_unidad_facturacion: unidad_facturacionSeleccionado.codigo_unidad_facturacion,
                        estado: 'ACTIVO'
                    }
                )); 
            //}
        } else {
            console.log("unidad_facturacionSeleccionado, edificio.id o ambos no están definidos.");
        }
}, [unidad_facturacionSeleccionado, dispatch]);

    if (!usuario) {
        return null; // Evita renderizar mientras se verifica la autenticación
    }

    const handleFilterChange = (e) => {    
        setFiltro(e.target.value.toLowerCase());
    };

    const requerimientosFiltrados = requerimientos.filter((requerimiento) => {
        const filter = filtro.trim();
        if (filter === '') return true; // Si el filtro está vacío, mostrar todos

        // Asegurar que los campos existen antes de aplicarle métodos
        const numero_requerimiento = requerimiento.numero_requerimiento?.toLowerCase() || '';
        const tipo_atencion = requerimiento.tipo_atencion?.toLowerCase() || '';
        const categoria = requerimiento.categoria?.toLowerCase() || '';
        const fecha_solicitud = requerimiento.fecha_solicitud?.toLowerCase() || '';
        const fecha_cierre = requerimiento.fecha_cierre?.toLowerCase() || '';
        const estado = requerimiento.estado?.toLowerCase() || '';

        return (
            numero_requerimiento.includes(filter) ||
            tipo_atencion.includes(filter) ||
            categoria.includes(filter) ||
            fecha_solicitud.includes(filter) ||
            fecha_cierre.includes(filter) ||     
            estado.includes(filter)
        );
    });

    return (
        <div className="container mis-requerimientos">
            <div className="card">
                <div className="card-content">
                    <span className="card-title">Requerimientos</span>
                    <div className="card-action">
                        <button 
                            className="btn orange tooltipped" 
                            data-position="top" 
                            data-tooltip="Nuevo Requerimiento" 
                            onClick={() => navigate(`/requerimiento/create`)}
                        >
                            <i className="material-icons left">add_box</i>Crear Requerimiento
                        </button>
                        <button 
                            className="btn blue tooltipped" 
                            data-position="top" 
                            data-tooltip="Volver" 
                            onClick={() => navigate('/dashboard')}
                        >
                            <i className="material-icons left">arrow_back</i>Volver
                        </button>              
                    </div>
                    
                    <h5>Listado</h5>

                    <div className="input-field">
                        <i className="material-icons prefix">search</i>
                        <input 
                            type="text" 
                            id="filtro_tabla_mis_requerimientos_propietario" 
                            value={filtro}
                            onChange={handleFilterChange} 
                            placeholder="Filtrar por cualquier campo" 
                        />
                    </div>

                    {status_get_requerimientos_x_depa === 'loading' && (
                        <div className="progress">
                            <div className="indeterminate"></div>
                        </div>
                    )}

                    {status_get_requerimientos_x_depa === 'failed' && (
                        <div className="card-panel red lighten-4 red-text text-darken-4">
                            <span>Error: {error_get_requerimientos_x_depa}</span>
                        </div>
                    )}

                    {status_get_requerimientos_x_depa === 'succeeded' && requerimientosFiltrados.length === 0 && (
                        <div className="card-panel yellow lighten-4 yellow-text text-darken-4">
                            <span>No se encontraron requerimientos que coincidan con el filtro.</span>
                        </div>
                    )}

                    {status_get_requerimientos_x_depa === 'succeeded' && requerimientosFiltrados.length > 0 && (
                        <table className="striped responsive-table tabla_mis_requerimientos_propietario">
                            <thead>
                                <tr>
                                    <th>Req.</th>
                                    <th>Solicitud</th>
                                    <th>Tipo</th>
                                    <th>Categoría</th>
                                    <th>Cierre</th>
                                    <th>Estado</th>
                                    <th>Registrador</th>
                                </tr>
                            </thead>
                            <tbody id="table-body-mis-requerimientos_propietario">                    
                                {requerimientosFiltrados.map((requerimiento) => (
                                    <tr key={requerimiento.id}>                                        
                                        <td>
                                            <Link to={`/requerimiento/view/${requerimiento.id}`}>
                                                {requerimiento.numero_requerimiento}
                                            </Link>
                                        </td>
                                        <td>{requerimiento.fecha_solicitud}</td>
                                        <td>{requerimiento.tipo_atencion}</td>
                                        <td>{requerimiento.categoria}</td>
                                        <td>{requerimiento.fecha_cierre}</td>
                                        <td>{requerimiento.estado}</td>
                                        <td>{requerimiento.usuarioRegistrador || 'N/A'}</td>                                        
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>   
            </div> 
        </div>
    );
};

export default MisRequerimientos;
