// src/pages/UploadAndListDepartments.js
import React from 'react';
import Carga_personas from './carga-personasPage';
import Personas_cargadas from './personas-cargadasPage';
import { useNavigate } from 'react-router-dom';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const Carga_lista_personas = () => {
  const navigate = useNavigate();
  useMaterializeTooltips();
  
  return (
    <div className="container">
      <h4>Carga Personas</h4>
      <div className="row ">
      <div className="col s12 m1">
      <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/personas')}>
              <i className="material-icons">arrow_back</i>
        </a>       
        </div>  
        </div>
      <Carga_personas />
      <Personas_cargadas />
    </div>
  );
};

export default Carga_lista_personas;