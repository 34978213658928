// ChangePasswordPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { update_password } from '../seguridad/seguridadThunk';
import { signOutUsuario } from '../auth/authSlice';
import { resetState as resetEdificio } from '../edificio/edificioSlice';
import { resetState as resetPersona } from '../edificio/personaSlice';
import { resetState as resetUnidadFacturacion } from '../edificio/unidad_facturacionSlice';
import { resetState as resetReservas } from '../mis-areas-comunes/reservaSlice';
import { resetState as resetAreasComunes } from '../mis-areas-comunes/area_comunSlice';
import M from "materialize-css/dist/js/materialize.min.js";
import './cambiarPasswordPage.css';

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [requesting, setRequesting] = useState(false);
  //const codigo_usuario = useSelector((state) => state.seguridad.json_resp_validar_token_reset.codigo_usuario); // Obtener el codigo_usuario del estado global  

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    status_password_reset,
    error_password_reset,
    status_validar_token_reset,
    status_update_password,
    error_validar_token_reset,
    error_update_password,
    json_resp_password_reset,
    json_resp_validar_token_reset,
    json_resp_update_password
  } = useSelector((state) => state.seguridad);

  const authState = useSelector((state) => state.auth) || { usuario: null };
  const { usuario } = authState;  // Extraer usuario de authState sin errores
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  let codigo_usuario = "";
  if (json_resp_validar_token_reset) {
    codigo_usuario = json_resp_validar_token_reset.codigo_usuario;
  } else {
    if (isAuthenticated && usuario) {
      codigo_usuario = usuario.codigo_usuario;
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      M.toast({ html: "Las contraseñas no coinciden", classes: "red darken-1" });
      return;
    }
    //dispatch(resetState()); // Limpiar estado antes de enviar una nueva solicitud
    setRequesting(true); // Activa el spinner
    dispatch(update_password({ codigo_usuario, newPassword })).finally(() => {
      setRequesting(false); // Desactiva el spinner cuando la solicitud finaliza
    });
  };

  useEffect(() => {
    //console.log("status_validar_token_reset "+ status_validar_token_reset + " json_resp_update_password.success " +json_resp_validar_token_reset.success);
    if (status_update_password === 'succeeded' && json_resp_update_password.success) {
      setPasswordUpdated(true); // Indica que la contraseña ha sido cambiada exitosamente

      dispatch(signOutUsuario());
      dispatch(resetEdificio()); // 🔥 Resetea `edificioSlice`
      dispatch(resetPersona()); // 🔥 Resetea `personaSlice`        
      dispatch(resetUnidadFacturacion()); // 🔥 Resetea `unidad_facturacionSlice`
      dispatch(resetAreasComunes()); // 🔥 Resetea `area_counSlice`
      dispatch(resetReservas()); // 🔥 Resetea `reservaSlice`   

      // Redirect
      //navigate('/');
    }
  }, [status_update_password, json_resp_update_password, navigate]);

  return (
    <div className="change-password-page">
      {requesting && (
        <div className="change-password-overlay">
          <div className="change-password-spinner-container">
            <div className="change-password-lds-ring"><div></div><div></div><div></div><div></div></div>
            <p>Enviando...</p>
          </div>
        </div>
      )}
      <div className="container change-password-container">
        <h4 className="center-align">Cambiar Contraseña</h4>
        <div className="card-panel z-depth-3">
          {!passwordUpdated ? (
            <form onSubmit={handleSubmit} className="row">
              {/* Usuario (No Editable) */}
              <div className="input-field col s12 center-align">
                <input
                  type="text"
                  id="codigo_usuario"
                  value={codigo_usuario}
                  disabled
                />
                <label htmlFor="codigo_usuario" className="active">Usuario</label>
              </div>
              <div className="input-field col s12 center-align">
                <input
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  disabled={requesting}
                />
                <label htmlFor="newPassword">Nueva Contraseña</label>
              </div>

              <div className="input-field col s12 center-align">
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  disabled={requesting}
                />
                <label htmlFor="confirmPassword">Confirmar Contraseña</label>
              </div>

              <div className="col s12 center-align">
                <button className="btn waves-effect waves-light" type="submit" disabled={requesting}>
                  {requesting ? "Actualizando..." : "Actualizar Contraseña"}
                </button>
              </div>

              {status_update_password === "failed" && (
                <p className="error-message">{error_update_password}</p>
              )}
            </form>
          ) : (
            <div className="success-message-container center-align">
              <p className="green-text text-darken-2">
                ¡Tu contraseña ha sido cambiada con éxito! Ahora puedes iniciar sesión con tu nueva contraseña.
              </p>
              <button
                className="btn waves-effect waves-light blue darken-2"
                onClick={() => navigate("/login")}
              >
                Ir a Iniciar Sesión
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;