// frontend/src/pages/justificacionFormPage.js 
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    get_justificacion,
    update_justificacion,
    save_justificacion,
    anular_justificacion
} from './justificacionThunk';
import { get_grupos_x_tipo } from '../medicamentos/grupoThunk';
import { useNavigate, useParams } from 'react-router-dom';
import { get_reuniones_x_edificio } from './reunionThunk';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './justificacionFormPage.css';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';

// Importar Firebase Storage
import { storage } from '../../firebase'; // Ajusta la ruta según la ubicación de tu archivo firebase.js
import { ref, uploadBytes, getDownloadURL, connectStorageEmulator } from 'firebase/storage';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

// Función para formatear fechas a "dd-mm-YYYY"
const formatDate = (date) => {
    const d = new Date(date);
    const day = (`0${d.getDate()}`).slice(-2);
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
};

const JustificacionFormPage = ({ mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: idJustificacion } = useParams();
    useMaterializeTooltips();

    registerLocale('es', es);

    const [isEditing, setIsEditing] = useState(mode !== 'view');
    const reuniones = useSelector((state) => state.reunion.reuniones) || [];
    const status_get_reuniones_x_edificio = useSelector((state) => state.reunion.status_get_reuniones_x_edificio);
    const error_get_reuniones_x_edificio = useSelector((state) => state.reunion.error_get_reuniones_x_edificio);
    const justificacion = useSelector(state => state.justificacion.justificacion);
    const codigo_usuario = useSelector((state) => state.auth.usuario.id);
    const titular = useSelector((state) => state.auth.usuario.titular);
    const unidad_facturacionSeleccionado = useSelector((state) => state.unidad_facturacion.unidad_facturacionSeleccionado);
    const codigo_unidad_facturacion = unidad_facturacionSeleccionado.codigo_unidad_facturacion;
    const edificioSeleccionado = useSelector((state) => state.edificio.edificio);
    const buildingId = edificioSeleccionado.id;
    const codigo_edificio = edificioSeleccionado.codigo;
    const [newMessage, setNewMessage] = useState(''); // Para el texto del mensaje adicional
    const [messageFile, setMessageFile] = useState(null); // Para el archivo adjunto del mensaje

    const [formData, setFormData] = useState({
        numero_justificacion: '',
        idreunion: '',
        asunto: '',
        fecha_reunion: '',
        hora_inicio: '',
        modalidad: '',
        codigo_unidad_facturacion: '',
        codigo_usuario: '',
        motivo: '',
        idmotivo_justificacion: '',
        fecha_solicitud: '', // Ahora será un string
        fecha_cierre: '',
        estado: '',
        detalle: '', // Nuevo campo para detalles
        archivos: [], // Nuevo campo para archivos adjuntos
        archivos_url: [],

    });

    // Estados para manejar la subida de archivos
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState('');


    useEffect(() => {
        dispatch(get_grupos_x_tipo({ buildingId, tipo: 'JUSTIFICACION' }));
        dispatch(get_reuniones_x_edificio({ buildingId }));
    }, [dispatch, buildingId]);

    useEffect(() => {
        console.log("entro a evaluar mode " + mode);
        if (mode !== 'create') {
            dispatch(get_justificacion(idJustificacion));
        } else {
            // Inicializar fecha_solicitud con la fecha actual en formato "dd-mm-YYYY" en modo create
            const today = formatDate(new Date());
            setFormData(prev => ({
                ...prev,
                fecha_solicitud: today
            }));
        }
    }, [dispatch, idJustificacion, mode]);

    useEffect(() => {
        if (justificacion && (mode === 'view' || mode === 'edit')) {
            setFormData({
                numero_justificacion: justificacion.numero_justificacion,
                idreunion: justificacion.idreunion,
                asunto: justificacion.asunto,
                fecha_reunion: justificacion.fecha_reunion,
                hora_inicio: justificacion.hora_inicio,
                modalidad: justificacion.modalidad,
                codigo_unidad_facturacion: justificacion.codigo_unidad_facturacion,
                codigo_usuario: justificacion.codigo_usuario,
                motivo: justificacion.motivo, // Asumiendo que 'categoria' tiene 'nombre'
                idmotivo_justificacion: justificacion.idmotivo_justificacion, // Asegúrate que 'categoria' tiene 'id'
                fecha_solicitud: justificacion.fecha_solicitud,
                fecha_cierre: justificacion.fecha_cierre ? formatDate(justificacion.fecha_cierre) : '',
                estado: justificacion.estado,
                detalle: justificacion.detalle || '', // Asegúrate de que 'detalle' existe
                archivos: [], // Inicialmente vacío, puedes cargar archivos existentes si es necesario
                archivos_url: justificacion.archivos_url || [],
            });
            setIsEditing(false);
        }
    }, [justificacion, mode]);

    const motivos_justificaciones = useSelector((state) => state.grupo.grupos) || [];

    useEffect(() => {
        if (motivos_justificaciones.length > 0 && !formData.idmotivo_justificacion) {
            const motivo_justificacionInicial = motivos_justificaciones[0];
            setFormData(prev => ({
                ...prev,
                idmotivo_justificacion: motivo_justificacionInicial.id,
                motivo: motivo_justificacionInicial.nombre,
                fecha_solicitud: mode === 'create' ? formatDate(new Date()) : prev.fecha_solicitud
            }));

        }

        // Inicializar Materialize CSS select
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields();
    }, [motivos_justificaciones, mode]);


    useEffect(() => {
        // Inicializar Materialize CSS select cuando isEditing cambia
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields();
    }, [isEditing]);

    useEffect(() => {
        if (!isEditing && mode === 'create') {
            const today = formatDate(new Date());
            setFormData(prevState => ({
                ...prevState,
                fecha_solicitud: today
            }));
        }
    }, [isEditing, mode]);

    // Manejador para cambios en campos de texto y textarea
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Manejador para cambios en el campo de archivos
    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setFormData(prev => ({
            ...prev,
            archivos: files
        }));
    };

    const handleSave = async () => {
        if (isEditing) {
            setUploading(true);
            setUploadError('');

            try {
                // Crear objeto base para la justificación
                const dataToSave = {
                    numero_justificacion: formData.numero_justificacion,
                    codigo_unidad_facturacion: formData.codigo_unidad_facturacion || codigo_unidad_facturacion,
                    idmotivo_justificacion: formData.idmotivo_justificacion,
                    motivo: formData.motivo,
                    detalle: formData.detalle,
                    fecha_solicitud: formData.fecha_solicitud,
                    fecha_cierre: formData.fecha_cierre || null,
                    archivos_url: formData.archivos_url || [],
                    estado: formData.estado,
                    buildingId: buildingId,
                    codigo_edificio: codigo_edificio,
                    codigo_usuario: codigo_usuario,
                    // Nuevos campos a guardar
                    fecha_reunion: formData.fecha_reunion,
                    hora_inicio: formData.hora_inicio,
                    modalidad: formData.modalidad,
                    asunto: formData.asunto,
                };

                if (mode === 'edit') {
                    dataToSave.usuarioActualizacion = codigo_usuario;
                }

                // Subir los archivos seleccionados a Firebase Storage
                const uploadPromises = formData.archivos.map(async (archivo) => {
                    const storageRefPath = `cargaJustificaciones/${idJustificacion || 'nuevo'}/${archivo.name}_${Date.now()}`;
                    const archivoRef = ref(storage, storageRefPath);

                    // Subir archivo y obtener la URL
                    await uploadBytes(archivoRef, archivo);
                    const downloadURL = await getDownloadURL(archivoRef);
                    return downloadURL;
                });

                // Esperar a que todas las subidas se completen
                const downloadURLs = await Promise.all(uploadPromises);

                // Agregar las URLs obtenidas al campo `archivos_url`
                dataToSave.archivos_url = [...dataToSave.archivos_url, ...downloadURLs];

                // Guardar la justificación en el backend
                if (mode === 'create') {
                    await dispatch(save_justificacion(dataToSave)).unwrap();
                } else {
                    await dispatch(update_justificacion({ idJustificacion, justificacionData: dataToSave })).unwrap();
                }

                M.toast({ html: 'Justificación guardada exitosamente.', classes: 'green' });
                navigate(-1);
            } catch (error) {
                console.error('Error al guardar la justificación:', error);
                setUploadError(error.message || 'Ocurrió un error al guardar la justificación.');
                M.toast({ html: 'Error al guardar la justificación.', classes: 'red' });
            } finally {
                setUploading(false);
            }
        }
    };


    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleAnular = () => {
        if (window.confirm("¿Estás seguro de que quieres anular este justificacion? Esta acción no se puede deshacer.")) {
            dispatch(anular_justificacion({ idJustificacion, usuarioAnulacion: codigo_usuario }));
            M.toast({ html: 'Justificacion anulado correctamente', classes: 'rounded' });
            navigate(-1);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        if (mode === 'create') {
            navigate(-1);
        }
    };

    const handleAddMessage = async () => {
        if (!newMessage && !messageFile) {
            M.toast({ html: 'Agrega un mensaje o archivo.', classes: 'red' });
            return;
        }

        setUploading(true);
        try {
            let fileUrl = null;

            if (messageFile) {
                // Subir archivo a Firebase Storage
                const storageRefPath = `mensajesJustificaciones/${idJustificacion}/${messageFile.name}_${Date.now()}`;
                const archivoRef = ref(storage, storageRefPath);
                await uploadBytes(archivoRef, messageFile);
                fileUrl = await getDownloadURL(archivoRef);
            }

            const newMessageData = {
                texto: newMessage,
                archivo: fileUrl,
                fecha: new Date().toISOString(),
                usuario: codigo_usuario,
                titular: titular, // Asegúrate de tener este dato
            };

            // Actualizar la colección justificacion con el nuevo mensaje
            const updatedMessages = [...(justificacion.mensajes || []), newMessageData];
            await dispatch(update_justificacion({ idJustificacion, justificacionData: { mensajes: updatedMessages } }));

            M.toast({ html: 'Mensaje agregado.', classes: 'green' });

            // Resetear campos
            setNewMessage('');
            setMessageFile(null);
        } catch (error) {
            console.error("Error al agregar mensaje:", error);
            M.toast({ html: 'Error al agregar mensaje.', classes: 'red' });
        } finally {
            setUploading(false);
        }
    };

    useEffect(() => {
        if (reuniones.length > 0 && !formData.idreunion) {
            const primeraReunion = reuniones[0];
            setFormData(prev => ({
                ...prev,
                idreunion: primeraReunion.id,
                asunto: primeraReunion.asunto,
                fecha_reunion: primeraReunion.fecha_reunion,
                hora_inicio: primeraReunion.hora_inicio,
                modalidad: primeraReunion.modalidad,
            }));
        }

        // Inicializar Materialize CSS select
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [reuniones, formData.idreunion]);


    useEffect(() => {
        // Inicializar Materialize para select y otros componentes dinámicos
        const elems = document.querySelectorAll('select, textarea');
        M.FormSelect.init(elems);
        M.updateTextFields(); // Actualiza los labels flotantes para campos de texto/textarea
    }, [formData]);

    
    const handleIdreunionChange = (e) => {
        const selectedIdreunion = e.target.value;
        const selectedReunion = reuniones.find(reunion => reunion.id === selectedIdreunion);

        if (selectedReunion) {
            setFormData(prev => ({
                ...prev,
                idreunion: selectedIdreunion,
                asunto: selectedReunion.asunto,
                fecha_reunion: selectedReunion.fecha_reunion,
                hora_inicio: selectedReunion.hora_inicio,
                modalidad: selectedReunion.modalidad,
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                idreunion: '',
                asunto: '',
                fecha_reunion: '',
                hora_inicio: '',
                modalidad: '',
            }));
        }
    };


    // Manejo de cambio de categoría
    const handleIdmotivoChange = (e) => {
        const selectedIdmotivo = e.target.value;
        setFormData(prev => ({
            ...prev,
            idmotivo_justificacion: selectedIdmotivo
        }));

        //console.log("selectedIdcategoria: " + selectedIdcategoria);
        //console.log("motivos_justificaciones: " + JSON.stringify(motivos_justificaciones));

        // Encontrar la categoría seleccionada
        const selectedMotivo = motivos_justificaciones.find(motivo => motivo.id === selectedIdmotivo);

        //console.log("selectedCategoria: " + JSON.stringify(selectedCategoria));

        if (selectedMotivo) {
            console.log("entro a poblar FormData: ");

            setFormData(prev => ({
                ...prev,
                motivo: selectedMotivo.nombre,
            }));

        } else {
            console.log("Motivo no encontrada o no existe");
            setFormData(prev => ({
                ...prev,
                idmotivo_justificacion: "",
                motivo: "",
            }));

        }
    };


    return (
        <div className="container justificacion-form">
            <div>
                <div className="card">
                    <div className="card-content">
                        <div className="card-action">
                            {isEditing ? (
                                <>
                                    <button
                                        className={`btn green tooltipped ${uploading ? 'disabled' : ''}`}
                                        data-position="top"
                                        data-tooltip="Guardar cambios"
                                        onClick={handleSave}
                                        disabled={uploading}
                                    >
                                        <i className="material-icons left">save</i>
                                        {uploading ? 'Guardando...' : 'Guardar'}
                                    </button>
                                    <button className="btn red tooltipped" data-position="top" data-tooltip="Cancelar edición" onClick={handleCancel}>
                                        <i className="material-icons left">cancel</i>Cancelar
                                    </button>
                                </>
                            ) : (
                                <>
                                    {/* 
                                    <button className="btn green tooltipped" data-position="top" data-tooltip="Editar detalles" onClick={handleEdit} >
                                        <i className="material-icons left">edit</i>Editar
                                    </button>
                                 */}
                                    <button className="btn orange tooltipped" data-tooltip="Anular Justificacion" onClick={handleAnular}>
                                        <i className="material-icons left">delete</i>Anular
                                    </button>
                                </>
                            )}
                            <button className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate(-1)}>
                                <i className="material-icons left">arrow_back</i>Volver
                            </button>
                        </div>
                        <span className="card-title">
                            {mode === 'create' ? 'Crear Justificacion' : mode === 'edit' ? 'Editar Justificacion' : 'Detalles del Justificacion'}
                        </span>
                        <form onSubmit={e => e.preventDefault()}>
                            <div className="row">
                                <div className="input-field col s6 m3">
                                    <select
                                        name="idreunion"
                                        value={formData.idreunion}
                                        onChange={handleIdreunionChange}
                                        disabled={!isEditing}
                                    >
                                        {reuniones.map((reunion) => (
                                            <option key={reunion.id} value={reunion.id}>
                                                {reunion.fecha_reunion} - {reunion.asunto}
                                            </option>
                                        ))}
                                    </select>
                                    <label>Reunión</label>
                                </div>

                                {mode !== 'create' && (
                                    <div className="input-field col s6 m2">
                                        <input
                                            type="text"
                                            name="numero_justificacion"
                                            value={formData.numero_justificacion || ''}
                                            onChange={handleInputChange}
                                            disabled={true}
                                        />
                                        <label htmlFor="numero_justificacion" className="active">Num. Just.</label>
                                    </div>
                                )}

                                <div className="input-field col s12 m4">
                                    <select
                                        name="idmotivo_justificacion"
                                        value={formData.idmotivo_justificacion || ''}
                                        onChange={handleIdmotivoChange}
                                        disabled={!isEditing}
                                    >
                                        <option value="" disabled>Selecciona un motivo</option>
                                        {Array.isArray(motivos_justificaciones) && motivos_justificaciones.map(s => (
                                            <option key={s.id} value={s.id}>
                                                {s.nombre}
                                            </option>
                                        ))}
                                    </select>
                                    <label>Motivo</label>
                                </div>
                            </div>
                            <div className="row">

                                <div className="input-field col s6 m2">
                                    <input
                                        type="text"
                                        name="fecha_solicitud"
                                        value={formData.fecha_solicitud || ''}
                                        onChange={handleInputChange}
                                        disabled={true}
                                    />
                                    <label className="active" htmlFor="fecha_solicitud">Fecha solicitud</label>
                                </div>

                                {mode !== 'create' && (
                                    <div className="row">
                                        <div className="input-field col s6 m2">
                                            <input
                                                type="text"
                                                name="fecha_cierre"
                                                value={formData.fecha_cierre || ''}
                                                onChange={handleInputChange}
                                                disabled={true}
                                            />
                                            <label className="active" htmlFor="fecha_cierre">Fecha cierre</label>
                                        </div>

                                        <div className="input-field col s6 m2">
                                            <input
                                                type="text"
                                                name="estado"
                                                value={formData.estado || ''}
                                                onChange={handleInputChange}
                                                disabled={true}
                                            />
                                            <label className="active" htmlFor="estado">Estado</label>
                                        </div>
                                    </div>
                                )}

                            </div>
                            <div className="row">
                                {/* Campo "Detalle" */}
                                <div className="input-field col s12">
                                    <textarea
                                        id="detalle"
                                        name="detalle"
                                        className="materialize-textarea"
                                        value={formData.detalle}
                                        onChange={handleInputChange}
                                        disabled={!isEditing}
                                    ></textarea>
                                    <label htmlFor="detalle" className={formData.detalle ? 'active' : ''}>Detalle</label>
                                </div>
                            </div>
                            <div className="row">
                                {mode === 'create' && (
                                    <div className="file-field input-field col s12">
                                        <div className="btn">
                                            <span>Subir Archivos</span>
                                            <input
                                                type="file"
                                                multiple
                                                onChange={handleFileChange}
                                                disabled={!isEditing}
                                            />
                                        </div>
                                        <div className="file-path-wrapper">
                                            <input className="file-path validate" type="text" placeholder="Selecciona uno o más archivos" />
                                        </div>
                                    </div>
                                )}
                            </div>

                            {(mode === 'view' || mode === 'edit') && formData.archivos_url && formData.archivos_url.length > 0 && (
                                <div className="row">
                                    <h5>Archivos Adjuntos</h5>
                                    <ul className="collection">
                                        {formData.archivos_url.map((fileUrl, index) => (
                                            <li key={index} className="collection-item">
                                                <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                                                    📄 Archivo {index + 1}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {formData.archivos.length > 0 && (
                                <div className="row">
                                    <div className="col s12">
                                        <ul className="collection">
                                            {formData.archivos.map((file, index) => (
                                                <li key={index} className="collection-item">
                                                    {file.name} ({(file.size / 1024).toFixed(2)} KB)
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            {(mode === 'view' || mode === 'edit') && (
                                <>
                                    <h5 className="message-header">Historial de Mensajes</h5>
                                    <div className="message-history">
                                        {justificacion && justificacion.mensajes && justificacion.mensajes.length > 0 ? (
                                            justificacion.mensajes.map((mensaje, index) => (
                                                <div
                                                    key={index}
                                                    className={`message-item ${mensaje.usuario === codigo_usuario ? 'sent' : 'received'}`}
                                                >
                                                    <div className="message-bubble">
                                                        <strong>{mensaje.titular || 'Usuario Desconocido'}</strong>
                                                        <p>{mensaje.texto}</p>
                                                        {mensaje.archivo && (
                                                            <a
                                                                href={mensaje.archivo}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="file-link"
                                                            >
                                                                📎 Ver Archivo
                                                            </a>
                                                        )}
                                                        <small className="message-date">
                                                            {new Date(mensaje.fecha).toLocaleString()}
                                                        </small>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="no-messages">No hay mensajes aún.</p>
                                        )}
                                    </div>

                                    <h5>Agregar Nuevo Mensaje</h5>
                                    <div className="new-message-section">
                                        <div className="row">
                                            <div className="input-field col s12">
                                                <textarea
                                                    id="newMessage"
                                                    name="newMessage"
                                                    className="materialize-textarea"
                                                    value={newMessage}
                                                    onChange={(e) => setNewMessage(e.target.value)}
                                                ></textarea>
                                                <label htmlFor="newMessage" className={newMessage ? 'active' : ''}>
                                                    Escribe un mensaje...
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="file-field input-field col s12">
                                                <div className="btn">
                                                    <span>Subir Archivo</span>
                                                    <input
                                                        type="file"
                                                        onChange={(e) => setMessageFile(e.target.files[0])}
                                                    />
                                                </div>
                                                <div className="file-path-wrapper">
                                                    <input
                                                        className="file-path validate"
                                                        type="text"
                                                        placeholder="Selecciona un archivo"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <button
                                                className={`btn green ${uploading ? 'disabled' : ''}`}
                                                onClick={handleAddMessage}
                                                disabled={uploading}
                                            >
                                                {uploading ? 'Guardando...' : 'Agregar Mensaje'}
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}




                        </form>
                        {/* Opcional: Mostrar estado de subida */}
                        {uploading && (
                            <div className="progress">
                                <div className="indeterminate"></div>
                            </div>
                        )}
                        {uploadError && (
                            <div className="red-text text-darken-2">
                                {uploadError}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JustificacionFormPage;