// src/components/ReservaFormAdminPage.js 

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    get_reserva,
    update_reserva,
    save_reserva,
    anular_reserva,
    validate_turno,
    generar_pdf,
    update_constancia_alquiler_reserva,
    update_constancia_garantia_reserva,
} from './reservaThunk';
import { clearReservaSeleccionada, clearTurnoValidation } from '../mis-areas-comunes/reservaSlice';
import { get_areas_comunes } from '../mis-areas-comunes/area_comunThunk';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './reservaFormPage.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import Cleave from 'cleave.js/react';
import { parseFromYYYYMM, formatPeriodo } from '../../utils/util';
import { TIPO_AREA_COMUN_GIMNASIO, TIPO_AREA_COMUN_PARRILLA, TIPO_AREA_COMUN_SALA_YOGA, TIPO_AREA_COMUN_SUM, RESERVA_ESTADO_RESERVADO, RESERVA_ESTADO_ANULADO } from '../../constants/constants';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';
import { get_unidades_facturacion_x_edificio } from '../edificio/unidad_facturacionThunk';
import { ref, uploadBytes, getDownloadURL, connectStorageEmulator } from 'firebase/storage';
import { storage } from '../../firebase';

const ReservaFormAdmin = ({ mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    useMaterializeTooltips();


    registerLocale('es', es);
    const { id: idReserva } = useParams();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const edificioSeleccionado = useSelector(state => state.edificio.edificio);
    const isAdminPath = window.location.pathname.includes("/reserva-admin");
    const unidades_facturacion = useSelector(state => state.unidad_facturacion.unidades_facturacion) || [];
    const buildingId = edificioSeleccionado?.id || '';
    const [loading, setLoading] = useState(false);
    const [isEditingDevolucion, setIsEditingDevolucion] = useState(false);

    // Estado para controlar descarga del PDF generado
    const [pdfStatus, setPdfStatus] = useState("idle");

    // Estados para determinar el modo de edición/creación
    //const [isEditing, setIsEditing] = useState(mode === 'edit');
    const [isEditing, setIsEditing] = useState(mode === 'edit' || mode === 'create');

    const [isCreating, setIsCreating] = useState(mode === 'create');

    // Función auxiliar para determinar si estamos en modo "edit" o "create"
    const isInEditOrCreateMode = () => isEditing || isCreating;

    // Estado para manejar la imagen de constancia de pago
    const [constanciaPagoAlquiler, setConstanciaPagoAlquiler] = useState(null);
    const [uploadingAlquiler, setUploadingAlquiler] = useState(false);
    const [uploadErrorLocalAlquiler, setUploadErrorLocalAlquiler] = useState('');
    const fileInputRefAlquiler = useRef(null); // Referencia al input de archivo

    const [constanciaPagoGarantia, setConstanciaPagoGarantia] = useState(null);
    const [uploadingGarantia, setUploadingGarantia] = useState(false);
    const [uploadErrorLocalGarantia, setUploadErrorLocalGarantia] = useState('');
    const fileInputRefGarantia = useRef(null); // Referencia al input de archivo

    const [selectedAreaComun, setSelectedAreaComun] = useState("SUM");

    // Obtener áreas comunes de tipo SUM desde Redux
    const areasComun = useSelector((state) => state.area_comun.areas_comunes);
    const reserva = useSelector((state) => state.reserva.reserva);
    const {
        status_save_reserva,
        error_save_reserva,
        status_update_reserva,
        error_update_reserva,
        status_anular_reserva,
        error_anular_reserva,
        status_validate_turno,
        error_validate_turno,
        turno_available,
        turno_message,
        error_update_constancia_garantia_reserva,
        error_update_constancia_alquiler_reserva,
    } = useSelector((state) => state.reserva);

    const usuario = useSelector((state) => state.auth.usuario);
    const codigo_usuario = usuario ? usuario.id : null;
    //console.log(" JSON.stringify(usuario) " + JSON.stringify(usuario))

    // Acceder a los datos pasados desde Calendario_reservasPage.js
    const { area_comun, fecha_reserva, turno } = location.state || {};

    const [formData, setFormData] = useState({
        tipo_area_comun: '',
        area_comun: '',
        fecha_reserva: new Date(),
        idturno: '',
        turno: '',
        turno_hora_inicio: '',
        turno_hora_fin: '',
        costo: 0,
        garantia: 0,
        aforo: 0,
        codigo_unidad_facturacion: '',
    });


    const [turnosDisponibles, setTurnosDisponibles] = useState([]);

    // Estados para manejo de carga y errores en modo "view" y "edit"
    const [loadingReserva, setLoadingReserva] = useState(false);
    const [errorReserva, setErrorReserva] = useState('');

    // Verificar autenticación
    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    // Función para formatear fechas locales
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2); // getMonth() es 0-based
        const day = (`0${date.getDate()}`).slice(-2);
        return `${year}-${month}-${day}`;
    };

    /**
     * Parsea una cadena de fecha 'YYYY-MM-DD' como fecha local.
     * @param {string} dateString - La cadena de fecha en formato 'YYYY-MM-DD'.
     * @returns {Date} - Objeto Date basado en la hora local.
     */
    const parseLocalDate = (dateString) => {
        const [year, month, day] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day); // Meses en JavaScript son 0-based
    };

    // Obtener áreas comunes al montar el componente
    useEffect(() => {
        // Obtener todas las áreas comunes de tipo SUM al cargar la página
        dispatch(get_unidades_facturacion_x_edificio(buildingId));
        dispatch(get_areas_comunes({ buildingId }));
    }, [buildingId, dispatch]);

    // **Nueva Función: Manejar la selección de archivo**
    const handleFileChangeAlquiler = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Validar el tipo de archivo (imagen)
            if (!file.type.startsWith('image/')) {
                M.toast({ html: 'Por favor, selecciona un archivo de imagen.', classes: 'red' });
                return;
            }
            setConstanciaPagoAlquiler(file);
        }
    };
    const handleInputChangeDevolucion = (event) => {
        const { name, value } = event.target;

        setFormData(prev => ({
            ...prev,
            recaudacion_garantia: {
                ...prev.recaudacion_garantia, // Mantener los valores previos de recaudacion_garantia
                [name]: value, // Actualizar solo el campo modificado
            }
        }));
    };
    const handleUploadConstanciaGarantia = async () => {
        if (!constanciaPagoGarantia) {
            M.toast({ html: 'Por favor, selecciona una imagen para la constancia de Garantia.', classes: 'red' });
            return;
        }

        setUploadingGarantia(true);
        setUploadErrorLocalGarantia('');

        try {
            // Conectar al emulador de Storage si se está ejecutando en local
            if (window.location.hostname === 'localhost') {
                connectStorageEmulator(storage, 'localhost', 9199);
            }
            // Crear una referencia al archivo en Firebase Storage
            const storageRefPath = `cargaReservas/${idReserva}/Garantia_${constanciaPagoGarantia.name}_${Date.now()}`;
            const constanciaRef = ref(storage, storageRefPath);

            // Subir el archivo
            const snapshot = await uploadBytes(constanciaRef, constanciaPagoGarantia);

            // Obtener la URL de descarga
            const downloadURL = await getDownloadURL(constanciaRef);

            console.log("Constancia de pago de Garantia subida. URL:", downloadURL);

            // Preparar los datos para enviar al backend
            const dataToSave = {
                usuarioRegistrador: codigo_usuario,
                constancia_pago_garantia_url: downloadURL,
                // Asegúrate de que el backend también registre la fecha de carga
            };
            const resultAction = await dispatch(update_constancia_garantia_reserva({ idReserva, reservaData: dataToSave }));
            if (update_constancia_garantia_reserva.fulfilled.match(resultAction)) {
                M.toast({ html: 'Constancia de pago de Garantia subida exitosamente.', classes: 'green' });
                // Opcional: Refrescar los detalles de la reserva si no se actualizan automáticamente
                dispatch(get_reserva(idReserva));
            } else {
                const errorMessage = error_update_constancia_garantia_reserva || 'Ocurrió un error al actualizar la constancia.';
                M.toast({ html: errorMessage, classes: 'red' });
                return;
            }

            // Resetear el archivo seleccionado
            setConstanciaPagoGarantia(null);
        } catch (error) {
            console.error("Error al subir la constancia de pago de Garantia:", error);
            setUploadErrorLocalGarantia(error.message || 'Ocurrió un error al subir la constancia de pago de Garantia.');
            M.toast({ html: uploadErrorLocalGarantia || 'Error al subir la constancia de pago de Garantia.', classes: 'red' });
        } finally {
            setUploadingGarantia(false);
        }
    };
    // **Nueva Función: Manejar la subida de la constancia de pago a Firebase Storage**
    const handleUploadConstanciaAlquiler = async () => {
        if (!constanciaPagoAlquiler) {
            M.toast({ html: 'Por favor, selecciona una imagen para la constancia de Alquiler.', classes: 'red' });
            return;
        }

        setUploadingAlquiler(true);
        setUploadErrorLocalAlquiler('');

        try {
            // Conectar al emulador de Storage si se está ejecutando en local
            if (window.location.hostname === 'localhost') {
                connectStorageEmulator(storage, 'localhost', 9199);
            }
            // Crear una referencia al archivo en Firebase Storage
            const storageRefPath = `cargaReservas/${idReserva}/Alquiler_${constanciaPagoAlquiler.name}_${Date.now()}`;
            const constanciaRef = ref(storage, storageRefPath);

            // Subir el archivo
            const snapshot = await uploadBytes(constanciaRef, constanciaPagoAlquiler);

            // Obtener la URL de descarga
            const downloadURL = await getDownloadURL(constanciaRef);

            console.log("Constancia de pago de Alquiler subida. URL:", downloadURL);

            // Preparar los datos para enviar al backend
            const dataToSave = {
                usuarioRegistrador: codigo_usuario,
                constancia_pago_alquiler_url: downloadURL,
                // Asegúrate de que el backend también registre la fecha de carga
            };
            const resultAction = await dispatch(update_constancia_alquiler_reserva({ idReserva, reservaData: dataToSave }));
            if (update_constancia_alquiler_reserva.fulfilled.match(resultAction)) {
                M.toast({ html: 'Constancia de pago de Alquiler subida exitosamente.', classes: 'green' });
                // Opcional: Refrescar los detalles de la reserva si no se actualizan automáticamente
                dispatch(get_reserva(idReserva));
            } else {
                const errorMessage = error_update_constancia_alquiler_reserva || 'Ocurrió un error al actualizar la constancia.';
                M.toast({ html: errorMessage, classes: 'red' });
                return;
            }

            // Resetear el archivo seleccionado
            setConstanciaPagoAlquiler(null);
        } catch (error) {
            console.error("Error al subir la constancia de pago de alquiler:", error);
            setUploadErrorLocalAlquiler(error.message || 'Ocurrió un error al subir la constancia de pago de alquiler.');
            M.toast({ html: uploadErrorLocalAlquiler || 'Error al subir la constancia de pago de Alquiler.', classes: 'red' });
        } finally {
            setUploadingAlquiler(false);
        }
    };

    const handleSaveDevolucion = async () => {
        if (!reserva || !reserva.id) {
            M.toast({ html: 'No se encontró la reserva para actualizar.', classes: 'red' });
            return;
        }

        // Validar que todos los campos de devolución estén completos
        //console.log("formData.recaudacion_garantia.banco_devolucion "+formData.recaudacion_garantia.banco_devolucion + " formData.cuenta_devolucion "+formData.recaudacion_garantia.cuenta_devolucion + " formData.recaudacion_garantia.dni_titular "+formData.recaudacion_garantia.dni_titular +" formData..recaudacion_garantiatitular_cuenta "+formData.recaudacion_garantia.titular_cuenta )
        if (!formData.recaudacion_garantia.banco_devolucion || !formData.recaudacion_garantia.cuenta_devolucion || !formData.recaudacion_garantia.dni_titular || !formData.recaudacion_garantia.titular_cuenta) {
            M.toast({ html: 'Por favor, complete todos los campos de devolución.', classes: 'red' });
            return;
        }

        setLoading(true);
        try {
            // Datos a actualizar en la reserva
            const dataToUpdate = {
                ...reserva, // Mantener toda la estructura de reserva
                recaudacion_garantia: {
                    ...reserva.recaudacion_garantia, // Mantener datos previos
                    ...formData.recaudacion_garantia, // Sobreescribir con los nuevos valores de la devolución
                },
            };
            // Enviar la actualización al backend
            const resultAction = await dispatch(update_reserva({ idReserva: reserva.id, reservaData: dataToUpdate }));

            if (update_reserva.fulfilled.match(resultAction)) {
                M.toast({ html: 'Datos de devolución guardados correctamente.', classes: 'green' });
                dispatch(get_reserva(reserva.id)); // Recargar datos
            } else {
                throw new Error(error_update_reserva || 'Error al guardar los datos de devolución.');
            }
        } catch (error) {
            console.error('Error en handleSaveDevolucion:', error);
            M.toast({ html: error.message, classes: 'red' });
        } finally {
            setLoading(false);
        }
    };

    const handleFileChangeGarantia = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Validar el tipo de archivo (imagen)
            if (!file.type.startsWith('image/')) {
                M.toast({ html: 'Por favor, selecciona un archivo de imagen.', classes: 'red' });
                return;
            }
            setConstanciaPagoGarantia(file);
        }
    };

    // Limpiar reserva seleccionada y validación al crear
    useEffect(() => {
        if (isCreating) {
            dispatch(clearReservaSeleccionada());
            dispatch(clearTurnoValidation());
        }
    }, [isCreating, dispatch]);

    useEffect(() => {
        if (areasComun.length > 0 && unidades_facturacion.length > 0) {
            const primeraArea = areasComun[0]; // Obtiene la primera área común de la lista
            const firstTurno = primeraArea.turnos[0] || {};
            const primeraUnidadFacturacion = unidades_facturacion[0].codigo_unidad_facturacion;
            
            //console.log(" JSON.stringify(primeraArea  "+JSON.stringify(primeraArea))
            setSelectedAreaComun(primeraArea);

            setTurnosDisponibles(primeraArea.turnos);

            setFormData(prev => ({
                ...prev,
                tipo_area_comun: primeraArea.tipo,
                area_comun: primeraArea.nombre,
                idturno: firstTurno.idturno || '',
                turno: firstTurno.turno || '',
                descripcion: firstTurno.descripcion || '',
                turno_hora_inicio: firstTurno.turno_hora_inicio || '',
                turno_hora_fin: firstTurno.turno_hora_fin || '',
                costo: firstTurno.costo || 0,
                garantia: firstTurno.garantia || 0,
                aforo: firstTurno.aforo || 0,
                codigo_unidad_facturacion: primeraUnidadFacturacion || '',
            }));
        }
    }, [areasComun, unidades_facturacion]);

    // Manejar el modo "view" y "edit" para obtener y poblar los datos de la reserva
    useEffect(() => {
        //console.log("ANALIZANDO mode " + mode + " idReserva: " + idReserva);
        if ((mode === 'view' || mode === 'edit') && idReserva) { // Añadido modo 'edit'
            setLoadingReserva(true);            

            dispatch(get_reserva(idReserva))
                .unwrap()
                .then((res) => {
                    //console.log("==> Trajo reserva y hara setFormData con JSON.stringify(res): " + JSON.stringify(res));
                    //console.log("==> res.area_comun: " + res.area_comun);
                    //console.log("==> JSON.stringify(areasComun): " + JSON.stringify(areasComun));

                    // Encontrar el objeto de area_comun en areasComun basado en el nombre
                    const selectedArea = areasComun.find(area => area.nombre === res.area_comun);
                    //console.log("==> JSON.stringify(selectedArea): " + JSON.stringify(selectedArea));

                    if (selectedArea) {
                        setSelectedAreaComun(selectedArea);
                        setTurnosDisponibles(selectedArea.turnos);
                        setFormData({
                            tipo_area_comun: res.tipo_area_comun,
                            area_comun: res.area_comun, // ya es una cadena
                            fecha_reserva: parseLocalDate(res.fecha_reserva), // Usar parseLocalDate
                            idturno: res.idturno,
                            turno: res.turno.turno,
                            descripcion: res.turno.descripcion,
                            turno_hora_inicio: res.turno.turno_hora_inicio,
                            turno_hora_fin: res.turno.turno_hora_fin,
                            costo: res.turno.costo,
                            garantia: res.turno.garantia,
                            aforo: res.turno.aforo,
                            codigo_unidad_facturacion: res.codigo_unidad_facturacion || '',
                        });
                    } else {
                        //console.error("Área Común no encontrada en areasComun");
                        //M.toast({ html: 'Área Común no encontrada.', classes: 'red' });
                    }

                    setLoadingReserva(false);
                })
                .catch((error) => {
                    console.error("Error al obtener la reserva:", error);
                    setErrorReserva(error.message || 'Error al obtener la reserva.');
                    setLoadingReserva(false);
                });
        }
    }, [mode, idReserva, dispatch, areasComun]);


    // Escuchar cambios en formData para realizar acciones dependientes (si es necesario)
    useEffect(() => {
        //console.log("formData actualizado:", JSON.stringify(formData));

        // Inicializar los selects de Materialize
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields();
    }, [formData]);

    useEffect(() => {
        //console.log("formData actualizado:", JSON.stringify(formData));

        // Inicializar los selects de Materialize
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields();
    }, [unidades_facturacion]);



    // Manejar cambios en los inputs
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };


    const handleDateChange = (date) => {
        setFormData(prev => ({ ...prev, fecha_reserva: date }));
    };

    const handleAreaComunChange = (e) => {
        const selectedAreaNombre = e.target.value;

        // Encontrar el área seleccionada para obtener sus turnos
        const selectedArea = areasComun.find(area => area.nombre === selectedAreaNombre);

        setSelectedAreaComun(selectedArea);

        if (selectedArea) {
            //console.log("Area Encontrada:", selectedArea);

            // Establecer las opciones disponibles para el turno
            setTurnosDisponibles(selectedArea.turnos);

            if (selectedArea.turnos.length > 0) {
                const firstTurno = selectedArea.turnos[0];
                setFormData(prev => ({
                    ...prev,
                    tipo_area_comun: selectedArea.tipo,
                    area_comun: selectedArea.nombre,
                    idturno: firstTurno.idturno || '',
                    turno: firstTurno.turno || '',
                    descripcion: firstTurno.descripcion || '',
                    turno_hora_inicio: firstTurno.turno_hora_inicio || '',
                    turno_hora_fin: firstTurno.turno_hora_fin || '',
                    costo: firstTurno.costo || 0,
                    garantia: firstTurno.garantia || 0,
                    aforo: firstTurno.aforo || 0,

                }));
            } else {
                // Si no hay turnos disponibles, resetear los campos relacionados
                setFormData(prev => ({
                    ...prev,
                    tipo_area_comun: '',
                    area_comun: '',
                    idturno: '',
                    turno: '',
                    descripcion: '',
                    turno_hora_inicio: '',
                    turno_hora_fin: '',
                    costo: 0,
                    garantia: 0,
                    aforo: 0,
                }));
            }
        } else {
            //console.log("Área no encontrada o no existe");
            setTurnosDisponibles([]);
            setFormData(prev => ({
                ...prev,
                idturno: '',
                turno: '',
                descripcion: '',
                turno_hora_inicio: '',
                turno_hora_fin: '',
                costo: 0,
                garantia: 0,
                aforo: 0,
            }));
        }
    };

    const formatNumber = (number) => {
        if (isNaN(number)) return '';
        return new Intl.NumberFormat('es-PE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(number);
    };
    const handlePrintPDF = async () => {
        if (!reserva) return;

        const url = reserva.url_pdf;

        if (url) {
            // Si ya existe el PDF, abrir en nueva pestaña
            window.open(url, '_blank');
        } else {
            try {
                setPdfStatus("generating");

                const resultAction = await dispatch(generar_pdf(idReserva));

                if (generar_pdf.fulfilled.match(resultAction)) {
                    const nuevoURL = resultAction.payload?.url_pdf;

                    if (nuevoURL) {
                        window.open(nuevoURL, '_blank');
                    } else {
                        M.toast({ html: 'No se pudo obtener el PDF generado.', classes: 'red' });
                    }
                } else {
                    throw new Error("Error al generar PDF");
                }
            } catch (error) {
                console.error("Error generando PDF:", error);
                M.toast({ html: 'Error al generar PDF.', classes: 'red' });
            } finally {
                setPdfStatus("idle");
            }
        }
    };
    const handleSave = async () => {
        setLoading(true);
        try {
            console.log("Datos al guardar:", formData);
            if (!formData.area_comun || !formData.idturno || !formData.codigo_unidad_facturacion) {
                M.toast({ html: 'Todos los campos son obligatorios.', classes: 'red' });
                return;
            }
            if (turno_available === false) {
                M.toast({ html: turno_message || 'El turno seleccionado no está disponible.', classes: 'red' });
                return;
            }
            console.log("OJO OJO selectedAreaComun?.tipo_area_comun: " + selectedAreaComun?.tipo);
            const dataToSave = {
                tipo_area_comun: selectedAreaComun?.tipo,
                area_comun: formData.area_comun,
                fecha_reserva: formatDate(formData.fecha_reserva),
                idturno: formData.idturno,
                turno: {
                    idturno: formData.idturno,
                    turno: formData.turno,
                    descripcion: formData.descripcion,
                    turno_hora_inicio: formData.turno_hora_inicio,
                    turno_hora_fin: formData.turno_hora_fin,
                    costo: formData.costo,
                    garantia: formData.garantia,
                    aforo: formData.aforo,
                },
                usuarioRegistrador: codigo_usuario,
                buildingId,
                codigo_unidad_facturacion: formData.codigo_unidad_facturacion,
            };

            let newIdReserva = idReserva; // Mantener el id actual en caso de edición

            if (isEditing && mode !== 'create') {
                // Actualizar reserva
                const resultAction = await dispatch(update_reserva({ idReserva, reservaData: dataToSave }));
                if (update_reserva.fulfilled.match(resultAction)) {
                    M.toast({ html: 'Reserva actualizada exitosamente.', classes: 'green' });
                } else {
                    const errorMessage = error_update_reserva || 'Ocurrió un error al actualizar la reserva.';
                    M.toast({ html: errorMessage, classes: 'red' });
                    return;
                }
            } else if (isCreating) {
                // Crear nueva reserva
                const resultAction = await dispatch(save_reserva(dataToSave));
                if (save_reserva.fulfilled.match(resultAction)) {
                    newIdReserva = resultAction.payload.id; // Obtener el ID de la reserva creada
                    M.toast({ html: 'Reserva creada exitosamente.', classes: 'green' });
                } else {
                    const errorMessage = error_save_reserva || 'Ocurrió un error al guardar la reserva.';
                    M.toast({ html: errorMessage, classes: 'red' });
                    return;
                }
            }
            if (newIdReserva) {
                // Cambiar a modo 'view' y recargar los datos de la reserva
                setIsEditing(false);
                setIsCreating(false);
                //console.log("OJO selectedAreaComun?.tipo: " +selectedAreaComun?.tipo)
                navigate(getReservaAdminViewPath(selectedAreaComun?.tipo, newIdReserva)); // Asegura que la URL también refleje el modo view
                dispatch(get_reserva(idReserva));

            }
        } catch (error) {
            M.toast({ html: 'Error al guardar la reserva.', classes: 'red' });
        } finally {
            setLoading(false);
        }
    };
    // Función auxiliar para obtener la ruta de visualización según tipo_area_comun
    const getReservaAdminViewPath = (tipo_area_comun, id) => {
        const rutas = {
            "SUM": `/reserva-admin-sum/view/${id}`,
            "PARRILLA": `/reserva-admin-parrilla/view/${id}`,
            "GIMNASIO": `/reserva-admin-gym/view/${id}`,
            "YOGA": `/reserva-admin-yoga/view/${id}`
        };

        return rutas[tipo_area_comun] || `/reserva-admin/view/${id}`;

    };
    const handleCrearNuevaReserva = () => {
        dispatch(clearReservaSeleccionada());
        dispatch(clearTurnoValidation());
        setIsEditing(false);
        setIsCreating(true);
        setFormData({
            area_comun: '',
            fecha_reserva: new Date(),
            idturno: '',
            turno: '',
            turno_hora_inicio: '',
            turno_hora_fin: '',
            costo: 0,
            garantia: 0,
            aforo: 0,
            codigo_unidad_facturacion: '',
        });
    };
    const handleSaveOLD = async () => {
        console.log("Datos al guardar:", formData); // Depuración
        if (!formData.area_comun || !formData.idturno || !formData.codigo_unidad_facturacion) {
            M.toast({ html: 'Todos los campos son obligatorios.', classes: 'red' });
            return;
        }

        const dataToSave = {
            ...formData,
            fecha_reserva: formatDate(formData.fecha_reserva),
            buildingId,
            usuarioRegistrador: codigo_usuario,
            turno: {
                idturno: formData.idturno,
                turno: formData.turno,
                descripcion: formData.descripcion,
                turno_hora_inicio: formData.turno_hora_inicio,
                turno_hora_fin: formData.turno_hora_fin,
                costo: formData.costo,
                garantia: formData.garantia,
                aforo: formData.aforo,
            },
        };

        try {
            console.log(" ANTES DE GRABAR dataToSave " + dataToSave)
            if (mode === 'edit') {
                await dispatch(update_reserva({ idReserva, reservaData: dataToSave })).unwrap();
                M.toast({ html: 'Reserva actualizada exitosamente.', classes: 'green' });

                // Permanecer en la misma página en modo "view"
                setIsEditing(false);
                dispatch(get_reserva(idReserva)); // Refrescar los datos actualizados

            } else {
                const nuevaReserva = await dispatch(save_reserva(dataToSave)).unwrap();
                M.toast({ html: 'Reserva creada exitosamente.', classes: 'green' });

                if (nuevaReserva?.id) {
                    // Establecer la reserva en modo vista y obtener sus datos
                    setIsEditing(false);
                    setIsCreating(false);
                    navigate(`/reserva-admin/view/${nuevaReserva.id}`);
                    dispatch(get_reserva(nuevaReserva.id));
                }
            }

            //navigate('/dashboard');
        } catch (error) {
            M.toast({ html: 'Error al guardar la reserva.', classes: 'red' });
        }
    };

    const handleValidateTurno = async () => {
        // Validar que todos los campos necesarios estén seleccionados
        if (!formData.area_comun) {
            M.toast({ html: 'Por favor, selecciona un Área Común.', classes: 'red' });
            return;
        }

        if (!formData.fecha_reserva) {
            M.toast({ html: 'Por favor, selecciona una Fecha de Reserva.', classes: 'red' });
            return;
        }

        if (!formData.turno_hora_inicio || !formData.turno_hora_fin) {
            M.toast({ html: 'El horario seleccionado no es válido.', classes: 'red' });
            return;
        }

        // Despachar el Thunk para validar el turno
        dispatch(validate_turno({
            buildingId,
            area_comun: formData.area_comun,
            fecha_reserva: formatDate(formData.fecha_reserva), // Usar formatDate para enviar la fecha
            idturno: formData.idturno,
            turno: formData.turno,
            descripcion: formData.descripcion,
            turno_hora_inicio: formData.turno_hora_inicio,
            turno_hora_fin: formData.turno_hora_fin,
        }));
    };

    const handleEdit = () => {
        setIsEditing(true);
    };
    // **Actualización: Cambiar el manejador de turno para usar idturno**
    const handleIdturnoChange = (e) => {
        const selectedIdturno = e.target.value;
        setFormData(prev => ({ ...prev, idturno: selectedIdturno }));

        //console.log("selectedIdturno: " + selectedIdturno);

        // Encontrar el turno seleccionado para obtener sus atributos
        const selectedTurno = turnosDisponibles.find(turno => turno.idturno === selectedIdturno);

        if (selectedTurno) {
            setFormData(prev => ({
                ...prev,
                tipo_area_comun: selectedAreaComun?.tipo,
                turno: selectedTurno.turno,
                descripcion: selectedTurno.descripcion,
                turno_hora_inicio: selectedTurno.turno_hora_inicio,
                turno_hora_fin: selectedTurno.turno_hora_fin,
                costo: selectedTurno.costo,
                garantia: selectedTurno.garantia,
                aforo: selectedTurno.aforo,
            }));
            dispatch(clearTurnoValidation());
        } else {
            //console.log("Turno no encontrado o no existe");
            setFormData(prev => ({
                ...prev,
                turno: '',
                descripcion: '',
                turno_hora_inicio: '',
                turno_hora_fin: '',
                costo: 0,
                garantia: 0,
                aforo: 0,
            }));
        }
    };

    const handleAnular = () => {
        if (window.confirm("¿Estás seguro de que quieres anular esta reserva? Esta acción no se puede deshacer.")) {
            dispatch(anular_reserva({ idReserva, usuarioAnulacion: codigo_usuario }))
                .then((resultAction) => {
                    if (anular_reserva.fulfilled.match(resultAction)) {
                        M.toast({ html: 'Reserva anulada correctamente', classes: 'rounded red' });
                        handleRetornar();

                    } else {
                        const errorMessage = error_anular_reserva || 'Ocurrió un error al anular la reserva.';
                        M.toast({ html: errorMessage, classes: 'red' });
                    }
                });
        }
    };

    const handleRetornar = () => {
        dispatch(clearReservaSeleccionada());
        navigate('/dashboard')

    };

    const handleCancel = () => {
        setIsEditing(false);
        dispatch(clearReservaSeleccionada());
        if (mode === 'create') {
            handleRetornar();
        }
    };


    return (
        <div className="container" >
            {loading && (
                <div className="reserva-overlay">
                    <div className="reserva-spinner-container">
                        <div className="reserva-lds-ring"><div></div><div></div><div></div><div></div></div>
                        <p>Procesando reserva...</p>
                    </div>
                </div>
            )}
            <div className="card" >
                <div className="card-content">
                    <div className="card-action">
                        {isInEditOrCreateMode() ? (
                            <>
                                <button
                                    type="button"
                                    className="btn green tooltipped"
                                    data-position="top"
                                    data-tooltip="Guardar cambios"
                                    onClick={handleSave}
                                    disabled={loading}
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    <i className="material-icons left">save</i>
                                    {loading ? 'Guardando...' : 'Guardar'}
                                </button>
                                <button
                                    type="button"
                                    className="btn red tooltipped"
                                    data-position="top"
                                    data-tooltip="Cancelar edición"
                                    onClick={handleCancel}
                                >
                                    <i className="material-icons left">cancel</i>Cancelar
                                </button>
                            </>
                        ) : (
                            <>
                                {mode === 'view' && (
                                    <>
                                        <button
                                            type="button"
                                            className={`btn blue tooltipped ${pdfStatus === 'generating' ? 'disabled' : ''}`}
                                            data-position="top"
                                            data-tooltip="Imprimir PDF"
                                            onClick={handlePrintPDF}
                                            disabled={pdfStatus === 'generating'}
                                        >
                                            <i className="material-icons left">picture_as_pdf</i>
                                            {pdfStatus === 'generating' ? 'Generando...' : 'Imprimir PDF'}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn orange tooltipped"
                                            data-tooltip="Anular Reserva"
                                            onClick={handleAnular}
                                        >
                                            <i className="material-icons left">delete</i>Anular
                                        </button>
                                    </>
                                )}
                            </>
                        )}
                        {mode === 'view' && (

                            <button
                                type="button"
                                className="btn blue tooltipped"
                                data-position="top"
                                data-tooltip="Volver"
                                onClick={handleRetornar}
                            >
                                <i className="material-icons left">arrow_back</i>Volver
                            </button>
                        )}
                        {mode === 'view' && (
                            <button
                                type="button"
                                className="btn purple tooltipped"
                                data-position="top"
                                data-tooltip="Crear Nueva Reserva"
                                onClick={handleCrearNuevaReserva}
                            >
                                <i className="material-icons left">add</i>Crear Nueva Reserva
                            </button>
                        )}
                    </div>

                    <span className="card-title">
                        {mode === 'create'
                            ? 'Crear Reserva '
                            : mode === 'edit'
                                ? 'Editar Reserva '
                                : mode === 'view'
                                    ? 'Detalles de la Reserva'
                                    : 'Detalles de la Reserva'}
                    </span>

                    {/* Mostrar indicadores de carga o error en modo "view" o "edit" */}
                    {(mode === 'view' || mode === 'edit') && loadingReserva && (
                        <div className="row">
                            <div className="col s12 center-align">
                                <div className="preloader-wrapper small active">
                                    <div className="spinner-layer spinner-blue-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                                <p>Cargando detalles de la reserva...</p>
                            </div>
                        </div>
                    )}

                    {(mode === 'view' || mode === 'edit') && errorReserva && (
                        <div className="row">
                            <div className="col s12 center-align">
                                <span className="red-text">Error: {errorReserva}</span>
                            </div>
                        </div>
                    )}
                    <div  >
                        {/* Mostrar el formulario solo si no está cargando en modo "view" o "edit" */}
                        {!(mode === 'view' && loadingReserva) && !(mode === 'edit' && loadingReserva) && (

                            <form onSubmit={e => e.preventDefault()}>

                                {/* Primera fila: Área Común y Fecha de Reserva con Botón Validar Turno */}
                                <div className="row">
                                    {/* Área Común */}
                                    <div className="input-field col s12 m3">
                                        <select
                                            name="area_comun"
                                            value={formData.area_comun}
                                            onChange={handleAreaComunChange}
                                            disabled={!isInEditOrCreateMode()}
                                        >
                                            {areasComun.map(area => (
                                                <option key={area.id} value={area.nombre}>{area.nombre}</option>
                                            ))}
                                        </select>
                                        <label htmlFor="area_comun">Área Común:</label>
                                    </div>
                                    {/* Unidad de facturación */}
                                    <div className="input-field col s6 m3">
                                        <select
                                            name="codigo_unidad_facturacion"
                                            value={formData.codigo_unidad_facturacion || ''}
                                            onChange={handleInputChange}
                                            disabled={!isEditing}
                                        >
                                            {Array.isArray(unidades_facturacion) && unidades_facturacion.map(s => (
                                                <option key={s.codigo_unidad_facturacion} value={s.codigo_unidad_facturacion}>
                                                    {s.codigo_unidad_facturacion}
                                                </option>
                                            ))}
                                        </select>
                                        <label>Unid. Facturac.</label>
                                    </div>

                                    {/* Fecha de Reserva */}
                                    <div className="input-field col s12 m3">
                                        <DatePicker
                                            selected={formData.fecha_reserva}
                                            onChange={handleDateChange}
                                            name="fecha_reserva"
                                            dateFormat="dd/MM/yyyy"
                                            locale="es"
                                            disabled={!isInEditOrCreateMode()}
                                            className="datepicker"
                                            minDate={mode !== 'view' ? new Date() : undefined} // En modo "view", permitir ver fechas pasadas
                                        />
                                        <label className="active" htmlFor="fecha_reserva">Fecha de Reserva:</label>
                                    </div>

                                    {/* Botón Validar Turno */}
                                    {isInEditOrCreateMode() && (
                                        <div className="col s12 m4" style={{ display: 'flex', alignItems: 'flex-end' }}>
                                            <button
                                                type="button"
                                                className="btn yellow darken-3 tooltipped"
                                                data-position="top"
                                                data-tooltip="Validar Turno"
                                                onClick={handleValidateTurno}
                                            >
                                                <i className="material-icons left">check_circle</i>Validar Turno
                                            </button>
                                        </div>
                                    )}
                                    {mode === 'view' && (
                                        <div className="row">
                                            <div className="input-field col s6 m3">
                                                <input
                                                    type="text"
                                                    name="estado"
                                                    value={reserva?.estado || "Desconocido"}
                                                    disabled
                                                />
                                                <label className="active" htmlFor="estado">Estado:</label>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* Mensajes de Validación */}
                                {isInEditOrCreateMode() && (
                                    <div className="row">
                                        <div className="col s12 m8 offset-m3">
                                            {/* Mostrar resultado de la validación */}
                                            {status_validate_turno === 'loading' && (
                                                <div className="row">
                                                    <div className="col s12 m3">
                                                        <span>Validando...</span>
                                                    </div>
                                                </div>
                                            )}

                                            {status_validate_turno === 'succeeded' && turno_available !== null && (
                                                <div className={`row ${turno_available ? 'green-text' : 'red-text'}`}>
                                                    <div className="col s12 m3">
                                                        <span>{turno_message}</span>
                                                    </div>
                                                </div>
                                            )}

                                            {status_validate_turno === 'failed' && (
                                                <div className="row red-text">
                                                    <div className="col s12 m3">
                                                        <span>Error: {error_validate_turno}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {/* Segunda fila: Selector de Turno y Horas de Turno Inicio y Fin */}
                                <div className="row">
                                    {/* Selector de Turno */}
                                    <div className="input-field col s12 m3">
                                        <select
                                            name="idturno" // Cambiar el name a idturno
                                            value={formData.idturno}
                                            onChange={handleIdturnoChange} // Cambiar el manejador a handleIdturnoChange
                                            disabled={!isInEditOrCreateMode() || turnosDisponibles.length === 0}
                                        >
                                            {turnosDisponibles.map((turno) => (
                                                <option key={turno.idturno} value={turno.idturno}>{turno.descripcion}</option>
                                            ))}
                                        </select>
                                        <label htmlFor="idturno">Turno:</label>
                                    </div>

                                    {/* Hora Inicio */}
                                    <div className="input-field col s12 m2">
                                        <input
                                            type="text"
                                            name="turno_hora_inicio"
                                            value={formData.turno_hora_inicio}
                                            disabled
                                        />
                                        <label className="active" htmlFor="turno_hora_inicio">Hora Inicio:</label>
                                    </div>

                                    {/* Hora Fin */}
                                    <div className="input-field col s12 m2">
                                        <input
                                            type="text"
                                            name="turno_hora_fin"
                                            value={formData.turno_hora_fin}
                                            disabled
                                        />
                                        <label className="active" htmlFor="turno_hora_fin">Hora Fin:</label>
                                    </div>

                                </div>

                                {/* Tercera fila: Costo, Garantía y Aforo */}
                                <div className="row">
                                    {/* Costo */}
                                    <div className="input-field col s12 m2">
                                        <Cleave
                                            placeholder="0.00"
                                            name="costo"
                                            value={formatNumber(formData.costo)}
                                            options={{
                                                numeral: true,
                                                numeralDecimalMark: '.',
                                                delimiter: ',',
                                                numeralDecimalScale: 2
                                            }}
                                            onChange={handleInputChange}
                                            disabled={true} // Solo lectura
                                        />
                                        <label className="active" htmlFor="costo">Costo:</label>
                                    </div>

                                    {/* Garantía */}
                                    <div className="input-field col s12 m2">
                                        <Cleave
                                            placeholder="0.00"
                                            name="garantia"
                                            value={formatNumber(formData.garantia)}
                                            options={{
                                                numeral: true,
                                                numeralDecimalMark: '.',
                                                delimiter: ',',
                                                numeralDecimalScale: 2
                                            }}
                                            onChange={handleInputChange}
                                            disabled={true} // Solo lectura
                                        />
                                        <label className="active" htmlFor="garantia">Garantía:</label>
                                    </div>

                                    {/* Aforo */}
                                    <div className="input-field col s12 m2">
                                        <input
                                            type="number"
                                            name="aforo"
                                            value={formData.aforo}
                                            onChange={handleInputChange}
                                            disabled={true} // Solo lectura
                                        />
                                        <label className="active" htmlFor="aforo">Aforo:</label>
                                    </div>
                                </div>

                                {/* **Nueva Sección: Información de Pago de Reserva en Modo View/Edit** */}
                                {/* Estos campos y botón solo se muestran en modos 'view' y 'edit' */}
                                {!isCreating && reserva?.recaudacion_alquiler && (
                                    <>
                                        <div className="row">
                                            <div className="col s12">
                                                <h5>Información de Pago de Alquiler de Reserva</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* Banco */}
                                            <div className="input-field col s12 m2">
                                                <input
                                                    type="text"
                                                    name="banco_alquiler "
                                                    value={reserva.recaudacion_alquiler.banco_alquiler || ''}
                                                    disabled
                                                />
                                                <label className="active" htmlFor="banco_alquiler">Banco:</label>
                                            </div>

                                            {/* Nombre de la Cuenta */}
                                            <div className="input-field col s12 m4">
                                                <input
                                                    type="text"
                                                    name="nombre_cuenta_alquiler "
                                                    value={reserva.recaudacion_alquiler.nombre_cuenta_alquiler || ''}
                                                    disabled
                                                />
                                                <label className="active" htmlFor="nombre_cuenta_alquiler">Nombre de la Cuenta:</label>
                                            </div>

                                            {/* Código de Recaudación */}
                                            <div className="input-field col s12 m3">
                                                <input
                                                    type="text"
                                                    name="codigo_recaudacion_alquiler "
                                                    value={reserva.recaudacion_alquiler.codigo_recaudacion_alquiler || ''}
                                                    disabled
                                                />
                                                <label className="active" htmlFor="codigo_recaudacion_alquiler">Código de Recaudación:</label>
                                            </div>
                                            {/* Mensaje de Recaudación */}
                                            <div className="input-field col s12 m3">
                                                <input
                                                    type="text"
                                                    name="estado_pago_alquiler"
                                                    value={reserva.recaudacion_alquiler.estado_pago_alquiler || ''}
                                                    disabled
                                                />
                                                <label className="active" htmlFor="estado_pago_alquiler">Pago Alquiler:</label>
                                            </div>
                                            {/* Mensaje de Recaudación */}
                                            <div className="input-field col s12">
                                                <label className="active" htmlFor="mensaje_pago_alquiler" style={{ marginBottom: "5px" }}>
                                                    Mensaje de Pago:
                                                </label>
                                                <textarea
                                                    id="mensaje_pago_alquiler"
                                                    name="mensaje_pago_alquiler"
                                                    value={reserva.recaudacion_alquiler?.mensaje_pago_alquiler || ''}
                                                    className="materialize-textarea orange-text text-darken-2"
                                                    readOnly
                                                    style={{
                                                        height: "100px", // Mantiene la altura mayor
                                                        overflowY: "auto", // Permite desplazamiento vertical si es necesario
                                                        backgroundColor: "#fff3e0", // Fondo suave para destacar
                                                        border: "1px solid #ff9800", // Borde naranja para llamar la atención
                                                        padding: "10px", // Espaciado interno para mejorar legibilidad
                                                        borderRadius: "5px", // Esquinas redondeadas
                                                        marginTop: "5px" // Separación del label
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {/* Renderizado Condicional para Constancia de Pago */}
                                        {reserva.recaudacion_alquiler.constancia_pago_alquiler_url ? (
                                            // Si la constancia ya está cargada
                                            <div className="row">
                                                <div className="col s12">
                                                    <p>
                                                        <strong>Constancia ya cargada el:</strong> {reserva.recaudacion_alquiler.fecha_constancia_alquiler_formateada}
                                                    </p>
                                                    <button
                                                        type="button"
                                                        className="btn blue tooltipped"
                                                        data-position="top"
                                                        data-tooltip="Visualizar Constancia de Pago"
                                                        onClick={() => window.open(reserva.recaudacion_alquiler.constancia_pago_alquiler_url, '_blank')}
                                                    >
                                                        <i className="material-icons left">visibility</i>Visualizar
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            // Si la constancia no está cargada, mostrar los botones de subida
                                            <>
                                                {/* Botón para subir la constancia de pago */}
                                                <div className="row">
                                                    <div className="col s12">
                                                        <button
                                                            type="button"
                                                            className="btn blue tooltipped"
                                                            data-position="top"
                                                            data-tooltip="Subir Pago de Alquiler"
                                                            onClick={() => fileInputRefAlquiler.current.click()}
                                                            disabled={uploadingAlquiler}
                                                        >
                                                            <i className="material-icons left">upload</i>Subir Pago de Alquiler
                                                        </button>
                                                        {/* Mostrar estado de subida */}
                                                        {uploadingAlquiler && <span style={{ marginLeft: '10px' }}>Subiendo...</span>}
                                                    </div>
                                                    {/* Mostrar mensaje de error si existe */}
                                                    {uploadErrorLocalAlquiler && (
                                                        <div className="col s12">
                                                            <span className="red-text">Error: {uploadErrorLocalAlquiler}</span>
                                                        </div>
                                                    )}
                                                </div>
                                                {/* Input oculto para seleccionar archivo */}
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    ref={fileInputRefAlquiler}
                                                    onChange={handleFileChangeAlquiler}
                                                />

                                                {/* Botón para confirmar la subida de la constancia de pago */}
                                                {constanciaPagoAlquiler && (
                                                    <div className="row">
                                                        <div className="col s12">
                                                            <button
                                                                type="button"
                                                                className="btn green tooltipped"
                                                                data-position="top"
                                                                data-tooltip="Confirmar Subida"
                                                                onClick={handleUploadConstanciaAlquiler}
                                                                disabled={uploadingAlquiler}
                                                            >
                                                                <i className="material-icons left">check</i>Confirmar Subida
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {/* Mostrar sección resaltada de Pago Banco solo si conciliacion_estado existe y es "CONCILIADO" */}
                                        {mode === 'view' && reserva?.recaudacion_alquiler?.conciliacion_estado === "CONCILIADO" && (
                                            <div className="row">
                                                <div className="col s12">
                                                    <div className="card blue-grey lighten-4" style={{ padding: "15px", borderRadius: "10px", border: "2px solid #607d8b" }}>
                                                        <h5 className="blue-grey-text text-darken-4">Pago Banco</h5>

                                                        <div className="row">
                                                            {/* Estado de Conciliación */}
                                                            <div className="input-field col s6 m2">
                                                                <input
                                                                    type="text"
                                                                    value={reserva.recaudacion_alquiler.conciliacion_estado}
                                                                    disabled
                                                                />
                                                                <label className="active">Estado:</label>
                                                            </div>

                                                            {/* Fecha de Pago */}
                                                            <div className="input-field col s6 m2">
                                                                <input
                                                                    type="text"
                                                                    value={reserva.recaudacion_alquiler.conciliacion_fecha_pago || "No registrada"}
                                                                    disabled
                                                                />
                                                                <label className="active">Fecha Pago:</label>
                                                            </div>

                                                            {/* Hora de Pago */}
                                                            <div className="input-field col s6 m2">
                                                                <input
                                                                    type="text"
                                                                    value={reserva.recaudacion_alquiler.conciliacion_hora_pago || "No registrada"}
                                                                    disabled
                                                                />
                                                                <label className="active">Hora Pago:</label>
                                                            </div>
                                                            {/* Importe de Pago */}
                                                            <div className="input-field col s6 m2">
                                                                <input
                                                                    type="text"
                                                                    value={reserva.recaudacion_alquiler.conciliacion_importe || "No registrad8"}
                                                                    disabled
                                                                />
                                                                <label className="active">Importe Pago:</label>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            {/* Número de Operación */}
                                                            <div className="input-field col s6 m2">
                                                                <input
                                                                    type="text"
                                                                    value={reserva.recaudacion_alquiler.conciliacion_numero_operacion || "No registrada"}
                                                                    disabled
                                                                />
                                                                <label className="active">Num. Operac.</label>
                                                            </div>

                                                            {/* Canal de Pago con formato */}
                                                            <div className="input-field col s6 m3">
                                                                <input
                                                                    type="text"
                                                                    value={reserva.recaudacion_alquiler.conciliacion_canal || "No registrada"}
                                                                    disabled
                                                                />
                                                                <label className="active">Canal de Pago:</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                                {!isCreating && reserva?.recaudacion_garantia && (
                                    <>
                                        <div className="row">
                                            <div className="col s12">
                                                <h5>Información de Pago de Garantía de Reserva</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* Banco */}
                                            <div className="input-field col s12 m2">
                                                <input
                                                    type="text"
                                                    name="banco_garantia"
                                                    value={reserva.recaudacion_garantia.banco_garantia || ''}
                                                    disabled
                                                />
                                                <label className="active" htmlFor="banco_garantia">Banco:</label>
                                            </div>

                                            {/* Nombre de la Cuenta */}
                                            <div className="input-field col s12 m4">
                                                <input
                                                    type="text"
                                                    name="nombre_cuenta_garantia "
                                                    value={reserva.recaudacion_garantia.nombre_cuenta_garantia || ''}
                                                    disabled
                                                />
                                                <label className="active" htmlFor="nombre_cuenta_garantia">Nombre de la Cuenta:</label>
                                            </div>

                                            {/* Código de Recaudación */}
                                            <div className="input-field col s12 m3">
                                                <input
                                                    type="text"
                                                    name="codigo_recaudacion_garantia"
                                                    value={reserva.recaudacion_garantia.codigo_recaudacion_garantia || ''}
                                                    disabled
                                                />
                                                <label className="active" htmlFor="codigo_recaudacion_garantia">Código de Recaudación:</label>
                                            </div>
                                            {/* Mensaje de Recaudación */}
                                            <div className="input-field col s12 m3">
                                                <input
                                                    type="text"
                                                    name="estado_pago_garantia"
                                                    value={reserva.recaudacion_garantia.estado_pago_garantia || ''}
                                                    disabled
                                                />
                                                <label className="active" htmlFor="estado_pago_garantia">Pago Garantia:</label>
                                            </div>
                                            {/* Mensaje de Recaudación */}
                                            <div className="input-field col s12">
                                                <label className="active" htmlFor="mensaje_pago_garantia" style={{ marginBottom: "5px" }}>
                                                    Mensaje de Pago:
                                                </label>
                                                <textarea
                                                    id="mensaje_pago_garantia"
                                                    name="mensaje_pago_garantia"
                                                    value={reserva.recaudacion_garantia?.mensaje_pago_garantia || ''}
                                                    className="materialize-textarea orange-text text-darken-2"
                                                    readOnly
                                                    style={{
                                                        height: "100px", // Mantiene la altura mayor
                                                        overflowY: "auto", // Permite desplazamiento vertical si es necesario
                                                        backgroundColor: "#fff3e0", // Fondo suave para destacar
                                                        border: "1px solid #ff9800", // Borde naranja para llamar la atención
                                                        padding: "10px", // Espaciado interno para mejorar legibilidad
                                                        borderRadius: "5px", // Esquinas redondeadas
                                                        marginTop: "5px" // Separación del label
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {/* Renderizado Condicional para Constancia de Pago */}
                                        {reserva.recaudacion_garantia.constancia_pago_garantia_url ? (
                                            // Si la constancia ya está cargada
                                            <div className="row">
                                                <div className="col s12">
                                                    <p>
                                                        <strong>Constancia ya cargada el:</strong> {reserva.recaudacion_garantia.fecha_constancia_garantia_formateada}
                                                    </p>
                                                    <button
                                                        type="button"
                                                        className="btn blue tooltipped"
                                                        data-position="top"
                                                        data-tooltip="Visualizar Constancia de Pago"
                                                        onClick={() => window.open(reserva.recaudacion_garantia.constancia_pago_garantia_url, '_blank')}
                                                    >
                                                        <i className="material-icons left">visibility</i>Visualizar
                                                    </button>
                                                </div>
                                            </div>

                                        ) : (
                                            // Si la constancia no está cargada, mostrar los botones de subida
                                            <>
                                                {/* Botón para subir la constancia de pago */}
                                                <div className="row">
                                                    <div className="col s12">
                                                        <button
                                                            type="button"
                                                            className="btn blue tooltipped"
                                                            data-position="top"
                                                            data-tooltip="Subir Pago de Garantia"
                                                            onClick={() => fileInputRefGarantia.current.click()}
                                                            disabled={uploadingGarantia}
                                                        >
                                                            <i className="material-icons left">upload</i>Subir Pago de Garantia
                                                        </button>
                                                        {/* Mostrar estado de subida */}
                                                        {uploadingGarantia && <span style={{ marginLeft: '10px' }}>Subiendo...</span>}
                                                    </div>
                                                    {/* Mostrar mensaje de error si existe */}
                                                    {uploadErrorLocalGarantia && (
                                                        <div className="col s12">
                                                            <span className="red-text">Error: {uploadErrorLocalGarantia}</span>
                                                        </div>
                                                    )}
                                                </div>
                                                {/* Input oculto para seleccionar archivo */}
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    ref={fileInputRefGarantia}
                                                    onChange={handleFileChangeGarantia}
                                                />

                                                {/* Botón para confirmar la subida de la constancia de pago */}
                                                {constanciaPagoGarantia && (
                                                    <div className="row">
                                                        <div className="col s12">
                                                            <button
                                                                type="button"
                                                                className="btn green tooltipped"
                                                                data-position="top"
                                                                data-tooltip="Confirmar Subida"
                                                                onClick={handleUploadConstanciaGarantia}
                                                                disabled={uploadingGarantia}
                                                            >
                                                                <i className="material-icons left">check</i>Confirmar Subida
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {/* Mostrar sección resaltada de Pago Banco solo si Garantia conciliacion_estado existe y es "CONCILIADO" */}
                                        {mode === 'view' && reserva?.recaudacion_garantia?.conciliacion_estado === "CONCILIADO" && (
                                            <div className="row">
                                                <div className="col s12">
                                                    <div className="card blue-grey lighten-4" style={{ padding: "15px", borderRadius: "10px", border: "2px solid #607d8b" }}>
                                                        <h5 className="blue-grey-text text-darken-4">Pago Banco</h5>

                                                        <div className="row">
                                                            {/* Estado de Conciliación */}
                                                            <div className="input-field col s6 m2">
                                                                <input
                                                                    type="text"
                                                                    value={reserva.recaudacion_garantia.conciliacion_estado}
                                                                    disabled
                                                                />
                                                                <label className="active">Estado:</label>
                                                            </div>

                                                            {/* Fecha de Pago */}
                                                            <div className="input-field col s6 m2">
                                                                <input
                                                                    type="text"
                                                                    value={reserva.recaudacion_garantia.conciliacion_fecha_pago || "No registrada"}
                                                                    disabled
                                                                />
                                                                <label className="active">Fecha Pago:</label>
                                                            </div>

                                                            {/* Hora de Pago */}
                                                            <div className="input-field col s6 m2">
                                                                <input
                                                                    type="text"
                                                                    value={reserva.recaudacion_garantia.conciliacion_hora_pago || "No registrada"}
                                                                    disabled
                                                                />
                                                                <label className="active">Hora Pago:</label>
                                                            </div>
                                                            {/* Importe de Pago */}
                                                            <div className="input-field col s6 m2">
                                                                <input
                                                                    type="text"
                                                                    value={reserva.recaudacion_garantia.conciliacion_importe || "No registrad8"}
                                                                    disabled
                                                                />
                                                                <label className="active">Importe Pago:</label>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            {/* Número de Operación */}
                                                            <div className="input-field col s6 m2">
                                                                <input
                                                                    type="text"
                                                                    value={reserva.recaudacion_garantia.conciliacion_numero_operacion || "No registrada"}
                                                                    disabled
                                                                />
                                                                <label className="active">Num. Operac.</label>
                                                            </div>

                                                            {/* Canal de Pago con formato */}
                                                            <div className="input-field col s6 m3">
                                                                <input
                                                                    type="text"
                                                                    value={reserva.recaudacion_garantia.conciliacion_canal || "No registrada"}
                                                                    disabled
                                                                />
                                                                <label className="active">Canal de Pago:</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </>
                                )}
                                {!isCreating && reserva?.recaudacion_garantia && (
                                    <>
                                        {/* Sección para datos de devolución de garantía */}
                                        <div className="row">
                                            <div className="col s12">
                                                <h5>Datos de Devolución de Garantía</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="input-field col s12 m3">
                                                <input
                                                    type="text"
                                                    name="banco_devolucion"
                                                    value={formData.recaudacion_garantia?.banco_devolucion || ''}
                                                    onChange={handleInputChangeDevolucion}
                                                    disabled={!isEditingDevolucion}
                                                />
                                                <label className="active" htmlFor="banco_devolucion">Banco:</label>
                                            </div>
                                            <div className="input-field col s12 m3">
                                                <input
                                                    type="text"
                                                    name="cuenta_devolucion"
                                                    value={formData.recaudacion_garantia?.cuenta_devolucion || ''}
                                                    onChange={handleInputChangeDevolucion}
                                                    disabled={!isEditingDevolucion}
                                                />
                                                <label className="active" htmlFor="cuenta_devolucion">Número de Cuenta:</label>
                                            </div>
                                            <div className="input-field col s12 m3">
                                                <input
                                                    type="text"
                                                    name="dni_titular"
                                                    value={formData.recaudacion_garantia?.dni_titular || ''}
                                                    onChange={handleInputChangeDevolucion}
                                                    disabled={!isEditingDevolucion}
                                                />
                                                <label className="active" htmlFor="dni_titular">DNI del Titular:</label>
                                            </div>
                                            <div className="input-field col s12 m3">
                                                <input
                                                    type="text"
                                                    name="titular_cuenta"
                                                    value={formData.recaudacion_garantia?.titular_cuenta || ''}
                                                    onChange={handleInputChangeDevolucion}
                                                    disabled={!isEditingDevolucion}
                                                />
                                                <label className="active" htmlFor="titular_cuenta">Titular de la Cuenta:</label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col s12">
                                                <button
                                                    type="button"
                                                    className={`btn ${isEditingDevolucion ? "green" : "blue"} tooltipped`}
                                                    data-position="top"
                                                    data-tooltip={isEditingDevolucion ? "Guardar Devolución" : "Modificar Devolución"}
                                                    onClick={() => {
                                                        if (isEditingDevolucion) {
                                                            handleSaveDevolucion();
                                                        }
                                                        setIsEditingDevolucion(!isEditingDevolucion);
                                                    }}
                                                >
                                                    <i className="material-icons left">{isEditingDevolucion ? "save" : "edit"}</i>
                                                    {isEditingDevolucion ? "Guardar Devolución" : "Modificar Devolución"}
                                                </button>
                                            </div>
                                        </div>

                                        {/* **Fin de la Nueva Sección** */}
                                    </>
                                )}
                            </form>
                        )}
                    </div>{/* **Fin de Div de Impresion** */}
                </div>


            </div>
        </div >
    );

};

export default ReservaFormAdmin;