import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { password_reset, validar_token_reset } from '../seguridad/seguridadThunk';
import { resetState } from '../seguridad/seguridadSlice';
import './recuperarPasswordPage.css';

const RecuperarPassword = () => {
  const [codigo_usuario, setCodigo_usuario] = useState('');
  const [token, setToken] = useState(['', '', '', '']);
  const [requesting, setRequesting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    status_password_reset,
    error_password_reset,
    status_validar_token_reset,
    error_validar_token_reset,
    json_resp_password_reset,
    json_resp_validar_token_reset,
  } = useSelector((state) => state.seguridad);

  useEffect(() => {
    if (status_validar_token_reset === 'succeeded' && json_resp_validar_token_reset.success) {
      navigate('/change-password');
    }
  }, [status_validar_token_reset, json_resp_validar_token_reset, navigate]);

  
  // Manejador del formulario de envío de código de usuario
  const handleSubmit = (e) => {
    e.preventDefault();
    setRequesting(true); // Activa el spinner

    dispatch(password_reset(codigo_usuario)).finally(() => {
      setRequesting(false); // Desactiva el spinner al terminar la petición
    });
  };

  const handleTokenSubmit = (e) => {
    e.preventDefault();
    const tokenString = token.join('');
    dispatch(validar_token_reset({ codigo_usuario, token: tokenString }));
  };

  const handleTokenChange = (e, index) => {
    const newToken = [...token];
    newToken[index] = e.target.value;
    setToken(newToken);
    if (e.target.value && index < 3) {
      document.getElementById(`token-input-${index + 1}`).focus();
    }
  };

  return (
    <div className="container" style={{ marginTop: '2rem' }}>
      {requesting && (
        <div className="recuperar-password-overlay">
          <div className="recuperar-password-spinner-container">
            <div className="recuperar-password-lds-ring"><div></div><div></div><div></div><div></div></div>
            <p>Enviando...</p>
          </div>
        </div>
      )}
      <h4 className="center-align">Restablecer Contraseña</h4>

      {/* Primer formulario: Ingreso del usuario */}
      <form onSubmit={handleSubmit} className="row">
        <div className="col s12 m8 offset-m2 l6 offset-l3">
          <div className="input-field">
            <input
              type="text"
              id="codigo_usuario"
              value={codigo_usuario}
              onChange={(e) => setCodigo_usuario(e.target.value)}
              required
              className="validate"
            />
            <label htmlFor="codigo_usuario">Ingrese su usuario</label>
          </div>
          <button
            type="submit"
            className="btn waves-effect waves-light col s12"            
            disabled={requesting || status_password_reset === "loading"}
          >
            {requesting || status_password_reset === "loading" ? "Enviando..." : "Restablecer Contraseña"}
          </button>
        </div>
      </form>

      {/* Mensajes de éxito o error */}
      <div className="row center-align">
        {status_password_reset === 'succeeded' && (
          <p className="green-text">¡Correo enviado! Por favor revise su bandeja.</p>
        )}
        {error_password_reset && <p className="red-text">{error_password_reset}</p>}
      </div>

      {/* Segundo formulario: Validación de Token */}
      {status_password_reset === 'succeeded' && (
        <form onSubmit={handleTokenSubmit} className="row">
          <div className="col s12 m8 offset-m2 l6 offset-l3">
            <div className="input-field center-align">
              {token.map((digit, index) => (
                <input
                  key={index}
                  id={`token-input-${index}`}
                  type="text"
                  value={digit}
                  onChange={(e) => handleTokenChange(e, index)}
                  maxLength="1"
                  style={{
                    width: '3rem',
                    margin: '0 0.5rem',
                    textAlign: 'center',
                    fontSize: '1.5rem',
                  }}
                />
              ))}
            </div>
            <button
              type="submit"
              className="btn waves-effect waves-light col s12"
              disabled={status_validar_token_reset === 'loading'}
            >
              {status_validar_token_reset === 'loading' ? 'Validando...' : 'Validar Token'}
            </button>
          </div>
        </form>
      )}

      {/* Mensajes de validación */}
      <div className="row center-align">
        {error_validar_token_reset && <p className="red-text">{error_validar_token_reset}</p>}
        {status_validar_token_reset === 'succeeded' && (
          <p className="green-text">{json_resp_validar_token_reset.message}</p>
        )}
      </div>
    </div>
  );
};

export default RecuperarPassword;
