import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import { setArea_comunSeleccionada, setAreas_comunes } from '../mis-areas-comunes/area_comunSlice';
import { get_reservas_x_area_comun } from '../mis-areas-comunes/reservaThunk';
import './calendarioReservasPage.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import { get_areas_comunes } from '../mis-areas-comunes/area_comunThunk';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const Calendario_reservasPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useMaterializeTooltips();
    
    // Obtenemos `tipo_area_comunSeleccionada` desde Redux
    const tipo_area_comunSeleccionada = useSelector(state => state.area_comun.tipo_area_comunSeleccionada);
    const area_comunSeleccionada = useSelector(state => state.area_comun.area_comunSeleccionada);
    const edificioSeleccionado = useSelector(state => state.edificio.edificio);
    const buildingId = edificioSeleccionado?.id; // ID del edificio
    const areasComun = useSelector(state => state.area_comun.areas_comunes);
    const reservas = useSelector(state => state.reserva.reservas);

    const [areaComun, setAreaComun] = useState(area_comunSeleccionada?.nombre || '');
    const [fechaSeleccionada, setFechaSeleccionada] = useState(new Date());
    const [turnos, setTurnos] = useState([]);
    const [reservas_periodo, setReservas_periodo] = useState([]);

    useEffect(() => {
        var elems = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(elems);
    }, [reservas_periodo]);

    // ✅ Cargar áreas comunes cada vez que cambia `tipo_area_comunSeleccionada`
    useEffect(() => {
        if (buildingId && tipo_area_comunSeleccionada) {
            console.log(`📌 Cargando áreas comunes para tipo: ${tipo_area_comunSeleccionada}`);
            dispatch(setAreas_comunes([]));  // 🔥 Limpiar áreas antes de actualizar
            dispatch(get_areas_comunes({ buildingId, tipo_area_comun: tipo_area_comunSeleccionada }));
        }
    }, [buildingId, tipo_area_comunSeleccionada, dispatch]);

    // ✅ Seleccionar automáticamente el primer área común si no hay una preseleccionada
    useEffect(() => {
        if (areasComun.length > 0) {
            let selectedArea = areaComun || areasComun[0]?.nombre || '';
            setAreaComun(selectedArea);
            dispatch(setArea_comunSeleccionada(selectedArea));
            dispatch(get_reservas_x_area_comun({ buildingId, area_comun: selectedArea }));

            const areaEncontrada = areasComun.find(area => area.nombre === selectedArea);
            setTurnos(areaEncontrada?.turnos || []);
        }
    }, [areasComun, areaComun, buildingId, dispatch]);

    // ✅ Cuando cambia `areaComun`, actualizar reservas y turnos
    useEffect(() => {
        if (areaComun) {
            console.log("📌 Seleccionando área común:", areaComun);
            dispatch(setArea_comunSeleccionada(areaComun));
            dispatch(get_reservas_x_area_comun({ buildingId, area_comun: areaComun }));

            const areaEncontrada = areasComun.find(area => area.nombre === areaComun);
            setTurnos(areaEncontrada?.turnos || []);
        }
    }, [areaComun, buildingId, dispatch]);

    // ✅ Cargar reservas obtenidas
    useEffect(() => {
        if (reservas) {
            setReservas_periodo(reservas);
        }
    }, [reservas]);

    // ✅ Inicializar select de Materialize cada vez que cambian las áreas comunes
    useEffect(() => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [areasComun]);

    const handleAreaChange = (e) => {
        setAreaComun(e.target.value);
    };

    const handleRetornar = () => {
        navigate(-1);
    };

    const handleTurnoClick = (turno, date) => { 
        if (!turno.disponible) return;
        const dataToPass = { area_comun: areaComun, fecha_reserva: date, turno: turno };
        navigate('/reserva-sum/create', { state: dataToPass });
    };

    const renderTileContent = ({ date, view }) => {
        if (view === 'month') {
            const fechaISO = date.toISOString().split('T')[0];
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const selectedDate = new Date(date);
            selectedDate.setHours(0, 0, 0, 0);
            if (selectedDate < today) return null;

            const reservasDelDia = reservas_periodo.filter(reserva => reserva.fecha_reserva === fechaISO);
            const turnosConDisponibilidad = turnos.map(turno => ({
                ...turno,
                disponible: !reservasDelDia.find(reserva => 
                    reserva.turno.turno_hora_inicio === turno.turno_hora_inicio &&
                    reserva.turno.turno_hora_fin === turno.turno_hora_fin &&
                    reserva.estado === 'SEPARADO'
                )
            }));

            return (
                <div className="tile-content">
                    {turnosConDisponibilidad.map((turno, index) => (
                        <div
                            key={index}
                            className={`turno ${turno.disponible ? 'disponible' : 'ocupado'} tooltipped`}
                            data-tooltip={`Turno: ${turno.turno_hora_inicio} - ${turno.turno_hora_fin}`}
                            onClick={turno.disponible ? () => handleTurnoClick(turno, date) : null}
                            style={{ cursor: turno.disponible ? 'pointer' : 'default' }}
                        >
                            {turno.turno_hora_inicio} - {turno.turno_hora_fin}
                        </div>
                    ))}
                </div>
            );
        }
    };

    return (
        <div className="container calendario-container">
            <h4 className="center-align">Calendario de Reservas {tipo_area_comunSeleccionada && `- ${tipo_area_comunSeleccionada}`}</h4>

            <div className="row valign-wrapper">
                <div className="input-field col s12 m8 l6">
                    <select value={areaComun} onChange={handleAreaChange}>
                        {areasComun.map(area => (
                            <option key={area.id} value={area.nombre}>{area.nombre}</option>
                        ))}
                    </select>
                    <label>Área Común</label>
                </div>

                <div className="col s12 m4 l6 legend-container">
                    <div className="legend">
                        <div className="legend-item"><div className="legend-color disponible"></div><span className="legend-label">Disponible</span></div>
                        <div className="legend-item"><div className="legend-color ocupado"></div><span className="legend-label">Reservado</span></div>
                        <div className="legend-item"><div className="legend-color pasado"></div><span className="legend-label">No Disponible</span></div>
                    </div>
                </div>

                <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={handleRetornar}>
                    <i className="material-icons left">arrow_back</i>Volver
                </a>
            </div>

            {areaComun && turnos.length > 0 && (
                <div className="row">
                    <div className="col s12">
                        <Calendar
                            onChange={setFechaSeleccionada}
                            value={fechaSeleccionada}
                            tileContent={renderTileContent}
                            locale="es-ES"
                            className="responsive-calendar"
                            minDate={new Date()}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Calendario_reservasPage;
