import { createSlice } from '@reduxjs/toolkit';
import { get_unidades_facturacion_x_edificio, get_unidad_facturacion, update_unidad_facturacion, save_unidad_facturacion, anular_unidad_facturacion, get_unidad_facturacion_x_unidad } from './unidad_facturacionThunk';

const initialState= {
  unidad_facturacion: null,
  unidades_facturacion: [],
  unidad_facturacionSeleccionado: null,
  fetchStatus: 'idle',
  error: null,
  status_get_unidad_facturacion: 'idle',
  error_get_unidad_facturacion: null,
  status_get_unidad_facturacion_x_unidad: 'idle',
  error_get_unidad_facturacion_x_unidad: null,
  status_update_unidad_facturacion: 'idle',
  error_update_unidad_facturacion: null,
  status_save_unidad_facturacion: 'idle',
  error_save_unidad_facturacion: null,
  status_anular_unidad_facturacion: 'idle',
  error_anular_unidad_facturacion: null,

}

const unidad_facturacionSlice = createSlice({
  name: 'unidad_facturacion',
  initialState,
  reducers: {
    setUnidad_facturacionSeleccionado: (state, action) => {
      state.unidad_facturacionSeleccionado = action.payload;
    },
    clearUnidad_facturacionSeleccionado(state) {
      state.unidad_facturacionSeleccionado = null;
      state.unidad_facturacion = null;
    },
    resetState: () => initialState, // 🔥 Reiniciar todo el estado al estado inicial
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_unidades_facturacion_x_edificio.pending, (state) => {
        state.fetchStatus = 'loading';
      })
      .addCase(get_unidades_facturacion_x_edificio.fulfilled, (state, action) => {
        state.fetchStatus = 'succeeded';
        //console.log("Propiedades cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.unidades_facturacion = action.payload;

      })
      .addCase(get_unidades_facturacion_x_edificio.rejected, (state, action) => {
        console.log("ejecutando Slice failed rejected ");
        state.fetchStatus = 'failed';
        console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch receipts';
      })

      .addCase(update_unidad_facturacion.pending, (state) => {
        state.status_update_unidad_facturacion = 'loading';
      })
      .addCase(update_unidad_facturacion.fulfilled, (state, action) => {
        state.status_update_unidad_facturacion = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.unidad_facturacion = action.payload.unidad_facturacion;

      })
      .addCase(update_unidad_facturacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_unidad_facturacion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_unidad_facturacion = action.payload || 'Failed to fetch building';
      })
      .addCase(save_unidad_facturacion.pending, (state) => {
        state.status_save_unidad_facturacion = 'loading';
      })
      .addCase(save_unidad_facturacion.fulfilled, (state, action) => {
        state.status_save_unidad_facturacion = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.unidad_facturacion = action.payload.unidad_facturacion;

      })
      .addCase(save_unidad_facturacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_unidad_facturacion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_unidad_facturacion = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_unidad_facturacion.pending, (state) => {
        state.status_save_unidad_facturacion = 'loading';
      })
      .addCase(anular_unidad_facturacion.fulfilled, (state, action) => {
        state.status_anular_unidad_facturacion = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.unidad_facturacion = action.payload.unidad_facturacion;

      })
      .addCase(anular_unidad_facturacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_unidad_facturacion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_unidad_facturacion = action.payload || 'Failed to fetch building';
      })
      .addCase(get_unidad_facturacion.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_unidad_facturacion.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.unidad_facturacion = action.payload;

      })
      .addCase(get_unidad_facturacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })

      .addCase(get_unidad_facturacion_x_unidad.pending, (state) => {
        state.status_get_unidad_facturacion_x_unidad = 'loading';
      })
      .addCase(get_unidad_facturacion_x_unidad.fulfilled, (state, action) => {
        state.status_get_unidad_facturacion_x_unidad = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.unidad_facturacion = action.payload;

      })
      .addCase(get_unidad_facturacion_x_unidad.rejected, (state, action) => {
        console.log("ejecutando Slice failed rejected "+ JSON.stringify(action.payload));
        state.status_get_unidad_facturacion_x_unidad = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_unidad_facturacion_x_unidad = action.payload || 'Failed to fetch building';
      });
  },
});

export const selectPropiedadById = (state, unidad_facturacionId) =>
  state.unidad_facturacion.unidades_facturacion.find((unidad_facturacion) => unidad_facturacion.id === unidad_facturacionId);
export const { setUnidad_facturacionSeleccionado, resetState, clearUnidad_facturacionSeleccionado } = unidad_facturacionSlice.actions;
export default unidad_facturacionSlice.reducer;
