import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cleave from 'cleave.js/react';
import DatePicker from 'react-datepicker';
import { useNavigate, useParams } from 'react-router-dom';
import { ESTADOS_LECTURA_AGUA, TIPOS_SUMINISTRO } from '../../constants/constants';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import {
    anular_lectura_agua,
    save_lectura_agua,
    update_lectura_agua,
    get_lectura_agua
} from './lectura_aguaThunk';
import { get_suministros_x_tipo } from '../edificio/suministrosThunk';
import { get_unidades_facturacion_x_edificio } from '../edificio/unidad_facturacionThunk';
import { storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL, connectStorageEmulator } from 'firebase/storage';
import Modal from 'react-modal'; // Importa Modal
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { parseFromYYYYMM, formatPeriodo } from '../../utils/util';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const Lectura_aguaForm = ({ mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useMaterializeTooltips();

    const { id: idLectura_agua } = useParams();
    const lectura_agua = useSelector(state => state.lectura_agua.lectura_agua);

    const [pastedValues, setPastedValues] = useState('');
    const [lecturas, setLecturas] = useState(
        Array(264).fill({ lectura: 0 }) // 264 lecturas inicialmente vacías
    );

    const [filterText, setFilterText] = useState("");

    const handlePaste = (event) => {
        const pastedData = event.target.value;
        setPastedValues(pastedData);

        // Convertimos los valores pegados en un array        
        const valuesArray = pastedData.split('\n').map(value => value.trim()).filter(value => value !== '');

        // Asegúrate de que solo se actualicen las primeras `n` filas (n = número de valores pegados)
        const updatedLecturas = formData.lecturas.map((item, index) => {
            if (index < valuesArray.length) {
                return {
                    ...item,
                    lectura: valuesArray[index] || 0, // Si no hay valor, coloca 0 por defecto
                };
            }
            return item; // Mantén el resto de las lecturas sin cambios
        });

        setFormData(prev => ({
            ...prev,
            lecturas: updatedLecturas,
        }));

    };

    const { status_save_lectura_agua, error_save_lectura_agua } = useSelector((state) => state.lectura_agua);

    const usuario = useSelector((state) => state.auth.usuario);
    const codigo_usuario = usuario ? usuario.id : null;

    const edificioSeleccionado = useSelector((state) => state.edificio.edificio);
    const buildingId = edificioSeleccionado.id;
    const codigo_edificio = edificioSeleccionado.codigo;

    const suministros = useSelector(state => state.suministros.suministros);
    const unidades_facturacion = useSelector(state => state.unidad_facturacion.unidades_facturacion);

    //const [isEditing, setIsEditing] = useState(mode !== 'view');
    const [isEditing, setIsEditing] = useState(mode !== 'view' && lectura_agua?.estado !== 'FACTURADO');
    const [uploadProgress, setUploadProgress] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null); // Para almacenar la URL de la imagen seleccionada
    const [showModal, setShowModal] = useState(false); // Para controlar la visibilidad del modal
    const [files, setFiles] = useState([]);
    const [uploadStatus, setUploadStatus] = useState("");
    const [unmatchedFiles, setUnmatchedFiles] = useState([]);


    registerLocale('es', es);

    // Inicializa el estado
    const [formData, setFormData] = useState({
        periodo: new Date(),
        fecha_lectura: new Date(),
        estado: ESTADOS_LECTURA_AGUA[0].label,
        nota: '',
        nro_suministro: '',
        lecturas: []
    });
    useEffect(() => {
        // Inicializar nro_suministro con el primer elemento de suministros si está disponible
        if (suministros.length > 0) {
            setFormData(prev => ({
                ...prev,
                nro_suministro: suministros[0].numero // Selecciona el primer suministro por defecto
            }));
        }
    }, [suministros]);
    useEffect(() => {
        // Solo inicializar lecturas si unidades_facturacion está disponible
        if (unidades_facturacion.length > 0) {
            setFormData(prev => ({
                ...prev,
                lecturas: unidades_facturacion.map(unidad => ({
                    lectura: 0,
                    nota: '',
                    foto: null,
                    idunidad_facturacion: unidad.id,
                    codigo_unidad_facturacion: unidad.codigo_unidad_facturacion,
                    orden: unidad.orden,
                }))
            }));
        }
    }, [unidades_facturacion]);

    useEffect(() => {
        dispatch(get_suministros_x_tipo({ buildingId, tipo_suministro: TIPOS_SUMINISTRO[0].value }));
        dispatch(get_unidades_facturacion_x_edificio(buildingId));

        if (mode !== 'create') {
            dispatch(get_lectura_agua(idLectura_agua));
        }
    }, [dispatch, idLectura_agua, mode, buildingId]);

    useEffect(() => {
        if (lectura_agua && (mode === 'view' || mode === 'edit')) {
            //console.log("lectura_agua data:", lectura_agua);  // Imprime toda la data de lectura_agua
            setFormData(prev => ({
                ...prev,
                periodo: parseFromYYYYMM(lectura_agua.periodo),
                fecha_lectura: new Date(lectura_agua.fecha_lectura),
                estado: lectura_agua.estado,
                nota: lectura_agua.nota,
                nro_suministro: lectura_agua.nro_suministro,
                lecturas: lectura_agua.lecturas.map((lectura_unidad_facturacion, index) => ({
                    lectura: lectura_unidad_facturacion?.lectura || 0, // Asegúrate de que se inicie con 0
                    nota: lectura_unidad_facturacion?.nota || '',
                    foto: lectura_unidad_facturacion?.foto || null,
                    codigo_unidad_facturacion: lectura_unidad_facturacion.codigo_unidad_facturacion,
                    orden: lectura_unidad_facturacion.orden,
                    fecha_registro_formateada: lectura_unidad_facturacion?.fecha_registro_formateada || '', // Asegúrate de agregar este campo
                }))
            }));
            setIsEditing(mode === 'edit');
        }
    }, [lectura_agua, mode, unidades_facturacion]);

    useEffect(() => {
        if (suministros) {
            var elems = document.querySelectorAll('select');
            M.FormSelect.init(elems);
            M.updateTextFields();
        }
    }, [suministros]);

    const handleRetornar = () => {
        navigate('/lecturas-agua');
    };

    const handleCancel = () => {
        navigate('/lecturas-agua');
    };
    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {

        //const periodoTimestamp = formData.periodo.getTime(); // Convierte la fecha a timestamp
        const periodo = formatPeriodo(formData.periodo); // Formateamos el periodo a YYYY-MM

        const updatedData = {
            ...formData
            , periodo: periodo,
        };

        if (isEditing) {
            if (mode === 'create') {
                const data = { ...updatedData, buildingId, usuarioRegistrador: codigo_usuario };
                //console.log(" En CREATE data: " + JSON.stringify(data));

                //dispatch(save_lectura_agua(data));

                const resultAction = await dispatch(save_lectura_agua(data));
                if (save_lectura_agua.fulfilled.match(resultAction)) {
                    console.log("Eentro por ok")
                    M.toast({ html: 'Lectura de agua guardada exitosamente.', classes: 'green' });
                } else if (save_lectura_agua.rejected.match(resultAction)) {
                    //console.log("Eentro por ERROR resultAction.error "+JSON.stringify(resultAction.error))
                    // Mostrar el mensaje de error si existe un error de validación en el payload
                    //console.log("error_save_lectura_agua "+error_save_lectura_agua)
                    //const errorMessage = resultAction.error.message || 'Ocurrió un error al guardar la lectura de agua.';
                    const errorMessage = error_save_lectura_agua || '-Ocurrió un error al guardar la lectura de agua.';
                    //M.toast({ html: resultAction.payload.error, classes: 'red' });
                    M.toast({ html: errorMessage, classes: 'red' });
                    return;
                } else {
                    console.log("Eentro por ELSE " + JSON.stringify(resultAction.payload))
                }
            } else {
                const data = { ...updatedData, usuarioActualizacion: codigo_usuario };
                dispatch(update_lectura_agua({ idLectura_agua, lectura_aguaData: data }));
            }

        }

        navigate('/lecturas-agua');
    };

    const handleLecturaChange = (value, index) => {
        const numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));
        setFormData(prev => {
            const newLecturas = [...prev.lecturas];
            //console.log(" En CHANGE LECTURA - ANTES newLecturas: " + JSON.stringify(newLecturas));
            newLecturas[index] = { ...newLecturas[index], lectura: isNaN(numericValue) ? 0 : numericValue };
            //console.log(" En CHANGE LECTURA - DESPUES newLecturas: " + JSON.stringify(newLecturas));
            return { ...prev, lecturas: newLecturas };
        });
    };

    const handleNotaChange = (value, index) => {
        setFormData(prev => {
            const newLecturas = [...prev.lecturas];
            //console.log(" En CHANGE NOTA - ANTES newLecturas: " + JSON.stringify(newLecturas));
            newLecturas[index] = { ...newLecturas[index], nota: value };
            //console.log(" En CHANGE NOTA - DESPUES newLecturas: " + JSON.stringify(newLecturas));
            return { ...prev, lecturas: newLecturas };
        });
    };

    const handleFileChange = async (file, index) => {
        const periodString = formData.periodo.toISOString().slice(0, 7); // Formato YYYY-MM
        //const randomId = Math.random().toString(36).substring(2, 15); // ID aleatorio
        //const filename = `${codigo_edificio}-${formData.lecturas[index].codigo_unidad_facturacion}-${periodString}-${randomId}`;
        //console.log("EN handleFileChange filename: "+filename)
        
        //const storagePath = `Contometros/${codigo_edificio}/medidor/${periodString}/${file.name}`;
        const storagePath = `Contometros/${codigo_edificio}/${formData.nro_suministro}/${periodString}/${file.name}`;        
        const storageRef = ref(storage, storagePath); // Crear referencia al archivo

        // Subir la imagen
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(prev => {
                    const newProgress = [...prev];
                    newProgress[index] = progress; // Actualiza solo el índice correspondiente
                    return newProgress;
                });
                console.log('Upload is ' + progress + '% done');

            },
            (error) => {
                console.error('Upload failed:', error);
            },
            async () => {
                // Subida completa, obtener la URL
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                // Actualizar el estado con la URL de la imagen
                setFormData(prev => {
                    const newLecturas = [...prev.lecturas];
                    newLecturas[index] = {
                        ...newLecturas[index],
                        foto: downloadURL
                    };
                    return { ...prev, lecturas: newLecturas };
                });
                setUploadProgress(prev => {
                    const newProgress = [...prev];
                    newProgress[index] = 0; // Reiniciar el progreso después de completar la carga
                    return newProgress;
                });
            }
        );
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleDateChange = (name, date) => {
        setFormData(prev => ({ ...prev, [name]: date }));
    };

    const handleFileUpload = async (event) => {
        const uploadedFiles = Array.from(event.target.files);
        setFiles(uploadedFiles);
        setUploadStatus("<span style='color: blue; font-weight: bold;'>Subiendo archivos...</span>");
        setUnmatchedFiles([]);

        let completedUploads = 0;
        let unmatched = [];

        for (const file of uploadedFiles) {
            const fileName = file.name.split('.')[0];
            const unidad = unidades_facturacion.find(u => u.codigo_unidad_facturacion === fileName);

            if (unidad) {
                //const periodString = formatPeriodo(new Date());
                const periodString = formData.periodo.toISOString().slice(0, 7); // Formato YYYY-MM
                const storagePath = `Contometros/${codigo_edificio}/${formData.nro_suministro}/${periodString}/${file.name}`;  
                //const storagePath = `Contometros/${codigo_edificio}/medidor/${periodString}/${file.name}`;
                
                const storageRef = ref(storage, storagePath);

                const uploadTask = uploadBytesResumable(storageRef, file);
                await new Promise((resolve, reject) => {
                    uploadTask.on('state_changed',
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            setUploadProgress(prev => ({ ...prev, [file.name]: progress }));
                        },
                        (error) => {
                            console.error('Error al subir la imagen:', error);
                            reject(error);
                        },
                        async () => {
                            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                            setFormData(prev => {
                                const newLecturas = prev.lecturas.map(l => {
                                    if (l.codigo_unidad_facturacion === unidad.codigo_unidad_facturacion) {
                                        return { ...l, foto: downloadURL };
                                    }
                                    return l;
                                });
                                return { ...prev, lecturas: newLecturas };
                            });
                            resolve();
                        }
                    );
                });
            } else {
                unmatched.push(file.name);
            }
        }

        let statusMessage = "<span style='color: green; font-weight: bold;'>Carga completada exitosamente.</span><br/><span style='color: red; font-weight: bold;'>Recuerde grabar la lectura de agua para actualizar los datos.</span>";
        if (unmatched.length > 0) {
            setUnmatchedFiles(unmatched);
            statusMessage += `<br/><span style='color: orange; font-weight: bold;'>Las siguientes imágenes no se cargaron porque no se encontró coincidencia: <br/>${unmatched.join("<br/>")}</span>`;

        }
        setUploadStatus(statusMessage);
    };


    return (
        <div className="container lectura-agua-form">
            <div>
                <div className="card">
                    <div className="card-content">
                        <div className="card-action">
                            {isEditing ? (
                                <>
                                    <a className="btn green tooltipped" data-position="top" data-tooltip="Guardar cambios" onClick={handleSave}>
                                        <i className="material-icons left">save</i>Guardar
                                    </a>
                                    <a className="btn red tooltipped" data-position="top" data-tooltip="Cancelar edición" onClick={handleCancel}>
                                        <i className="material-icons left">cancel</i>Cancelar
                                    </a>
                                </>
                            ) : (
                                <a className="btn green tooltipped" data-position="top" data-tooltip="Editar detalles" onClick={handleEdit}>
                                    <i className="material-icons left">edit</i>Editar
                                </a>
                            )}

                            <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={handleRetornar}>
                                <i className="material-icons left">arrow_back</i>
                            </a>
                        </div>

                        <span className="card-title">{mode === 'create' ? 'Crear Lectura de Agua' : mode === 'edit' ? 'Editar Lectura de Agua' : 'Detalles de la Lectura de Agua'}</span>
                        <form onSubmit={e => e.preventDefault()}>
                            <div className="row">
                                <div className="input-field col s6 m4">
                                    <select
                                        name="nro_suministro"
                                        value={formData.nro_suministro || ''}
                                        onChange={handleInputChange}
                                        disabled={!isEditing}
                                    >
                                        {suministros.map(s => (
                                            <option key={s.id} value={s.numero}>{s.nombre} - {s.numero}</option>
                                        ))}
                                    </select>
                                    <label>N° Suministro</label>
                                </div>

                                <div className="input-field col s6 m2">
                                    <DatePicker
                                        selected={formData.periodo}
                                        onChange={date => handleDateChange('periodo', date)}
                                        showMonthYearPicker
                                        dateFormat="MM/yyyy"
                                        name="periodo"
                                        disabled={mode === 'view'}
                                        locale="es" // Establece el locale aquí
                                    />
                                    <label className="active" htmlFor="periodo">Periodo:</label>
                                </div>
                                <div className="input-field col s6 m3">
                                    <DatePicker
                                        selected={formData.fecha_lectura}
                                        onChange={date => handleDateChange('fecha_lectura', date)}
                                        name="fecha_lectura"
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="dd/mm/yyyy"
                                        disabled={mode === 'view'}
                                    />
                                    <label className="active" htmlFor="fecha_lectura">Lectura:</label>
                                </div>
                                <div className="input-field col s6 m2">
                                    <input
                                        type="text"
                                        name="estado"
                                        value={formData.estado || ''}
                                        disabled={true}
                                    />
                                    <label htmlFor="estado" className="active">Estado</label>
                                </div>
                            </div>

                            <div className="row">

                                <div className="input-field col s6 m6">
                                    <input
                                        type="text"
                                        name="nota"
                                        value={formData.nota || ''}
                                        onChange={handleInputChange}
                                        disabled={!isEditing}
                                    />
                                    <label htmlFor="nota" className="active">Nota</label>
                                </div>
                            </div>

                            <div className="row">
                                {/* Textarea para pegar los valores de Excel */}
                                <textarea
                                    value={pastedValues}
                                    onChange={handlePaste}
                                    placeholder="Pega aquí la columna de valores de lecturas desde Excel"
                                    rows="10"
                                    style={{ width: '100%', marginBottom: '20px' }}
                                ></textarea>
                            </div>

                            <div className="row">
                                <div className="input-field col s12">
                                    <h5>Carga fotos de Contometros</h5>
                                    {isEditing && <input type="file" multiple accept="image/*" onChange={handleFileUpload} />}
                                    {uploadStatus && <p dangerouslySetInnerHTML={{ __html: uploadStatus }}></p>}
                                    
                                </div>
                            </div>

                            <h2>Unidades de Facturación</h2>
                            <input
                                type="text"
                                placeholder="Filtrar por Unidad de Facturación"
                                onChange={(e) => setFilterText(e.target.value)}
                                style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
                            />
                            <div style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '400px', maxWidth: '100%' }}>
                                <table className="striped">
                                    <thead>
                                        <tr>
                                            <th>Unid.Fact.</th>
                                            <th>Registro</th>
                                            <th>Lectura Actual (m³)</th>
                                            <th>Nota</th>
                                            <th>Foto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.lecturas
                                            .filter(lectura => lectura.codigo_unidad_facturacion.includes(filterText))
                                            .map((lectura_unidad_facturacion, index) => (
                                                <tr key={lectura_unidad_facturacion.codigo_unidad_facturacion}>
                                                    <td>{lectura_unidad_facturacion.codigo_unidad_facturacion}</td>
                                                    <td>{lectura_unidad_facturacion.fecha_registro_formateada}</td>
                                                    <td>
                                                        <Cleave
                                                            placeholder="0.00"
                                                            options={{
                                                                numeral: true,
                                                                numeralDecimalMark: '.',
                                                                numeralDecimalScale: 2,
                                                                numeralThousandsGroupStyle: 'thousand'
                                                            }}
                                                            value={lectura_unidad_facturacion?.lectura || ''}
                                                            onChange={(e) => handleLecturaChange(e.target.value, index)}
                                                            required
                                                            disabled={!isEditing}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            value={lectura_unidad_facturacion?.nota || ''}
                                                            onChange={(e) => handleNotaChange(e.target.value, index)}
                                                            disabled={!isEditing}
                                                        />
                                                    </td>
                                                    <td>
                                                        {lectura_unidad_facturacion?.foto ? (
                                                            <button onClick={() => {
                                                                setSelectedImage(lectura_unidad_facturacion.foto);
                                                                setShowModal(true);
                                                            }}>
                                                                Ver Foto
                                                            </button>
                                                        ) : (
                                                            <button disabled>
                                                                Ver Foto
                                                            </button>
                                                        )}
                                                        <input type="file" accept="image/*" onChange={(e) => {
                                                            const file = e.target.files[0];
                                                            if (file) {
                                                                handleFileChange(file, index);
                                                            }
                                                        }} disabled={!isEditing} />
                                                        {uploadProgress[index] > 0 && (
                                                            <div>
                                                                <progress value={uploadProgress[index]} max="100" />
                                                                <span>{uploadProgress[index].toFixed(2)}%</span>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </form>
                        {/* Modal para mostrar la imagen */}
                        <Modal
                            isOpen={showModal}
                            onRequestClose={() => setShowModal(false)} // Cerrar modal al hacer clic fuera
                            style={{
                                overlay: {
                                    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Fondo semitransparente
                                },
                                content: {
                                    display: 'flex',
                                    flexDirection: 'column', // Coloca los elementos en columna
                                    alignItems: 'center',
                                    border: 'none',
                                    background: 'transparent',
                                    overflow: 'hidden',
                                    padding: 0, // Sin padding
                                },
                            }}
                        >
                            {/* Contenedor para la imagen */}
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '80%', // Para cubrir el área del modal
                                    width: '100%', // Para cubrir el área del modal
                                    cursor: 'default' // Mantener el cursor por defecto
                                }}
                                onClick={() => setShowModal(false)} // Cerrar el modal al hacer clic en el fondo
                            >
                                <img
                                    src={selectedImage}
                                    alt="Contador"
                                    style={{ maxWidth: '60%', maxHeight: '80%', borderRadius: '8px', cursor: 'pointer' }} // Cambiar cursor a mano solo en la imagen
                                />
                            </div>
                            {/* Botón para cerrar el modal */}
                            <button
                                onClick={() => setShowModal(false)}
                                style={{ marginTop: '10px', alignSelf: 'center' }} // Alinear al centro
                            >
                                Cerrar
                            </button>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Lectura_aguaForm;
