import { createSlice } from '@reduxjs/toolkit';
import { 
  get_plantilla_activo, 
  update_plantilla_activo, 
  save_plantilla_activo, 
  anular_plantilla_activo, 
  get_plantillas_activo_x_edificio 
} from './plantilla_activoThunk';

const initialState = {
  plantilla_activo: null,
  plantillas_activo: [],
  plantilla_activoSeleccionada: null,
  status: 'idle',
  error: null,
  status_get_plantillas_activo_x_edificio: 'idle',
  error_get_plantillas_activo_x_edificio: null,
  status_update_plantilla_activo: 'idle',
  error_update_plantilla_activo: null,    
  status_save_plantilla_activo: 'idle',
  error_save_plantilla_activo: null,
  status_anular_plantilla_activo: 'idle',
  error_anular_plantilla_activo: null,
};

const plantilla_activoSlice = createSlice({
  name: 'plantilla_activo',
  initialState,
  reducers: {
    setPlantilla_activoSeleccionada: (state, action) => {
      state.plantilla_activoSeleccionada = action.payload;
    },
    clearPlantilla_activoSeleccionada: (state) => {
      state.plantilla_activoSeleccionada = null;
      state.plantilla_activo = null;
    },
    resetStateGetPlantillas_activo_x_edificio: (state) => {
      state.status_get_plantillas_activo_x_edificio = 'idle';
      state.error_get_plantillas_activo_x_edificio = null;
      state.plantillas_activo = []; // 🔥 Ahora también resetea la lista
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_plantilla_activo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_plantilla_activo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.plantilla_activo = action.payload;
      })
      .addCase(get_plantilla_activo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Error al obtener plantilla activo';
      })
      
      .addCase(update_plantilla_activo.pending, (state) => {
        state.status_update_plantilla_activo = 'loading';
      })
      .addCase(update_plantilla_activo.fulfilled, (state, action) => {
        state.status_update_plantilla_activo = 'succeeded';        
        state.plantilla_activo = action.payload.plantilla_activo;
      })
      .addCase(update_plantilla_activo.rejected, (state, action) => {
        state.status_update_plantilla_activo = 'failed';
        state.error_update_plantilla_activo = action.payload || 'Error al actualizar plantilla activo';
      })
      
      .addCase(save_plantilla_activo.pending, (state) => {
        state.status_save_plantilla_activo = 'loading';
      })
      .addCase(save_plantilla_activo.fulfilled, (state, action) => {
        state.status_save_plantilla_activo = 'succeeded';
        state.plantilla_activo = action.payload.plantilla_activo;
      })
      .addCase(save_plantilla_activo.rejected, (state, action) => {
        state.status_save_plantilla_activo = 'failed';
        state.error_save_plantilla_activo = action.payload || 'Error al guardar plantilla activo';
      })
      
      .addCase(anular_plantilla_activo.pending, (state) => {
        state.status_anular_plantilla_activo = 'loading'; // 🔥 Corrección aquí
      })
      .addCase(anular_plantilla_activo.fulfilled, (state, action) => {
        state.status_anular_plantilla_activo = 'succeeded';
        state.plantilla_activo = action.payload.plantilla_activo;
      })
      .addCase(anular_plantilla_activo.rejected, (state, action) => {
        state.status_anular_plantilla_activo = 'failed';
        state.error_anular_plantilla_activo = action.payload || 'Error al anular plantilla activo';
      })
      
      .addCase(get_plantillas_activo_x_edificio.pending, (state) => {
        state.status_get_plantillas_activo_x_edificio = 'loading';
      })
      .addCase(get_plantillas_activo_x_edificio.fulfilled, (state, action) => {
        state.status_get_plantillas_activo_x_edificio = 'succeeded';
        state.plantillas_activo = action.payload;
      })
      .addCase(get_plantillas_activo_x_edificio.rejected, (state, action) => {
        state.status_get_plantillas_activo_x_edificio = 'failed';
        state.error_get_plantillas_activo_x_edificio = action.payload || 'Error al obtener plantillas de activo';
        state.plantillas_activo = []; // 🔥 Ahora limpia la lista en caso de error
      });
  },
});

// 🔥 Exportación de acciones y selectores corregidos
export const { 
  setPlantilla_activoSeleccionada, 
  clearPlantilla_activoSeleccionada, 
  resetStateGetPlantillas_activo_x_edificio 
} = plantilla_activoSlice.actions;

export const selectPlantillas_activo_ById = (state, idActivo) => 
  state.plantilla_activo?.plantillas_activo?.find((plantilla_activo) => plantilla_activo.id === idActivo) || null;

export default plantilla_activoSlice.reducer;
