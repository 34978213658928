import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js';

import {
    get_unidad_facturacion,
    update_unidad_facturacion,
    save_unidad_facturacion,
} from './unidad_facturacionThunk';

// Importamos los modales
import BuscarPersonaModal from './BuscarPersonaModal';
import BuscarUnidadInmobiliariaModal from './BuscarUnidadInmobiliariaModal';

const Unidad_facturacionForm = ({ mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: unidadFacturacionId } = useParams();

    // Estado para controlar el modo edición (true si es 'edit' o 'create')
    const [isEditing, setIsEditing] = useState(mode !== 'view');

    // Estado para controlar la apertura de los modales
    const [showModalPersona, setShowModalPersona] = useState(false);
    const [showModalUnidad, setShowModalUnidad] = useState(false);

    // Obtenemos la unidad de facturación desde Redux
    const unidadFacturacionRedux = useSelector(
        (state) => state.unidad_facturacion.unidad_facturacion
    );
    const fetchStatus = useSelector((state) => state.unidad_facturacion.fetchStatus);

    // Obtenemos el edificio para disponer de buildingId
    const edificio = useSelector((state) => state.edificio.edificio);
    const edificioId = edificio?.id;

    // Estado local para el formulario principal
    const [formData, setFormData] = useState({
        codigo_unidad_facturacion: '',
        area_ocupada_total: '',
        porcentaje_participacion: '',
        porcentaje_participacion_8dec: '',
        estado: '',
        orden: '',
    });

    // Arrays para almacenar las relaciones: propietarios y propiedades
    const [propietarios, setPropietarios] = useState([]);
    const [propiedades, setPropiedades] = useState([]);

    // Inicializamos Materialize al montar el componente
    useEffect(() => {
        M.AutoInit();
    }, []);

    // Cargar la unidad de facturación (cuando no estemos creando)
    useEffect(() => {
        if (mode !== 'create' && unidadFacturacionId) {
            dispatch(get_unidad_facturacion(unidadFacturacionId));
        }
    }, [dispatch, unidadFacturacionId, mode]);

    // Cuando llega la data desde Redux, rellenamos los estados locales
    useEffect(() => {
        if (unidadFacturacionRedux && (mode === 'view' || mode === 'edit')) {
            setFormData({
                codigo_unidad_facturacion:
                    unidadFacturacionRedux.codigo_unidad_facturacion || '',
                area_ocupada_total: unidadFacturacionRedux.area_ocupada_total || '',
                porcentaje_participacion:
                    unidadFacturacionRedux.porcentaje_participacion || '',
                porcentaje_participacion_8dec:
                    unidadFacturacionRedux.porcentaje_participacion_8dec || '',
                estado: unidadFacturacionRedux.estado || '',
                orden: unidadFacturacionRedux.orden || '',
            });
            setPropietarios(unidadFacturacionRedux.propietarios || []);
            setPropiedades(unidadFacturacionRedux.propiedades || []);
            setIsEditing(mode === 'edit');
        }
    }, [unidadFacturacionRedux, mode]);

    // Actualizar Materialize para inputs y selects
    useEffect(() => {
        M.updateTextFields();
        M.FormSelect.init(document.querySelectorAll('select'));
    }, [formData, isEditing, propietarios, propiedades]);

    // Manejo de cambios en los campos principales
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Guardar (crear o actualizar)
    const handleSave = async () => {
        const finalData = {
            ...formData,
            propietarios,
            propiedades,
        };

        if (mode === 'create') {
            await dispatch(save_unidad_facturacion(finalData));
            setIsEditing(false);
        } else {
            await dispatch(
                update_unidad_facturacion({
                    idUnidad_facturacion: unidadFacturacionId,
                    unidad_facturacionData: finalData,
                })
            );
            dispatch(get_unidad_facturacion(unidadFacturacionId));
            setIsEditing(false);
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        navigate(-1);
    };

    // ================== Manejo de Propietarios ==================
    const handleAddPropietario = () => {
        if (!isEditing) return;
        setShowModalPersona(true); // Abre el modal de búsqueda de persona
    };

    // Callback que recibe la persona encontrada en el modal
    const handleOnAddPropietario = (personaEncontrada) => {
        // Validar duplicados: evitar agregar el mismo DNI si ya existe
        const existe = propietarios.some(
            (prop) =>
                prop.tipo_documento === personaEncontrada.tipo_documento &&
                prop.numero_documento === personaEncontrada.numero_documento
        );
        if (!existe) {
            setPropietarios((prev) => [...prev, personaEncontrada]);
        } else {
            M.toast({ html: 'Ya existe un propietario con este DNI.' });
        }
    };

    const handleRemovePropietario = (index) => {
        if (!isEditing) return;
        setPropietarios((prev) => prev.filter((_, i) => i !== index));
    };

    // ================== Manejo de Propiedades ==================
    const handleAddPropiedad = () => {
        if (!isEditing) return;
        setShowModalUnidad(true); // Abre el modal de búsqueda de unidad inmobiliaria
    };

    // Callback que recibe la unidad inmobiliaria encontrada
    const handleOnAddPropiedad = (unidadEncontrada) => {
        // Validar duplicados: evitar agregar la misma unidad si ya existe
        const duplicado = propiedades.some(
            (p) =>
                p.tipo_unidad_inmobiliaria === unidadEncontrada.tipo_unidad_inmobiliaria &&
                p.numero_unidad_inmobiliaria === unidadEncontrada.numero_unidad_inmobiliaria
        );
        if (duplicado) {
            M.toast({ html: 'Esa unidad ya existe en la tabla.' });
            return;
        }
        setPropiedades((prev) => [...prev, unidadEncontrada]);
    };

    const handleRemovePropiedad = (index) => {
        if (!isEditing) return;
        setPropiedades((prev) => prev.filter((_, i) => i !== index));
    };

    if (fetchStatus === 'loading') {
        return <div>Cargando datos...</div>;
    }

    return (
        <div className="container">
            {/* Modal para buscar propietario */}
            <BuscarPersonaModal
                isOpen={showModalPersona}
                onClose={() => setShowModalPersona(false)}
                onAddPropietario={handleOnAddPropietario}
            />
            {/* Modal para buscar unidad inmobiliaria */}
            <BuscarUnidadInmobiliariaModal
                isOpen={showModalUnidad}
                onClose={() => setShowModalUnidad(false)}
                onAddPropiedad={handleOnAddPropiedad}
                buildingId={edificioId} // Se obtiene desde el slice de edificio
            />
            {/* Acciones */}
            <div className="card-action" style={{ marginBottom: '1rem' }}>
                {isEditing ? (
                    <>
                        <button
                            className="btn green"
                            onClick={handleSave}
                            style={{ marginRight: '1rem' }}
                        >
                            <i className="material-icons left">save</i>Guardar
                        </button>
                        <button
                            className="btn red"
                            onClick={handleCancel}
                            style={{ marginRight: '1rem' }}
                        >
                            <i className="material-icons left">cancel</i>Cancelar
                        </button>
                    </>
                ) : (
                    mode !== 'create' && (
                        <button
                            className="btn green"
                            onClick={handleEdit}
                            style={{ marginRight: '1rem' }}
                        >
                            <i className="material-icons left">edit</i>Editar
                        </button>
                    )
                )}
                <button className="btn blue" onClick={handleCancel}>
                    <i className="material-icons left">arrow_back</i>Volver
                </button>
            </div>

            <h4>
                {mode === 'create'
                    ? 'Registrar Unidad de Facturación'
                    : isEditing
                        ? 'Editar Unidad de Facturación'
                        : 'Detalle de Unidad de Facturación'}
            </h4>

            {/* Formulario principal */}
            <div className="row">
                <div className="input-field col s12 m4">
                    <input
                        type="text"
                        name="codigo_unidad_facturacion"
                        value={formData.codigo_unidad_facturacion}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    />
                    <label
                        className={formData.codigo_unidad_facturacion ? 'active' : ''}
                    >
                        Cód. Unidad Facturación
                    </label>
                </div>

                <div className="input-field col s12 m4">
                    <input
                        type="number"
                        step="any"
                        name="area_ocupada_total"
                        value={formData.area_ocupada_total}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    />
                    <label className={formData.area_ocupada_total ? 'active' : ''}>
                        Área Ocupada Total
                    </label>
                </div>

                <div className="input-field col s12 m4">
                    <input
                        type="number"
                        step="any"
                        name="porcentaje_participacion"
                        value={formData.porcentaje_participacion}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    />
                    <label
                        className={formData.porcentaje_participacion ? 'active' : ''}
                    >
                        % Participación
                    </label>
                </div>
            </div>

            <div className="row">
                <div className="input-field col s12 m4">
                    <input
                        type="number"
                        step="any"
                        name="porcentaje_participacion_8dec"
                        value={formData.porcentaje_participacion_8dec}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    />
                    <label
                        className={
                            formData.porcentaje_participacion_8dec ? 'active' : ''
                        }
                    >
                        % Part. (8 decimales)
                    </label>
                </div>

                <div className="input-field col s12 m4">
                    <input
                        type="number"
                        name="orden"
                        value={formData.orden}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    />
                    <label className={formData.orden ? 'active' : ''}>Orden</label>
                </div>

                <div className="input-field col s12 m4">
                    <select
                        name="estado"
                        value={formData.estado}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    >
                        <option value="" disabled>
                            Seleccionar
                        </option>
                        <option value="ACTIVO">ACTIVO</option>
                        <option value="INACTIVO">INACTIVO</option>
                    </select>
                    <label>Estado</label>
                </div>
            </div>

            {/* Tabla de propietarios */}
            <h5>Propietarios</h5>
            {isEditing && (
                <button className="btn" onClick={handleAddPropietario}>
                    <i className="material-icons left">person_add</i>Agregar Propietario
                </button>
            )}
            <div style={{ marginTop: '1rem', overflowX: 'auto' }}>
                <table className="striped responsive-table">
                    <thead>
                        <tr>
                            <th>Tipo Documento</th>
                            <th>Número Documento</th>
                            <th>Nombres</th>
                            {isEditing && <th>Eliminar Relación</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {propietarios.map((prop, i) => (
                            <tr key={i}>
                                <td>{prop.tipo_documento}</td>
                                <td>{prop.numero_documento}</td>
                                <td>{prop.nombres}</td>
                                {isEditing && (
                                    <td>
                                        <button
                                            className="btn red"
                                            onClick={() => handleRemovePropietario(i)}
                                        >
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Tabla de propiedades */}
            <h5 style={{ marginTop: '2rem' }}>Unidades Inmobiliarias</h5>
            {isEditing && (
                <button className="btn" onClick={handleAddPropiedad}>
                    <i className="material-icons left">add</i>Agregar Propiedad
                </button>
            )}
            <div style={{ marginTop: '1rem', overflowX: 'auto' }}>
                <table className="striped responsive-table">
                    <thead>
                        <tr>
                            <th>Tipo Unidad Inmob.</th>
                            <th>Nro. Unidad Inmob.</th>
                            <th>Área Ocupada</th>
                            {isEditing && <th>Eliminar Relación</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {propiedades.map((prop, i) => (
                            <tr key={i}>
                                <td>{prop.tipo_unidad_inmobiliaria}</td>
                                <td>{prop.numero_unidad_inmobiliaria}</td>
                                <td>{prop.area_ocupada}</td>
                                {isEditing && (
                                    <td>
                                        <button
                                            className="btn red"
                                            onClick={() => handleRemovePropiedad(i)}
                                        >
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Unidad_facturacionForm;
