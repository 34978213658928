import { createSlice } from '@reduxjs/toolkit';
import { get_movimiento_cuenta, get_movimientos_cuenta} from './movimiento_cuentaThunk';

const initialState= {
  movimiento_cuenta: null,
  movimientos_cuenta: [],
  movimiento_cuentaSeleccionada: null,
  status: 'idle',
  error: null,
  status_get_movimiento_cuenta: 'idle',
  error_get_movimiento_cuenta: null,    
  status_get_movimientos_cuenta: 'idle',
  error_get_movimientos_cuenta: null,
}

const movimiento_cuentaSlice = createSlice({
  name: 'movimiento_cuenta',
  initialState,
  reducers: {
    setMovimiento_bancoSeleccionada: (state, action) => {
      state.movimiento_cuentaSeleccionada = action.payload;
      
    },
    clearMovimiento_bancoSeleccionada(state) {
      state.movimiento_cuentaSeleccionada = null;
      state.movimiento_cuenta = null;
    },    
    resetState: () => initialState, // 🔥 Reiniciar todo el estado al estado inicial
    clearMovimiento_bancos: (state) => {
      state.movimientos_cuenta = []; // Limpiar movimiento_cuentas antes de una nueva consulta
      state.status_get_movimientos_cuenta = 'idle';
      state.error_get_movimientos_cuenta = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_movimiento_cuenta.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_movimiento_cuenta.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.movimiento_cuenta = action.payload;

      })
      .addCase(get_movimiento_cuenta.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })
      
      .addCase(get_movimientos_cuenta.pending, (state) => {
        state.status_get_movimientos_cuenta = 'loading';
        
      })
      .addCase(get_movimientos_cuenta.fulfilled, (state, action) => {
        state.status_get_movimientos_cuenta = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.movimientos_cuenta = action.payload;

      })
      .addCase(get_movimientos_cuenta.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_movimientos_cuenta = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_movimientos_cuenta = action.payload || 'Failed to fetch building';
      })      


  },
});

export const { setMovimiento_bancoSeleccionada, clearMovimiento_bancoSeleccionada, clearMovimiento_bancos } = movimiento_cuentaSlice.actions;
export const selectMovimiento_bancosById = (state, idMovimiento_banco) =>
    state.movimiento_cuenta.movimiento_cuentas.find((movimiento_cuenta) => movimiento_cuenta.id === idMovimiento_banco);
export const { resetState } = movimiento_cuentaSlice.actions;
export default movimiento_cuentaSlice.reducer;
