import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './MovimientosBancariosPage.css';
import { get_movimientos_cuenta } from './movimiento_cuentaThunk';
import { get_cuentas_banco } from './cuenta_bancoThunk';
import { format } from 'date-fns';
import { formatDate, formatCurrency, formatPeriod, formatDate02, formatearPeriodo_MesAnio01, formatearImporte01 } from '../../utils/formateo';


const MovimientosBancarios = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const cuentas = useSelector((state) => state.cuenta_banco.cuentas_banco);
    const movimientos = useSelector((state) => state.movimiento_cuenta.movimientos_cuenta);
    const status = useSelector((state) => state.movimiento_cuenta.status);
    const edificioSeleccionado = useSelector((state) => state.edificio.edificio);
    const buildingId = edificioSeleccionado.id; // ID del edificio desde la URL
    const [filtro, setFiltro] = useState('');

    const [cuentaSeleccionada, setCuentaSeleccionada] = useState('');
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);
    const [descripcion, setDescripcion] = useState('');
    const [tipoMovimiento, setTipoMovimiento] = useState('TODOS');

    useEffect(() => {
        dispatch(get_cuentas_banco(buildingId));
    }, [dispatch, buildingId]);

    useEffect(() => {
        if (cuentas.length > 0) {
            setCuentaSeleccionada(cuentas[0].id);
        }
    }, [cuentas]);

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [cuentaSeleccionada, tipoMovimiento]);

    const handleBuscar = () => {

        const formattedFechaInicio = fechaInicio ? format(fechaInicio, 'dd-MM-yyyy') : null;
        const formattedFechaFin = fechaFin ? format(fechaFin, 'dd-MM-yyyy') : null;
        console.log(" cuentaSeleccionada " + JSON.stringify(cuentaSeleccionada))

        dispatch(get_movimientos_cuenta({
            buildingId: buildingId,
            cuenta_bancoId: cuentaSeleccionada,
            fecha_inicio: formattedFechaInicio,
            fecha_fin: formattedFechaFin,
            descripcion,
            tipo_movimiento: tipoMovimiento,
        })
        );
    };
    const handleFilterChange = (e) => {
        setFiltro(e.target.value);
    };
    const movimientosFiltrados = Array.isArray(movimientos) ? movimientos.filter((mov) => {
        const montoFormateado = formatearImporte01(mov.monto) || '';

        const filterLowerCase = filtro.toLowerCase();

        return (
            (mov.fecha_operacion || '').toLowerCase().includes(filterLowerCase) ||
            (mov.hora_operacion || '').toLowerCase().includes(filterLowerCase) ||
            (mov.descripcion || '').toLowerCase().includes(filterLowerCase) ||
            (mov.tipo_movimiento || '').toLowerCase().includes(filterLowerCase) ||
            (mov.numero_operacion || '').toLowerCase().includes(filterLowerCase) ||
            (mov.medio_atencion || '').toLowerCase().includes(filterLowerCase) ||
            (mov.banco || '').toLowerCase().includes(filterLowerCase) ||
            (mov.numero_cuenta || '').toLowerCase().includes(filterLowerCase) ||
            (montoFormateado.toLowerCase().includes(filterLowerCase))

        );
    }) : [];

    console.log("Movimientos obtenidos:", movimientos);
    return (
        <div className="container">
            <div className="card">
                <div className="card-action">
                    <button className="btn orange" onClick={() => navigate('/movimientos-crep')}>CREP</button>
                </div>
                <div className="card-content">
                    <span className="card-title">Movimientos Bancarios</span>
                    <div className="row">
                        <div className="input-field col s12 m4">
                            <select value={cuentaSeleccionada} onChange={(e) => setCuentaSeleccionada(e.target.value)}>
                                {cuentas.map((cuenta) => (
                                    <option key={cuenta.id} value={cuenta.id}>
                                        {cuenta.banco} - {cuenta.numero_cuenta}
                                    </option>
                                ))}
                            </select>
                            <label>Cuentas Bancarias</label>
                        </div>
                        <div className="input-field col s6 m2">
                            <DatePicker selected={fechaInicio} onChange={setFechaInicio} dateFormat="dd-MM-yyyy" placeholderText="Fecha Inicio" />
                        </div>
                        <div className="input-field col s6 m2">
                            <DatePicker selected={fechaFin} onChange={setFechaFin} dateFormat="dd-MM-yyyy" placeholderText="Fecha Fin" />
                        </div>
                        <div className="input-field col s6 m2">
                            <select value={tipoMovimiento} onChange={(e) => setTipoMovimiento(e.target.value)}>
                                <option value="TODOS">TODOS</option>
                                <option value="INGRESO">INGRESO</option>
                                <option value="EGRESO">EGRESO</option>
                            </select>
                            <label>Tipo de Movimiento</label>
                        </div>
                        <div className="input-field col s6 m2">
                            <input type="text" value={descripcion} onChange={(e) => setDescripcion(e.target.value)} placeholder="Descripción" />
                        </div>
                        <div className="col s12 m4">
                            <button className="btn blue" onClick={handleBuscar}>Buscar</button>
                        </div>
                    </div>
                    <div className="col s12 m12 input-field">
                        <label htmlFor="filtro_tabla_movimientos_cuenta"><i className="material-icons">search</i> Filtrar</label>
                        <input
                            type="text"
                            id="filtro_tabla_movimientos_cuenta"
                            value={filtro}
                            onChange={handleFilterChange}
                        />
                    </div>
                    <div className="table-container">
                        {status === 'loading' ? <div>Cargando...</div> : (
                            <table className="striped responsive-table tabla_movimientos_cuenta">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Hora</th>
                                        <th>Descripción</th>
                                        <th>Monto</th>
                                        <th>Saldo</th>
                                        <th>Tipo</th>
                                        <th>Oper.</th>
                                        <th>Medio</th>
                                        <th>Banco</th>
                                        <th>Cuenta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {movimientosFiltrados.length > 0 ? (
                                        movimientosFiltrados.map((mov) => (
                                            <tr key={mov.id}>
                                                <td>{mov.fecha_operacion}</td>
                                                <td>{mov.hora_operacion}</td>
                                                <td>{mov.descripcion}</td>
                                                <td>{mov.monto}</td>
                                                <td>{mov.saldo}</td>
                                                <td>{mov.tipo_movimiento}</td>
                                                <td>{mov.numero_operacion}</td>
                                                <td>{mov.medio_atencion}</td>
                                                <td>{mov.banco}</td>
                                                <td>{mov.numero_cuenta}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="10" style={{ textAlign: 'center' }}>No se encontraron movimientos.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default MovimientosBancarios;
