import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetCargas_crep } from './carga_crepSlice';



export const update_carga_crep = createAsyncThunk('carga_crep/update_carga_crep', async ({ idCarga_crep, carga_crepData }, thunkAPI) => {
  //console.log("ejecutando thunk update_carga_crep con idCarga_crep: " + idCarga_crep);

  try {

    const response = await apiClient.put(`/cargas_crep/${idCarga_crep}`, carga_crepData);

    const carga_crep = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetCargas_crep(carga_crep.buildingId));// Resetear el estado para refetch de cargas_crep

    return carga_crep;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const carga_crep_masivo = createAsyncThunk('carga_crep/carga_crep_masivo', async (carga_crepData, thunkAPI) => {

    try {
  
      const response = await apiClient.post(`/cargas_crep/carga_crep_masivo`, carga_crepData);
  
      const carga_crep = response.data;
  
      // Despachar acción para resetear el estado de fetching
      thunkAPI.dispatch(resetStateGetCargas_crep(carga_crep.buildingId));// Resetear el estado para refetch de cargas_crep
  
      return carga_crep;
  
    } catch (error) {
      console.log("error.response.data " + error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
export const save_carga_crep = createAsyncThunk('carga_crep/save_carga_crep', async (carga_crepData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/cargas_crep`, carga_crepData);

    const carga_crep = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetCargas_crep(carga_crep.buildingId));// Resetear el estado para refetch de cargas_crep

    return carga_crep;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_carga_crep = createAsyncThunk('carga_crep/anular_carga_crep', async ({ idCarga_crep, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_carga_crep con idCarga_crep: " + idCarga_crep);

  try {

    const response = await apiClient.patch(`/cargas_crep/${idCarga_crep}/anular`, { usuarioAnulacion });

    const carga_crep = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetCargas_crep(carga_crep.buildingId));// Resetear el estado para refetch de cargas_crep

    return carga_crep;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_carga_crep = createAsyncThunk('carga_crep/get_carga_crep', async (id, thunkAPI) => {
  console.log("ejecutando thunk get_carga_crep con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/cargas_crep/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const carga_crep = response.data;

    return carga_crep;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_cargas_crep = createAsyncThunk('carga_crep/get_cargas_crep', async (codigo_unidad_facturacion, thunkAPI) => {
  console.log("ejecutando thunk get_cargas_crep con codigo_unidad_facturacion: "+codigo_unidad_facturacion);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/cargas_crep',{
        params: {codigo_unidad_facturacion}
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const cargas_crep = response.data;
          
      return cargas_crep ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const get_cargas_crep_admin = createAsyncThunk('carga_crep/get_cargas_crep_admin', async ({ tipo_area_comun, fecha_inicio, fecha_fin, estado_pago, estado, codigo_unidad_facturacion }, thunkAPI) => {
  console.log("ejecutando thunk get_cargas_crep_admin con codigo_unidad_facturacion: "+codigo_unidad_facturacion);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/cargas_crep/admin',{
        params: {
          tipo_area_comun,
          fecha_inicio,
          fecha_fin,
          estado_pago,
          estado,
          codigo_unidad_facturacion,
        },
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const cargas_crep = response.data;
          
      return cargas_crep ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
