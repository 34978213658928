import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get_recibos_x_planilla } from '../mis_recibos/recibosThunk';
import {
  formatDate,
  formatCurrency,
  formatPeriod,
  formatDate02,
  formatearPeriodo_MesAnio01,
  formatearImporte01
} from '../../utils/formateo';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import useMaterializeTooltips from '../../utils/useMaterializeTooltips';

const RecibosPage = () => {
  const params = useParams();
  useMaterializeTooltips();

  const planilla = useSelector((state) => state.planilla.planilla) || {};
  const idPlanilla = params.idPlanilla || params.id;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    recibos = [],
    fetchStatus_get_recibos_x_planilla,
    error_get_recibos_x_planilla,
  } = useSelector((state) => state.recibos);

  // Estado para el filtro de búsqueda
  const [filtro, setFiltro] = useState('');

  useEffect(() => {
    if (idPlanilla) {
      dispatch(get_recibos_x_planilla(idPlanilla));
    }
  }, [dispatch, idPlanilla]);

  const handleRetornar = () => {
    navigate('/planillas-recibos');
  };

  const handleFilterChange = (e) => {
    setFiltro(e.target.value);
  };

  const handleExportExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Recibos');

    worksheet.columns = [
      { header: 'Unidad de Facturación', key: 'unidad_facturacion', width: 20 },
      { header: 'Porcentaje Participación', key: 'porcentaje', width: 20 },
      ...conceptosUnicos.map((concepto) => ({ header: concepto, key: concepto, width: 20 })),
      { header: 'Total_mes', key: 'total_mes', width: 20 },
      { header: 'Suma', key: 'suma', width: 20 },
      { header: 'Propietario', key: 'nombres_propietarios', width: 40 }
      
    ];

    recibosFiltrados.forEach((recibo) => {
      const row = {
        unidad_facturacion: recibo.codigo_unidad_facturacion,
        porcentaje: recibo.porcentaje_participacion,
        //suma: formatearImporte01(recibo.conceptos?.reduce((sum, c) => sum + c.importe, 0) || 0)
        total_mes: recibo.total_mes || 0,
        suma: recibo.conceptos?.reduce((sum, c) => sum + c.importe, 0) || 0,
        nombres_propietarios: recibo.nombres_propietarios
      };

      conceptosUnicos.forEach((concepto) => {
        const foundConcepto = recibo.conceptos?.find((c) => c.nombre_concepto === concepto);
        //row[concepto] = foundConcepto ? formatearImporte01(foundConcepto.importe) : '';
        row[concepto] = foundConcepto ? foundConcepto.importe : 0;
      });

      worksheet.addRow(row);
    });

    worksheet.getRow(1).font = { bold: true };

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `Recibos_${formatearPeriodo_MesAnio01(planilla.periodo)}.xlsx`);
  };


  // Obtener todos los conceptos únicos en los recibos
  /*
  const conceptosUnicos = Array.from(new Set(
    recibos.flatMap((recibo) => recibo.conceptos?.map((c) => c.nombre_concepto) || [])
  ));
  */
  const conceptosUnicos = Array.from(new Set(
    (recibos || []).flatMap((recibo) => (recibo.conceptos ? recibo.conceptos.map((c) => c.nombre_concepto) : []))
  ));

  console.log("📌 Recibos recibidos:", recibos);
  console.log("📌 conceptosUnicos antes de renderizar:", conceptosUnicos);

  // Filtrar los recibos sin alterar la estructura de columnas y filas
  const recibosFiltrados = recibos.filter((recibo) => {
    const filterLower = filtro.toLowerCase();
    const codigoUnidad = (recibo.codigo_unidad_facturacion || '').toLowerCase();
    const porcentaje = (recibo.porcentaje_participacion ? recibo.porcentaje_participacion.toString() : '').toLowerCase();
    return (
      codigoUnidad.includes(filterLower) ||
      porcentaje.includes(filterLower)
    );
  });

  const formatNumber = (number) => {
    return new Intl.NumberFormat('es-PE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  if (fetchStatus_get_recibos_x_planilla === 'loading') {
    return <div>Cargando recibos...</div>;
  }

  if (fetchStatus_get_recibos_x_planilla === 'failed') {
    return <div>Error: {error_get_recibos_x_planilla}</div>;
  }

  return (
    <div className="container">
      <h2>
        Recibos - Periodo: {planilla.periodo ? formatearPeriodo_MesAnio01(planilla.periodo) : ''}
      </h2>
      <div className="card-action">
        <a
          className="btn blue tooltipped"
          data-position="top"
          data-tooltip="Volver"
          onClick={handleRetornar}
        >
          <i className="material-icons left">arrow_back</i>
        </a>
        <button className="btn green" onClick={handleExportExcel}>
          <i className="material-icons left">file_download</i> Descargar Excel
        </button>
      </div>

      <div className="col s12 m12 input-field" style={{ maxWidth: '400px', margin: '0 auto' }}>
        <label htmlFor="filtro_recibos">
          <i className="material-icons">search</i> Filtrar
        </label>
        <input
          type="text"
          id="filtro_recibos"
          value={filtro}
          onChange={handleFilterChange}
        />
      </div>

      <div style={{ width: '100%', maxHeight: '400px', overflowX: 'auto', overflowY: 'auto', margin: '0 auto' }}>
        <table className="striped responsive-table" style={{ minWidth: '800px' }}>
          <thead>
            <tr>
              <th>Unidad de Facturación</th>
              <th>Porcentaje Participación</th>
              {/*{conceptosUnicos.map((nombre_concepto) => (
                <th key={nombre_concepto}>{nombre_concepto.length > 30 ? `${nombre_concepto.slice(0, 30)}...` : nombre_concepto}</th>
              ))}*/}
              {(conceptosUnicos || []).map((nombre_concepto) => (
                <th key={nombre_concepto}>{nombre_concepto.length > 30 ? `${nombre_concepto.slice(0, 30)}...` : nombre_concepto}</th>
              ))}
              <th>Total_mes</th>
              <th>Suma</th>
            </tr>
          </thead>
          <tbody>
            {recibosFiltrados.map((recibo) => (
              <tr key={recibo.numero_recibo}>
                <td><Link to={`/recibo/${recibo.id}`}>{recibo.codigo_unidad_facturacion}</Link></td>
                <td>{recibo.porcentaje_participacion}</td>
                {conceptosUnicos.map((nombre_concepto) => {
                  const concepto = recibo.conceptos?.find((c) => c.nombre_concepto === nombre_concepto);
                  return <td key={nombre_concepto}>{concepto ? formatNumber(concepto.importe) : ''}</td>;
                })}
                <td>{formatNumber(recibo.total_mes || 0)}</td>
                <td>{formatNumber(recibo.conceptos?.reduce((sum, c) => sum + c.importe, 0) || 0)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecibosPage;
